import React, {useState, useEffect} from 'react';
import { MakeRequests } from '../../utils/request'
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';
import { useNavigate } from 'react-router-dom';

import { MotifTable,
        MotifButton,
        MotifIcon,
        MotifIconButton
    } from '@ey-xd/motif-react'

import { editorIcModeEdit24px } from '@ey-xd/motif-react/assets/icons';
import { tooltitle } from '../../utils/utils';

const ClientOverview = () => {
    const request = new MakeRequests()
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();
    const navigate = useNavigate();

    const [clientData, setClientData] = useState()

    useEffect(() => {
        const getClientsData = async () =>{
            setIsLoading(true);
            var data = await request.get('client-overview')

            setClientData(data)
            setIsLoading(false);
        }
        getClientsData()
    }, [])

    const editClient = (id) => {
        navigate('/clientoverview/addclient', { state: { clientId: id } })
    }

    const columnDef = [
        {
            headerName: ' ',
            cellRenderer: (props) => {

                return <MotifIconButton
                  type="button"
                  title={t('tablebutton', {ns: 'admin'})}
                  onClick={() => {
                    editClient(props)
                  }}>
                  <MotifIcon src={editorIcModeEdit24px} />
                </MotifIconButton>
              },
            field: 'id',
            width: 60,
            pinned: 'left',
            headerComponentParams: {
              dataColumn: false
            }
        },
        {
            headerName: t('name_client', {ns: 'admin'}),
            field: 'name',
            minWidth: 100,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
        },
        {
            headerName: t('name_admin', {ns: 'admin'}),
            field: 'admin_name',
            minWidth: 100,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1
        },
        {
            headerName: t('email_admin', {ns: 'admin'}),
            field: 'admin_email',
            minWidth: 150,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1
        },
        {
            headerName: t('language', {ns: 'admin'}),
            field: 'language.language_description',
            minWidth: 50,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
        },
        {
            headerName: t('num_users', {ns: 'admin'}),
            field: 'employee_count',
            minWidth: 100,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
        },
        {
            headerName: t('num_entities', {ns: 'admin'}),
            field: 'entity_count',
            minWidth: 100,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
        },
        // Start and end date will be added in version 2.0
        // {
        //     headerName: t('startdate', {ns: 'admin'}),
        //     field: 'start_date',
        //     minWidth: 100,
        //     resizable: true,
        //     sortable: true,
        //     filter: true,
        //     wrapText: true,
        //     autoHeight: true,
        //     flex: 1,
        // },
        // {
        //     headerName: t('enddate', {ns: 'admin'}),
        //     field: 'end_date',
        //     minWidth: 100,
        //     resizable: true,
        //     sortable: true,
        //     filter: true,
        //     wrapText: true,
        //     autoHeight: true,
        //     flex: 1,
        // },
    ];

    return (
        <>  
        <title>{t('clientoverview', {ns: 'menu'})} | {tooltitle}</title>
        <div className="d-flex justify-content-center">
            <h1>{t('clientoverview', {ns: 'menu'})}</h1>
        </div>
            <div className='row p-3'>
                <div className='col-6'>
                    <MotifButton onClick={() => navigate('/clientoverview/addclient', {state:{clientId:null}})}>
                        {t('new_client', {ns: 'admin'})}
                    </MotifButton>
                </div>
            </div>
            { !isLoading && clientData?.length > 0 &&
                <div className='row p-3'>
                    <MotifTable  className='pb-5'
                        columnDefs={columnDef}
                        rowData={clientData}
                        onRowClicked={row => { editClient(row.data.id); }}
                        onCellKeyDown={row => {
                        if (row.event.key === 'Enter') {
                            editClient(row.data.id);
                            }
                        }}
                        compact={true}
                        zebra={true}
                        pagination={clientData?.length > 30 ? true : false}
                        paginationPageSize="25"/>
                </div>
            }   
        </>
    )
}

export default ClientOverview