import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { request } from '../../utils/request';

const LoginRedirect = () => {
    
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const checkPrivacyNoticeAccepted = async () => {
        try {
            const response = await request.get('privacy-notice/');

            // Check if the response is empty and set defaults if needed
            const { accepted = false, is_superuser = false } = response[0] || {};
            
            if (accepted) {
                if (is_superuser) {
                    navigate('/clientoverview');
                } else {
                    navigate('/overview/');
                }
            } else {
                navigate('/privacy_notice/');
            }
        } catch (error) {
            console.error('Error checking privacy notice acceptance.', error);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        // Check if the expected query parameters are present
        const hasRequiredParams = queryParams.has('employee_name') && queryParams.has('username');
        
        if (hasRequiredParams) {
        // Extract the values from the query parameters
        const employeeName = queryParams.get('employee_name');
        const username = queryParams.get('username');
        const functionTitle = queryParams.get('function_title');
        const client = queryParams.get('client');
        const language = queryParams.get('language');

        i18n.changeLanguage(language.toLowerCase()); // change the language
    
        // Set the values in localStorage
        localStorage.setItem('employee_name', employeeName);
        localStorage.setItem('username', username);
        localStorage.setItem('function_title', functionTitle);
        localStorage.setItem('client', client);
        localStorage.setItem('language', language);

        checkPrivacyNoticeAccepted();

        }
    }, [navigate]);
  
    // Render nothing or a loading indicator as this page will redirect
    return null;
  };

export default LoginRedirect