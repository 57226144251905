import React from 'react';
import {
	MotifFormField,
	MotifFileUploader,
	MotifFileUploaderItem,
	MotifIcon,
} from '@ey-xd/motif-react';
import { MakeRequests } from '../utils/request';
import { sanitizeFilename } from '../utils/utils';
import { actionIcDescription24px } from '@ey-xd/motif-react/assets/icons';
import { useTranslation } from 'react-i18next';

const request = new MakeRequests();

// Helper function to create a new document
const createNewDocument = (document, blobPath, taskId) => {
	const timestamp = Date.now();
	const folderSegment = taskId ? `/${taskId}` : ''; // if the upload is for a task, upload it to the taskId directory
	const path = `${blobPath}${folderSegment}/${timestamp}-${document.name}`;

	return {
		file: document,
		filename: document.name,
		path: path,
		timestamp: timestamp,
	};
};

// Helper function to remove a document
const removeDocument = (setFileData, documentToRemove, deleteUrl) => {
	setFileData(prevFileData => prevFileData.filter(file => file.path !== documentToRemove.path));

	if (documentToRemove.id) {
		try {
			const response = request.delete(`${deleteUrl}/${documentToRemove.id}/delete_document/`);
		} catch (error) {
			console.error(error.message);
		}
	}
};

const FileUploader = ({ blobPath, taskId, fileData, setFileData, disabled, deleteUrl, label, maxFiles }) => {
	const { t } = useTranslation();

	const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'csv', 'txt', 'rtf', 'odt', 'ods', 'odp', 'jpg', 'jpeg', 'png', 'gif', 'tiff', 'bmp'];

	const allowedFileTypes = [
		'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		'text/csv', 'text/plain', 'application/rtf', 'application/vnd.oasis.opendocument.text',
		'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.oasis.opendocument.presentation',
		'image/jpeg', 'image/png', 'image/gif', 'image/tiff', 'image/bmp'
	];

	const maxFileSize = 50 * 1024 * 1024; // 50 MB

	// Regular expression for validating filenames
	const filenameValidator = /^[\u00C0-\u017F\w\s,.'\-@$€%():;&/]+$/; // equivalent to the description_validator in the backend

	const handleRemoveDocument = (document) => {
		removeDocument(setFileData, document, deleteUrl);
	};

	const uploadDocuments = async (newFiles) => {
		const validFiles = [];

		for (const newFile of newFiles) {
			const fileExtension = newFile.name.split('.').pop().toLowerCase();

			// Validate file extension
			if (!allowedExtensions.includes(fileExtension)) {
				alert(t('forbidden_extension', { ns: 'general', extension: fileExtension }));
				continue;
			}

			// Validate file type
			if (!allowedFileTypes.includes(newFile.type)) {
				alert(t('forbidden_filetype', { ns: 'general', type: newFile.type }));
				continue;
			}

			// Validate file size
			if (newFile.size > maxFileSize) {
				alert(t('file_too_large', { ns: 'general', name: newFile.name }));
				continue;
			}

			// Validate filename against regex
			if (!filenameValidator.test(newFile.name)) {
				alert(t('invalid_filename', { ns: 'general', name: newFile.name }));
				continue;
			}

			validFiles.push(newFile);
		}

		const uploadPromises = validFiles.map(async (newFile) => {
			const attachmentData = createNewDocument(newFile, blobPath, taskId);
			setFileData(fileData => [...fileData, attachmentData]);
		});

		await Promise.all(uploadPromises);
	};

	const UploadingItem = ({ document }) => {
		const sanitizedFilename = sanitizeFilename(document.filename);

		return (
			<MotifFileUploaderItem
				key={document.path}
				aria-label={`Click to remove document - ${sanitizedFilename}`}
				removable={true}
				onRemove={() => handleRemoveDocument(document)}
				fileName={sanitizedFilename}
				fileIcon={<MotifIcon src={actionIcDescription24px} />}
			>
				{sanitizedFilename}
			</MotifFileUploaderItem>
		);
	};

	return (
		<div>
			<MotifFormField style={{ marginTop: '1%' }}>
				<MotifFileUploader
					disabled={disabled}
					label={label}
					maxFiles={maxFiles}
					required={false}
					onDrop={uploadDocuments}
				>
					{!disabled ? fileData.map(document => <UploadingItem key={document.path} document={document} />) : null}
				</MotifFileUploader>
			</MotifFormField>
		</div>
	);
};

export default FileUploader;