import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    MotifBreadcrumb,
    MotifBreadcrumbItem,
    } from '@ey-xd/motif-react'

import { useTranslation } from 'react-i18next';

const BreadcrumbHeader = () => {
    const { t, i18n } = useTranslation();
    var location = useLocation().pathname;
    
    const getLocationArray = () => {
        location = location.replace(/^\//, "");
        location = location.replace(/\/$/, "");

        var array = location.split("/")

        return array
    }

    const locationArray = getLocationArray()

    return ( 
        <>
            <MotifBreadcrumb id='header-breadcrumb'>
                <MotifBreadcrumbItem >
                    <a href='/overview/'>
                        InControl Tool
                    </a>
                    
                </MotifBreadcrumbItem>
                {locationArray.map((item, index) => {
                    var itemArray = locationArray.slice(0, index+1) //Creating an array with the location for each element of the breadcrumb

                    return (
                    <MotifBreadcrumbItem> 
                        <a href={`/${itemArray.join("/")}/`}>
                            {t(item, {ns: 'menu'})}
                        </a>
                    </MotifBreadcrumbItem>
                    )   
                }
                                    
                )}
            </MotifBreadcrumb>
        </>
    )
    }        

export default BreadcrumbHeader