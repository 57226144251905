import React, { useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';
import { tooltitle } from '../../utils/utils';

import ScreenReaderLabel from '../../components/ScreenReaderLabel';

import {
  MotifTable,
  MotifButton,
  MotifFormField,
  MotifSearch,
  MotifIconButton,
  MotifIcon
} from '@ey-xd/motif-react'

import { 
  MotifHardwareIcKeyboardArrowDown24px,
  MotifNavigationIcCheck24px,
  MotifActionIcAssignment24px
 } from '@ey-xd/motif-icon';

import RiskDetails from './RiskDetails';
import HeatMapChart from './HeatMapChart';
import { MakeRequests } from '../../utils/request';

const RiskOverview = () => {

  const { t } = useTranslation();
  const { userRole, isSuperuser } = useOutletContext();
  const { isLoading, setIsLoading } = useLoading();

  const [scaleData, setScaleData] = useState({});
  const [scaleNumber, setScaleNumber] = useState(1);
  const [risks, setRisks] = useState();
  
  const [riskData, setRiskData] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [isHeatmapActive, setIsHeatmapActive] = useState(false);
  const [heatmapFilteredData, setHeatmapFilteredData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [isRiskDetailsVisible, setRiskDetailsVisibility] = useState(false);

  const [showHeatmap, setShowHeatmap] = useState(false);
  const [heatMapData, setHeatMapData] = useState({});
  const [currentImpact, setCurrentImpact] = useState(null);
  const [currentProbability, setCurrentProbability] = useState(null);

  const navigate = useNavigate();

  const request = new MakeRequests()

    // Fetch initial data
    useEffect(() => {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          const scaleData = await request.get('key-risk-data');
          const data = await request.get('risk', {});
          setScaleData(scaleData);
          setScaleNumber(scaleData[0].scale.number);
          setRisks(data);
          setDisplayedData(data); // Display full dataset initially
          setIsLoading(false);
        } catch (error) {
          console.error(error.message);
        }
      };
      fetchData();
    }, []);
  
    // Aggregate data for heatmap
    useEffect(() => {
      if (risks && risks.length > 0 && scaleNumber) {
        const aggregateData = risks.reduce((acc, { impact, probability }) => {
          const key = `${impact}-${probability}`;
          acc[key] = acc[key] ? acc[key] + 1 : 1;
          return acc;
        }, {});
        setHeatMapData(Object.entries(aggregateData).map(([key, count]) => {
          const [impact, probability] = key.split('-').map(Number);
          return { impact, probability, count };
        }));
      }
    }, [risks, scaleNumber]);
  
  // Handle search within `displayedData`
  const filterData = (inputValue) => {
    setSearchValue(inputValue);
    const sourceData = isHeatmapActive ? heatmapFilteredData : risks;
    const results = [];

    if (inputValue && inputValue.length > 0) {
      const searchValue = inputValue.toLowerCase();

      sourceData.forEach(item => {
        // Check if any entities match the search term
        const entityMatch = item.subtheme && item.subtheme.entity?.some(entity =>
          entity.name.toLowerCase().includes(searchValue)
        );

        // Check if any departments match the search term
        const departmentMatch = item.departments?.some(departmentRelation =>
          departmentRelation.department.department.toLowerCase().includes(searchValue)
        );

        // Check if the search term matches any of the item's main or nested fields
        if (
          (item.risk && item.risk.toLowerCase().includes(searchValue)) || // risk
          (item.description && item.description.toLowerCase().includes(searchValue)) || // risk description
          (item.process && item.process.toLowerCase().includes(searchValue)) || // process
          (item.subtheme && item.subtheme.description_code?.toLowerCase().includes(searchValue)) || // subtheme description_code
          (item.subtheme && item.subtheme.theme?.description.toLowerCase().includes(searchValue)) || // theme description
          (item.risk_level && item.risk_level.description?.toLowerCase().includes(searchValue)) || // risk level
          (item.strategy && item.strategy.description?.toLowerCase().includes(searchValue)) || // strategy description
          entityMatch || // entity name match
          departmentMatch // department name match
        ) {
          results.push(item);
        }
      });

      setDisplayedData(results);
    } else {
      // Reset to full heatmap filtered data or full dataset if search is cleared
      setDisplayedData(isHeatmapActive ? heatmapFilteredData : risks);
    }
  };

  // Handle heatmap square click
  const handleHeatmapClick = (impact, probability) => {
    const filteredByHeatmap = risks.filter(
      risk => risk.impact === impact && risk.probability === probability
    );
    
    setHeatmapFilteredData(filteredByHeatmap);
    setDisplayedData(filteredByHeatmap); // Update displayed data to filtered results
    setIsHeatmapActive(true); // Set heatmap filtering as active
  };

  // Clear filters when the heatmap is hidden
  useEffect(() => {
    if (!showHeatmap) {
      setIsHeatmapActive(false);
      setDisplayedData(risks);
      setHeatmapFilteredData([]);
      setCurrentImpact(null);
      setCurrentProbability(null);
      setSearchValue('');
    }
  }, [showHeatmap, risks]);

  const columnDef = [
    {
      headerName: ' ',
      cellRenderer: (props) => {
        return <MotifIconButton
          type="button"
          title={t('tablebuttonrisks', {ns: 'general'})}>
          <MotifIcon src={MotifActionIcAssignment24px} />
        </MotifIconButton>
      },
      field: 'id',
      width: 60,
      pinned: 'left',
      headerComponentParams: {
        dataColumn: false,
        headerEndComponent: (
          <MotifIconButton aria-label="Click here to see options" type="button">
            <MotifIcon src={MotifHardwareIcKeyboardArrowDown24px} />
          </MotifIconButton>
        )
      }
    },
    {
      headerName: t('theme', {ns: 'general'}),
      field: 'subtheme.theme.description',
      resizable: true,
      minWidth: 100,
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
    },
    {
      headerName: t('subtheme', {ns: 'general'}),
      field: 'subtheme.description_code',
      resizable: true,
      minWidth: 100,
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      flex: 1,
    },
    {
      headerName: t('process', {ns: 'general'}),
      field: 'process',
      minWidth: 170,
      sortable: true,
      resizable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      flex: 2,
    },
    {
      headerName: t('risk', {ns: 'general'}),
      field: 'risk',
      minWidth: 170,
      sortable: true,
      resizable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      flex: 3,
    },
    {
      headerName: 'Key risk',
      cellRenderer: ({ value }) => (
        value ? 
        <MotifIcon src={MotifNavigationIcCheck24px} /> : ''
      ),
      field: 'key',
      minWidth: 105,
      width: 105,
      sortable: true,
    },
  ]
  return (
    <>
    <title>{t('risks', {ns: 'menu'})} | {tooltitle}</title>
    <div className="d-flex justify-content-center">
      <div className="row"><h1>{t('risks', {ns: 'menu'})}</h1></div>
    </div>
    <div className="d-flex justify-content-center">
      <div className="row">
        <MotifButton
          disabled={isLoading || !heatMapData}
          size="small"
          onClick={() => setShowHeatmap(!showHeatmap)}
          variant={ !showHeatmap ? "primary" : "warn" }
          className="mb-3"
        >
          { !showHeatmap ? t('show_heatmap', {ns: 'general'}) : t('hide_heatmap', {ns: 'general'}) }
        </MotifButton>
      </div>
    </div>
    {showHeatmap && heatMapData && scaleNumber ? (
        <div className="row mx-2 mt-3 mb-5">
          <div className="col d-flex justify-content-center align-items-center">
            <HeatMapChart 
                data={heatMapData} 
                risks={risks} 
                setFilteredData={handleHeatmapClick}
                maxRanking={scaleNumber * scaleNumber} 
                searchValue={searchValue}
                setIsHeatmapActive={setIsHeatmapActive}
                displayedData={displayedData}
                setDisplayedData={setDisplayedData}
                setHeatmapFilteredData={setHeatmapFilteredData}
                setSearchValue={setSearchValue}
                filterData={filterData}
                setCurrentImpact={setCurrentImpact} 
                setCurrentProbability={setCurrentProbability}
              />
          </div>
        </div>
      ) : null }
    
    <div className="row justify-content-center">
        <div className="col-8">
          <MotifFormField>
            <MotifSearch 
              value={searchValue} 
              onEnter={event => filterData(event.target.value)}
              onChange={event => filterData(event.target.value)}
              onSelect={val => filterData(val)}  
              onClear={() => setSearchValue('')}
              aria-label="Search in risks" 
              placeholder={t('search risks', {ns: 'screenreader'})}
              disabled={isLoading}
            />
          </MotifFormField>
        </div>
      </div>
      
      
      <div className="row ms-2 mt-3">
          <div className="col d-flex align-items-center">
              <div className="col-auto">
                {userRole === 'Admin' &&
                  <MotifButton
                    variant="primary"
                    onClick={() => navigate('/risks/add_risk/', { state: { riskId: null } })}
                    disabled={isLoading}>
                    {t('add_risk', {ns: 'general'})}
                  </MotifButton>
                }
              </div>
              <div className="col text-center">
                <span>
                  {/* Help text to show the user on which impact/probability they filtered on the heatmap */}
                  {currentImpact && currentProbability
                    ? t('heatmap_filtered', {
                        ns: 'general',
                        currentImpact,
                        currentProbability,
                        ranking: currentImpact * currentProbability // Calculate ranking here
                      })
                    : null}
                </span>
              </div>
          </div>
      </div>

      
      { risks ?
      <>
      <div className="row mx-2 mt-3">
        <div className="col">
          <MotifTable className='pb-5'
            columnDefs={columnDef}
            // rowData={filteredData.length > 0 ? filteredData : risks}
            rowData={displayedData}
            onRowClicked={row => { setRiskData(row.data); setRiskDetailsVisibility(!isRiskDetailsVisible); }}
            onCellKeyDown={row => {
              if (row.event.key === 'Enter') {
                setRiskData(row.data)
                setRiskDetailsVisibility(!isRiskDetailsVisible);
              }
            }}
            compact={true}
            zebra={true}
            pagination={risks?.length > 30 ? true : false}
            paginationPageSize="25"
            />
        </div>
      </div>
      </>
      : null }

      <RiskDetails scaleNumber={scaleNumber} riskData={riskData} isModalVisible={isRiskDetailsVisible} setModalVisibility={setRiskDetailsVisibility} navigate={navigate} hideEditButton={false} />
    </>
  )
}

export default RiskOverview