import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';
import {
    MotifModal,
    MotifModalHeader,
    MotifModalFooter,
    MotifModalBody,
    MotifButton,
    MotifFormField,
    MotifCheckbox,
    MotifLabel,
    MotifSelect,
    MotifOption,
    MotifAvatar
} from '@ey-xd/motif-react';
import { MotifActionIcHome24px, MotifContentIcLink24px } from '@ey-xd/motif-icon';
import { request } from '../../utils/request';

const CompanyOverview = ({
    isModalVisible,
    setModalVisibility,
    preSelectedDepartments,
    selectedDepartments,
    setSelectedDepartments
}) => {
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();
    const [entities, setEntities] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [applicableToWholeCompany, setApplicability] = useState(false);
    const [countries, setCountries] = useState([]);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [selectAllClicked, setSelectAllClicked] = useState(false);
    const [departmentActives, setDepartmentActives] = useState({});
    const [entityActives, setEntityActives] = useState({});

    useEffect(() => {
        const getData = async () => {
            setEntities(await request.get('entity/'));
            setDepartments(await request.get('department-entity'));
            setIsLoading(false);
        };
        getData();
    }, []);

    useEffect(() => {
        const tempCountries = [];
        departments?.forEach(dep => {
            entities.forEach(entity => {
                if (!tempCountries.some(country => country.id === entity.country.id)) {
                    tempCountries.push(entity.country);
                }
            });
        });
        setCountries(tempCountries);
    }, [departments]);

    // When preSelectedDepartments changes, update visual states for entities and departments
    useEffect(() => {
        if (preSelectedDepartments && preSelectedDepartments.length > 0) {
            const activeEntities = {};
            const activeDepartments = {};

            preSelectedDepartments.forEach(dep => {
                activeEntities[dep.entity] = true; // Set the entity as active
                activeDepartments[dep.department] = true; // Set the department as active
            });

            setEntityActives(activeEntities);
            setDepartmentActives(activeDepartments);
        }
    }, [preSelectedDepartments]);

    const handleCheckAllChange = (event) => {
        setSelectAllClicked(true);
        const isChecked = event.target.checked;
        setApplicability(isChecked);

        if (isChecked) {
            const allSelections = departments.map(dep => ({
                department: dep.department.id,
                entity: dep.entity.id
            }));

            const activeEntities = {};
            const activeDepartments = {};

            entities.forEach(entity => {
                activeEntities[entity.id] = true;
            });

            departments.forEach(dep => {
                activeDepartments[dep.department.id] = true;
            });

            setEntityActives(activeEntities);
            setDepartmentActives(activeDepartments);
            setSelectedDepartments(allSelections);
        } else {
            setEntityActives({});
            setDepartmentActives({});
            setSelectedDepartments([]);
        }
    };

    const handleEntityClick = (entity, departments) => {
        const entitySelected = !!entityActives[entity.id];
        const updatedDepartments = departments.map(dep => ({
            department: dep.department.id,
            entity: entity.id
        }));

        setEntityActives(prevState => ({
            ...prevState,
            [entity.id]: !entitySelected
        }));

        setDepartmentActives(prevState => {
            const updatedState = { ...prevState };
            departments.forEach(dep => {
                updatedState[dep.department.id] = !entitySelected;
            });
            return updatedState;
        });

        if (entitySelected) {
            setSelectedDepartments(prevState => prevState.filter(item => item.entity !== entity.id));
        } else {
            setSelectedDepartments(prevState => [...prevState, ...updatedDepartments]);
        }
    };

    const handleDepartmentClick = (department, entity) => {
        const departmentSelected = !!departmentActives[department.department.id];
        const departmentObject = { department: department.department.id, entity: entity.id };

        setDepartmentActives(prevState => ({
            ...prevState,
            [department.department.id]: !departmentSelected
        }));

        if (departmentSelected) {
            setSelectedDepartments(prevState => prevState.filter(item => item.department !== department.department.id));
        } else {
            setSelectedDepartments(prevState => [...prevState, departmentObject]);
        }
    };

    // Helper function to handle Enter key for selecting/deselecting departments only
    const handleDepartmentKeyDown = (event, department, entity) => {
        event.stopPropagation(); // Prevent the event from bubbling up to the entity
        if (event.key === 'Enter') {
            handleDepartmentClick(department, entity);
        }
    };

    // Helper function to handle Enter key for selecting/deselecting entities only
    const handleEntityKeyDown = (event, entity) => {
        if (event.key === 'Enter') {
            handleEntityClick(entity, departments.filter(dep => dep.entity.id === entity.id));
        }
    };

    return (
        <>
        <style jsx="true">{`
            .item:hover {
                background-color: rgba(0, 123, 255, 0.1); /* override the standard Motif ugly white color upon hover */
            }
            .item:focus {
                outline: 2px solid rgba(0, 123, 255, 0.5); /* Softer blue outline with transparency */
                outline-offset: 2px; /* Small gap between the element and the outline */
                border-radius: 4px; /* Smoother, more rounded corners */
                background-color: rgba(0, 123, 255, 0.1); /* Very light blue background for a softer highlight */
                transition: background-color 0.2s ease, outline-color 0.2s ease; /* Smooth transition effect */
            }
        `}</style>
   
        <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
            <MotifModalHeader>{t('modal_entities_header', { ns: 'add_risk' })}</MotifModalHeader>
            <MotifModalBody>
                <div className="d-flex flex-column">
                    <div className="mb-3 py-3" style={{ borderBottom: '1px solid #e1e1e6' }}>
                        {countries.length > 1 && (
                            <MotifFormField>
                                <MotifLabel>{t('countries', { ns: 'general' })}</MotifLabel>
                                <MotifSelect
                                    id="company-select-country"
                                    value={selectedCountries}
                                    multiple={true}
                                    filter={true}
                                    onChange={setSelectedCountries}
                                    disabled={isLoading}
                                    visibleOptions="3"
                                >
                                    {countries.map((item, i) => (
                                        <MotifOption key={i} value={item.id.toString()}>{item.country}</MotifOption>
                                    ))}
                                </MotifSelect>
                            </MotifFormField>
                        )}
                        <MotifFormField>
                            <MotifCheckbox
                                id="checkbox-client"
                                value="checkbox-client"
                                name="checkbox-client"
                                checked={applicableToWholeCompany}
                                onChange={handleCheckAllChange}
                                disabled={isLoading}
                            >
                                {(selectedCountries.length === 0 || countries.length === 1)
                                    ? t('modal_entities_applicable_organization', { ns: 'add_risk' })
                                    : t('modal_entities_applicable_selection', { ns: 'add_risk' })}
                            </MotifCheckbox>
                        </MotifFormField>
                    </div>
                    <div className="d-flex flex-column">
                        {entities
                            .filter(entity => !selectedCountries.length || selectedCountries.includes(entity.country.id.toString()))
                            .map(entity => (
                                <div
                                    key={entity.id}
                                    className="item mb-3"  // Add class "item" for focusable elements
                                    tabIndex="0"
                                    onKeyDown={(event) => handleEntityKeyDown(event, entity)}
                                >
                                    <div className="d-flex flex-row align-items-center mb-2 me-3 item" onClick={() => handleEntityClick(entity, departments.filter(dep => dep.entity.id === entity.id))}>
                                        <MotifAvatar
                                            style={{ width: '25px', height: '25px' }}
                                            className="me-2"
                                            src={MotifActionIcHome24px}
                                            size="small"
                                            variant="icon"
                                            status={entityActives[entity.id] ? 'online' : null}
                                        />
                                        <div>{entity.name}</div>
                                    </div>
                                    <div className="mx-3">
                                        {departments
                                            .filter(dep => dep.entity.id === entity.id && dep.department.department !== 'Default')
                                            .map(dep => (
                                                <div
                                                    key={dep.department.id}
                                                    className="item"  // Add class "item" for departments
                                                    tabIndex="0"
                                                    onKeyDown={(event) => handleDepartmentKeyDown(event, dep, entity)}
                                                    onClick={() => handleDepartmentClick(dep, entity)}
                                                >
                                                    <MotifAvatar
                                                        style={{ width: '20px', height: '20px' }}
                                                        className="me-2"
                                                        variant="icon"
                                                        src={MotifContentIcLink24px}
                                                        status={departmentActives[dep.department.id] ? 'online' : null}
                                                    />
                                                    <div className="mb-3">{dep.department.department}</div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>
            </MotifModalBody>
            <MotifModalFooter>
                <MotifButton size="medium" type="button" onClick={() => setModalVisibility(false)}>
                    {preSelectedDepartments.length > 0 ? t('edit', { ns: 'general' }) : t('add', { ns: 'general' })}
                </MotifButton>
                <MotifButton size="medium" variant="secondary" type="button" onClick={() => setModalVisibility(false)}>
                    {t('cancel', { ns: 'general' })}
                </MotifButton>
            </MotifModalFooter>
        </MotifModal>
        </>
    );
};

export default CompanyOverview;
