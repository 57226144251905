import React, { useState, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../utils/loadingcontext';
import { colors } from '../../../utils/utils';

import {
  MotifButton,
  MotifTable,
  MotifIcon,
  MotifIconButton
} from '@ey-xd/motif-react';

import {
  imageIcEdit24px,
  hardwareIcKeyboardArrowDown24px,
  NavigationIcClose24px
} from '@ey-xd/motif-react/assets/icons';

import NewEntityModal from './NewEntityModal.jsx'
import NewDepartmentModal from './NewDepartmentModal.jsx';
import DeleteDepartmentModal from './DeleteDepartmentModal';
import NewFunctionModal from './NewFunctionModal.jsx'
import DeleteFunctionModal from './DeleteFunctionModal';

import { request } from '../../../utils/request.js';

const Entity = () => {

  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useLoading();

  const [entities, setEntities] = useState([]);
  const [functionTitles, setFunctionTitles] = useState([]);
  const [selectedFunctionTitles, setSelectedFunctionTitles] = useState([]);
  const [functionTitleToDeleteId, setFunctionTitleToDeleteId] = useState(null);
  const [functionTitletoDeleteName, setFunctionTitletoDeleteName] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [departmentToDeleteId, setDepartmentToDeleteId] = useState(null);
  const [departmentToDeleteName, setDepartmentToDeleteName] = useState(null);

  const [entityId, setEntityId] = useState(null);

  const [name, setName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [countries, setCountries] = useState([])

  const [isEntityModalVisible, setEntityModalVisibility] = useState(false);
  const [isDepartmentModalVisible, setDepartmentModalVisibility] = useState(false);
  const [isDeleteDepartmentModalVisible, setDeleteDepartmentModalVisibility] = useState(false);
  const [isFunctionModalVisible, setFunctionModalVisibility] = useState(false);
  const [isDeleteFunctionModalVisible, setDeleteFunctionModalVisibility] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [entityToEdit, setEntityToEdit] = useState(null);

  const getDepartments = async () => {

    setDepartments(await request.get('department-entity/', {}));
  }

  const getFunctionTitles = async () => {

    setFunctionTitles(await request.get('function-entity/', {}));
  }
  
  const resetSelectedDepartments = useMemo(() => {

    setSelectedDepartments(departments.filter(item => item.entity.id == entityId && item.department.department !== 'Default'))
    setSelectedFunctionTitles(functionTitles.filter(item => item.entity.id == entityId))

  }, [departments, functionTitles, entityId])

  const getData = useEffect(() => {

    const fetchData = async () => {
      setIsLoading(true);

      setCountries(await request.get('country/', {}));
      setEntities(await request.get('entity/', {}));
      
      getDepartments();
      getFunctionTitles();

      setIsLoading(false);
    };

    fetchData();

  }, []);

  const setEditData = (entityId) => {
    setEditMode(true)

    const selectedEntity = entities.filter(entity => entity.id === entityId)[0]

    setEntityToEdit(selectedEntity.id);

    setName(selectedEntity.name);
    setStreetName(selectedEntity.street_name);
    setHouseNumber(selectedEntity.house_number);
    setPostalCode(selectedEntity.postal_code)
    setCity(selectedEntity.city);
    setCountry(selectedEntity.country.id.toString());

    setEntityModalVisibility(true);
  }  

  const columnDef = [
    {
      headerName: ' ',
      cellRenderer: ({ value }) => (
        <MotifIconButton value={value}
          type="button"
          title={t('tablebutton', {ns: 'admin'})}>
          <MotifIcon src={imageIcEdit24px} />
        </MotifIconButton>
      ),
      field: 'id',
      width: 60,
      pinned: 'left',
      headerComponentParams: {
        dataColumn: false,
        headerEndComponent: (
          <MotifIconButton aria-label="Click here to edit" type="button">
            <MotifIcon src={hardwareIcKeyboardArrowDown24px} />
          </MotifIconButton>
        )
      }
    },
    {
      headerName: t('name', {ns: 'admin'}),
      field: 'name',
      sortable: true,
      filter: true,
      flex: 3
    },
    {
      headerName: t('street', {ns: 'admin'}),
      field: 'street_name',
      sortable: true,
      filter: true,
      flex: 3
    },
    {
      headerName: t('number', {ns: 'admin'}),
      field: 'house_number',
      sortable: true,
      filter: true,
      flex: 2
    },
    {
      headerName: t('postal', {ns: 'admin'}),
      field: 'postal_code',
      sortable: true,
      filter: true,
      flex: 2
    },
    {
      headerName: t('city', {ns: 'admin'}),
      field: 'city',
      sortable: true,
      filter: true,
      flex: 2
    },
    {
      headerName: t('country', {ns: 'general'}),
      field: 'country.country',
      sortable: true,
      filter: true,
      flex: 2
    }
  ]

  return (
    <>
  <div className="container-fluid">
    {/* Top Row: Buttons */}
    <div className="row justify-content-left mt-5">
      {/* Left Column: New Entity Button */}
      <div className="col">
        <MotifButton
          size="medium"
          type="button"
          onClick={() => { setEditMode(false); setEntityModalVisibility(true); }}
          disabled={isLoading}
        >
          {t('new_entity', { ns: 'admin' })}
        </MotifButton>
      </div>

      {/* Right Column: Edit Entity, New Department, New Function Title Buttons */}
      <div className="col">
        <div className="d-flex justify-content-end">
          <MotifButton
            size="medium"
            type="button"
            onClick={() => { setEditData(entityId); }}
            className="mx-2"
            disabled={isLoading || !entityId}
          >
            {t('edit_entity', { ns: 'admin' })}
          </MotifButton>
          <MotifButton
            size="medium"
            type="button"
            onClick={() => { setDepartmentModalVisibility(true); }}
            className="mx-2"
            disabled={isLoading || !entityId}
          >
            {t('new_department', { ns: 'admin' })}
          </MotifButton>
          <MotifButton
            size="medium"
            type="button"
            onClick={() => { setFunctionModalVisibility(true); }}
            className="mx-2"
            disabled={isLoading || !entityId}
          >
            {t('new_function_title', { ns: 'admin' })}
          </MotifButton>
        </div>
      </div>
    </div>

    {/* Main Content: Table and Details */}
    <div className="row justify-content-left mt-5">
      {/* Left Column: MotifTable */}
      <div className="col-12 col-lg-10">
        {entities ? (
          <>
            {/* Alerts for entity and departments */}
            {entityId === null && (
              <div className="alert alert-info mt-2">
                {t('entity_alert_select', { ns: 'admin' })}
              </div>
            )}

            <MotifTable
              columnDefs={columnDef}
              rowData={entities}
              onRowClicked={(row) => { setEntityId(row.data.id); }}
              onCellKeyDown={(event) => {
                if (event.event.key === 'Enter') {
                  setEntityId(event.data.id);
                }
              }}
              compact={true}
              zebra={true}
              pagination={entities?.length > 30}
              paginationPageSize="25"
            />
          </>
        ) : null}
      </div>

      {/* Right Column: Departments and Function Titles */}
      <div className="col-12 col-lg-2">
        {/* Departments */}
        {entityId !== null && selectedDepartments?.length === 0 && (
          <div className="alert alert-warning mt-2">
            {t('entity_alert_nodep', { ns: 'admin' })}
          </div>
        )}
        {entityId !== null && selectedDepartments?.length > 0 && (
          <div className="mt-3">
            <h6>{t('departments', { ns: 'general' })}</h6>
            <ul className="list-group">
              {selectedDepartments.map((item) => (
                <li key={item.department.id} className="list-group-item d-flex justify-content-between align-items-center">
                  {item.department.department}
                  <MotifIcon
                    onClick={() => {
                      setDepartmentToDeleteName(item.department.department);
                      setDepartmentToDeleteId(item.department.id);
                      setDeleteDepartmentModalVisibility(true);
                    }}
                    style={{ cursor: 'pointer' }}
                    src={NavigationIcClose24px}
                    size="25"
                  />
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Function Titles */}
        {entityId !== null && selectedFunctionTitles?.length === 0 && (
          <div className="alert alert-warning mt-2">
            {t('entity_alert_nofunc', { ns: 'admin' })}
          </div>
        )}

        {entityId !== null && selectedFunctionTitles?.length > 0 && (
          <div className="mt-3">
            <h6>{t('function_titles', { ns: 'general' })}</h6>
            <ul className="list-group">
              {selectedFunctionTitles.map((item) => (
                <li key={item.id} className="list-group-item d-flex justify-content-between align-items-center">
                  {item.function_title.function_title}
                  <MotifIcon
                    onClick={() => {
                      setFunctionTitletoDeleteName(item.function_title.function_title);
                      setFunctionTitleToDeleteId(item.id);
                      setDeleteFunctionModalVisibility(true);
                    }}
                    style={{ cursor: 'pointer' }}
                    src={NavigationIcClose24px}
                    size="25"
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  </div>

      <NewEntityModal
        isModalVisible={isEntityModalVisible}
        setModalVisibility={setEntityModalVisibility}
        entities={entities}
        setEntities={setEntities}
        editMode={editMode}
        setEditMode={setEditMode}
        entityToEdit={entityToEdit}
        name={name}
        setName={setName}
        streetName={streetName}
        setStreetName={setStreetName}
        houseNumber={houseNumber}
        setHouseNumber={setHouseNumber}
        postalCode={postalCode}
        setPostalCode={setPostalCode}
        city={city}
        setCity={setCity}
        country={country}
        setCountry={setCountry}
        countries={countries} />

      <NewDepartmentModal
        departments={departments}
        isModalVisible={isDepartmentModalVisible}
        setModalVisibility={setDepartmentModalVisibility}
        getDepartments={getDepartments}
        entityId={entityId} />

      <DeleteDepartmentModal
        isModalVisible={isDeleteDepartmentModalVisible}
        setModalVisibility={setDeleteDepartmentModalVisibility}
        getDepartments={getDepartments}
        departmentId={departmentToDeleteId}
        departmentName={departmentToDeleteName}
        entityId={entityId} />

      <NewFunctionModal
        isModalVisible={isFunctionModalVisible}
        setModalVisibility={setFunctionModalVisibility}
        entityId={entityId}
        getFunctionTitles={getFunctionTitles} />

      <DeleteFunctionModal
        isModalVisible={isDeleteFunctionModalVisible}
        setModalVisibility={setDeleteFunctionModalVisibility}
        functionTitleId={functionTitleToDeleteId}
        functionTitleName={functionTitletoDeleteName}
        getFunctionTitles={getFunctionTitles} />

    </>
  )
}

export default Entity