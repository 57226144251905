import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import {
  useNavigate,
  useLocation,
  createBrowserRouter,
  RouterProvider,
  Navigate
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import './index.scss';
import "./services/i18n";

import {
  MotifProgressLoader
} from '@ey-xd/motif-react';

import { LoadingProvider } from './utils/loadingcontext';
import { request } from './utils/request';
import LoginPage from './views/loginpage/Loginpage';
import LoginPageOld from './views/loginpage/LoginpageOld'
import LoginRedirect from './views/loginpage/LoginRedirect';
import BasePage from './views/base/base';
import OverviewPage from './views/overview/OverviewPage';
import CompletedTasks from './views/overview/CompletedTasks';
import RiskOverview from './views/risks/RiskOverview';
import AddRisk from './views/risks/AddRisk';
import ControlOverview from './views/controls/ControlOverview';
import AddControl from './views/controls/AddControl';
import PerformControlOverviewPage from './views/performcontrol/PerformControlOverviewPage';
import PerformControlPage from './views/performcontrol/PerformControlPage';
import PerformMonitoringOverviewPage from './views/performmonitoring/PerformMonitoringOverviewPage';
import PerformMonitoringPage from './views/performmonitoring/PerformMonitoringPage';
import MonitoringOverview from './views/monitoring/MonitoringOverview';
import AddMonitoring from './views/monitoring/AddMonitoring';
import Admin from './views/admin/Admin';
import Account from './views/account/Account';
import Calender from './views/calender/Calender';
import EyAdmin from './views/eyadmin/newclient';
import ClientOverview from './views/eyadmin/clientoverview';
import Documents from './views/documents/Documents';
import PrivacyNotice from './views/privacynotice/privacynotice';
import AdminOverview from './views/eyadmin/adminoverview';
import NewAdmin from './views/eyadmin/newadmin';
import Error from './views/error/error';

const ProtectRoute = ({ element, requiredRole }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [access, setAccess] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [isSuperuser, setIsSuperuser] = useState(false);

  useEffect(() => {
    const fetchAccessRights = async () => {
          try {
            const response = await request.get('auth/check-auth-status/');
            if (!response.is_authenticated) {
              // Only navigate to /login if not already on /login
              if (location.pathname !== '/login') {
                navigate('/login');
              }
            } else {
              const userRole = response.user_type;
              const isSuperuser = response.is_superuser === true;

              let hasAccess = false;
              if (requiredRole === 'eyadmin') {
                hasAccess = isSuperuser;
              } else if (requiredRole === 'clientadmin') {
                hasAccess = userRole === 'Admin' || isSuperuser;
              } else if (requiredRole === 'user') {
                hasAccess = !isSuperuser || userRole === 'Admin';
              }
              
              if (!hasAccess) {
                navigate('/accessdenied');
              } else {
                setUserRole(userRole);
                setIsSuperuser(isSuperuser);
                setAccess(true);
              }
            }
          } catch (error) {
            console.error('Error fetching account details.');
            if (location.pathname !== '/login') {
              // Only navigate to /login if not already on /login
              navigate('/login');
            }
          }
      };

    fetchAccessRights();
  }, [requiredRole, navigate]);

  return access ? React.cloneElement(element, { userRole, isSuperuser }) : <MotifProgressLoader variant="default" />;
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: <LoginPage />
  },
   // Conditionally add the old login route for debug purposes
   ...(process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' ? [
    {
      path: "/login2",
      element: <LoginPageOld />
    }
  ] : []),
  {
    path: "/login_redirect",
    element: <ProtectRoute requiredRole={'user'} element={<LoginRedirect />} />,
  },
  {
    path: "/privacy_notice",
    element: <ProtectRoute requiredRole={'user'} element={<PrivacyNotice />} />,
  },
  {
    path: "/",
    element: <ProtectRoute requiredRole={'user'} element={<BasePage />} />, // Wrap BasePage with ProtectRoute
    children: [
      {
        index: true,
        element: <Navigate to="/login" replace />,
      },
      {
        path: "admin",
        element: 
            <ProtectRoute requiredRole={'clientadmin'} element={<Admin />} />
      },
      {
        path: "account",
        element: <Account />
      },
      {
        path: "overview",
        element: <ProtectRoute requiredRole={'user'} element={<OverviewPage />} />
      },
      {
        path: "overview/completed_tasks",
        element: <ProtectRoute requiredRole={'user'} element={<CompletedTasks />} />
      },
      {
        path: "risks/",
        element: <ProtectRoute requiredRole={'user'} element={<RiskOverview />} />
      },
      {
        path: "risks/add_risk",
        element: <ProtectRoute requiredRole={'user'} element={<AddRisk />} />
      },
      {
        path: "controls/",
        element: <ProtectRoute requiredRole={'user'} element={<ControlOverview />} />
      },
      {
        path: "controls/add_control",
        element: <ProtectRoute requiredRole={'user'} element={<AddControl />} />
      },
      {
        path: "performcontroloverview",
        element: <ProtectRoute requiredRole={'user'} element={<PerformControlOverviewPage />} />
      },
      {
        path: "performcontroloverview/performcontrol",
        element: <ProtectRoute requiredRole={'user'} element={<PerformControlPage />} />
      },
      {
        path: "monitoring",
        element: <ProtectRoute requiredRole={'user'} element={<MonitoringOverview />} />
      },
      {
        path: "monitoring/add_monitoring",
        element: <ProtectRoute requiredRole={'user'} element={<AddMonitoring />} />
      },
      {
        path: "performmonitoringoverview",
        element: <ProtectRoute requiredRole={'user'} element={<PerformMonitoringOverviewPage />} />
      },
      {
        path: "performmonitoringoverview/performmonitoring",
        element: <ProtectRoute requiredRole={'user'} element={<PerformMonitoringPage />} />
      },
      {
        path: "clientoverview/addclient",
        element: 
            <ProtectRoute requiredRole={'eyadmin'} element={<EyAdmin />} />
      },
      {
        path: "clientoverview",
        element: 
            <ProtectRoute requiredRole={'eyadmin'} element={<ClientOverview />} />
      },
      {
        path: "documents",
        element: <ProtectRoute requiredRole={'user'} element={<Documents />}></ProtectRoute>
      },
      {
        path: "adminoverview",
        element: 
            <ProtectRoute requiredRole={'eyadmin'} element={<AdminOverview />} />
      },
      {
        path: "adminoverview/addadmin",
        element: 
            <ProtectRoute requiredRole={'eyadmin'} element={<NewAdmin />} />
      },
      {
        path: "accessdenied",
        element: <Error />
      }
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <LoadingProvider>
    <RouterProvider router={router} />
  </LoadingProvider>
  // </React.StrictMode>
);

reportWebVitals();
