import React, { useState, useEffect, useMemo, useCallback } from 'react'

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../utils/loadingcontext';

import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifLabel,
    MotifFormField,
    MotifInput,
    MotifErrorMessage,
} from '@ey-xd/motif-react';

import { validateGeneralInput } from '../../../utils/utils';

import { request } from '../../../utils/request';


const NewDepartmentModal = ({
    isModalVisible,
    setModalVisibility,
    getDepartments,
    entityId }) => {
    
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const[isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [name, setName] = useState('');

    const addDepartment = async () => {
        setIsFormSubmitted(true);

        if (name.length > 0) {
            setIsLoading(true);
            var data = {
                'entity': entityId,
                'department': name
            }
    
            try {
                await request.post('department/', data, 'POST');
                setIsFormSubmitted(false);
            } catch (error) {
                console.error(error.message)
            } finally {
                setIsLoading(false);
            }
    
            await getDepartments();
            setName('');
        }

    }
    
    return (
        <>
            <MotifModal visibleOptions={3}
                        show={isModalVisible} 
                        onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>{t('new_department', {ns: 'admin'})}</MotifModalHeader>
                <MotifModalBody>
                    <div className='row'>
                        <div className="col">
                            <MotifFormField>
                                <MotifLabel>{t('name', {ns: 'general'})}</MotifLabel>
                                <MotifInput value={name}
                                    onChange={event => setName(validateGeneralInput(event.target.value).cleanedValue)}
                                    required={true}
                                    maxLength={256}
                                /> {(name.length === 0 && isFormSubmitted) && <MotifErrorMessage>
                                {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                            </MotifFormField>
                        </div>
                    </div>

                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" disabled={isLoading} onClick={() => { setModalVisibility(false); addDepartment() }}>
                        {t('add', {ns: 'general'})}
                    </MotifButton>
                    <MotifButton
                        size="medium"
                        variant="secondary"
                        type="button"
                        disabled={isLoading}
                        onClick={() => setModalVisibility(false)}
                    >
                        {t('cancel', {ns: 'general'})}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default NewDepartmentModal