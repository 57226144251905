import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MakeRequests } from '../../utils/request';

import { useLoading } from '../../utils/loadingcontext'

import { useTranslation } from 'react-i18next';

import ControlTaskDescription from '../../components/ControlTaskDescription';
import PerformControlForm from './PerformControlForm';
import PerformControlFormReviewer from './PerformControlFormReviewer';
import Communication from '../../components/Communication';
import { tooltitle } from '../../utils/utils';

const PerformControlPage = () => {
  const { t, i18n } = useTranslation();

  const { isLoading, setIsLoading } = useLoading();
  const [dataFetched, setDataFetched] = useState(false);

  const navigate = useNavigate();
  const { state } = useLocation();
  const { taskId } = state || {};
  const { completed } = state || {};

  const [executionDate, setExecutionDate] = useState(null);

  const [fiscalYear, setFiscalYear] = useState('');
  const [clientFiscalYear, setClientFiscalYear] = useState('');
  const [execution, setExecution] = useState('1'); // yes by default

  const [controlTask, setControlTask] = useState();
  const [reviewerTask, setReviewerTask] = useState(); // ControlTask has a reviewer
  const [taskFinal, setTaskFinal] = useState(false);

  const [draft, setDraft] = useState([]);

  const [communication, setCommunication] = useState({responsible: [], reviewer: [],});

  const [role, setRole] = useState('');

  const request = new MakeRequests();

  useEffect(() => {
    setTaskFinal(controlTask?.status.id === 1);
  }, [controlTask])
  
  useEffect(() => {
    const fetchData = async () => {

      let param = completed === 1 || taskFinal ? '-completed' : '';
  
      try {
        setIsLoading(true);
        const bookyearResponse = await request.get('bookyear');
        const controlTaskResponse = await request.get(`control-task${param}/${taskId}/`);

        setTaskFinal(controlTaskResponse?.status.id === 1);
  
        setClientFiscalYear(bookyearResponse);
        setControlTask(controlTaskResponse);

        if (controlTaskResponse?.control?.has_reviewer === true || controlTaskResponse?.archived_function_reviewer) {
          const [responsible, reviewer] = await Promise.all([
            request.get(`perform-control/?controltask=${taskId}`),
            request.get(`perform-control-review/?controltask=${taskId}`),
          ]);
          setCommunication({ responsible, reviewer });
          setReviewerTask(true);
        } else {
          setReviewerTask(false);
        }
  
        setFiscalYear(controlTaskResponse?.fiscal_year || '');
        setExecution(controlTaskResponse?.execution === false ? '0' : '1');
        if (completed === 1) {
          setExecutionDate(new Date(controlTaskResponse?.execution_date)); // only set the executed date if task is finished
        }

        setIsLoading(false);
  
      } catch (error) {
        console.error(error.message);
      } finally {
        setDataFetched(true);
      }
    };
  
    fetchData();
  }, [taskFinal]);

  // This useEffect is specifically for processing communication data
  useEffect(() => {
    if (controlTask && dataFetched) {
      const userRole = getUserRole(controlTask, localStorage.getItem('username'));
      setRole(userRole);

      // Ensure we use the most up-to-date controlTask information
      if(!taskFinal) {
        setDraft(getDraftCommunications(userRole, communication, controlTask));
      }
    }
  }, [communication, controlTask]);

  const getUserRole = (controlTask, username) => {
    if (username === controlTask?.responsible_username) {
      return 'responsible';
    } else if (username === controlTask?.reviewer_username) {
      return 'reviewer';
    }
    return 'other';
  };
  
  const getDraftCommunications = (role, communication, controlTask) => {
    if (role === 'responsible' && controlTask?.reviewer_username) {
      return communication.responsible.filter(com => com.is_draft === true);
    } else if (role === 'reviewer') {
      return communication.reviewer.filter(com => com.is_draft === true);
    }
  };
  return (
    <>
    <title>{t('performcontrol', {ns: 'menu'})} | {tooltitle}</title>
    <div className="d-flex justify-content-center">
      <h1>{t('performcontrol', {ns: 'menu'})}</h1>
    </div>
      <div className="row ms-2 mt-5">
        <div className="col-3">
          {controlTask && <ControlTaskDescription controlTask={controlTask} taskFinal={completed} />}
        </div>
        <div className="col-8">
          {!reviewerTask && dataFetched ? <PerformControlForm role={role} controlTask={controlTask} taskId={taskId} taskFinal={completed} setTaskFinal={setTaskFinal} executionDate={executionDate} fiscalYear={fiscalYear} setFiscalYear={setFiscalYear} clientFiscalYear={clientFiscalYear} execution={execution} setExecution={setExecution} navigate={navigate} request={request} /> : ''}
          {reviewerTask && dataFetched ? <PerformControlFormReviewer draft={draft} role={role} controlTask={controlTask} taskId={taskId} taskFinal={completed} setTaskFinal={setTaskFinal} executionDate={executionDate} fiscalYear={fiscalYear} setFiscalYear={setFiscalYear} clientFiscalYear={clientFiscalYear} execution={execution} setExecution={setExecution} navigate={navigate} request={request} /> : ''}
          {reviewerTask && dataFetched ? <Communication communication={communication} taskFinal={completed} type='control' /> : null}
        </div>
      </div>
    </>
  )
}

export default PerformControlPage  