import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MotifFormField, MotifToggleSwitch } from '@ey-xd/motif-react';

const FilterToggleSwitch = ({ isLoading, switchName, setOverviewSwitch }) => {
    const { t } = useTranslation();
    const [switchState, setSwitchState] = useState(true); // Default to true

    useEffect(() => {
        try {
            // Load the initial state from localStorage
            let savedState = localStorage.getItem(switchName);

            if (savedState !== null && savedState !== undefined) {
                const parsedState = JSON.parse(savedState);
                
                if (typeof parsedState === "boolean") {
                    setSwitchState(parsedState);
                    setOverviewSwitch(parsedState);
                } else {
                    // console.warn(`Unexpected value in localStorage for ${switchName}: ${parsedState}`);
                    localStorage.setItem(switchName, JSON.stringify(true)); // Set default to true if corrupted
                }
            } else {
                // If no value is found, set default to true
                localStorage.setItem(switchName, JSON.stringify(true));
                setSwitchState(true);
                setOverviewSwitch(true);
            }
        } catch (error) {
            // console.error(`Failed to parse localStorage value for ${switchName}:`, error);
            // If an error occurs, set the state to true by default and save it
            localStorage.setItem(switchName, JSON.stringify(true));
            setSwitchState(true);
            setOverviewSwitch(true);
        }
    }, [switchName, setOverviewSwitch]);

    const handleToggleChange = (event) => {
        const checked = event.target.checked;
        setSwitchState(checked);
        setOverviewSwitch(checked);
        localStorage.setItem(switchName, JSON.stringify(checked));
    };

    return (
        <div className="d-flex justify-content-center align-items-center">
            <MotifFormField>
                <MotifToggleSwitch
                    offLabel={t('switch_personal', { ns: 'general' })}
                    onLabel={t('switch_all', { ns: 'general' })}
                    checked={switchState}
                    onChange={handleToggleChange}
                    id="toggle"
                    disabled={isLoading}
                />
            </MotifFormField>
        </div>
    );
};

export default FilterToggleSwitch;
