import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    MotifFormField,
    MotifLabel,
    MotifInput,
    MotifButton,
    MotifErrorMessage,
    MotifHeader,
    MotifHeaderLogo,
    MotifIcon,
    MotifModal,
    MotifModalHeader,
    MotifModalFooter,
    MotifModalBody,
    MotifProgressLoader,
    MotifTextLink
} from '@ey-xd/motif-react';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import FormErrorMessage from '../../components/FormErrorMessage';

import { MotifEy } from '@ey-xd/motif-icon';

import { request } from '../../utils/request';

const LoginPageOld = () => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const [isValidUserName, setIsValidUserName] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [invalidLogin, setInvalidLogin] = useState(false);

    const [isMessageModalVisible, setMessageModalVisibility] = useState(false);
    const [message, setMessage] = useState(false);

    const [languageIsDutch, setLanguageIsDutch] = useState(false);

    useEffect(() => {
        // if user tries to open the login page while logged in, send them back to the overview
        const fetchUserAuthInfo = async () => {
            try {
                const response = await request.get('auth/check-auth-status/');
                if (response.is_authenticated === true) {
                    if (response.is_superuser) {
                        navigate('/clientoverview/');
                    } else {
                        navigate('/overview/')
                    }
                }
            } catch (error) {
                console.error('Error fetching user auth info:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserAuthInfo();
    }, [navigate]);

    useEffect(() => {
        // Frame-busting script
        if (window.top !== window.self) {
            window.top.location = window.self.location;
        }
    }, []);

    const checkPrivacyNoticeAccepted = async () => {
        try {
            const response = (await request.get('privacy-notice/'))[0];
            const { accepted, is_superuser } = response;

            if (accepted) {
                if (is_superuser) {
                    navigate('/clientoverview');
                } else {
                    navigate('/overview/');
                }
            } else {
                navigate('/privacy_notice/');
            }
        } catch (error) {
            console.error('Error checking privacy notice acceptance.');
        }
    };

    const validateForm = () => {

        setIsValidUserName(userName.length > 0);
        setIsValidPassword(password.length > 0);

    }


    const submitForm = async () => {

        setIsFormSubmitted(true);
        validateForm();

        var submitData = {
            "username": userName,
            "password": password
        }

        setIsLoading(true);

        try {
            var response = await request.post('login2/', submitData, 'POST');
        } catch (error) {
            console.error(error.message);
            setInvalidLogin(true);
            setIsLoading(false);
            return;  // Exit the function if there's an error
        }

        if (response && response.success) {
            i18n.changeLanguage(response.success.language.toLowerCase());
            localStorage.setItem('employee_name', `${response.success['first_name']} ${response.success['last_name']}`);
            localStorage.setItem('username', response.success['username']);
            localStorage.setItem('client', response.success['client']);
            localStorage.setItem('function_title', response.success['function_title']);
            localStorage.setItem('language', response.success['language'].toLowerCase());

            checkPrivacyNoticeAccepted();
        } else {
            console.error('Login failed:', response ? response.error : 'No response from server');
            setInvalidLogin(true);
            setIsLoading(false);
        }
    }


    useEffect(() => {
        languageIsDutch ? i18n.changeLanguage('nl') : i18n.changeLanguage('en')
    }, [languageIsDutch])

    return (
        <>
        <title>{t('login', {ns: 'menu'})} | InControl</title>
        { isLoading ?
            <MotifProgressLoader /* bug: show={isLoading} is always triggered as true, added isLoading ? : null as workaround */
                show={isLoading}
                variant="default"
                fullscreen={true}
                />  
        : null}
            <div className="row d-inline">
                <div className="col-1">
                    <MotifHeader
                        fixed={true}
                        logo={
                            <MotifHeaderLogo>
                                <a href="/overview">
                                    <MotifIcon alt='EY Logo' title='EY Logo' src={MotifEy} stroke={0} size="30" />
                                </a>
                            </MotifHeaderLogo>
                        }
                        appHeaderName="InControl Tool">
                    </MotifHeader>
                </div>
            </div>

            <div className="row justify-content-center mt-5">
                <div className="col-4" >

                    <h1>{t('login', {ns: 'login'})}</h1>

                    <MotifFormField>
                        <MotifLabel>{t('email', { ns: 'login' })}</MotifLabel>
                        <MotifInput id="username"
                            type="email"
                            value={userName}
                            required={true}
                            onChange={event => setUserName(event.target.value)}
                            maxLength={256}
                            onKeyDown={event => {
                                if (event.key === 'Enter' && !isLoading) {
                                    event.preventDefault();  // Prevent the default form submit behavior
                                    submitForm();
                                }
                            }} />
                        {
                            (isFormSubmitted && !isValidUserName) &&
                            <MotifErrorMessage>{t('valid_email', { ns: 'login' })}</MotifErrorMessage>
                        }
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('password', { ns: 'login' })}</MotifLabel>
                        <MotifInput id="password"
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            type='password'
                            maxLength={128}
                            onKeyDown={event => {
                                if (event.key === 'Enter' && !isLoading) {
                                    event.preventDefault();  // Prevent the default form submit behavior
                                    submitForm();
                                }
                            }} />

                        {
                            (isFormSubmitted && !isValidPassword) &&
                            <MotifErrorMessage>{t('valid_password', { ns: 'login' })}</MotifErrorMessage>
                        }

                    </MotifFormField>

                    {invalidLogin && <MotifErrorMessage>{t('incorrect_login', { ns: 'login' })}</MotifErrorMessage>}
                </div>
            </div>
            <div className="row justify-content-center mt-3">
                <div className="col-4" >
                    <MotifButton disabled={isLoading} onClick={() => submitForm()}>{t('login', { ns: 'login' })}</MotifButton>
                    <br />
                    <div>
                        <MotifTextLink onClick={() => navigate('/login/')}>
                            New login (debug) &gt;
                        </MotifTextLink>
                    </div>
                </div>
            </div>
               

                <MotifModal
                    show={isMessageModalVisible}
                    onClose={() => {
                        setMessageModalVisibility(false);
                        setMessage('');
                    }}>
                    <MotifModalHeader>{t('alert', { ns: 'general' })}</MotifModalHeader>
                    <MotifModalBody>
                        {message}
                    </MotifModalBody>
                    <MotifModalFooter>
                        <MotifButton disabled={isLoading} size="medium" type="button" onClick={() => {
                            setMessageModalVisibility(false);
                            setMessage('');
                        }}>
                            OK
                        </MotifButton>
                    </MotifModalFooter>
                </MotifModal>

        </>
    )
}

export default LoginPageOld