import React, { useState } from 'react'

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../utils/loadingcontext';

import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifLabel,
    MotifFormField,
    MotifInput,
    MotifErrorMessage
} from '@ey-xd/motif-react';

import { validateGeneralInput } from '../../../utils/utils';

import { request } from '../../../utils/request';


const NewFunctionModal = ({
    isModalVisible,
    setModalVisibility,
    getFunctionTitles,
    entityId }) => {
    
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const[isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [name, setName] = useState('');

    const validateForm = () => {
        if (name.length > 0) {
            return true
        }
        return false;
    }

    const addFunctionTitle = async () => {
        setIsFormSubmitted(true);
        if (validateForm()) {
            setIsLoading(true);
            // Prepare the data object based on whether a new function title is being created or an existing one is being linked
            const data = {
                'function_title': name,
                'entity': entityId
            };
        
            try {
                // Make a single API call to create the function title (if necessary) and link it to the entity
                await request.post('new-function-entity/', data, 'POST');

                setIsFormSubmitted(false);
                
                // Refresh the list of function titles
                await getFunctionTitles();

                // Hide modal
                setModalVisibility(false);
        
                // Clear the form fields
                setName('');
            } catch (error) {
                console.error("Failed to add function title:", error.message);
                // Handle the error as needed (e.g., show a message to the user)
            } finally {
                setIsLoading(false);
            }
        }
    };
    
    
    return (
        <>
            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>{t('new_function_title', {ns: 'admin'})}</MotifModalHeader>
                <MotifModalBody>
                    <div className='row'>
                        <div className="col">
                            <MotifFormField>
                                <MotifLabel>{t('name', {ns: 'general'})}</MotifLabel>
                                <MotifInput value={name}
                                    onChange={event => {
                                        setName(validateGeneralInput(event.target.value).cleanedValue);
                                    }}
                                    required={true}
                                    maxLength={256}
                                    disabled={isLoading}
                                /> {(name.length === 0 && isFormSubmitted) && <MotifErrorMessage>
                                {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                            </MotifFormField>
                        </div>
                    </div>

                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" disabled={isLoading} onClick={() => { addFunctionTitle() }}>
                        {t('add', {ns: 'general'})}
                    </MotifButton>
                    <MotifButton
                        size="medium"
                        variant="secondary"
                        type="button"
                        disabled={isLoading}
                        onClick={() => setModalVisibility(false)}
                    >
                        {t('cancel', {ns: 'general'})}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default NewFunctionModal