import React, { useState, useEffect } from 'react'

import { useNavigate, ScrollRestoration, useOutletContext } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import {
	MotifTable,
	MotifButton,
	MotifIconButton,
	MotifIcon,
	MotifToggleSwitch,
	MotifFormField,
	MotifSearch
} from '@ey-xd/motif-react'

import {
	imageIcEdit24px,
	hardwareIcKeyboardArrowDown24px,
	actionIcAssignment24px,
} from '@ey-xd/motif-react/assets/icons';

import { MakeRequests } from '../../utils/request'
import { isExpired, tooltitle } from '../../utils/utils';

import FilterToggleSwitch from '../../components/FilterToggleSwitch';
import MonitoringDetails from './MonitoringDetails';

const MonitoringOverview = () => {

	const { t } = useTranslation();
	const { userRole, isSuperuser } = useOutletContext();
	const { isLoading, setIsLoading } = useLoading();
	const currentEmployeeName = localStorage.getItem('employee_name');

	const [overviewSwitch, setOverviewSwitch] = useState(true);
	const [missingFieldsSwitch, setMissingFieldsSwitch] = useState(false);
	const [expiredSwitch, setExpiredSwitch] = useState(false);

	const [monitoring, setMonitoring] = useState();
	const [myMonitoring, setMyMonitoring] = useState();
	const [expiredMonitoring, setExpiredMonitoring] = useState([]);

	const [monitoringData, setMonitoringData] = useState({});
	const [hasMonitoringNumber, setHasMonitoringNumber] = useState(false);

	const [monitoringWithMissingFields, setMonitoringWithMissingFields] = useState([])

	const [filteredData, setFilteredData] = useState({ selectedItems: [], selectedMyItems: [] });
	const [searchValue, setSearchValue] = useState('');
	const [isMonitoringDetailsVisible, setMonitoringDetailsVisibility] = useState(false)

	const navigate = useNavigate();

	const request = new MakeRequests()

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const data = await request.get('monitoring/', {});

				// Filter non-expired monitoring separately for general and user-specific monitoring.
				const nonExpiredMonitoring = data.filter(monitoring => !isExpired(monitoring.expiry));
				const myFilteredMonitoring = nonExpiredMonitoring.filter(monitoring =>
					monitoring.responsible_fullname === currentEmployeeName ||
					monitoring.reviewer_fullname === currentEmployeeName
				);

				// Filter expired monitoring.
				const expiredMonitoringData = data.filter(monitoring => isExpired(monitoring.expiry));

				// Set states.
				setMonitoring(nonExpiredMonitoring);
				setMyMonitoring(myFilteredMonitoring);
				setExpiredMonitoring(expiredMonitoringData);

				setIsLoading(false);
			} catch (error) {
				console.error(error.message);
			}
		};

		fetchData();
	}, []);


	const doesItemMatchSearch = (item, searchValue) => {
		const controlMatches = item.controls.some(control => {
			const basicMatches = (
				control.control && control.control.toLowerCase().includes(searchValue) || // controls
				control.risk && control.risk.risk && control.risk.risk.toLowerCase().includes(searchValue) || // risks
				control.risk && control.risk.subtheme && control.risk.subtheme.description && control.risk.subtheme.description.toLowerCase().includes(searchValue) || // subthemes
				control.risk && control.risk.subtheme && control.risk.subtheme.theme && control.risk.subtheme.theme.description && control.risk.subtheme.theme.description.toLowerCase().includes(searchValue) // themes
			);
			return basicMatches;
		});

		const entityMatches = item.unique_entities.some(entity => {
			return entity.name && entity.name.toLowerCase().includes(searchValue); // entities
		});

		return controlMatches ||
			entityMatches || // Added this line for matching unique entity names
			(item.monitoring && item.monitoring.toLowerCase().includes(searchValue)) || // monitoring
			(item.monitoring_number && item.monitoring_number.toLowerCase().includes(searchValue)) || // monitoring number
			(item.monitoring_type && item.monitoring_type.description && item.monitoring_type.description.toLowerCase().includes(searchValue)) || // monitoring type
			(item.frequency && item.frequency.description && item.frequency.description.toLowerCase().includes(searchValue)) || // frequency
			(item.responsible_fullname && item.responsible_fullname.toLowerCase().includes(searchValue)) || // responsible
			(item.reviewer_fullname && item.reviewer_fullname.toLowerCase().includes(searchValue)) || // reviewer
			(item.notify_on_failure_fullname && item.notify_on_failure_fullname.toLowerCase().includes(searchValue)) || // notify on failure
			(item.expiry && item.expiry.toLowerCase().includes(searchValue)); // expiry date
	};

	const filterData = (inputValue) => {
		setSearchValue(inputValue);
		let allResults = [];
		let myResults = [];

		if (inputValue.length > 0) {
			const searchValue = inputValue.toLowerCase(); // Convert once and use throughout

			// Filter all monitoring items
			monitoring.forEach(item => {
				if (doesItemMatchSearch(item, searchValue)) {
					allResults.push(item);
				}
			});

			// Filter admin's monitoring items
			myMonitoring.forEach(item => {
				if (doesItemMatchSearch(item, searchValue)) {
					myResults.push(item);
				}
			});

			// Set filtered data for both monitoring and myMonitoring
			setFilteredData({
				items: allResults,
				myItems: myResults
			});

		} else {
			// Reset to the whole list when search input is cleared
			setFilteredData({ items: [], myItems: [] });
		}
	};


	const getTableData = () => {
		if (missingFieldsSwitch && !expiredSwitch) {
			return searchValue.length > 0 ? filteredData.myItems : monitoringWithMissingFields;
		} else if (expiredSwitch) {
			return searchValue.length > 0 ? filteredData.myItems : expiredMonitoring;
		} else if (overviewSwitch) {
			// Toggle between "overview" and "my" views
			return searchValue.length > 0 ? filteredData.items : monitoring;
		} else {
			return searchValue.length > 0 ? filteredData.myItems : myMonitoring;
		}
	}

	useEffect(() => { // check if any monitoring has a monitoring number, if not, hide the table column
		const someMonitoringNumberExists = monitoring?.some(monitoring => monitoring?.monitoring_number !== undefined && monitoring?.monitoring_number !== null && monitoring?.monitoring_number.length > 0);

		setHasMonitoringNumber(someMonitoringNumberExists);
	}, [monitoring]);

	useEffect(() => {
		if (userRole === 'Admin') {
			/* Filter monitoring which has missing required fields */
			const filteredMonitoring = monitoring?.filter(monitoring => {
				/* Check if 'responsible', 'notify_on_failure', 'reviewer' fields are null. */
				return monitoring.responsible === null || monitoring.reviewer === null || monitoring.notify_on_failure === null;
			}) || [];

			const monitoringWithMissingFields = filteredMonitoring.map((monitoring) => ({
				...monitoring,
				missingFields: ['responsible', 'notify_on_failure', 'reviewer'].filter(fieldName => monitoring[fieldName] === null).map(fieldName => t(fieldName, { ns: 'general' })),
			}));

			setMonitoringWithMissingFields(monitoringWithMissingFields);
		}
	}, [monitoring]);

	const columnDef = [
		{
			headerName: ' ',
			cellRenderer: (props) => {

				return <MotifIconButton
					type="button"
					title={t('tablebutton', { ns: 'add_monitoring' })}>
					<MotifIcon src={actionIcAssignment24px} />
				</MotifIconButton>

			},
			field: 'id',
			width: 60,
			pinned: 'left',
			headerComponentParams: {
				dataColumn: false,
				headerEndComponent: (
					<MotifIconButton aria-label="Click here to see options" type="button">
						<MotifIcon src={hardwareIcKeyboardArrowDown24px} />
					</MotifIconButton>
				)
			}
		},
		{
			headerName: '#',
			field: 'monitoring_number',
			minWidth: 100,
			resizable: true,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 1,
			hide: !hasMonitoringNumber,
		},
		{
			headerName: t('missing_fields', { ns: 'general' }),
			field: 'missingFields',
			minWidth: 100,
			sortable: true,
			filter: true,
			flex: 1,
			hide: !(missingFieldsSwitch && monitoringWithMissingFields.length > 0)
		},
		{
			headerName: t('expiry', { ns: 'general' }),
			field: 'expiry',
			minWidth: 100,
			sortable: true,
			filter: true,
			flex: 2,
			hide: !(expiredSwitch && expiredMonitoring.length > 0)
		},
		{
			headerName: t('subtheme', { ns: 'general' }),
			cellRenderer: ({ value }) => {
				const uniqueSubthemes = [...new Set(value.map(control => control.risk.subtheme.description_code))];
				const subthemes = uniqueSubthemes.join(', ');
				return (
					<>
						{subthemes}
					</>
				);
			},
			field: 'controls',
			minWidth: 170,
			resizable: true,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('risk', { ns: 'general' }),
			cellRenderer: ({ value }) => {
				const uniqueRisks = [...new Set(value.map(control => control.risk.risk))];
				const risks = uniqueRisks.join(', ');
				return (
					<>
						{risks}
					</>
				);
			},
			field: 'controls',
			minWidth: 170,
			sortable: true,
			resizable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('monitoring', { ns: 'general' }),
			field: 'monitoring',
			minWidth: 170,
			sortable: true,
			resizable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('monitoring_type', { ns: 'general' }),
			field: 'monitoring_type.description',
			minWidth: 140,
			sortable: true,
			resizable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 1,
		},
		{
			headerName: t('frequency', { ns: 'general' }),
			field: 'frequency.description',
			minWidth: 100,
			sortable: true,
			filter: true,
			flex: 1,
		},
		{
			headerName: t('responsible', { ns: 'general' }),
			field: 'responsible_fullname',
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			flex: 1,
			hide: !overviewSwitch,
		},
		{
			headerName: t('reviewer', { ns: 'general' }),
			field: 'reviewer_fullname',
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			flex: 1,
			hide: !overviewSwitch,
		},
	]

	return (
		<>
			<title>{t('monitoring', { ns: 'menu' })} | {tooltitle}</title>
			<div className="d-flex justify-content-center">
				<h1>{t('monitoring', { ns: 'menu' })}</h1>
			</div>
			{userRole === 'Admin' && monitoring?.length > 0 && !missingFieldsSwitch && !expiredSwitch && (
				<FilterToggleSwitch switchName={'MonitoringOverview'} isLoading={isLoading} overviewSwitch={overviewSwitch} setOverviewSwitch={setOverviewSwitch} />
			)}

			<div className="row justify-content-center">
				<div className="col-8">
					<MotifFormField>
						<MotifSearch
							value={searchValue}
							id="search-monitoring"
							onEnter={event => filterData(event.target.value)}
							onClear={event => filterData('')}
							onSelect={val => setSearchValue(val)}
							aria-label="Search in monitoring"
							placeholder={t('search2', { ns: 'general' })}
							disabled={isLoading} />
					</MotifFormField>
				</div>
			</div>

			{userRole === 'Admin' &&
				<div className="row ms-2 mt-3">
					<div className="col-2">
						<MotifButton
							onClick={() => navigate('/monitoring/add_monitoring/', { state: { monitoringId: null } })}
							disabled={isLoading}
							className="m-2"
						>
							{t('add_monitoring', { ns: 'general' })}
						</MotifButton>
						{(monitoringWithMissingFields.length) ?
							<MotifToggleSwitch
								offLabel=''
								onLabel={t('missing_field_switch', { ns: 'add_monitoring' })}
								checked={missingFieldsSwitch}
								onChange={event => {
									setMissingFieldsSwitch(event.target.checked);
									setExpiredSwitch(false);
									setOverviewSwitch(true);
								}}
								id="toggle2"
							/>
							: null}
					</div>
					<div class="col-auto ms-auto m-1">
						{(expiredMonitoring?.length > 0) ?
							<MotifToggleSwitch
								offLabel={t('expired_switch_off', { ns: 'add_control' })}
								onLabel={t('expired_switch_on', { ns: 'add_monitoring' })}
								checked={expiredSwitch}
								onChange={event => {
									setExpiredSwitch(event.target.checked);
									setMissingFieldsSwitch(false);
									setOverviewSwitch(true);
								}}
								id="toggle3"
							/>
							: null}
					</div>
				</div>
			}

			{monitoring ?
				<div className="row justify-content-center mx-2 mt-3">
					<div className="col">
						{monitoring && myMonitoring ?
							<MotifTable className='pb-5'
								columnDefs={columnDef}
								rowData={getTableData()}
								onRowClicked={row => { setMonitoringData(row.data); setMonitoringDetailsVisibility(!isMonitoringDetailsVisible); }}
								onCellKeyDown={row => {
									if (row.event.key === 'Enter') {
										setMonitoringData(row.data)
										setMonitoringDetailsVisibility(!isMonitoringDetailsVisible);
									}
								}}
								compact={true}
								zebra={true}
								pagination={getTableData()?.length > 30}
								paginationPageSize="25"
							/>
							: null}
					</div>
				</div>
				: null}
			<ScrollRestoration />

			<MonitoringDetails monitoringData={monitoringData} isModalVisible={isMonitoringDetailsVisible} setModalVisibility={setMonitoringDetailsVisibility} navigate={navigate} />

		</>
	)
}

export default MonitoringOverview