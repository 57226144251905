import React, { useState, useEffect } from 'react';
import UploadItem from '../../components/UploadItem';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import { formatDate, adjustFileData, calculateFiscalYear, sanitizeFilename, validateCommentInput } from '../../utils/utils';

import {
    MotifFormField,
    MotifLabel,
    MotifDatePicker,
    MotifOption,
    MotifSelect,
    MotifTextArea,
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifIcon,
    MotifCardHeader,
    MotifCard,
    MotifCardBody,
    MotifIconButton,
    MotifTextLink,
    MotifErrorMessage,
    MotifComment
  } from '@ey-xd/motif-react'

  import { navigationIcMoreHoriz24px, fileIcAttachment24px } from '@ey-xd/motif-react/assets/icons';


const PerformControlForm = ({ controlTask, taskId, taskFinal, setTaskFinal, executionDate, fiscalYear, setFiscalYear, execution, setExecution, clientFiscalYear, role, navigate, request }) => {

    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();
    
    const [isModalVisible, setModalVisibility] = useState(false);
    const [modalData, setModalData] = useState({
      headerText: t('alert', { ns: 'general' }),
      bodyText: '',
      footerContent: '',
      hideCloseButton: true,
    });
    
    const showModal = (bodyText, footerContent, hideCloseButton = true) => {
      setModalData(prevData => ({
        ...prevData,
        bodyText,
        footerContent,
        hideCloseButton,
      }));
      setModalVisibility(true); // Make sure the modal is shown
    };
    
    const [forceRenderKey, setForceRenderKey] = useState('');

    const [performControlId, setPerformControlId] = useState(null);
   
    const [fiscalYearItems, setFiscalYearItems] = useState([]);
    
    // const [execution, setExecution] = useState('');
    const [comments, setComments] = useState('');
    const [attachments, setAttachments] = useState([]);
    const [blobUrls, setBlobUrls] = useState({}); // This will store the blob URLs. Key is attachment ID, value is blob URL.

    const [fileData, setFileData] = useState([]); 

    const [disableForm, setDisableForm] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [formAction, setFormAction] = useState(null);

      useEffect(() => {
        if(controlTask?.deadline) {
            const calculatedFiscalYear = calculateFiscalYear(clientFiscalYear[0], controlTask?.deadline);
            if (calculatedFiscalYear) {
                // Check if the fiscal year format is standard calendar year
                const isStandardCalendarYear = clientFiscalYear[0].start_month === 1 && clientFiscalYear[0].start_day === 1 &&
                    clientFiscalYear[0].end_month === 12 && clientFiscalYear[0].end_day === 31;
    
                let newFiscalYearItems = [];
    
                if (isStandardCalendarYear) {
                    // For standard calendar year, generate options without splitting
                    const year = Number(calculatedFiscalYear); // Convert to number to perform arithmetic
                    newFiscalYearItems = [`${year - 1}`, `${year}`, `${year + 1}`];
                } else {
                    // For split financial years, keep the original logic
                    const yearParts = calculatedFiscalYear.split('/').map(Number); // Convert strings to numbers
                    const prevYear = yearParts[0] - 1; // Previous year
                    const nextYear = (yearParts[1] || yearParts[0]) + 1; // Next year
    
                    newFiscalYearItems = [`${prevYear}/${prevYear + 1}`, calculatedFiscalYear, `${nextYear}/${nextYear + 1}`];
                }
    
                setFiscalYearItems(newFiscalYearItems);
    
                if (!fiscalYear && calculatedFiscalYear) {
                    setFiscalYear(calculatedFiscalYear); // prefill calculated fiscal year based on the deadline
                }
            }
        }
    }, [clientFiscalYear, controlTask?.deadline]);
    

    useEffect(() => {
        !!fiscalYear && setForceRenderKey(crypto.randomUUID());
        !!execution && setForceRenderKey(crypto.randomUUID());
      }, [fiscalYearItems, execution]);

      useEffect(() => {
        const fetchPerformControl = async () => {
          setIsLoading(true);
          try {
            const response = await request.get(`perform-control?controltask=${taskId}`);
            if (response.length > 0) {
              setPerformControlId(response[0]['id']);
              setComments(response[0]['comment'] || ''); // If comment is null, set it to an empty string to avoid errors with .length
              setTaskFinal(response[0].controltask.status == 1);
              setAttachments(response[0]['attachments']);
            } else {
              // No perform control data for this controltask
            }
          } catch (error) {
            console.error(error.message);
          } finally {
            setIsLoading(false);
          }
        }
        fetchPerformControl();
      }, []);

      useEffect(() => {
        if (!taskFinal && attachments?.length > 0) { // allow user to delete attachments if the input is still a draft
          setFileData(adjustFileData(attachments));
        }
      }, [attachments])

      useEffect(() => {
        if (taskFinal 
          || role == 'other'
          || controlTask?.status.id == 1)
          {
            setDisableForm(true)
          }
        else {
          setDisableForm(false)
        }
      }, [controlTask, role]);

      useEffect(() => {
        const fetchBlobs = async () => {
          setIsLoading(true);
          try {
            for (const attachment of attachments) {
              try {
                const response = await request.get(`perform-control/stream_blob/${attachment.id}/`);
                if (response.ok) {
                  const blob = await response.blob();
                  const url = URL.createObjectURL(blob);
          
                  // Store the blob URL in state
                  setBlobUrls(prevUrls => ({ ...prevUrls, [attachment.id]: url }));
                } else {
                  console.error('Failed to fetch blob for attachment.');
                }
              } catch (error) {
                console.error('Failed to fetch blob for attachment.', error.message);
              }
            }
          } finally {
            setIsLoading(false);
          }
        };
      
        if (attachments.length > 0) {
          fetchBlobs();
        }
      }, [attachments]);
      

    const warningForm = () => {
      showModal(
        t('modal_confirm_body', { ns: 'perform_control' }),
        <>
          <MotifButton
            className="me-3"
            disabled={isLoading}
            onClick={() => { submitForm('submit') }}>
            {t('confirm', { ns: 'general' })}
          </MotifButton>
          <MotifButton
            className="me-3"
            disabled={isLoading}
            onClick={() => setModalVisibility(false)}>
            {t('cancel', { ns: 'general' })}
          </MotifButton>
        </>,
        true
      );
    };

    const resetCtask = async () => {
      try {
        setIsLoading(true);
        await request.post(`reset-ctask/${taskId}/`, {}, 'PATCH');
      } catch (error) {
        console.error(error.message)
      } finally {
        navigate('/performcontroloverview');
      }
    };
      
    const validateForm = () => {
      if (fiscalYear &&
          execution.length > 0 &&
          comments.length > 0) {
          return true
      }
      setModalVisibility(false); // geen pop-up venster bij incompleet formulier
      return false;
    }

    const submitForm = async (action) => {
      setIsFormSubmitted(true); 
      setFormAction(action); 
      
      if (action === 'save' || validateForm()) { // Skip validation if action is 'save'
          setIsLoading(true);
          try {
              if(action === 'submit') { 
                  await request.post(`control-task/${taskId}/`, {"status": 1, "fiscal_year": fiscalYear ? fiscalYear : null, execution: execution && execution === '1' ? true : false }, 'PATCH'); // Task is completed, set status completed
                  setDisableForm(true);
                  setTaskFinal(true);
                  
                  showModal(
                    t('modal_complete_body', { ns: 'perform_control' }),
                    <>
                      <MotifButton size="medium" type="button" disabled={isLoading} onClick={() => navigate('/performcontroloverview')}>
                        {t('modal_added_button_taskoverview', { ns: 'perform_control' })}
                      </MotifButton>
                      <MotifButton size="medium" type="button" disabled={isLoading} onClick={() => navigate('/overview')}>
                        {t('dashboard', { ns: 'general' })}
                      </MotifButton>
                    </>
                  );
              } else {
                  await request.post(`control-task/${taskId}/`, {"status": 4, "fiscal_year": fiscalYear ? fiscalYear : null, execution: execution && execution === '1' ? true : false }, 'PATCH'); // Task is saved as draft, set status to 'Being processed by responsible'
                  
                  showModal(
                    t('modal_draft_body', { ns: 'perform_control' }),
                    <>
                      <MotifButton className="me-3" disabled={isLoading} onClick={() => setModalVisibility(false)}>
                        {t('close', { ns: 'general' })}
                      </MotifButton>
                    </>
                  );
              }
  
              let performControlBody = {
                  comment: comments.length > 0 ? comments : null,
                  controltask: taskId,
                  is_draft: action === 'save' ? 1 : 0, 
              };
              
              let url = performControlId ? `perform-control/${performControlId}/` : 'perform-control/';
              let method = performControlId ? 'PATCH' : 'POST';
              
              processFormSubmission(url, performControlId, performControlBody, method);
          } catch (error) {
              console.error(error.message);
          } finally {
              setIsLoading(false);
          }
      }
  };

  const processFormSubmission = async (url, performControlId, performControlBody, method) => {
    setIsLoading(true);
    try {
        let response;
        let currentPerformControlId = performControlId; // Use existing performControlId if it exists

        if (method === 'POST') {
            response = await request.post(`perform-control/`, performControlBody, method);
            currentPerformControlId = response.id; // Update currentPerformControlId with new id
            setPerformControlId(currentPerformControlId); // Update state
        } else if (method === 'PATCH') {
            response = await request.post(`perform-control/${currentPerformControlId}/`, performControlBody, method);
        }

        // File upload logic
        if (fileData.length > 0) {
            const uploadPromises = fileData.filter(file => file.file instanceof Blob).map((file) => {
                const formData = new FormData();
                formData.append('file', file.file);
                formData.append('performcontrol', currentPerformControlId);

                const sanitizedFilename = sanitizeFilename(file.filename);
                
                formData.append('filename', sanitizedFilename);
                formData.append('path', file.path);
                formData.append('timestamp', file.timestamp);

                return request.post(`perform-control/${currentPerformControlId}/upload_document/?taskId=${taskId}`, formData, 'POST');
            });

            await Promise.all(uploadPromises);
        }

        // Use showModal to update body and footer together
        showModal(
            t('modal_complete_body', { ns: 'perform_control' }), // Body text
            <>
                <MotifButton size="medium" type="button" disabled={isLoading} onClick={() => navigate('/performcontroloverview')}>
                    {t('modal_added_button_taskoverview', { ns: 'perform_control' })}
                </MotifButton>
                <MotifButton size="medium" type="button" disabled={isLoading} onClick={() => navigate('/overview')}>
                    {t('dashboard', { ns: 'general' })}
                </MotifButton>
            </> // Footer buttons
        );
    } catch (error) {
        console.error(error.message);
    } finally {
        setIsLoading(false);
    }
  };


  return (
    <>
      {taskFinal && executionDate ? // only show executed date if task is finished
        <MotifFormField>
            <MotifLabel>{t('execution_date', {ns: 'general'})}</MotifLabel>
            <MotifDatePicker
              calendarType="iso8601"
              disabled={true} // auto set in backend, not set by user
              value={executionDate}
              showLeadingZeros={true}
              format="dd-MM-yyyy"
              yearPlaceholder="YYYY"
              dayPlaceholder="DD"
              monthPlaceholder="MM"
            />
        </MotifFormField>
        : null }

        <MotifFormField>
            <MotifLabel id="select-fy">{t('fiscal_year', {ns: 'general'})}</MotifLabel>
            <MotifSelect 
            key={forceRenderKey}
            disabled={disableForm||isLoading}
            value={fiscalYear}
            onChange={val => setFiscalYear(val)}
            ariaLabelledBy="select-fy">
            {fiscalYearItems.map((item, i) => (<MotifOption key={i} value={item}>{item}</MotifOption>))}
            </MotifSelect>
            {(fiscalYear.length == 0 && isFormSubmitted && formAction == 'submit') && <MotifErrorMessage>
              {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
        </MotifFormField> 
        
        <MotifFormField>
            <MotifLabel id="select-execution">{t('executed', {ns: 'general'})}</MotifLabel>
            <MotifSelect 
            key={forceRenderKey}
            disabled={disableForm||isLoading}
            value={execution}
            onChange={val => setExecution(val)}
            ariaLabelledBy="select-execution">
              <MotifOption key={1} value={'1'}>{t('yes', {ns: 'general'})}</MotifOption>
              <MotifOption key={2} value={'0'}>{t('no', {ns: 'general'})}</MotifOption>
            </MotifSelect>
            {(execution.length == 0 && isFormSubmitted && formAction == 'submit') && <MotifErrorMessage>
              {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
        </MotifFormField> 

        { !taskFinal ?
        <MotifFormField>
            <MotifLabel>{t('comments', {ns: 'general'})}</MotifLabel>
            <MotifTextArea 
            value={comments}
            disabled={disableForm||isLoading}
            onChange={event => setComments(validateCommentInput(event.target.value).cleanedValue)}
            maxLength={2000}
            />
            {(comments.length == 0 && isFormSubmitted && formAction == 'submit') && <MotifErrorMessage>
              {t('required_field', {ns: 'general'})}</MotifErrorMessage>}
        </MotifFormField>
        : null}

        { !disableForm ?
          <UploadItem blobPath="controls" deleteUrl="perform-control-documents" fileData={fileData} setFileData={setFileData} taskId={taskId} disabled={disableForm} label={t('task_uploaditem', {ns: 'general'})} maxFiles={30} />
        : null}

        <div className="row mt-2 mb-3" style={{ width: '100%' }}>
          <div className="col d-flex align-items-center">
            <MotifButton
              disabled={disableForm || isLoading}
              className="me-2"  // Use a smaller margin between the first two buttons
              onClick={() => { submitForm('save') }}>
              {t('save', {ns: 'general'})}
            </MotifButton>
            
            <MotifButton
              variant="primary-alt"
              disabled={disableForm || isLoading}
              onClick={() => { warningForm() }}>
              {t('save_submit', {ns: 'general'})}
            </MotifButton>
          </div>

          {/* Conditionally render the reset button if debug features are enabled */
          process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' ? (
            <div className="col text-right d-flex justify-content-end">
              <MotifTextLink href="#" disabled={isLoading} onClick={() => { resetCtask() }}>Reset (debug)</MotifTextLink>
            </div>
          ) : null}
          
        </div>

        { taskFinal && executionDate && comments ?
        <div className="col" style={{display: "block", minWidth: "70%", whiteSpace: 'pre-wrap'}}>
          <MotifComment 
            author={controlTask?.archived_responsible_fullname || controlTask?.archived_function_responsible || t('none', {ns: 'general'})}
            content={comments}
            dateTime={formatDate(executionDate)}
            replyPosition={null}
            // actions={attachments.length > 0 ? <MotifIconButton type="button" aria-label="Actions" onClick={() => showAttachments(attachments)}><MotifIcon src={fileIcAttachment24px} /></MotifIconButton> : ''}
          >
          </MotifComment>
        </div>
        : null }

        <div className="row ms-2 mt-5">
          {  attachments.length > 0 ? 
            <MotifCard>
                <MotifCardHeader variant="dark">
                <span className="motif-h6">{t('attachments', {ns: 'general'})}</span>
                {/* <MotifIconButton aria-label="Click here to see options" type="button">
                    <MotifIcon src={navigationIcMoreHoriz24px} />
                </MotifIconButton> */}
                </MotifCardHeader>
                <MotifCardBody>
                {attachments.map((file, index) => (
                <div key={index}>
                    <MotifTextLink href={blobUrls[file.id]} target="_blank" rel="noopener noreferrer">
                    {file.name}
                    </MotifTextLink>
                </div>
                ))}
                </MotifCardBody>
            </MotifCard>
            : null
          }
        </div>

        <MotifModal show={isModalVisible} onClose={() => window.location.reload()}>
        <MotifModalHeader closeModalButton={modalData.hideCloseButton}>
          {modalData.headerText}
        </MotifModalHeader>
        <MotifModalBody>{modalData.bodyText}</MotifModalBody>
        <MotifModalFooter>{modalData.footerContent}</MotifModalFooter>
      </MotifModal>
    </>
    );
}
        

export default PerformControlForm;