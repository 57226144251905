import React, { useState, useEffect, useRef, useCallback } from 'react';
import { json, useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';
import { formatDate, validateGeneralInput, validateDescriptionInput } from '../../utils/utils';

import {
    MotifLabel,
    MotifFormField,
    MotifSelect,
    MotifOption,
    MotifTextArea,
    MotifButton,
    MotifIconButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifDatePicker,
    MotifMessage,
    MotifErrorMessage,
    MotifIcon,
    MotifTooltip,
    MotifInput,
    MotifTable
} from '@ey-xd/motif-react';

import FormErrorMessage from '../../components/FormErrorMessage';

import { MakeRequests } from '../../utils/request';

import ScreenReaderLabel from '../../components/ScreenReaderLabel';

import { contentIcFilterList24px, actionIcDeleteForever24px, actionIcHelpOutline24px, contentIcClear24px, hardwareIcKeyboardArrowDown24px } from '@ey-xd/motif-react/assets/icons';
import { tooltitle } from '../../utils/utils';

const AddMonitoring = () => {

    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();
    const [dataFetched, setDataFetched] = useState(false);

    const { state } = useLocation();
    const { monitoringId } = state || {};
    const navigate = useNavigate();
    const baseUrl = 'create-monitoring/'
    const screenReaderAlertRef = useRef(null);

    // form variables
    const [control, setControl] = useState('');
    const [controls, setControls] = useState('');
    const [monitoring, setMonitoring] = useState('');
    const [monitoringType, setMonitoringType] = useState('');
    const [frequency, setFrequency] = useState('');
    const [responsible, setResponsible] = useState('');
    const [reviewer, setReviewer] = useState('');
    const [notifyOnFailure, setNotifyOnFailure] = useState('');
    const [monitoringDescription, setMonitoringDescription] = useState('');
    const [proof, setProof] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [deadline, setDeadline] = useState(null);
    const [expiry, setExpiry] = useState(null);
    const [controlItems, setControlItems] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState([]); // store the entity IDs of the selected controls, so responsible/reviewer/NoF persons can be filtered
    const [filteredControlItems, setFilteredControlItems] = useState([]);
    const [monitoringTypeItems, setMonitoringTypeItems] = useState([]);
    const [frequencyItems, setFrequencyItems] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [responsibleItems, setResponsibleItems] = useState([]);
    const [reviewerItems, setReviewerItems] = useState([]);
    const [notifyOnFailureItems, setNotifyOnFailureItems] = useState([]);
    const [checkedStart, setCheckedStart] = useState(false);
    const [checkedDeadline, setCheckedDeadline] = useState(false);
    const [cstartDate, setCStartDate] = useState(null);
    const [cDeadline, setCDeadline] = useState(null);

    // form validation variables
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    // modal variables
    const [isModalVisible, setModalVisibility] = useState(false);
    const [isModalAddItemVisible, setModalAddItemVisibility] = useState(false);
    const [isModalFiltersVisible, setModalFiltersVisibility] = useState(false);
    const [isMessageModalVisible, setMessageModalVisibility] = useState(false);

    const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false);
    const [isDeletedModalVisible, setDeletedModalVisibility] = useState(false);
    // State to hold fetched tasks and selected tasks during deletion
    const [pendingTasks, setPendingTasks] = useState([]);
    const [selectedTasks, setSelectedTasks] = useState([]);

    const request = new MakeRequests();

    // filter: belastingmiddel, proces, risico
    const [initialSubthemeItems, setInitialSubthemeItems] = useState([]);
    const [initialProcessItems, setInitialProcessItems] = useState([]);
    const [initialRiskItems, setInitialRiskItems] = useState([]);

    const [filteredSubthemeItems, setFilteredSubthemeItems] = useState([]);
    const [filteredProcessItems, setFilteredProcessItems] = useState([])
    const [filteredRiskItems, setFilteredRiskItems] = useState([])

    const [subtheme, setSubtheme] = useState([]);
    const [process, setProcess] = useState([]);
    const [risk, setRisk] = useState([]);

    const [isFiltered, setIsFiltered] = useState(false);

    const [subthemeItems, setSubthemeItems] = useState([]);
    const [processItems, setProcessItems] = useState([]);
    const [riskItems, setRiskItems] = useState([]);
    // end filter

    const [monitoringNumber, setMonitoringNumber] = useState('');

    // This useEffect handles setting selected entities after controls are set
    useEffect(() => {
        if (!dataFetched || !controls.length) return;
        const entityIds = new Set(); // Use a Set to ensure uniqueness

        // Iterate over each control ID in controls
        controls.forEach((controlId) => {
            // Find the corresponding control item by ID
            const controlItem = controlItems.find(item => item.id.toString() === controlId);

            // If found, extract each entity ID from the control item and add it to the set
            controlItem?.entity.forEach(entity => {
                entityIds.add(entity.id.toString()); // Convert to string if needed, depending on your ID format
            });
        });

        // Convert the Set to an array and update the selectedEntities state
        setSelectedEntities([...entityIds]);
    }, [controls, controlItems, dataFetched]);

    // This useEffect handles filtering employees based on selected entities
    useEffect(() => {
        if (!dataFetched || !selectedEntities.length) return;

        // Filter out employees based on their eligibility
        const filterOutByIds = (employeeList, idsToFilterOut) => {
            return employeeList.filter(
                (employee) => !idsToFilterOut.includes(employee.id.toString())
            );
        };

        const checkIfStillEligible = (employeeIdList, eligibleEntities) => {
            return employeeIdList.every((employeeId) => {
                const employee = employees.find((emp) => emp.id.toString() === employeeId);
                return employee?.entities.some((entityId) =>
                    eligibleEntities.includes(entityId.toString()) // Ensure comparison consistency
                );
            });
        };

        // Check if the current selections are still valid
        const currentSelectionIds = [responsible, reviewer, notifyOnFailure].filter(id => id);
        const allAreEligible = checkIfStillEligible(currentSelectionIds, selectedEntities);

        if (!allAreEligible) {
            // If not all are eligible, reset the fields
            setResponsible('');
            setReviewer('');
            setNotifyOnFailure('');
        }

        // Filter and sort employees
        const filteredEmployees = employees.filter(employee =>
            employee.entities.some(entityId => selectedEntities.includes(entityId.toString()))
        );

        const sortByFirstName = (a, b) => a.first_name.toUpperCase().localeCompare(b.first_name.toUpperCase());

        const responsibleFiltered = filterOutByIds(filteredEmployees, [reviewer, notifyOnFailure].filter(Boolean)).sort(sortByFirstName);
        const reviewerFiltered = filterOutByIds(filteredEmployees, [responsible, notifyOnFailure].filter(Boolean)).sort(sortByFirstName);
        const notifyOnFailureFiltered = filterOutByIds(filteredEmployees, [responsible, reviewer].filter(Boolean)).sort(sortByFirstName);

        setResponsibleItems(responsibleFiltered);
        setReviewerItems(reviewerFiltered);
        setNotifyOnFailureItems(notifyOnFailureFiltered);
    }, [responsible, reviewer, notifyOnFailure, selectedEntities, employees, dataFetched]);

    const fieldTranslations = {
        controls: { key: 'controls', ns: 'general', required: true },
        monitoringNumber: { key: 'monitoring_number', ns: 'general', required: false },
        monitoring: { key: 'monitoring', ns: 'general', required: true },
        monitoringType: { key: 'monitoring_type', ns: 'general', required: true },
        startDate: { key: 'first_startdate', ns: 'general', required: true }, 
        deadline: { key: 'first_deadline', ns: 'general', required: true },
        expiry: { key: 'expiry_optional', ns: 'general', required: false },
        frequency: { key: 'frequency', ns: 'general', required: true },
        responsible: { key: 'responsible', ns: 'general', required: true },
        reviewer: { key: 'reviewer', ns: 'general', required: true },
        notifyOnFailure: { key: 'notify_on_failure', ns: 'general', required: true },
        monitoringDescription: { key: 'monitoring_desc', ns: 'general', required: true },
        proof: { key: 'proof', ns: 'general', required: true },
    };    
  
    const validateForm = () => {
        const errors = Object.entries(fieldTranslations)
            .filter(([field, { required }]) => {
                const fieldValue = eval(field); // Dynamically get the field's value
    
                if (!required) return false; // Skip non-required fields
    
                // Validate based on field type
                if (typeof fieldValue === 'string') {
                    return fieldValue.trim().length === 0; // Check for empty strings
                } else if (Array.isArray(fieldValue)) {
                    return fieldValue.length === 0; // Check for empty arrays
                } else {
                    return !fieldValue; // Check for undefined, null, or other falsy values
                }
            })
            .map(([field]) => field);

        if (errors.length > 0) {
            const unfilledFields = errors.map(field => {
                const { key, ns } = fieldTranslations[field];
                return t(key, { ns });
            }).join(', ');
    
            screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields}`;
            return false;
        }
    
        return true;
    };

    const submitForm = () => {

        setIsFormSubmitted(true);

        if (validateForm()) {
            postData(monitoringId ? 'PUT' : 'POST');
            setModalAddItemVisibility(true);
        }

    }

    const postData = async (how) => {

        if (how === 'POST' || how === 'PUT') {
            var startDateFormat = formatDate(startDate);
            var deadlineFormat = formatDate(deadline);
            if (expiry !== null) {
                var expiryFormat = formatDate(expiry);
            } else {
                var expiryFormat = null
            }
        }

        var monitoringBody = {
            'controls': controls,
            'monitoring': monitoring,
            'monitoring_type': monitoringType,
            'monitoring_desc': monitoringDescription,
            'proof': proof,
            'frequency': frequency,
            'responsible': responsible,
            'reviewer': reviewer,
            'notify_on_failure': notifyOnFailure,
            'start_date': startDateFormat,
            'deadline': deadlineFormat,
            'expiry': expiryFormat,
            'deleted': 0,
            'monitoring_number': monitoringNumber
        };

        if (how === 'POST') {

            try {
                await request.post(baseUrl, monitoringBody, how);
                setModalAddItemVisibility(true);
            } catch (error) {
                console.error(error.message)
            } finally {
                setIsLoading(false)
            }

        } else if (how === 'PUT') {

            try {
                await request.post(`${baseUrl}${monitoringId}/`, monitoringBody, how)
                setModalAddItemVisibility(true);
            }
            catch (error) {
                console.error(error.message)
            } finally {
                setIsLoading(false)
            }
        }
    }

    const fetchPendingTasksForMonitoring = async () => {
        try {
            setIsLoading(true);
            const data = await request.get(`del-related-mtask?related_delete=${monitoringId}`, {});
            // Check if tasks were returned
            if (data && data.length > 0) {
                setPendingTasks(data);
                setDeleteModalVisibility(true);
            } else {
                // If no tasks are found, proceed to delete the monitoring
                await deleteMonitoring(monitoringId);
            }
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const deleteMonitoring = async () => {
        try {
            setIsLoading(true);
            // Delete selected tasks if any
            if (selectedTasks.length > 0) {
                await request.post(`del-related-mtask`, { "tasks": selectedTasks }, 'DELETE');
            }

            // Soft delete the monitoring
            await request.post(`${baseUrl}${monitoringId}/`, { "deleted": true }, 'PATCH');

            setDeleteModalVisibility(false); // Close the current modal
            setDeletedModalVisibility(true); // Show the confirmation modal
        } catch (error) {
            console.error(error.message);
        } finally {
            setIsLoading(false);
        }
    };

    const getmonitoringData = async () => {
        setIsLoading(true);

        try {
            var monitoringData = await request.get(`${baseUrl}${monitoringId}`, {});
            const controlIds = monitoringData.controls?.map(control => control.id.toString());
            setControls(controlIds);
            setMonitoring(monitoringData['monitoring']);
            setMonitoringType(monitoringData['monitoring_type']['id'].toString());
            setFrequency(monitoringData['frequency']['id'].toString());
            if (monitoringData['responsible'] !== null) {
                setResponsible(monitoringData['responsible'].toString());
            }
            if (monitoringData['reviewer'] !== null) {
                setReviewer(monitoringData['reviewer'].toString());
            }
            if (monitoringData['notify_on_failure'] !== null) {
                setNotifyOnFailure(monitoringData['notify_on_failure'].toString());
            }
            setMonitoringDescription(monitoringData['monitoring_desc']);
            setProof(monitoringData['proof']);
            setStartDate(new Date(monitoringData['start_date']));
            setDeadline(new Date(monitoringData['deadline']));
            setCStartDate(new Date(monitoringData['start_date']));
            setCDeadline(new Date(monitoringData['deadline']));
            setCheckedStart(true);
            setCheckedDeadline(true);
            if (monitoringData['expiry']) {
                setExpiry(new Date(monitoringData['expiry']));
            }
            if (monitoringData['monitoring_number']) {
                setMonitoringNumber(monitoringData['monitoring_number']);
            }
        } catch (error) {
            console.error(error.message)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            try {
                const initialControlItems = await request.get(`control`, {})
                setControlItems(initialControlItems);
                setFilteredControlItems(initialControlItems);

                setMonitoringTypeItems(await request.get(`monitoring-type`, {}));
                setFrequencyItems(await request.get(`frequency`, {}));

                var employees = await request.get(`all-employee-details`, {})
                var filteredEmployees = employees.filter((item) => item.status.id === 1 || item.status.id === 2); // Only client-admins and users
                setEmployees(filteredEmployees);
                setResponsibleItems(filteredEmployees);
                setReviewerItems(filteredEmployees);
                setNotifyOnFailureItems(filteredEmployees);

                const processRiskItems = await request.get(`risk`, {});
                const uniqueProcessItems = [...new Set(processRiskItems.map(item => item.process))]; // use unique process entries for the process dropdown
                setProcessItems(uniqueProcessItems);
                setRiskItems(processRiskItems);

                setSubthemeItems(await request.get(`subtheme`, {}));

            } catch (error) {
                console.error(error.message)
            } finally {
                monitoringId != null && await getmonitoringData();
                setIsLoading(false);
                setDataFetched(true);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const filteredSubthemeItems = subthemeItems.filter(
            subthemeItem => controlItems.some(
                controlItem => controlItem.risk.subtheme.id == subthemeItem.id
            )
        );
        setInitialSubthemeItems(filteredSubthemeItems);
        setFilteredSubthemeItems(filteredSubthemeItems);

        const filteredProcessItems = processItems.filter(
            processItem => controlItems.some(
                controlItem => controlItem.risk.process == processItem
            )
        );
        setInitialProcessItems(filteredProcessItems)
        setFilteredProcessItems(filteredProcessItems);

        const uniqueRiskItems = [...new Set(riskItems.map(item => item.risk))];
        const filteredRiskItems = uniqueRiskItems.filter(
            riskItem => controlItems.some(
                controlItem => controlItem.risk.risk == riskItem
            )
        );
        setInitialRiskItems(filteredRiskItems);
        setFilteredRiskItems(filteredRiskItems);
    }, [subthemeItems, riskItems, processItems]);

    useEffect(() => {   // filter select boxes
        if (subtheme && subtheme.length > 0) {
            const filteredProcessItems = initialProcessItems.filter(
                processItem => controlItems.some(
                    controlItem => subtheme.includes(controlItem.risk.subtheme.id.toString()) && controlItem.risk.process == processItem
                )
            );

            const filteredRiskItems = initialRiskItems.filter(
                riskItem => controlItems.some(
                    controlItem => subtheme.includes(controlItem.risk.subtheme.id.toString()) && controlItem.risk.risk == riskItem
                )
            );
            setFilteredProcessItems(filteredProcessItems);
            setFilteredRiskItems(filteredRiskItems);
        } else {
            setFilteredProcessItems(initialProcessItems);
            setFilteredRiskItems(initialRiskItems);
        }
    }, [subtheme]);

    useEffect(() => {
        if (process && process.length > 0) {
            const filteredSubthemeItems = initialSubthemeItems.filter(
                subthemeItem => controlItems.some(
                    controlItem => process.includes(controlItem.risk.process) && controlItem.risk.subtheme.id == subthemeItem.id
                )
            );

            const filteredRiskItems = initialRiskItems.filter(
                riskItem => controlItems.some(
                    controlItem => process.includes(controlItem.risk.process) && controlItem.risk.risk == riskItem
                )
            );
            setFilteredSubthemeItems(filteredSubthemeItems);
            setFilteredRiskItems(filteredRiskItems);
        } else {
            setFilteredSubthemeItems(initialSubthemeItems);
            setFilteredRiskItems(initialRiskItems);
        }
    }, [process]);

    useEffect(() => {
        if (risk && risk.length > 0) {
            const filteredSubthemeItems = initialSubthemeItems.filter(
                subthemeItem => controlItems.some(
                    controlItem => risk.includes(controlItem.risk.risk) && controlItem.risk.subtheme.id == subthemeItem.id
                )
            );

            const filteredProcessItems = initialProcessItems.filter(
                processItem => controlItems.some(
                    controlItem => risk.includes(controlItem.risk.risk) && controlItem.risk.process == processItem
                )
            );
            setFilteredSubthemeItems(filteredSubthemeItems);
            setFilteredProcessItems(filteredProcessItems);
        } else {
            setFilteredSubthemeItems(initialSubthemeItems);
            setFilteredProcessItems(initialProcessItems);
        }
    }, [risk]);

    const setFilters = () => {
        setIsFiltered(true);
        setModalFiltersVisibility(false);
        const filters = [
            { field: 'risk.subtheme.id', value: subtheme },
            { field: 'risk.process', value: process },
            { field: 'risk.risk', value: risk },
        ];

        if (filters) {
            const filteredArray = controlItems.filter((item) => {
                return filters.some((filter) => {

                    if (!Array.isArray(filter.value) || filter.value.length === 0) {
                        return false;
                    }

                    if (filter.field === 'risk.subtheme.id') {
                        return item.risk.subtheme && filter.value.includes(item.risk.subtheme.id.toString());
                    }
                    else {
                        return filter.value.includes(item.risk[filter.field.split('.')[1]]);
                    }
                });
            });
            setFilteredControlItems(filteredArray);
        }
    };

    const clearFilters = () => {
        setIsFiltered(false);

        setSubtheme([]);
        setProcess([]);
        setRisk([]);

        setFilteredControlItems(controlItems);
        setFilteredSubthemeItems(initialSubthemeItems);
        setFilteredProcessItems(initialProcessItems);
        setFilteredRiskItems(initialRiskItems);
    }

    const columnDef = [
        {
            headerName: ' ',
            cellRenderer: (props) => {
                const isChecked = selectedTasks.includes(props.data.id);
          
                return (
                  <input
                    type="checkbox"
                    checked={isChecked}
                    readOnly={true} // check box toggle is handled by onRowClicked
                  />
                );
            },
            field: 'id',
            width: 43,
            pinned: 'left',
            headerComponentParams: {
                dataColumn: false,
                headerEndComponent: (
                    <MotifIconButton aria-label="Click here to see options" type="button">
                        <MotifIcon src={hardwareIcKeyboardArrowDown24px} />
                    </MotifIconButton>
                )
            }
        },
        {
            headerName: t('monitoring', { ns: 'general' }),
            field: 'monitoring.monitoring_desc',
            minWidth: 170,
            sortable: true,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 3,
        },
        {
            headerName: t('status', { ns: 'general' }),
            field: 'status.description',
            width: 250,
            sortable: true,
            filter: true,
            wrapText: true,
            resizable: true,
            autoHeight: true,
        },
        {
            headerName: t('startdate', { ns: 'general' }),
            field: 'start_date',
            width: 180,
            sortable: true,
            filter: true,
            sort: 'asc',
        },
        {
            headerName: t('deadline', { ns: 'general' }),
            field: 'deadline',
            width: 180,
            sortable: true,
            filter: true,
        },
        {
            headerName: t('responsible', { ns: 'general' }),
            field: 'responsible_fullname',
            minWidth: 140,
            wrapText: true,
            sortable: true,
            filter: true,
            flex: 1,
        },
        {
            headerName: t('reviewer', { ns: 'general' }),
            field: 'reviewer_fullname',
            minWidth: 140,
            wrapText: true,
            sortable: true,
            filter: true,
            flex: 1,
        },
    ];

    // Handler function to select/deselect tasks
    const handleSelectTask = (id) => {
        setSelectedTasks((prevSelectedTasks) =>
            prevSelectedTasks.includes(id)
            ? prevSelectedTasks.filter((taskId) => taskId !== id)
            : [...prevSelectedTasks, id]
        );
    };
        
    // onRowClicked function for handling row clicks
    const handleRowClick = (params) => {
        handleSelectTask(params.data.id); // Call the function to toggle the task selection
    };

    const handleKeyDown = (event) => {
        if (event.event.key === 'Enter') {  // Check for Enter key
        const rowData = event.node.data;  // Retrieve row data from the event
        handleRowClick({ data: rowData }); // Pass the data to handleRowClick function
        }
    };

    return (
        <>
        <title>{t('add_monitoring', {ns: 'menu'})} | {tooltitle}</title>
        <div className="d-flex justify-content-center">
            <h1>{t('add_monitoring', {ns: 'menu'})}</h1>
        </div>
            <style jsx="true">{`
            .motif-date-picker-wrapper {
                max-width: 18rem;
                    
            }
            .flex-container {
                display: flex;
                align-items: center;
            }
            .motif-modal-size-xl {  /* make the xl modal wider */
                width: 70%;
                max-width: 100%; /* overwrite the default max-width */
                height: 30%;
                overflow: auto;
            }
            .motif-table .motif-table-wrapper {
                /* for unknown reason, it locks the MotifTable wrapper size to 300px height and width, making the table very tiny. Override with this. */
                width: 100%;
                height: auto;
                padding: 1%;
            }
            `}</style>

            <div className="row justify-content-center mx-2 mt-5"
                style={{
                    overflowY: 'scroll',
                    height: '80vh'
                }}>
                <div className="col-6">
                    <div className="d-flex flex-row-reverse">
                        {isFiltered && <MotifIconButton className="p-1" type="button" onClick={clearFilters}><MotifIcon src={actionIcDeleteForever24px} /></MotifIconButton>}
                        <MotifIconButton className="p-1" type="button" onClick={() => setModalFiltersVisibility(true)}><MotifIcon src={contentIcFilterList24px} /><MotifLabel>Filter</MotifLabel></MotifIconButton>
                    </div>

                    <MotifFormField>
                        <MotifLabel id="select-controls">{t('controls', { ns: 'general' })}</MotifLabel>
                        <ScreenReaderLabel id='sr-controls' message={`${t('controls', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
                        <MotifSelect
                            multiple="true"
                            value={controls}
                            disabled={isLoading}
                            ariaLabelledBy={'sr-controls'}
                            onChange={val => setControls(val)}>
                            {filteredControlItems.map((item, i) => (<MotifOption key={i} value={item.id.toString()}>{item.control}</MotifOption>))}
                        </MotifSelect>
                        <MotifMessage>{filteredControlItems.length + ' results'}</MotifMessage>
                        {(controls.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('monitoring_number', { ns: 'general' })}</MotifLabel>
                        <MotifInput value={monitoringNumber ? monitoringNumber : ''}
                            onChange={event => setMonitoringNumber(validateGeneralInput(event.target.value).cleanedValue)}
                            disabled={isLoading}
                            maxLength={10} />
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('monitoring', { ns: 'general' })}</MotifLabel>
                        <ScreenReaderLabel id='sr-monitoring' message={`${t('monitoring', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
                        <MotifInput value={monitoring}
                            onChange={event => setMonitoring(validateDescriptionInput(event.target.value).cleanedValue)}
                            disabled={isLoading}
                            aria-labelledby='sr-monitoring'
                            maxLength={100} />
                        {(monitoring.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id="select-monitoringtype">{t('monitoring_type', { ns: 'general' })}</MotifLabel>
                        <ScreenReaderLabel id='sr-monitoringtype' message={`${t('monitoring_type', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
                        <MotifSelect
                            value={monitoringType}
                            disabled={isLoading}
                            ariaLabelledBy={'sr-monitoringtype'}
                            onChange={val => setMonitoringType(val)}>
                            {monitoringTypeItems.map((item, i) => (<MotifOption key={i} value={item.id.toString()}>{item.description}</MotifOption>))}
                        </MotifSelect>
                        {(monitoringType.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                    </MotifFormField>


                    <div className="d-flex flex-wrap justify-content-between">
                        <div className="d-flex" style={{ width: '100%' }}>
                        { !monitoringId ? // hide startdate and deadline in edit mode, the first task has already been created
                            <MotifFormField tabindex='0' className="me-3" style={{ flex: '1' }}>
                                <MotifLabel>{t('first_startdate', {ns: 'general'})}</MotifLabel>
                                <ScreenReaderLabel id='sr-startdate' message={`${t('first_startdate', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                                <MotifDatePicker
                                    calendarType="iso8601"
                                    value={startDate}
                                    onChange={date => setStartDate(date)}
                                    showLeadingZeros={true}
                                    disabled={isLoading}
                                    format="dd-MM-yyyy"
                                    yearPlaceholder="YYYY"
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    maxDate={deadline ? deadline : null}
                                    ariaLabelledBy='sr-startdate'
                                />
                                {(startDate === null && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                            </MotifFormField>
                            : null}
                            { !monitoringId ? // hide startdate and deadline in edit mode, the first task has already been created
                            <MotifFormField tabindex='0' style={{ flex: '1' }}>
                                <MotifLabel>{t('first_deadline', {ns: 'general'})}</MotifLabel>
                                <ScreenReaderLabel id='sr-deadline' message={`${t('first_deadline', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                                <MotifDatePicker
                                    calendarType="iso8601"
                                    value={deadline}
                                    onChange={date => setDeadline(date)}
                                    showLeadingZeros={true}
                                    disabled={isLoading}
                                    format="dd-MM-yyyy"
                                    yearPlaceholder="YYYY"
                                    dayPlaceholder="DD"
                                    monthPlaceholder="MM"
                                    minDate={startDate ? startDate : null}
                                    ariaLabelledBy='sr-deadline'
                                />
                                {(deadline === null && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                            </MotifFormField>
                            : null}
                        </div>
                        <MotifFormField tabindex='0' style={{ width: '100%' }}>
                            <div className="flex-container" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                <MotifLabel>{t('expiry_optional', {ns: 'general'})}</MotifLabel>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <MotifDatePicker
                                        calendarType="iso8601"
                                        value={expiry}
                                        onChange={date => setExpiry(date)}
                                        showLeadingZeros={true}
                                        disabled={isLoading}
                                        format="dd-MM-yyyy"
                                        yearPlaceholder="YYYY"
                                        dayPlaceholder="DD"
                                        monthPlaceholder="MM"
                                        minDate={deadline ? deadline : null}
                                    />
                                    <MotifTooltip
                                        id="tooltip-2"
                                        trigger={
                                            <MotifIconButton aria-label="Tooltip" type="button" aria-describedby="tooltip-2">
                                                <MotifIcon src={actionIcHelpOutline24px} />
                                            </MotifIconButton>
                                        }
                                        variant="alt"
                                        placement="top"
                                        flip={true}
                                        hideCloseButton={false}
                                    >
                                        {t('expiry_tooltip', {ns: 'add_monitoring'})}
                                    </MotifTooltip>
                                </div>
                                { expiry ?
                                <MotifIconButton aria-label="Clear" type="button" onClick={() => {setExpiry(null)}} style={{ marginTop: '8px' }}>
                                    <MotifIcon src={contentIcClear24px} /> 
                                </MotifIconButton> : ''}
                            </div>
                        </MotifFormField>
                    </div>

                    <MotifFormField>
                        <MotifLabel id="select-frequency">{t('frequency', { ns: 'general' })}</MotifLabel>
                        <ScreenReaderLabel id='sr-frequency' message={`${t('frequency', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
                        <MotifSelect
                            value={frequency}
                            onChange={val => setFrequency(val)}
                            ariaLabelledBy={'sr-frequency'}
                            disabled={isLoading}>
                            {frequencyItems.map((item, i) => (<MotifOption key={i} value={item.id.toString()}>{item.description}</MotifOption>))}
                        </MotifSelect>
                        {(frequency.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id="select-responsible">{t('responsible', { ns: 'general' })}</MotifLabel>
                        <ScreenReaderLabel id='sr-responsible' message={`${t('responsible', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect
                            value={responsible}
                            onChange={val => setResponsible(val)}
                            disabled={isLoading}
                            ariaLabelledBy={'sr-responsible'}
                            filter>
                            {responsibleItems.map(item => <MotifOption key={item.id}
                                value={item.id.toString()}>{`${item.first_name} ${item.last_name}`}</MotifOption>)}
                        </MotifSelect>
                        {(responsible.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id="select-reviewer">{t('reviewer', { ns: 'general' })}</MotifLabel>
                        <ScreenReaderLabel id='sr-reviewer' message={`${t('reviewer', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
                        <MotifSelect
                            value={reviewer}
                            onChange={val => setReviewer(val)}
                            disabled={isLoading}
                            ariaLabelledBy={'sr-reviewer'}
                            filter>
                            {reviewerItems.map(item => <MotifOption key={item.id}
                                value={item.id.toString()}>{`${item.first_name} ${item.last_name}`}</MotifOption>)}
                        </MotifSelect>
                        {(reviewer.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel id="select-notifyonfailure">{t('notify_on_failure', { ns: 'general' })}</MotifLabel>
                        <ScreenReaderLabel id='sr-nof' message={`${t('notify_on_failure', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
                        <MotifSelect
                            value={notifyOnFailure}
                            onChange={val => setNotifyOnFailure(val)}
                            disabled={isLoading}
                            ariaLabelledBy={'sr-nof'}
                            filter>
                            {notifyOnFailureItems.map(item => <MotifOption key={item.id}
                                value={item.id.toString()}>{`${item.first_name} ${item.last_name}`}</MotifOption>)}
                        </MotifSelect>
                        {(notifyOnFailure.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                        {((responsible.length > 0 && reviewer.length > 0 && responsible == reviewer) ||
                            (responsible.length > 0 && notifyOnFailure.length > 0 && responsible == reviewer) ||
                            (reviewer.length > 0 && notifyOnFailure.length > 0 && reviewer == notifyOnFailure)) && <MotifErrorMessage>
                                {t('valid_responsible_reviewer_notifyonfailure', { ns: 'add_monitoring' })}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('monitoring_desc', { ns: 'general' })}</MotifLabel>
                        <ScreenReaderLabel id='sr-description' message={`${t('monitoring_desc', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
                        <MotifTextArea value={monitoringDescription}
                            onChange={event => setMonitoringDescription(validateDescriptionInput(event.target.value).cleanedValue)}
                            rows={5}
                            disabled={isLoading}
                            aria-labelledby='sr-description'
                            maxLength={2000} />
                        {(monitoringDescription.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('proof', { ns: 'general' })}</MotifLabel>
                        <ScreenReaderLabel id='sr-proof' message={`${t('proof', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
                        <MotifTextArea value={proof}
                            onChange={event => setProof(validateDescriptionInput(event.target.value).cleanedValue)}
                            rows={5}
                            disabled={isLoading}
                            aria-labelledby='sr-proof'
                            maxLength={2000} />
                        {(proof.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', {ns: 'screenreader'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <div className="d-flex flex-row">
                        <MotifButton
                            className="mt-2"
                            onClick={() => {

                                submitForm()
                            }}
                            disabled={isLoading}>
                            {monitoringId ? t('edit', { ns: 'general' }) : t('add', { ns: 'general' })}
                        </MotifButton>

                        {monitoringId &&
                            <MotifButton
                                className="m-2"
                                variant="warn"
                                onClick={() => setModalVisibility(true)}
                                disabled={isLoading}>
                                {t('remove', { ns: 'general' })}
                            </MotifButton>
                        }

                    </div>
                    <div
                        ref={screenReaderAlertRef}
                        aria-live="assertive"
                        className="visually-hidden"
                        role="alert"
                    ></div>
                </div>
            </div>
            <MotifModal show={isModalFiltersVisible} onClose={() => setModalFiltersVisibility(false)} variant="slideout">
                <MotifModalHeader>{t('control_filter', { ns: 'add_monitoring' })}</MotifModalHeader>
                <MotifModalBody>
                    <MotifFormField>
                        <MotifLabel id="select-subtheme">{t('subtheme_filter', { ns: 'add_monitoring' })}</MotifLabel>
                        <MotifSelect
                            showSelectAllButton={true}
                            filter={true}
                            multiple={true}
                            value={subtheme}
                            onChange={val => setSubtheme(val)}
                        >
                            {filteredSubthemeItems.map((item, i) => (<MotifOption key={i} value={item.id.toString()}>{item.description_code}</MotifOption>))}
                        </MotifSelect>
                        <MotifMessage>{filteredSubthemeItems.length + ' results'}</MotifMessage>
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel id="select-process">{t('process_filter', { ns: 'add_monitoring' })}</MotifLabel>
                        <MotifSelect
                            showSelectAllButton={true}
                            filter={true}
                            multiple={true}
                            value={process}
                            onChange={val => setProcess(val)}
                        >
                            {filteredProcessItems.map((process, i) => (<MotifOption key={i} value={process}>{process}</MotifOption>))}
                        </MotifSelect>
                        <MotifMessage>{filteredProcessItems.length + ' results'}</MotifMessage>
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel id="select-risk">{t('risk_filter', { ns: 'add_monitoring' })}</MotifLabel>
                        <MotifSelect
                            showSelectAllButton={true}
                            filter={true}
                            multiple={true}
                            value={risk}
                            onChange={val => setRisk(val)}
                        >
                            {filteredRiskItems.map((risk, i) => (<MotifOption key={i} value={risk}>{risk}</MotifOption>))}
                        </MotifSelect>
                        <MotifMessage>{filteredRiskItems.length + ' results'}</MotifMessage>
                    </MotifFormField>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={setFilters}>
                        {t('apply', { ns: 'general' })}
                    </MotifButton>
                    <MotifButton size="medium" type="button" variant="warn" onClick={clearFilters}>
                        {t('reset', { ns: 'general' })}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>


            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader closeModalButton={true}>{t('modal_delete_confirm_header', { ns: 'add_monitoring' })}</MotifModalHeader>
                <MotifModalBody>{t('modal_delete_confirm_body', { ns: 'add_monitoring' })}</MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={fetchPendingTasksForMonitoring}>
                        {t('confirm', { ns: 'general' })}
                    </MotifButton>
                    <MotifButton
                        size="medium"
                        type="button"
                        onClick={() => setModalVisibility(false)}
                    >
                        {t('cancel', { ns: 'general' })}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isDeleteModalVisible} onClose={() => setDeleteModalVisibility(false)} size='xl' style={{ minHeight: "55%" }}>
                <MotifModalHeader>{t('modal_delete_confirm_header', { ns: 'add_monitoring' })}</MotifModalHeader>
                <MotifModalBody>
                    <>
                        {pendingTasks.length > 0 ?
                            <>
                                <div>{t('modal_delete_pendingtasks', { ns: 'add_monitoring' })}</div>
                                <MotifTable className='mb-3 h-auto'
                                    columnDefs={columnDef}
                                    rowData={pendingTasks}
                                    onRowClicked={handleRowClick}
                                    onCellKeyDown={(event) => handleKeyDown(event)} 
                                    compact={true}
                                    zebra={true}
                                />
                            </>
                            : null}
                    </>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={deleteMonitoring}>
                        {t('confirm', { ns: 'general' })}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isDeletedModalVisible} onClose={() => setDeletedModalVisibility(false)}>
                <MotifModalHeader closeModalButton={true}>{t('modal_delete_confirm_header', { ns: 'add_monitoring' })}</MotifModalHeader>
                <MotifModalBody>
                    {t('modal_deleted_body', { ns: 'add_monitoring' })}
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={() => navigate('/monitoring/')}>
                        {t('modal_added_button_monitoring', { ns: 'add_monitoring' })}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isModalAddItemVisible} onClose={() => setModalAddItemVisibility(false)}>
                <MotifModalHeader closeModalButton={true}>{!monitoringId ? t('modal_added_header', { ns: 'add_monitoring' }) : t('modal_edited_header', { ns: 'add_monitoring' })}</MotifModalHeader>
                <MotifModalBody>{!monitoringId ? t('modal_added_body', { ns: 'add_monitoring' }) : t('modal_edited_body', { ns: 'add_monitoring' })}</MotifModalBody>
                <MotifModalFooter>

                    <MotifButton size="medium" type="button" onClick={() => {
                        setModalAddItemVisibility(false);
                        window.location.reload(false);
                    }}>
                        {t('modal_added_button_newmonitoring', { ns: 'add_monitoring' })}
                    </MotifButton>
                    <MotifButton size="medium" type="button" onClick={() => navigate('/monitoring/')}>
                        {t('modal_added_button_monitoring', { ns: 'add_monitoring' })}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default AddMonitoring