import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import UploadItem from '../../../components/UploadItem';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../utils/loadingcontext';

import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifLabel,
    MotifFormField,
    MotifSelect,
    MotifOption,
    MotifErrorMessage,
    MotifInput,
    MotifTextLink
} from '@ey-xd/motif-react';

import { request } from '../../../utils/request';

import { validateGeneralInput, validateDescriptionInput, sanitizeFilename } from '../../../utils/utils';

import ScreenReaderLabel from '../../../components/ScreenReaderLabel';

const NewDocumentModal = ({isModalVisible, setModalVisibility, selectedFileId, attData, setAttData, entities, editMode, setEditMode, isFormSubmitted, setIsFormSubmitted, setFiles}) => {
    
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();
    
    const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false);
    const [ConfirmModalText, setConfirmModalText] = useState('');
    const [ConfirmModalFooter, setConfirmModalFooter] = useState('');
    const [hideModalCloseButton, setHideModalCloseButton] = useState(true);

    const [forceRenderKey, setForceRenderKey] = useState('');

    const [category, setCategory] = useState('1');
    const [categories, setCategories] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedEntities, setSelectedEntities] = useState([]);
    const screenReaderAlertRef = useRef(null);

    const [fileData, setFileData] = useState([]);

    const [isAttModalVisible, setAttModalVisibility] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [blobUrls, setBlobUrls] = useState({});

    useEffect(() => {
        !!entities.length && setForceRenderKey(crypto.randomUUID());
        !!categories.length && setForceRenderKey(crypto.randomUUID());
    }, [entities, category]);

    useEffect(() => {
        setIsLoading(true);
    
        const fetchData = async () => {
            try {
                const categories = await request.get(`document-categories/`);
                setCategories(categories);
    
                if (editMode) {
                    const response = await request.get(`file/${selectedFileId}/`);
                    setCategory(response['category']['id'].toString());
                    setTitle(response['title']);
                    setDescription(response['description']);
                    const entityIds = response.entity?.map(entity => entity.id.toString());
                    setSelectedEntities(entityIds);
    
                    const adjustedFileData = response['attachments'].map(att => ({
                        id: att.id,
                        filename: att.name,
                        path: att.path,
                    }));
                    setFileData(adjustedFileData);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchData();
    }, [selectedFileId, editMode]);

    const fieldTranslations = {
        category: { key: 'category', ns: 'general', required: false },
        title: { key: 'document_title', ns: 'admin', required: true },
        description: { key: 'document_description_optional', ns: 'admin', required: false },
        selectedEntities: { key: 'entity', ns: 'general', required: true },
        fileData: { key: 'upload_document', ns: 'admin', required: true },
    };
    
    const validateForm = () => {
        const errors = Object.entries(fieldTranslations)
            .filter(([field, { required }]) => {
                const fieldValue = eval(field); // Dynamically get the field's value
    
                if (!required) return false; // Skip non-required fields
    
                // Validate based on field type
                if (typeof fieldValue === 'string') {
                    return fieldValue.trim().length === 0; // Check for empty strings
                } else if (Array.isArray(fieldValue)) {
                    return fieldValue.length === 0; // Check for empty arrays
                } else if (fieldValue instanceof File) {
                    return !fieldValue.name; // Check for missing file name for File objects
                } else {
                    return !fieldValue; // Check for undefined, null, or other falsy values
                }
            })
            .map(([field]) => field);
    
        if (errors.length > 0) {
            const unfilledFields = errors.map(field => {
                const { key, ns } = fieldTranslations[field];
                return t(key, { ns });
            }).join(', ');
    
            screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields}`;
            return false;
        }
    
        return true;
    };
    
    const clearData = () => {
        
        setEditMode(false);
        setIsFormSubmitted(false);
        setTitle(''); 
        setDescription(''); 
        setSelectedEntities([]); 
        setFileData([]); 
        setAttData([]);

    }

    useEffect(() => {
        if(!isModalVisible) { // if modal is closed, clear form data
            clearData();
        }
    }, [isModalVisible])

    const handleSubmit = async () => {
        setIsFormSubmitted(true);
    
        if (validateForm()) {
            setIsLoading(true);
    
            const formData = new FormData();
    
            // Filter to include only new files (those which have a 'file' property of type Blob)
            fileData.filter(file => file.file instanceof Blob).forEach((file) => {
                const sanitizedFilename = sanitizeFilename(file.filename);
    
                formData.append('file', file.file, sanitizedFilename);  // The third parameter sets the filename
                formData.append('filename', sanitizedFilename);
                formData.append('path', file.path);
                formData.append('timestamp', file.timestamp);
            });
    
            // Add other form data fields
            formData.append('entity', selectedEntities.join(','));
            formData.append('category', category);
            formData.append('title', title);
            formData.append('description', description);
    
            const endpoint = editMode ? `file/${selectedFileId}/` : `file/upload_document/`;
            const method = editMode ? 'PATCH' : 'POST';
    
            try {
                await request.post(endpoint, formData, method);
            } catch (error) {
                console.error(error.message);
            } finally {
                setFiles(await request.get('file/', {})); // refresh attachments data after adding
                setIsLoading(false);
                setModalVisibility(false);
            }
        }
    }
    const fetchBlobUrl = async (attachment) => {
        setIsLoading(true);

        try {
          const response = await request.get(`file/stream_blob/${attachment.id}/`);
          if (response.ok) {
              const blob = await response.blob();
              return URL.createObjectURL(blob);
          }
            
        } catch (error) {
          console.error(error.message);
        } finally {
          setIsLoading(false)
        }
        return null;
    };

    const showAttachments = async (attData) => {
        const newBlobUrls = {};
        for (let attachment of fileData) {
          newBlobUrls[attachment.id] = await fetchBlobUrl(attachment);
        }
        setBlobUrls(newBlobUrls);
        setAttachments(attData);
        setAttModalVisibility(true);
    };

    const warningForm = () => {
        setConfirmModalText(t('modal_document_remove_confirm_body', {ns: 'admin'}))
        setHideModalCloseButton(true);
        setConfirmModalFooter(
            <>
            <MotifButton
                variant="warn"
                className="me-3"
                disabled={isLoading}
                onClick={() => { handleDelete() }}>{t('confirm', {ns: 'general'})}</MotifButton>
            <MotifButton
                className="me-3"
                disabled={isLoading}
                onClick={() => setConfirmModalVisibility(false)}>{t('cancel', {ns: 'general'})}</MotifButton>
            </>
        );
        setConfirmModalVisibility(true);
      }

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            const response = await request.delete(`file/${selectedFileId}/delete/`);
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsLoading(false);
            }

        setFiles(await request.get('file/', {})); // refresh attachment data after deletion

        setConfirmModalText(t('modal_document_removed_body', {ns: 'admin'}))
        setHideModalCloseButton(true);
        setConfirmModalFooter(
        <>
        <MotifButton
            disabled={isLoading}
            className="me-3"
            onClick={() => {setConfirmModalVisibility(false);setModalVisibility(false);}}>{t('close', {ns: 'general'})}</MotifButton>
        </>
        );
        setConfirmModalVisibility(true);
    };

    return (
        <>
            <MotifModal show={isModalVisible} 
                        onClose={() => {{setModalVisibility(false);}}}>
                <MotifModalHeader>{t('add_document', {ns: 'admin'})}</MotifModalHeader>
                <MotifModalBody>
                    <MotifFormField>
                        <MotifLabel id="select-category">{t('category', {ns: 'general'})}</MotifLabel>
                        <MotifSelect
                            id="select-category"
                            key={forceRenderKey}
                            value={category}
                            onChange={val => setCategory(val)}
                            ariaLabelledBy="select-category"
                            visibleOptions='3'
                        >
                            {categories.map(
                                item => (<MotifOption
                                    key={item.id.toString()}
                                    value={item.id.toString()}
                                >{item.description}
                                </MotifOption>)
                                )
                            }
                        </MotifSelect>
                        {(category.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('valid_entity', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>{t('document_title', {ns: 'admin'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-document_title' message={`${t('document_title', {ns: 'admin'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={title}
                            onChange={event => setTitle(validateGeneralInput(event.target.value).cleanedValue)}
                            required={true}
                            maxLength={256}
                            aria-labelledby='sr-document_title'
                        />
                        {(title.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('document_valid_title', {ns: 'admin'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>{t('document_description_optional', {ns: 'admin'})}</MotifLabel>
                        <MotifInput value={description}
                            onChange={event => setDescription(validateDescriptionInput(event.target.value).cleanedValue)}
                            required={true}
                            maxLength={2000}
                        />
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel id="select-entity">{t('entity', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-entity' message={`${t('entity', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect
                            id="select-entities"
                            multiple={true}
                            // showSelectAllButton={true} bug: clicking selectall does not trigger setSelectedEntities
                            key={forceRenderKey}
                            value={selectedEntities}
                            onChange={val => setSelectedEntities(val)}
                            ariaLabelledBy="sr-entity"
                            visibleOptions='3'
                        >
                            {entities.map(
                                item => (<MotifOption
                                    key={item.id.toString()}
                                    value={item.id.toString()}
                                >{item.name}
                                </MotifOption>)
                                )
                            }
                        </MotifSelect>
                        {(selectedEntities.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                            {t('document_valid_entity', {ns: 'admin'})}</MotifErrorMessage>}
                    </MotifFormField>

                    {editMode && attData ?
                    <MotifTextLink href='#' onClick={() => showAttachments(attData)}>{t('open_uploaded_documents', {ns: 'admin'})}</MotifTextLink>
                    : null }
                    
                    <UploadItem 
                        blobPath="documents" 
                        deleteUrl="file-documents" 
                        fileData={fileData}
                        setFileData={setFileData} 
                        taskId={false} 
                        disabled={false} 
                        label={t('upload_document', {ns: 'admin'})} 
                        maxFiles={30}
                    />
                    {(fileData.length == 0 && isFormSubmitted) && <MotifErrorMessage>
                        {t('document_valid_file', {ns: 'admin'})}</MotifErrorMessage>}
                            
                </MotifModalBody>
                <MotifModalFooter>
                <div
                    ref={screenReaderAlertRef}
                    aria-live="assertive"
                    className="visually-hidden"
                    role="alert"
                ></div>
                <div className="row" style={{width: '100%'}}>
                        <div className="col-6 text-left">
                            {editMode && selectedFileId ? 
                            <MotifButton
                                disabled={isLoading}
                                variant="warn"
                                onClick={() => { warningForm() }}
                            >
                                    {t('remove', {ns: 'general'})}
                            </MotifButton>
                            : null}
                        </div>
                        <div className="col-6 text-right d-flex justify-content-end">
                            <MotifButton 
                                disabled={isLoading} 
                                size="medium" 
                                type="button" 
                                onClick={() => { handleSubmit() }}
                                style={{margin: '5px'}}
                            >
                                    {editMode ? t('edit', {ns: 'general'}) : t('add', {ns: 'general'})}
                            </MotifButton>
                                
                            <MotifButton
                                disabled={isLoading}
                                size="medium"
                                variant="secondary"
                                type="button"
                                onClick={() => {setModalVisibility(false);}}
                                style={{margin: '5px'}}
                            >
                                {t('cancel', {ns: 'general'})}
                            </MotifButton>
                    </div>
                </div>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isConfirmModalVisible} onClose={() => setConfirmModalVisibility(false)}>
                <MotifModalHeader closeModalButton={hideModalCloseButton}>{t('alert', {ns: 'general'})}</MotifModalHeader>
                <MotifModalBody>{ConfirmModalText}</MotifModalBody>
                <MotifModalFooter>{ConfirmModalFooter}</MotifModalFooter>
            </MotifModal>

            <MotifModal show={isAttModalVisible} onClose={() => setAttModalVisibility(false)}>
                <MotifModalHeader>{t('attachments', {ns: 'general'})}</MotifModalHeader>
                <MotifModalBody>
                    {attachments.map((file, index) => (
                        <ul key={index}>
                            <li>
                                <MotifTextLink href={blobUrls[file.id]} target="_blank" rel="noopener noreferrer">
                                {file.name}
                                </MotifTextLink>
                            </li>
                        </ul>
                        ))}
                </MotifModalBody>
          </MotifModal>
        </>
    )
}

export default NewDocumentModal