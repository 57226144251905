import React, { useState, useEffect } from 'react';
import { MakeRequests } from '../utils/request'

import { useTranslation } from 'react-i18next';

import {
  MotifComment,
  MotifIconButton,
  MotifIcon,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifModalFooter,
  MotifTextLink,
} from '@ey-xd/motif-react'

import { fileIcAttachment24px } from '@ey-xd/motif-react/assets/icons';

const request = new MakeRequests()

const formatDate = (dateStr) => {
  let dateObj = new Date(dateStr);
  return dateObj.toLocaleString('en-GB', {
    year: 'numeric', 
    month: '2-digit', 
    day: '2-digit', 
    hour: '2-digit', 
    minute: '2-digit', 
    hour12: false 
  });
}

const Communication = ({communication, type, taskFinal}) => {

  const { t } = useTranslation();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [blobUrls, setBlobUrls] = useState({}); // This will store the blob URLs. Key is attachment ID, value is blob URL.

  const [combinedCommuncation, setCombinedCommunication] = useState([]);
  useEffect(() => {
    setCombinedCommunication([
      ...communication.responsible.filter(communication => !communication.is_draft).map(communication => ({...communication, role: 'responsible'})), 
      ...communication.reviewer.filter(communication => !communication.is_draft).map(communication => ({...communication, role: 'reviewer'}))
    ].sort((a, b) => new Date(b.comment_date) - new Date(a.comment_date)));
  }, [communication]);

  const fetchBlobUrl = async (attachment, role) => {
    let endpoint = '';
    if (role === 'responsible') {
      endpoint = `perform-${type}/stream_blob/${attachment.id}/`;
    } else if (role === 'reviewer') {
      endpoint = `perform-${type}-review/stream_blob/${attachment.id}/`;
    }
    const response = await request.get(endpoint);
    if (response.ok) {
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    }
    return null;
  };

  const showAttachments = async (value, role) => {
    const newBlobUrls = {};
    for (let attachment of value) {
      newBlobUrls[attachment.id] = await fetchBlobUrl(attachment, role);
    }
    setBlobUrls(newBlobUrls);
    setAttachments(value);
    setModalVisibility(true);
  };

    return (
<div className="row ms-2 mt-3">
  {combinedCommuncation.map((communication, index) => (
  <div className="col" key={index} style={{display: "block", minWidth: "70%", whiteSpace: 'pre-wrap'}}>
        <MotifComment 
          author={
            taskFinal
              ? (communication.archived_fullname || communication.archived_function || t('none', { ns: 'general' })) +
                ' (' + (communication.role|| t('none', { ns: 'general' })) + ')'
              : (communication.fullname || t('none', { ns: 'general' })) +
                ' (' + (communication.role || t('none', { ns: 'general' })) + ')'
          }
          content={communication.comment}
          dateTime={formatDate(communication.comment_date)}
          replyPosition={null}
          actions={communication.attachments.length > 0 ? <MotifIconButton type="button" aria-label="Actions" onClick={() => showAttachments(communication.attachments, communication.role)}><MotifIcon src={fileIcAttachment24px} /></MotifIconButton> : ''}
          >
          
        </MotifComment></div>))}
        <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
          <MotifModalHeader>{t('attachments', {ns: 'general'})}</MotifModalHeader>
          <MotifModalBody>
          {attachments.map((file, index) => (
                    <div key={index}>
                      <MotifTextLink href={blobUrls[file.id]} target="_blank" rel="noopener noreferrer">
                        {file.name}
                      </MotifTextLink>
                    </div>
                  ))}
          </MotifModalBody>
          <MotifModalFooter>
          </MotifModalFooter>
        </MotifModal>
      </div>
  )
}
export default Communication