import React, { useState, useEffect, useRef } from 'react';
import UploadItem from '../../components/UploadItem';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import { adjustFileData, calculateFiscalYear, sanitizeFilename, validateCommentInput } from '../../utils/utils';

import {
	MotifFormField,
	MotifLabel,
	MotifOption,
	MotifSelect,
	MotifTextArea,
	MotifButton,
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifErrorMessage,
	MotifDatePicker,
	MotifTextLink
} from '@ey-xd/motif-react'
import ScreenReaderLabel from '../../components/ScreenReaderLabel';

const PerformControlFormReviewer = ({ draft, role, controlTask, taskId, taskFinal, setTaskFinal, executionDate, fiscalYear, setFiscalYear, clientFiscalYear, execution, setExecution, navigate, request }) => {

	const { t } = useTranslation();
	const { isLoading, setIsLoading } = useLoading();

	const [forceRenderKey, setForceRenderKey] = useState('');
	const screenReaderAlertRef = useRef(null);

	const [isModalVisible, setModalVisibility] = useState(false);
	const [modalData, setModalData] = useState({
		headerText: t('alert', { ns: 'general' }),
		bodyText: '',
		footerContent: '',
		hideCloseButton: true,
	});

	const showModal = (bodyText, footerContent, hideCloseButton = true) => {
		setModalData(prevData => ({
			...prevData,
			bodyText,
			footerContent,
			hideCloseButton,
		}));
		setModalVisibility(true); // Make sure the modal is shown
	};

	const [commentId, setCommentId] = useState(null);

	const [comments, setComments] = useState('');
	const [attachments, setAttachments] = useState([]);

	const [completedSelect, setCompletedSelect] = useState('');

	const [fiscalYearItems, setFiscalYearItems] = useState([]);

	const [fileData, setFileData] = useState([]); // Store file metadata and blobs locally

	const [disableForm, setDisableForm] = useState(true);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [formAction, setFormAction] = useState(null);

	useEffect(() => {
		!!completedSelect && setForceRenderKey(crypto.randomUUID());
		!!fiscalYear && setForceRenderKey(crypto.randomUUID());
	}, [completedSelect, fiscalYearItems]);

	useEffect(() => {
		if (controlTask?.deadline) {
			const calculatedFiscalYear = calculateFiscalYear(clientFiscalYear[0], controlTask?.deadline);
			if (calculatedFiscalYear) {
				// Check if the fiscal year format is standard calendar year
				const isStandardCalendarYear = clientFiscalYear[0].start_month === 1 && clientFiscalYear[0].start_day === 1 &&
					clientFiscalYear[0].end_month === 12 && clientFiscalYear[0].end_day === 31;

				let newFiscalYearItems = [];

				if (isStandardCalendarYear) {
					// For standard calendar year, generate options without splitting
					const year = Number(calculatedFiscalYear); // Convert to number to perform arithmetic
					newFiscalYearItems = [`${year - 1}`, `${year}`, `${year + 1}`];
				} else {
					// For split financial years, keep the original logic
					const yearParts = calculatedFiscalYear.split('/').map(Number); // Convert strings to numbers
					const prevYear = yearParts[0] - 1; // Previous year
					const nextYear = (yearParts[1] || yearParts[0]) + 1; // Next year

					newFiscalYearItems = [`${prevYear}/${prevYear + 1}`, calculatedFiscalYear, `${nextYear}/${nextYear + 1}`];
				}

				setFiscalYearItems(newFiscalYearItems);

				if (!fiscalYear && calculatedFiscalYear) {
					setFiscalYear(calculatedFiscalYear); // prefill calculated fiscal year based on the deadline
				}
			}
		}
	}, [clientFiscalYear, controlTask?.deadline]);

	useEffect(() => {
		try {
			if (draft.length > 0) {
				setCommentId(draft[0]['id']);
				setComments(draft[0]['comment'] || ''); // If comment is null, set it to an empty string to avoid errors with .length
				setAttachments(draft[0]['attachments']);
			}
		} catch (error) {
			console.error(error.message);
		} finally {
			setIsLoading(false);
		}
	}, [draft, role]);

	useEffect(() => {
		if (draft.length > 0 && !taskFinal && attachments?.length > 0) { // allow user to delete attachments if the input is still a draft
			setFileData(adjustFileData(attachments));
		}
	}, [attachments])

	const shouldDisableForm = (taskFinal, role, controlTaskStatusId) => {
		if (taskFinal || role === 'other' || controlTaskStatusId === 1) {
			return true;
		}
		if (role === 'reviewer' && [2, 4, 5].includes(controlTaskStatusId)) {
			return true;
		}
		if (role === 'responsible' && controlTaskStatusId === 3) {
			return true;
		}
		return false;
	};
	useEffect(() => {
		const disable = shouldDisableForm(taskFinal, role, controlTask?.status.id);
		setDisableForm(disable);
	}, [taskFinal, role, controlTask]);

	const warningForm = () => {
		// Set the body text based on role and other conditions
		const bodyText = role === 'reviewer' && completedSelect === '0'
			? t('modal_confirm_return_body', { ns: 'perform_control' })
			: role === 'responsible'
			? t('modal_send_body', { ns: 'perform_control' })
			: t('modal_confirm_body', { ns: 'perform_control' });

		// Use `showModal` to update both the body and footer simultaneously
		showModal(
			bodyText,  // Modal body content
			<>
				<MotifButton
					className="me-3"
					disabled={isLoading}
					onClick={() => { submitForm('submit') }}>
					{t('confirm', { ns: 'general' })}
				</MotifButton>
				<MotifButton
					className="me-3"
					disabled={isLoading}
					onClick={() => setModalVisibility(false)}>
					{t('cancel', { ns: 'general' })}
				</MotifButton>
			</>,  // Modal footer buttons
			true  // Hide modal close button
		);
	};

	const fieldTranslations = {
		fiscalYear: { key: 'fiscal_year', ns: 'general', required: true },
		execution: { key: 'executed', ns: 'general', required: true },
		comments: { key: 'comments', ns: 'general', required: true },
		completedSelect: { key: 'review_task_completed', ns: 'perform_control', required: role === 'reviewer' ? true : false }
	};

	const resetCtask = async () => {
		try {
		  setIsLoading(true);
		  await request.post(`reset-ctask/${taskId}/`, {}, 'PATCH');
		} catch (error) {
		  console.error(error.message)
		} finally {
		  navigate('/performcontroloverview');
		}
	  };

	const validateForm = () => {
		const errors = Object.entries(fieldTranslations)
			.filter(([field, { required }]) => {
				const fieldValue = eval(field); // Dynamically get the field's value

				if (!required) return false; // Skip non-required fields

				// Validate based on field type
				if (typeof fieldValue === 'string') {
					return fieldValue.trim().length === 0; // Check for empty strings
				} else if (Array.isArray(fieldValue)) {
					return fieldValue.length === 0; // Check for empty arrays
				} else {
					return !fieldValue; // Check for undefined, null, or other falsy values
				}
			})
			.map(([field]) => field);

		if (errors.length > 0) {
			const unfilledFields = errors.map(field => {
				const { key, ns } = fieldTranslations[field];
				return t(key, { ns });
			}).join(', ');

			screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields}`;
			setModalVisibility(false);
			return false;
		}

		return true;
	};

	const submitForm = async (action) => {
		setIsFormSubmitted(true);
		setFormAction(action);

		if (action === 'save' || validateForm()) { // Skip validation if action is 'save'
			setIsLoading(true);
			try {
				let modalBodyText = '';
				let disableForm = false;

				if (action === 'submit' && role == 'responsible' && (controlTask?.status.id == 2 || controlTask?.status.id == 4 || controlTask?.status.id == 5)) {
					// Pending reviewer after submission by responsible
					await request.post(`control-task/${taskId}/`, { "status": 3, "fiscal_year": fiscalYear ? fiscalYear : null, execution: execution === '1' }, 'PATCH');
					disableForm = true;
					modalBodyText = t('review_modal_added_body', { ns: 'perform_control' });

				} else if (action === 'save' && role == 'responsible' && (controlTask?.status.id == 2 || controlTask?.status.id == 4 || controlTask?.status.id == 5)) {
					// In progress with the responsible after responsible saves as draft from unstarted status
					if (controlTask?.status.id !== 4) {
						await request.post(`control-task/${taskId}/`, { "status": 4, "fiscal_year": fiscalYear ? fiscalYear : null, execution: execution === '1' }, 'PATCH');
					}
					disableForm = true;
					modalBodyText = t('modal_draft_body', { ns: 'perform_control' });

				} else if (action === 'submit' && role == 'reviewer' && completedSelect === '1') {
					// Completed and submitted by reviewer
					await request.post(`control-task/${taskId}/`, { "status": 1, "fiscal_year": fiscalYear ? fiscalYear : null, execution: execution === '1' }, 'PATCH');
					disableForm = true;
					setTaskFinal(true);
					modalBodyText = t('review_modal_completed_body', { ns: 'perform_control' });

				} else if (action === 'submit' && role == 'reviewer' && completedSelect === '0') {
					// Reviewer returns to responsible
					await request.post(`control-task/${taskId}/`, { "status": 2, "fiscal_year": fiscalYear ? fiscalYear : null, execution: execution === '1' }, 'PATCH');
					disableForm = true;
					modalBodyText = t('review_modal_returned_body', { ns: 'perform_control' });

				} else if (action === 'save' && role == 'reviewer') {
					// Reviewer saves as draft
					modalBodyText = t('modal_draft_body', { ns: 'perform_control' });
				}

				// Set the URL, body, and method for responsible and reviewer
				let url, performControlBody, method;
				if (role === 'responsible') {
					performControlBody = {
						comment: comments.length > 0 ? comments : null,
						controltask: taskId,
						is_draft: action === 'save' ? 1 : 0,
					};
					url = commentId ? `perform-control/${commentId}/` : 'perform-control/';
					method = commentId ? 'PATCH' : 'POST';

				} else if (role === 'reviewer') {
					performControlBody = {
						comment: comments.length > 0 ? comments : null,
						controltask: taskId,
						is_draft: action === 'save' ? 1 : 0,
					};
					url = commentId ? `perform-control-review/${commentId}/` : 'perform-control-review/';
					method = commentId ? 'PATCH' : 'POST';
				}

				await processFormSubmission(url, commentId, performControlBody, method);

				// Use showModal to update modal body and footer text
				showModal(
					modalBodyText,
					<>
						<MotifButton size="medium" type="button" disabled={isLoading} onClick={() => navigate('/performcontroloverview')}>
							{t('modal_added_button_taskoverview', { ns: 'perform_control' })}
						</MotifButton>
						<MotifButton size="medium" type="button" disabled={isLoading} onClick={() => navigate('/overview')}>
							{t('dashboard', { ns: 'general' })}
						</MotifButton>
					</>,
					!disableForm  // Show/hide the close button based on the form state
				);

				setDisableForm(disableForm);

			} catch (error) {
				console.error(error.message);
			} finally {
				setIsLoading(false);
			}
		}
	};

	const processFormSubmission = async (url, commentId, performControlBody, method) => {
		setIsLoading(true); // Set loading state at the start of the operation

		try {
			const response = await request.post(url, performControlBody, method);
			let newCommentId = !commentId ? response.id + '/' : '';  // If no commentId yet (no saved draft used), add it.

			// File upload logic
			if (fileData.length > 0) {
				const uploadPromises = fileData.filter(file => file.file instanceof Blob).map((file) => {
					const formData = new FormData();
					formData.append('file', file.file);  // Ensure file is a Blob
					formData.append('performcontrol', newCommentId);

					const sanitizedFilename = sanitizeFilename(file.filename);
					formData.append('filename', sanitizedFilename);
					formData.append('path', file.path);
					formData.append('timestamp', file.timestamp);

					// Conditional fields based on role
					if (role === 'responsible') {
						formData.append('execution_comment', newCommentId);
					} else if (role === 'reviewer') {
						formData.append('reviewer_comment', newCommentId);
					}

					return request.post(`${url}${newCommentId}upload_document/?taskId=${taskId}`, formData, 'POST');
				});
				await Promise.all(uploadPromises);
			}

			// Use `showModal` to update both the body and footer together
			showModal(
				t('modal_complete_body', { ns: 'perform_control' }), // Modal body content
				<>
					<MotifButton size="medium" type="button" disabled={isLoading} onClick={() => {
						setModalVisibility(false);
						navigate('/performcontroloverview');
					}}>
						{t('modal_added_button_taskoverview', { ns: 'perform_control' })}
					</MotifButton>
					<MotifButton size="medium" type="button" disabled={isLoading} onClick={() => {
						setModalVisibility(false);
						navigate('/overview');
					}}>
						{t('dashboard', { ns: 'general' })}
					</MotifButton>
				</>  // Modal footer buttons
			);
		} catch (error) {
			console.error(error.message);
		} finally {
			setIsLoading(false); // Ensure loading is set to false after all operations
		}
	};

	return (
		<>
			{taskFinal && executionDate ? // only show executed date if task is finished
				<MotifFormField>
					<MotifLabel>{t('execution_date', { ns: 'general' })}</MotifLabel>
					<MotifDatePicker
						calendarType="iso8601"
						disabled={true} // auto set in backend, not set by user
						value={executionDate}
						showLeadingZeros={true}
						format="dd-MM-yyyy"
						yearPlaceholder="YYYY"
						dayPlaceholder="DD"
						monthPlaceholder="MM"
					/>
					{(!executionDate && isFormSubmitted && formAction == 'submit') && <MotifErrorMessage>
						{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
				</MotifFormField>
				: null}

			<MotifFormField>
				<MotifLabel id="select-fy">{t('fiscal_year', { ns: 'general' })}</MotifLabel>
				<ScreenReaderLabel id='sr-fiscal_year' message={`${t('fiscal_year', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
				<MotifSelect
					ariaLabelledBy="sr-fiscal_year"
					key={forceRenderKey}
					disabled={disableForm || isLoading}
					value={fiscalYear}
					onChange={val => setFiscalYear(val)}>
					{fiscalYearItems.map((item, i) => (<MotifOption key={i} value={item}>{item}</MotifOption>))}
				</MotifSelect>
				{(fiscalYear.length == 0 && isFormSubmitted && formAction == 'submit') && <MotifErrorMessage>
					{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
			</MotifFormField>

			<MotifFormField>
				<MotifLabel id="select-execution">{t('executed', { ns: 'general' })}</MotifLabel>
				<ScreenReaderLabel id='sr-execution' message={`${t('executed', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
				<MotifSelect
					ariaLabelledBy="sr-execution"
					key={forceRenderKey}
					disabled={disableForm || isLoading}
					value={execution}
					onChange={val => setExecution(val)}>
					<MotifOption key={1} value={'1'}>{t('yes', { ns: 'general' })}</MotifOption>
					<MotifOption key={2} value={'0'}>{t('no', { ns: 'general' })}</MotifOption>
				</MotifSelect>
				{(execution.length == 0 && isFormSubmitted && formAction == 'submit') && <MotifErrorMessage>
					{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
			</MotifFormField>


			<MotifFormField style={{ marginTop: '1%' }}>
				<ScreenReaderLabel id='sr-comments' message={`${t('comments', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
				<MotifLabel position="in">{t('comments', { ns: 'general' })}</MotifLabel>
				<MotifTextArea
					aria-labelledby='sr-comments'
					disabled={disableForm || isLoading}
					value={comments}
					rows={5}
					onChange={event => setComments(validateCommentInput(event.target.value).cleanedValue)}
					maxLength={2000}
				/>
				{(comments.length == 0 && isFormSubmitted && formAction !== 'save') && <MotifErrorMessage>
					{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
			</MotifFormField>

			{!disableForm ?
				<UploadItem blobPath="controls" deleteUrl="perform-control-documents" fileData={fileData} setFileData={setFileData} taskId={taskId} disabled={taskFinal} label={t('task_uploaditem', { ns: 'general' })} maxFiles={30} />
				: ''}
			{role == 'reviewer' /*&& completedSelectItems*/ ?
				<MotifFormField style={{ width: "250px" }}>
					<ScreenReaderLabel id='sr-completed' message={`${t('review_task_completed', { ns: 'perform_control' })} ${t('required', { ns: 'screenreader' })}`} />
					<MotifLabel id="select-completed">{t('review_task_completed', { ns: 'perform_control' })}</MotifLabel>
					<MotifSelect
						key={forceRenderKey}
						disabled={disableForm || isLoading}
						value={completedSelect}
						onChange={val => setCompletedSelect(val)}
						ariaLabelledBy="sr-completed">
						<MotifOption key='1' value='1'>{t('completed_yes', { ns: 'perform_control' })}</MotifOption>
						<MotifOption key='0' value='0'>{t('completed_no', { ns: 'perform_control' })}</MotifOption>
					</MotifSelect>
					{(role == 'reviewer' && completedSelect.length == 0 && isFormSubmitted && formAction !== 'save') && <MotifErrorMessage>
						{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
				</MotifFormField> : null}
			<div
				ref={screenReaderAlertRef}
				aria-live="assertive"
				className="visually-hidden"
				role="alert"
			></div>

			<div className="row mt-2 mb-3" style={{ width: '100%' }}>
				<div className="col d-flex align-items-center">
					<MotifButton
					disabled={disableForm || isLoading}
					className="me-2"  // Use a smaller margin between the first two buttons
					onClick={() => { submitForm('save') }}>
					{t('save', {ns: 'general'})}
					</MotifButton>
					
					<MotifButton
					variant="primary-alt"
					disabled={disableForm || isLoading}
					onClick={() => { warningForm() }}>
					{t('save_submit', {ns: 'general'})}
					</MotifButton>
				</div>

				{/* Conditionally render the reset button if debug features are enabled */
				process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' ? (
					<div className="col text-right d-flex justify-content-end">
						<MotifTextLink href="#" disabled={isLoading} onClick={() => { resetCtask() }}>Reset (debug)</MotifTextLink>
					</div>
				) : null}
			</div>

			<MotifModal show={isModalVisible} onClose={() => window.location.reload()}>
				<MotifModalHeader closeModalButton={modalData.hideCloseButton}>
					{modalData.headerText}
				</MotifModalHeader>
				<MotifModalBody>{modalData.bodyText}</MotifModalBody>
				<MotifModalFooter>{modalData.footerContent}</MotifModalFooter>
			</MotifModal>
		</>
	);
}

export default PerformControlFormReviewer;