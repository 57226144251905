import React, { useState, useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next';

import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifLabel,
    MotifFormField,
    MotifInput,
    MotifSelect,
    MotifErrorMessage,
    MotifOption
  } from '@ey-xd/motif-react';

import { validateGeneralInput, validateDescriptionInput } from '../../../utils/utils';
import { request } from '../../../utils/request';
import ScreenReaderLabel from '../../../components/ScreenReaderLabel';


const NewEntityModal = ({ isModalVisible, setModalVisibility, setEntities, editMode, setEditMode, entityToEdit, name, setName, streetName, setStreetName, houseNumber, setHouseNumber, postalCode, setPostalCode, city, setCity, country, setCountry, countries}) => {

    const { t } = useTranslation();

    const [forceRenderKey, setForceRenderKey] = useState('');

    const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false);
    const [ConfirmModalText, setConfirmModalText] = useState('');
    const [ConfirmModalFooter, setConfirmModalFooter] = useState('');
    const [hideModalCloseButton, setHideModalCloseButton] = useState(true);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const screenReaderAlertRef = useRef(null);

    const warningForm = () => {
        setConfirmModalText(t('modal_entity_remove_confirm_body', {ns: 'admin'}))
        setHideModalCloseButton(true);
        setConfirmModalFooter(
        <>
          <MotifButton
            variant="warn"
            className="me-3"
            onClick={() => { handleDelete() }}>{t('confirm', {ns: 'general'})}</MotifButton>
          <MotifButton
            className="me-3"
            onClick={() => setConfirmModalVisibility(false)}>{t('cancel', {ns: 'general'})}</MotifButton>
        </>
        );
        setConfirmModalVisibility(true);
      }
    
    const handleDelete = async () => {
        try {
            var response = await request.post(`entity/${entityToEdit}/`, {"deleted": true}, 'PATCH');
            } catch (error) {
                console.error(error.message);
            } finally {
                setEntities(await request.get('entity/', {})); // refresh MotifTable data after deleting
            }

        setConfirmModalText(t('modal_entity_removed_body', {ns: 'admin'}))
        setHideModalCloseButton(true);
        setConfirmModalFooter(
            <>
                <MotifButton
                    className="me-3"
                    onClick={() => {setConfirmModalVisibility(false);setModalVisibility(false);}}>{t('close', {ns: 'general'})}</MotifButton>
            </>
        );
        setConfirmModalVisibility(true);
    };
    

    useEffect(() => {
        !!country.length && setForceRenderKey(crypto.randomUUID());

    }, [country]);

    const clearData = () => {

        setEditMode(false);
        setIsFormSubmitted(false);
        setName('');
        setStreetName('');
        setHouseNumber('');
        setPostalCode('');
        setCity('');
        setCountry('');
    
    }

    useEffect(() => {
        if(!isModalVisible) { // if modal is closed, clear form data
            clearData();
        }
    }, [isModalVisible])

    const addEntity = async (how) => {
        setIsFormSubmitted(true);

        if (validateForm()) {
            let url;
        
            var data = {
                'name': name,
                'street_name': streetName,
                'house_number': houseNumber,
                'postal_code': postalCode,
                'city': city,
                'country': country,
                'deleted': 0
            };
        
            if (how === 'POST') {
                url = 'entity/';
            } else {
                url = `entity/${entityToEdit}/`;
            }
        
            try {
                // Perform the POST or PATCH request
                var response = await request.post(url, data, how);
            } catch (error) {
                console.error(error.message);
            } finally {
                setEntities(await request.get('entity/', {})); // reload entities after successful add/edit
                setModalVisibility(false); 
            }
        }
    }

    const fieldTranslations = {
        name: { key: 'name', ns: 'admin', required: true },
        streetName: { key: 'street', ns: 'admin', required: true },
        houseNumber: { key: 'number', ns: 'admin', required: true },
        postalCode: { key: 'postal', ns: 'admin', required: true },
        city: { key: 'city', ns: 'admin', required: true },
        country: { key: 'country', ns: 'general', required: true },
    };

    const validateForm = () => {
        const errors = Object.entries(fieldTranslations)
            .filter(([field, { required }]) => {
                const fieldValue = eval(field); // Dynamically get the field's value
                return required && (!fieldValue || fieldValue.trim().length === 0);
            })
            .map(([field]) => field);

        if (errors.length > 0) {
            const unfilledFields = errors.map(field => {
                const { key, ns } = fieldTranslations[field];
                return t(key, { ns });
            }).join(', ');

            screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields}`;
            return false;
        }

        return true;
    };


    return (
        <>
            <MotifModal show={isModalVisible} onClose={() => {setModalVisibility(false)}}>
                <MotifModalHeader>{!entityToEdit ? t('new_entity', {ns: 'admin'}) : t('edit_entity', {ns: 'admin'})}</MotifModalHeader>
                <MotifModalBody>
                    <MotifFormField>
                        <MotifLabel>{t('name', {ns: 'admin'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-name' message={`${t('name', {ns: 'admin'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={name}
                            onChange={event => setName(validateDescriptionInput(event.target.value).cleanedValue)}
                            required={true}
                            maxLength={256}
                            aria-labelledby='sr-name'
                        />
                        {(name.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('street', {ns: 'admin'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-street' message={`${t('street', {ns: 'admin'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={streetName}
                            onChange={event => setStreetName(validateGeneralInput(event.target.value).cleanedValue)}
                            required={true}
                            maxLength={256}
                            aria-labelledby='sr-street'
                        />
                        {(streetName.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('number', {ns: 'admin'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-number' message={`${t('number', {ns: 'admin'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={houseNumber}
                            onChange={event => setHouseNumber(validateGeneralInput(event.target.value).cleanedValue)}
                            required={true}
                            maxLength={10}
                            aria-labelledby='sr-number'
                        />
                        {(houseNumber.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('postal', {ns: 'admin'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-postal' message={`${t('postal', {ns: 'admin'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={postalCode}
                            onChange={event => setPostalCode(validateGeneralInput(event.target.value).cleanedValue)}
                            required={true}
                            maxLength={10}
                            aria-labelledby='sr-postal'
                        />
                        {(postalCode.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    
                    <MotifFormField>
                        <MotifLabel>{t('city', {ns: 'admin'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-city' message={`${t('city', {ns: 'admin'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={city}
                            onChange={event => setCity(validateGeneralInput(event.target.value).cleanedValue)}
                            required={true}
                            maxLength={256}
                            aria-labelledby='sr-city'
                        />
                        {(city.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('country', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-country' message={`${t('country', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifSelect 
                            key={forceRenderKey}
                            value={country}
                            filter={true}
                            onChange={val => setCountry(val)}
                            visibleOptions='3'
                            ariaLabelledBy="sr-country">
                            {countries.map(item => (<MotifOption key={item.id} value={item.id.toString()}>{item.country}</MotifOption>))}
                        </MotifSelect>
                        {(country.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>

                </MotifModalBody>
                <MotifModalFooter>
                    <div
                        ref={screenReaderAlertRef}
                        aria-live="assertive"
                        className="visually-hidden"
                        role="alert"
                    ></div>
                    <div className="row" style={{width: '100%'}}>
                        <div className="col-6 text-left">
                            {editMode && entityToEdit ? 
                            <MotifButton 
                                variant='warn' 
                                onClick={() => { warningForm() }}
                            >
                                {t('remove', {ns: 'general'})}
                            </MotifButton>
                            : null }
                        </div>
                        <div className="col-6 text-right d-flex justify-content-end">
                            <MotifButton 
                                size="medium" 
                                type="button" 
                                onClick={() => { addEntity(!editMode ? 'POST' : 'PUT') }} 
                                style={{margin: '5px'}}
                            >
                                {!editMode ? t('add', {ns: 'general'}) : t('edit', {ns: 'general'})}
                            </MotifButton>

                            <MotifButton
                                size="medium"
                                variant="secondary"
                                type="button"
                                onClick={() => {setModalVisibility(false)}}
                                style={{margin: '5px'}}
                            >
                                {t('cancel', {ns: 'general'})}
                            </MotifButton>
                        </div>
                    </div>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isConfirmModalVisible} onClose={() => {setConfirmModalVisibility(false)}}>
                <MotifModalHeader closeModalButton={hideModalCloseButton}>{t('alert', {ns: 'general'})}</MotifModalHeader>
                <MotifModalBody>{ConfirmModalText}</MotifModalBody>
                <MotifModalFooter>{ConfirmModalFooter}</MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default NewEntityModal