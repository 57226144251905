import React, { useState, useEffect, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';
import { tooltitle } from '../../utils/utils';

import {
	MotifInput,
	MotifTextArea,
	MotifLabel,
	MotifFormField,
	MotifSelect,
	MotifOption,
	MotifCheckbox,
	MotifButton,
	MotifSlider,
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifIcon,
	MotifIconButton,
	MotifTextLink,
	MotifInlineMessage,
	MotifErrorMessage
} from '@ey-xd/motif-react';

import { contentIcSelectAll24px } from '@ey-xd/motif-react/assets/icons';

import { request } from '../../utils/request';
import { validateDescriptionInput } from '../../utils/utils';

import CompanyOverview from './CompanyOverview';
import RiskList from './RiskList';

import ScreenReaderLabel from '../../components/ScreenReaderLabel';

const AddRisk = () => {

	const { t } = useTranslation();
	const { isLoading, setIsLoading } = useLoading();

	const { state } = useLocation();
	const { riskId } = state || {};
	const navigate = useNavigate();
	const screenReaderAlertRef = useRef(null);

	// form data variables
	const [risk, setRisk] = useState('');
	const [description, setDescription] = useState('');
	const [theme, setTheme] = useState('');
	const [subtheme, setSubtheme] = useState('');
	const [subthemeData, setSubthemeData] = useState([]);
	const [fetched_subtheme_id, setFetchedSubthemeId] = useState(null); // to make sure subtheme is properly prefilled when editing
	const [process, setProcess] = useState('');
	const [riskLevel, setRiskLevel] = useState('');
	const [impact, setImpact] = useState('1');
	const [probability, setProbability] = useState('1');
	const [ranking, setRanking] = useState('1');
	const [scale, setScale] = useState('');
	const [maxRanking, setMaxRanking] = useState('');
	const [keyRisk, setKeyrisk] = useState(false);
	const [keyRiskThreshold, setKeyRiskThreshold] = useState(null);
	const [strategy, setStrategy] = useState('');
	const [forceRenderKey, setForceRenderKey] = useState('');
	const [preSelectedDepartments, setPreSelectedDepartments] = useState([]);
	const [themeItems, setThemeItems] = useState([]);
	const [subthemeItems, setSubthemeItems] = useState([]);
	const [relevantSubthemeItems, setRelevantSubthemeItems] = useState([]);
	const [riskLevelItems, setRiskLevelItems] = useState([]);
	const [strategyItems, setStrategyItems] = useState([]);
	const [submitType, setSubmitType] = useState('');
	const [selectedDepartments, setSelectedDepartments] = useState([])

	// modal variables
	const [isModalVisible, setModalVisibility] = useState(false);
	const [isModalRiskListVisible, setModalRiskListVisibility] = useState(false);
	const [isModalRiskAddedVisible, setModalRiskAddedVisibility] = useState(false);
	const [isEntityModalVisible, setEntityModalVisibility] = useState(false);
	const [isDeletedModalVisible, setDeletedModalVisibility] = useState(false);

	// form validation variables
	const [isFormSubmitted, setFormSubmitted] = useState(false);

	const submitForm = () => {

		setFormSubmitted(true);

		if (validateForm()) {
			submitType === 'POST' ? addNewRisk() : updateRisk();
		}
	};

	const fieldTranslations = {
		risk: { key: 'risk', ns: 'general', required: true },
		theme: { key: 'theme', ns: 'general', required: true },
		subtheme: { key: 'subtheme', ns: 'general', required: true },
		description: { key: 'description', ns: 'general', required: true },
		process: { key: 'process', ns: 'general', required: true },
		riskLevel: { key: 'risk_level', ns: 'general', required: true },
		strategy: { key: 'strategy', ns: 'general', required: true },
	};

	const validateForm = () => {
		const errors = Object.entries(fieldTranslations)
			.filter(([field, { required }]) => {
				const fieldValue = eval(field); // Dynamically get the field's value

				if (!required) return false; // Skip non-required fields

				// Validate based on field type
				if (typeof fieldValue === 'string') {
					return fieldValue.trim().length === 0; // Check for empty strings
				} else if (Array.isArray(fieldValue)) {
					return fieldValue.length === 0; // Check for empty arrays
				} else {
					return !fieldValue; // Check for undefined, null, or other falsy values
				}
			})
			.map(([field]) => field);

		if (errors.length > 0) {
			const unfilledFields = errors.map(field => {
				const { key, ns } = fieldTranslations[field];
				return t(key, { ns });
			}).join(', ');

			screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields}`;
			return false;
		}

		return true;
	};

	useEffect(() => {

		riskId ? setSubmitType('PUT') : setSubmitType('POST');

	}, [riskId])

	const addNewRisk = async () => {
		setIsLoading(true);

		var riskBody = {
			"subtheme": subtheme,
			"risk_level": riskLevel,
			"process": process,
			"risk": risk,
			"description": description,
			"impact": impact,
			"probability": probability,
			"ranking": ranking,
			"key": keyRisk,
			"strategy": strategy,
			"deleted": 0,
			"departments": selectedDepartments.map(dep => ({
				"department": dep.department,
				"entity": dep.entity
			}))
		};

		try {
			await request.post('risk/', riskBody, 'POST');
			setModalRiskAddedVisibility(true);
		} catch (error) {
			console.error(error.message);
		} finally {
			setIsLoading(false);
		}
	}

	const updateRisk = async () => {
		setIsLoading(true);

		// Construct the request body for updating the risk
		const riskBody = {
			"subtheme": subtheme,
			"risk_level": riskLevel,
			"process": process,
			"risk": risk,
			"description": description,
			"impact": impact,
			"probability": probability,
			"ranking": ranking,
			"key": keyRisk,
			"strategy": strategy,
			"departments": selectedDepartments.map(dep => ({
				"department": dep.department,
				"entity": dep.entity
			}))
		};

		try {
			await request.post(`risk/${riskId}/`, riskBody, 'PUT');
			setModalRiskAddedVisibility(true);
		} catch (error) {
			console.error(error.message);
		} finally {
			setIsLoading(false);
		}
	}

	const deleteRisk = async () => {
		// Soft delete the risk
		try {
			await request.post(`risk/${riskId}/`, { "deleted": true }, 'PATCH');
			setDeletedModalVisibility(true);
		} catch (error) {
			console.error(error.message)
		}
	}

	const getRiskData = async () => {
		setIsLoading(true);
		try {
			var riskData = await request.get(`risk/${riskId}`, {});


			const formattedDepartments = riskData['departments'].map(item => ({
				department: item.department.id,
				entity: item.entity.id
			}));
			setPreSelectedDepartments(formattedDepartments);
			setSelectedDepartments(formattedDepartments);


			setRisk(riskData['risk']);
			setDescription(riskData['description']);
			setRiskLevel(riskData['risk_level']['id'].toString());
			setProcess(riskData['process']);
			setTheme(riskData['subtheme']['theme']['id'].toString());
			setFetchedSubthemeId(riskData['subtheme']['id'].toString());
			setImpact(riskData['impact']);
			setProbability(riskData['probability']);
			setRanking(riskData['ranking']);
			setKeyrisk(riskData['key']);
			setStrategy(riskData['strategy']['id'].toString());
		} catch (error) {
			console.error(error.message)
		} finally {
			setIsLoading(false);
		}
	}

	const getRiskDepartments = async () => {
		setIsLoading(true);

		try {
			var riskDepartments = await request.get(`risk-department/?risk=${riskId}`);
			setPreSelectedDepartments(riskDepartments.map(item => { return { 'department': item.department, 'entity': item.entity } }));
		} catch (error) {
			console.error(error.message)
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		setRanking(impact * probability);

		if (keyRiskThreshold != null) {
			setKeyrisk(ranking >= keyRiskThreshold);
		}

	}, [impact, probability]);

	useEffect(() => {
		if (keyRiskThreshold != null) {
			setKeyrisk(ranking >= keyRiskThreshold);
		}
	}, [ranking]);

	useEffect(() => {
		// There is a bug in MotifSlider (v6.5.0). Setting min attribute to '1' glitches out the sliders. It will show the values 1 too high (e.g. when the slider is on its lowest, 1, it will show as 2). The bug has been reported to the Motif team 12-9-2024
		// This useEffect serves as a workaround so 0 values can't be submitted.
		// There is also seemingly a bug where you can pull the slider under the minimum (e.g. -1) and over the maximum, this also gets prevented by this useEffect.
		// parseInt because MotifSlider sets its values and attributes as strings, not integers.
		if (parseInt(impact) <= 0) {
			setImpact('1');
		}
		if (parseInt(probability) <= 0) {
			setProbability('1');
		}

		if (parseInt(impact) >= parseInt(scale)) {
			setImpact(scale.toString());
		}
		if (parseInt(probability) >= parseInt(scale)) {
			setProbability(scale.toString());
		}
	}, [impact, probability]);

	useEffect(() => {

		const fetchData = async () => {
			setIsLoading(true);
			try {
				setRiskLevelItems(await request.get('risklevel'));
				setStrategyItems(await request.get('strategylevel'));

				var clientData = await request.get('key-risk-data')

				setKeyRiskThreshold(clientData[0].key_risk_threshold);

				var scaleNumber = clientData[0].scale.number;

				setScale(scaleNumber)
				setMaxRanking((scaleNumber * scaleNumber).toString())

				riskId != null && getRiskData();
				riskId != null && getRiskDepartments();

				setSubthemeData(await request.get('subtheme'));
			} catch (error) {
				console.error(error.message)
			} finally {
				setIsLoading(false);
			}
		};

		fetchData();

	}, []);

	useEffect(() => { // Set theme dropdowns
		// Extract unique entity IDs from the selected departments
		const selectedEntityIds = [...new Set(selectedDepartments.map(dept => dept.entity))];

		const relevantSubthemeItems = subthemeData.filter(item => item.entity.some(entity => selectedEntityIds.includes(entity.id))); // filter subthemes for item relevant to the selected entities
		const relevantThemeItems = Array.from(new Map( // filter themes to show relevant themes
			relevantSubthemeItems.map(item => [
				item.theme.id,
				{ id: item.theme.id, description: item.theme.description }])
		).values());

		setRelevantSubthemeItems(relevantSubthemeItems)
		setThemeItems(relevantThemeItems)
	}, [selectedDepartments, subthemeData]);

	useEffect(() => {
		const themeExists = subthemeItems.find(item => item.theme.id == theme);

		if (!themeExists) {
			setSubtheme(''); // if the user has changed the theme, and had already selected a subtheme that is no longer relevant to the newly selected theme, clear the subtheme dropdown
		}

		const filteredSubthemeItems = relevantSubthemeItems.filter(item => item.theme.id == theme);
		setSubthemeItems(filteredSubthemeItems);

		if (fetched_subtheme_id && relevantSubthemeItems.find(item => item.id == fetched_subtheme_id)) {
			setSubtheme(fetched_subtheme_id); // if editing, make sure the subtheme dropdown gets prefilled
			setFetchedSubthemeId(null); // Clear fetched_subtheme_id after it is used
		}
	}, [theme, relevantSubthemeItems, fetched_subtheme_id])

	useEffect(() => {
		!!riskLevelItems.length && setForceRenderKey(crypto.randomUUID());
		!!strategyItems.length && setForceRenderKey(crypto.randomUUID());
		!!themeItems.length && setForceRenderKey(crypto.randomUUID());
		!!subthemeItems.length && setForceRenderKey(crypto.randomUUID());
		!!ranking.length && setForceRenderKey(crypto.randomUUID());
	}, [riskLevel, strategyItems, themeItems, subthemeItems]);

	return (

		<>
			<title>{t('add_risk', { ns: 'menu' })} | {tooltitle}</title>
			<div className="d-flex justify-content-center">
				<h1>{t('add_risk', { ns: 'menu' })}</h1>
			</div>
			<div className="row justify-content-center mx-2 mt-5"
				style={{
					overflowY: 'scroll',
					height: '80vh'
				}}>
				<div className="col-6" >
					<div disabled={isLoading}
						onClick={() => setEntityModalVisibility(!isEntityModalVisible)}
						className="d-flex align-items-center mt-2 mb-2"
						style={{ fontWeight: 'bold', cursor: 'pointer' }}>
						<ScreenReaderLabel id='sr-entities' message={`${t('entity_link', { ns: 'add_risk' })} ${t('required', { ns: 'screenreader' })}`} />
						<MotifIconButton tabIndex="0" aria-labelledby='sr-entities'><MotifIcon src={contentIcSelectAll24px} /></MotifIconButton>
						{t('entity_link', { ns: 'add_risk' })}
					</div>
					{(selectedDepartments.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('valid_departments', { ns: 'add_risk' })}</MotifErrorMessage>}

					<MotifFormField>
						<MotifLabel>{t('risk', { ns: 'general' })}</MotifLabel>
						<ScreenReaderLabel id='sr-risk' message={`${t('risk', { ns: 'general' })}, ${t('required', { ns: 'screenreader' })}`} />
						<MotifInput
							aria-labelledby='sr-risk'
							value={risk}
							disabled={isLoading}
							onChange={(event) => {
								setRisk(validateDescriptionInput(event.target.value).cleanedValue);
							}}
							maxLength={100}
						/>
						{(risk.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', { ns: 'screenreader' })}</MotifErrorMessage>}
						<MotifTextLink
							href="#"
							onClick={() => { setModalRiskListVisibility(true); }}
							disabled={isLoading}
						>
							{t('risk_list', { ns: 'add_risk' })}
						</MotifTextLink>
					</MotifFormField>

					<MotifFormField>
						<MotifLabel id="select-theme">{t('theme', { ns: 'general' })}</MotifLabel>
						<ScreenReaderLabel id='sr-theme' message={`${t('theme', { ns: 'general' })}, ${t('required', { ns: 'screenreader' })}`} />
						<MotifSelect
							ariaLabelledBy='sr-theme'
							key={forceRenderKey}
							disabled={isLoading}
							value={theme}
							onChange={val => setTheme(val)}>
							{themeItems.map((item, i) => (<MotifOption key={i} value={item.id.toString()}>{item.description}</MotifOption>))}
						</MotifSelect>
						{selectedDepartments.length == 0 ? <MotifInlineMessage>{t('entity_message', { ns: 'add_risk' })}</MotifInlineMessage> : null}
						{selectedDepartments.length > 0 && themeItems.length === 0 ? <MotifInlineMessage>{t('entity_subthemes_message', { ns: 'add_risk' })}</MotifInlineMessage> : null /* department(s) were selected, but they have no themes, notify user to add themes to the selected departments */}
						{(theme.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', { ns: 'screenreader' })}</MotifErrorMessage>}
					</MotifFormField>

					<MotifFormField>
						<MotifLabel id="select-subtheme">{t('subtheme', { ns: 'general' })}</MotifLabel>
						<ScreenReaderLabel id='sr-subtheme' message={`${t('subtheme', { ns: 'general' })}, ${t('required', { ns: 'screenreader' })}`} />
						<MotifSelect
							ariaLabelledBy='sr-subtheme'
							key={forceRenderKey}
							disabled={isLoading}
							value={subtheme}
							onChange={val => setSubtheme(val)}>
							{subthemeItems.map((item, i) => (
								<MotifOption key={i} value={item.id.toString()}>{item.description_code}</MotifOption>
							))}
						</MotifSelect>
						{subthemeItems.length == 0 && themeItems.length > 0 ? <MotifInlineMessage>{t('theme_message', { ns: 'add_risk' })}</MotifInlineMessage> : null}
						{(subtheme.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', { ns: 'screenreader' })}</MotifErrorMessage>}
					</MotifFormField>

					<MotifFormField>
						<MotifLabel>{t('description', { ns: 'general' })}</MotifLabel>
						<ScreenReaderLabel id='sr-description' message={`${t('description', { ns: 'general' })}, ${t('required', { ns: 'screenreader' })}`} />
						<MotifTextArea value={description}
							aria-labelledby='sr-description'
							disabled={isLoading}
							onChange={(event) => {
								setDescription(validateDescriptionInput(event.target.value).cleanedValue);
							}}
							rows={5}
							maxLength={2000} />
						{(description.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', { ns: 'screenreader' })}</MotifErrorMessage>}
					</MotifFormField>

					<MotifFormField>
						<MotifLabel>{t('process', { ns: 'general' })}</MotifLabel>
						<ScreenReaderLabel id='sr-process' message={`${t('process', { ns: 'general' })}, ${t('required', { ns: 'screenreader' })}`} />
						<MotifInput aria-labelledby='sr-process'
							value={process}
							disabled={isLoading}
							onChange={(event) => {
								setProcess(validateDescriptionInput(event.target.value).cleanedValue);
							}}
							maxLength={256} />
						{(process.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', { ns: 'screenreader' })}</MotifErrorMessage>}
					</MotifFormField>

					<MotifFormField>
						<MotifLabel id="select-risklevel">{t('risk_level', { ns: 'general' })}</MotifLabel>
						<ScreenReaderLabel id='sr-risk-level' message={`${t('risk_level', { ns: 'general' })}, ${t('required', { ns: 'screenreader' })}`} />
						<MotifSelect
							disabled={isLoading}
							key={forceRenderKey}
							value={riskLevel}
							onChange={val => setRiskLevel(val)}
							ariaLabelledBy="sr-risk-level">
							{riskLevelItems.map((item, i) => (<MotifOption key={i} value={item.id.toString()}>{item.description}</MotifOption>))}
						</MotifSelect>
						{(riskLevel.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', { ns: 'screenreader' })}</MotifErrorMessage>}
					</MotifFormField>

					<MotifFormField>
						<MotifLabel id="select-strategy">{t('strategy', { ns: 'general' })}</MotifLabel>
						<ScreenReaderLabel id='sr-strategy' message={`${t('strategy', { ns: 'general' })}, ${t('required', { ns: 'screenreader' })}`} />
						<MotifSelect
							ariaLabelledBy='sr-strategy'
							key={forceRenderKey}
							disabled={isLoading}
							value={strategy}
							onChange={val => setStrategy(val)}>
							{strategyItems.map((item, i) => (<MotifOption key={i} value={item.id.toString()}>{item.description}</MotifOption>))}
						</MotifSelect>
						{(strategy.length == 0 && isFormSubmitted) && <MotifErrorMessage> {t('required', { ns: 'screenreader' })}</MotifErrorMessage>}
					</MotifFormField>

					{maxRanking && scale ? // wait til scale and maxRanking is set or it may visually glitch
						<>
							<MotifFormField>
								<MotifLabel id="slider-impact">{t('impact', { ns: 'general' })}</MotifLabel>
								<MotifSlider
									value={impact}
									disabled={isLoading}
									onChange={event => setImpact(event.target.value)}
									id="slider-impact"
									min="0"
									max={scale.toString()}
								/>
							</MotifFormField>

							<MotifFormField>
								<MotifLabel id="slider-prob">{t('probability', { ns: 'general' })}</MotifLabel>
								<MotifSlider
									disabled={isLoading}
									value={probability}
									onChange={event => setProbability(event.target.value)}
									id="slider-prob"
									min="0"
									max={scale.toString()}
								/>
							</MotifFormField>

							<MotifFormField>
								<MotifLabel id="select-ranking">{t('ranking', { ns: 'general' })}</MotifLabel>
								<MotifSlider
									value={ranking}
									disabled={true}
									onChange={event => setRanking(event.target.value)}
									id="select-ranking"
									min="0"
									max={maxRanking.toString()}
								/>
							</MotifFormField>
						</>
						: null}

					<MotifFormField>
						<MotifCheckbox
							id="checkbox-key-risk"
							value="checkbox-key-risk"
							name="checkbox-key-risk"
							className="mt-3"
							checked={keyRisk}
							disabled={isLoading}
							onChange={event => setKeyrisk(event.target.checked)}
						>
							Key risk
						</MotifCheckbox>
					</MotifFormField>
					<div
						ref={screenReaderAlertRef}
						aria-live="assertive"
						className="visually-hidden"
						role="alert"
					></div>

					<div className="d-flex flex-row">
						<MotifButton
							className="mt-2"
							onClick={submitForm}
							disabled={isLoading}
						>
							{riskId ? t('edit', { ns: 'general' }) : t('add', { ns: 'general' })}
						</MotifButton>

						{riskId ?
							<MotifButton
								className="m-2"
								variant="warn"
								disabled={isLoading}
								onClick={() => setModalVisibility(true)}>
								{t('remove', { ns: 'general' })}
							</MotifButton>
							: null}

					</div>
				</div>
			</div>
			<CompanyOverview
				isModalVisible={isEntityModalVisible}
				setModalVisibility={setEntityModalVisibility}
				preSelectedDepartments={preSelectedDepartments}
				selectedDepartments={selectedDepartments}
				setSelectedDepartments={setSelectedDepartments}
			/>

			<MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
				<MotifModalHeader>{t('modal_delete_confirm_header', { ns: 'add_risk' })}</MotifModalHeader>
				<MotifModalBody>{t('modal_delete_confirm_body', { ns: 'add_risk' })}</MotifModalBody>
				<MotifModalFooter>
					<MotifButton size="medium" type="button" onClick={deleteRisk}>
						{t('confirm', { ns: 'general' })}
					</MotifButton>
					<MotifButton
						size="medium"
						type="button"
						onClick={() => setModalVisibility(false)}
					>
						{t('cancel', { ns: 'general' })}
					</MotifButton>
				</MotifModalFooter>
			</MotifModal>

			<RiskList isModalVisible={isModalRiskListVisible} setModalVisibility={setModalRiskListVisibility} isLoading={isLoading} setIsLoading={setIsLoading} setRisk={setRisk} setProcess={setProcess} setDescription={setDescription} />

			<MotifModal show={isDeletedModalVisible} onClose={() => setDeletedModalVisibility(false)}>
				<MotifModalHeader closeModalButton={true}>{t('modal_delete_confirm_header', { ns: 'add_risk' })}</MotifModalHeader>
				<MotifModalBody>
					{t('modal_deleted_body', { ns: 'add_risk' })}
				</MotifModalBody>
				<MotifModalFooter>
					<MotifButton size="medium" type="button" onClick={() => navigate('/risks/')}>
						{t('modal_added_button_risk', { ns: 'add_risk' })}
					</MotifButton>
				</MotifModalFooter>
			</MotifModal>

			<MotifModal show={isModalRiskAddedVisible} onClose={() => setModalRiskAddedVisibility(false)}>
				<MotifModalHeader closeModalButton={true}>{!riskId ? t('modal_added_header', { ns: 'add_risk' }) : t('modal_edited_header', { ns: 'add_risk' })}</MotifModalHeader>
				<MotifModalBody>{!riskId ? t('modal_added_body', { ns: 'add_risk' }) : t('modal_edited_body', { ns: 'add_risk' })}</MotifModalBody>
				<MotifModalFooter>
					<MotifButton size="medium" type="button" onClick={() => window.location.reload(true)}>
						{t('modal_added_button_newrisk', { ns: 'add_risk' })}
					</MotifButton>
					<MotifButton size="medium" type="button" onClick={() => navigate('/controls/add_control/', { state: { controlId: null } })}>
						{t('modal_added_button_newcontrol', { ns: 'add_risk' })}
					</MotifButton>
					<MotifButton size="medium" type="button" onClick={() => navigate('/risks/', { state: { riskId: null } })}>
						{t('modal_added_button_risk', { ns: 'add_risk' })}
					</MotifButton>
				</MotifModalFooter>
			</MotifModal>
		</>
	)
}

export default AddRisk