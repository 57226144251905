import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import {
  MotifTable,
  MotifChip,
  MotifTextLink,
  MotifIcon,
  MotifIconButton,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
} from '@ey-xd/motif-react'

import {
  editorIcAttachFile24px,
  hardwareIcKeyboardArrowDown24px
} from '@ey-xd/motif-react/assets/icons';

import { MakeRequests } from '../../utils/request';

const MonitoringTaskDocuments = () => {

  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useLoading();

  const request = new MakeRequests();

  const [isModalVisible, setModalVisibility] = useState(false);

  const [attachments, setAttachments] = useState([]);
  const [blobUrls, setBlobUrls] = useState({}); // This will store the blob URLs. Key is attachment ID, value is blob URL.

  const [executions, setExecutions] = useState();
  const [reviews, setReviews] = useState();
  const [mergedComments, setMergedComments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const executionData = await request.get('perform-monitoring/?attachments=1', {})
      const reviewData = await request.get('perform-monitoring-review/?attachments=1', {})

      setExecutions(executionData);
      setReviews(reviewData);
      setIsLoading(false);

    };

    fetchData();
  }, []);

  useEffect(() => {
    const mergedTasks = {};

    // Process execution data
    executions?.forEach(execution => {
      const taskId = execution.monitoringtask.id;
      if (!mergedTasks[taskId]) {
        mergedTasks[taskId] = {
          ...execution,
          combinedAttachments: execution.attachments.map(att => ({ ...att, type: 'execution' })),
        };
      } else {
        mergedTasks[taskId].combinedAttachments.push(...execution.attachments.map(att => ({ ...att, type: 'execution' })));
      }
    });

    // Process review data
    reviews?.forEach(review => {
      const taskId = review.monitoringtask.id;
      if (!mergedTasks[taskId]) {
        mergedTasks[taskId] = {
          ...review,
          combinedAttachments: review.attachments.map(att => ({ ...att, type: 'review' })),
        };
      } else {
        mergedTasks[taskId].combinedAttachments.push(...review.attachments.map(att => ({ ...att, type: 'review' })));
      }
    });

    setMergedComments(Object.values(mergedTasks));
  }, [executions, reviews]);


  const fetchBlobUrl = async (attachment) => {
    const pathWithoutLeadingSlash = attachment.path.startsWith('/') ? attachment.path.slice(1) : attachment.path; // cut off the first slash
    const response = await request.get(pathWithoutLeadingSlash);
    if (response.ok) {
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    }
    return null;
  };

    const showAttachments = async (value) => {
        const newBlobUrls = {};
        for (let attachment of value) {
            newBlobUrls[attachment.id] = await fetchBlobUrl(attachment);
        }
        setBlobUrls(newBlobUrls);
        setAttachments(value);
        setModalVisibility(true);
    };

  const columnDef = [
    {
      headerName: ' ',
      cellRenderer: () => (
            <MotifIconButton type="button" title={t('tablebuttondocuments', {ns: 'general'})}> 
              <MotifIcon src={editorIcAttachFile24px} />
            </MotifIconButton>
        ),
      field: 'combinedAttachments',
      width: 60,
      pinned: 'left',
      headerComponentParams: {
        dataColumn: false,
        headerEndComponent: (
          <MotifIconButton aria-label="Click here to see options" type="button">
            <MotifIcon src={hardwareIcKeyboardArrowDown24px} />
          </MotifIconButton>
        )
      }
    },
    {
      headerName: t('subtheme', { ns: 'general' }),
      field: 'monitoringtask.monitoring.controls',
      cellRenderer: ({ value }) => {
              const uniqueSubthemes = [...new Set(value.map(control => control.risk.subtheme.description_code))];
              const subthemes = uniqueSubthemes.join(', ');
            return (
              <>
                {subthemes}
              </>
              );
            },
      minWidth: 170,
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      flex: 2,
    },
    {
      headerName: t('risks', { ns: 'general' }),
      field: 'monitoringtask.monitoring.controls',
      cellRenderer: ({ value }) => {
              const uniqueRisks = [...new Set(value.map(control => control.risk.risk))];
              const risks = uniqueRisks.join(', ');
            return (
              <>
                {risks}
              </>
              );
            },
      minWidth: 170,
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      flex: 2,
    },
    {
      headerName: t('monitoring', { ns: 'general' }),
      field: 'monitoringtask.monitoring.monitoring',
      minWidth: 170,
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      flex: 2,
    },
    {
      headerName: t('status', { ns: 'general' }),
      field: 'monitoringtask.status',
      sortable: true,
      cellRenderer: ({ value }) => (
            <div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
                <MotifChip variant={value.chipVariant}>{value.description}</MotifChip>
            </div>
        ),
      minWidth: 300,
    },
    {
      headerName: t('deadline', { ns: 'general' }),
      field: 'monitoringtask.deadline',
      sortable: true,
      filter: true,
      sort: 'asc',
    },
    {
      headerName: t('responsible', { ns: 'general' }),
      field: 'monitoringtask.responsible_fullname',
      minWidth: 140,
      wrapText: true,
      sortable: true,
      filter: true,
    },
    {
      headerName: t('reviewer', { ns: 'general' }),
      field: 'monitoringtask.reviewer_fullname',
      minWidth: 140,
      wrapText: true,
      sortable: true,
      filter: true,
    },
    {
      headerName: t('fiscal_year', { ns: 'general' }),
      field: 'monitoringtask.fiscal_year',
      minWidth: 25,
      sortable: true,
      filter: true,
    },
  ]

  return (
      <>
        <div className="row justify-content-center mx-2 my-4">
          <div className="col">
          { mergedComments ?
          <MotifTable className='pb-5'
                columnDefs={columnDef}
                rowData={mergedComments}
                onRowClicked={row => showAttachments(row.data.combinedAttachments)}
                onCellKeyDown={row => {
                  if (row.event.key === 'Enter') {
                    showAttachments(row.data.combinedAttachments);
                  }
                }}
                compact={true}
                zebra={true}
                pagination={mergedComments?.length > 30 ? true : false}
                paginationPageSize="25"
            />
            : null}
        </div>
      </div>
      <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
        <MotifModalHeader>{t('attachments', { ns: 'general' })}</MotifModalHeader>
        <MotifModalBody>
          {attachments.filter(att => att.type === 'execution').length > 0 && (
            <>
              <strong>{t('responsible', { ns: 'general' })}</strong>
              <ul>
                {attachments.filter(att => att.type === 'execution').map((file, index) => (
                  <li key={index}>
                    <MotifTextLink href={blobUrls[file.id]} target="_blank" rel="noopener noreferrer">
                      {file.name}
                    </MotifTextLink>
                  </li>
                ))}
              </ul>
            </>
          )}

          {attachments.filter(att => att.type === 'review').length > 0 && (
            <>
              <strong>{t('reviewer', { ns: 'general' })}</strong>
              <ul>
                {attachments.filter(att => att.type === 'review').map((file, index) => (
                  <li key={index}>
                    <MotifTextLink href={blobUrls[file.id]} target="_blank" rel="noopener noreferrer">
                      {file.name}
                    </MotifTextLink>
                  </li>
                ))}
              </ul>
            </>
          )}
        </MotifModalBody>
      </MotifModal>


    </>
  );
}

export default MonitoringTaskDocuments;