import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';
import { request } from '../../utils/request';
import { tooltitle } from '../../utils/utils';

const Error = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    setIsLoading(false);

    useEffect(() => {
        
        const fetchUserAuthInfo = async () => {
          try {
            await new Promise(resolve => setTimeout(resolve, 8000)); // wait 8 sec before sending away
            const response = await request.get('auth/check-auth-status/');
            if (!response.is_authenticated) {
              navigate('/login/');
            } else {

            }
          } catch (error) {
            console.error('Error fetching user auth info:', error);
            navigate('/login/');
          }
        };
    
        fetchUserAuthInfo();
      }, [navigate]);

    return (
        <>
        <title>{t('accessdenied', {ns: 'menu'})} | {tooltitle}</title>
            <div className='row justify-content-center mx-2 mt-5 text-center'>
                <h1 className='fs-3'>{t('no_access', {ns:'general'})}</h1>
            </div>
            <div className='row justify-content-center mx-2 mt-5 text-center'>
                <p>{t('no_access_message', {ns:'general'})}</p>
            </div>
        </>
    )
}

export default Error