import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    MotifModal,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader,
    MotifButton,
} from '@ey-xd/motif-react';

import RiskDetailsItem from './RiskDetailsItem';

const RiskDetails = ({ scaleNumber, riskData, isModalVisible, setModalVisibility, navigate, hideEditButton }) => { 
    
    const { t } = useTranslation();
    const { userRole, isSuperuser } = useOutletContext();

    const getGroupedData = (departments)  => { // find unique entities and combine with all their departments
        const grouped = {};
    
        departments?.forEach(item => {
            const entityName = item.entity.name;
            const departmentName = item.department.department;
    
            if (!grouped[entityName]) {
                grouped[entityName] = [];
            }
    
            // Condition to skip adding 'Default' if it's the only department for the entity
            if (!(departmentName === 'Default' && grouped[entityName].length === 0)) {
                grouped[entityName].push(departmentName);
            }
        });
        
        return grouped;
    }
    
    const groupedData = getGroupedData(riskData.departments);

    const riskQualification = `${t('risk_qualification', { ns: 'general' })} (${t('impact', { ns: 'general' })} × ${t('probability', { ns: 'general' })})`;

    const data = { 
        [t('entities_departments', {ns: 'general'})]: (
            <ul>
              {Object.entries(groupedData).map(([entity, departments], i) => {
                // Use a Set to store unique departments
                const uniqueDepartments = new Set(departments.filter(dep => dep !== "Default"));
          
                const departmentString = uniqueDepartments.size === 0
                  ? ""
                  : `(${[...uniqueDepartments].join(", ")})`;
          
                return (
                  <li key={i}>
                    {entity} {departmentString}
                  </li>
                );
              })}
            </ul>
        ),
        [t('theme', {ns: 'general'})]: riskData.subtheme?.theme.description,
        [t('subtheme', {ns: 'general'})]: riskData.subtheme?.description_code,
        [t('process', {ns: 'general'})]: riskData.process,
        [t('risk', {ns: 'general'})]: riskData.risk,
        [t('description', {ns: 'general'})]: riskData.description,
        [t('risk_level', {ns: 'general'})]: riskData.risk_level?.description,
        [t('strategy', {ns: 'general'})]: riskData.strategy?.description,
        [riskQualification]: (
            <div>
                <div>{t('impact', { ns: 'general' })} ({t('scale', { ns: 'general' })}: 1-{scaleNumber}) - {riskData.impact}</div>
                <div>{t('probability', { ns: 'general' })} ({t('scale', { ns: 'general' })}: 1-{scaleNumber}) - {riskData.probability}</div>
                <div>= {t('ranking', { ns: 'general' })} ({t('scale', { ns: 'general' })}: 1-{scaleNumber * scaleNumber}) - {riskData.ranking}</div>
            </div>
        ),
        'Key risk': riskData.key === true ? [t('yes', {ns: 'general'})] : [t('no', {ns: 'general'})]
    }

    return (
        <>
            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>{t('risk_details', {ns: 'general'})}</MotifModalHeader>
                <MotifModalBody>
                    <div className='d-flex flex-column' style={{ whiteSpace: 'pre-wrap' }}>
                        {Object.entries(data).map(([key, value]) => <RiskDetailsItem description={key} value={value} />)}
                    </div>


                </MotifModalBody>
                <MotifModalFooter>
                    { hideEditButton ? null : // hide the edit button when the pop up is opened from MonitoringTaskDescription
                    <>
                        {userRole === 'Admin' &&
                            <MotifButton
                            className="me-3"
                            onClick={() => navigate('/risks/add_risk/', { state: { riskId: riskData.id } })}>{t('edit', {ns: 'general'})}</MotifButton>
                        }
                    </> 
                    }
                        <MotifButton
                        className="me-3"
                        onClick={() => setModalVisibility(false)}>{t('close', {ns: 'general'})}</MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default RiskDetails