import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../utils/loadingcontext';

import {
    MotifButton,
    MotifTable,
    MotifIcon,
    MotifIconButton
} from '@ey-xd/motif-react';

import {
    imageIcEdit24px,
    hardwareIcKeyboardArrowDown24px
} from '@ey-xd/motif-react/assets/icons';

import { request } from '../../../utils/request.js';

import NewThemeModal from './NewThemeModal.jsx'

const Themes = () => {

    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const [subthemeData, setSubthemeData] = useState([]);

    const [isModalVisible, setModalVisibility] = useState(false);
    const [editMode, setEditMode] = useState(false); // add or edit subtheme
    const [selectedSubthemeId, setSelectedSubthemeId] = useState('')
    const [theme, setTheme] = useState('')
    const [subtheme, setSubtheme] = useState('');
    const [entity, setEntity] = useState([]);
    const [country, setCountry] = useState('')
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    // for the edit modal
    const [themeData, setThemeData] = useState([])
    const [countries, setCountries] = useState([])
    const [entityData, setEntityData] = useState([])

      useEffect(() => {
        const fetchData = async () => {

            setIsLoading(true);

            try {
                setSubthemeData(await request.get('subtheme/', {}));
                setThemeData(await request.get('theme/'));
                setCountries(await request.get('country/'));
                setEntityData(await request.get('entity/'));    
            } catch (error) {
                console.error(error.message)
            } finally {
                setIsLoading(false);
            }
          };

        fetchData();

    }, [])

    const columnDef = [
        {
          headerName: ' ',
          cellRenderer: ({ data }) => (
            <MotifIconButton value={data.id}
                type="button"
                title={t('tablebutton', {ns: 'admin'})}>
                <MotifIcon src={imageIcEdit24px} />
            </MotifIconButton>
        ),
          field: 'id',
          width: 60,
          pinned: 'left',
          sort: 'asc',
          headerComponentParams: {
            dataColumn: false,
            headerEndComponent: (
              <MotifIconButton aria-label="Click here to edit" type="button">
                <MotifIcon src={hardwareIcKeyboardArrowDown24px} />
              </MotifIconButton>
            )
          }
        },
        {
          headerName: t('theme', {ns: 'general'}),
          field: 'theme.description',
          sortable: true,
          filter: true,
          flex: 1,
        },
        {
          headerName: t('subtheme', {ns: 'general'}),
          field: 'description',
          sortable: true,
          filter: true,
          flex: 1,
        },
        {
          headerName: t('country', {ns: 'general'}),
          field: 'country.country',
          sortable: true,
          filter: true,
          flex: 1,
        },
        {
          headerName: t('entities', {ns: 'general'}),
          cellRenderer: ({ value }) => {
            const names = value.map(item => item.name).join(", ");
        return (
          <>
            <p>{names}</p>
          </>
          );
        },
          field: 'entity',
          sortable: true,
          filter: true,
          flex: 2,
        },
      ]
    return (
        <>
            <div className="row justify-content-left mt-5">
                <div className="col-8">
                    <MotifButton onClick={() => {setEditMode(false); setSubtheme(''); setCountry(''); setEntity(''); setIsFormSubmitted(false); setModalVisibility(true)}} disabled={isLoading}>{t('add_subtheme', {ns: 'admin'})}</MotifButton>
                </div>
            </div>

            { subthemeData ?
            <div className="row justify-content-left mt-5">
                <div className="col">
                  <MotifTable className='pb-5'
                    columnDefs={columnDef}
                    rowData={subthemeData}
                    onRowClicked={row => { setEditMode(true); setSelectedSubthemeId(row.data.id); setModalVisibility(true); }}
                    onCellKeyDown={row => {
                      if (row.event.key === 'Enter') {
                        setEditMode(true);
                        setSelectedSubthemeId(row.data.id);
                        setModalVisibility(true);
                      }
                    }}
                    compact={true}
                    zebra={true}
                    pagination={subthemeData?.length > 30 ? true : false}
                    paginationPageSize="25"
                    />
                </div>
            </div>
            : null }

            <NewThemeModal
                isModalVisible={isModalVisible}
                setModalVisibility={setModalVisibility}
                editMode={editMode}
                setEditMode={setEditMode}
                selectedSubthemeId={selectedSubthemeId}
                subthemeData={subthemeData}
                setSubthemeData={setSubthemeData}
                theme={theme}
                setTheme={setTheme}
                subtheme={subtheme} 
                setSubtheme={setSubtheme} 
                entity={entity}
                setEntity={setEntity} 
                country={country}
                setCountry={setCountry}
                themeData={themeData}
                countries={countries} 
                entityData={entityData}
                isFormSubmitted={isFormSubmitted} 
                setIsFormSubmitted={setIsFormSubmitted} />
        </>
    )
}

export default Themes