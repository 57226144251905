import { useTranslation } from 'react-i18next';

const TaskCount = ({ colors, overdueTasks, todayTasks, soonTasks, toDoTasks }) => {

    const { t } = useTranslation();

    return (
        <div className="row justify-content-center text-center mt-3 pt-3 p-5">
            <div className="col d-flex flex-column justify-content-center align-items-center">
                <p style={{ color: colors.lightgrey, fontSize:"2rem", fontWeight:"bold" }}>{t('overdue', {ns: 'general'})}</p>
                <p style={{ color: colors.lightgrey, fontSize:"2rem", fontWeight:"bold" }}>{overdueTasks.controls.length + overdueTasks.monitoring.length}</p>
            </div>
            <div className="col d-flex flex-column justify-content-center align-items-center">
                <p style={{ color: colors.lightgrey, fontSize:"2rem", fontWeight:"bold" }}>{t('today', {ns: 'general'})}</p>
                <p style={{ color: colors.lightgrey, fontSize:"2rem", fontWeight:"bold" }}>{todayTasks.controls.length + todayTasks.monitoring.length}</p>
            </div>
            <div className="col d-flex flex-column justify-content-center align-items-center">
                <p style={{ color: colors.lightgrey, fontSize:"2rem", fontWeight:"bold" }}>{t('soon', {ns: 'general'})}</p>
                <p style={{ color: colors.lightgrey, fontSize:"2rem", fontWeight:"bold" }}>{soonTasks.controls.length + soonTasks.monitoring.length}</p>
            </div>
            <div className="col d-flex flex-column justify-content-center align-items-center">
                <p style={{ color: colors.lightgrey, fontSize:"2rem", fontWeight:"bold" }}>{t('to_do', {ns: 'general'})}</p>
                <p style={{ color: colors.lightgrey, fontSize:"2rem", fontWeight:"bold" }}>{toDoTasks.controls.length + toDoTasks.monitoring.length}</p>
            </div>
        </div>
    );
}

export default TaskCount;