import React, { useState } from 'react'

import { useTranslation } from 'react-i18next';

import {
    MotifTabNavigation as MotifTabNav,
    MotifTabControl
} from '@ey-xd/motif-react';

import GeneralDocuments from './GeneralDocuments.jsx';
import ControlTaskDocuments from './ControlTaskDocuments.jsx';
import MonitoringTaskDocuments from './MonitoringTaskDocuments.jsx';
import { tooltitle } from '../../utils/utils';

const Documents = () => {

    const { t } = useTranslation();

    const [tabKey, handleTabKey] = useState(0);

    return (
        <>
        <title>{t('documents', {ns: 'menu'})} | {tooltitle}</title>
        <div className="d-flex justify-content-center">
            <h1>{t('documents', {ns: 'menu'})}</h1>
        </div>
            <MotifTabNav
                className="mt-3 mx-2"
                controlled>
                <MotifTabControl
                    onClick={() => handleTabKey(0)}
                    className={tabKey === 0 ? 'motif-active' : ''}
                >
                    {t('general', {ns: 'general'})}
                </MotifTabControl>
                <MotifTabControl tabIndex="0"
                    onClick={() => handleTabKey(1)}
                    className={tabKey === 1 ? 'motif-active' : ''}
                >
                    {t('taxpolicy', {ns: 'menu'})}
                </MotifTabControl>
                <MotifTabControl tabIndex="0"
                    onClick={() => handleTabKey(2)}
                    className={tabKey === 2 ? 'motif-active' : ''}
                >
                    {t('controls', {ns: 'general'})}
                </MotifTabControl>
                <MotifTabControl tabIndex="0"
                    onClick={() => handleTabKey(3)}
                    className={tabKey === 3 ? 'motif-active' : ''}
                >
                    {t('monitoring', {ns: 'general'})}
                </MotifTabControl>
            </MotifTabNav>

            <div className="mx-2">
                {tabKey === 0 && <GeneralDocuments taxpolicy={'?tp=0'} />}
                {tabKey === 1 && <GeneralDocuments taxpolicy={'?tp=1'} />}
                {tabKey === 2 && <ControlTaskDocuments />}
                {tabKey === 3 && <MonitoringTaskDocuments />}
            </div>
        </>
    )
}

export default Documents