import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../utils/loadingcontext';

import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifLabel,
    MotifFormField,
    MotifTabNavigation as MotifTabNav,
    MotifSelect,
    MotifOption,
    MotifInput,
    MotifErrorMessage,
    MotifInlineMessage
} from '@ey-xd/motif-react';

import { request } from '../../../utils/request';

import { validateGeneralInput } from '../../../utils/utils';

import ScreenReaderLabel from '../../../components/ScreenReaderLabel';

const NewThemeModal = ({isModalVisible, setModalVisibility, editMode, setEditMode, selectedSubthemeId, subthemeData, setSubthemeData, theme, setTheme, subtheme, setSubtheme, country, setCountry, entity, setEntity, themeData, countries, entityData, isFormSubmitted, setIsFormSubmitted}) => {

    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const [isConfirmModalVisible, setConfirmModalVisibility] = useState(false);
    const [ConfirmModalText, setConfirmModalText] = useState('');
    const [ConfirmModalFooter, setConfirmModalFooter] = useState('');
    const [hideModalCloseButton, setHideModalCloseButton] = useState(true);
    const screenReaderAlertRef = useRef(null);

    const [forceRenderKey, setForceRenderKey] = useState('');

    useEffect(() => {
        !!theme.length && setForceRenderKey(crypto.randomUUID());
        !!entity.length && setForceRenderKey(crypto.randomUUID());
        !!country.length && setForceRenderKey(crypto.randomUUID());

    }, [themeData, entityData, countries]);

    useEffect(() => {
        if (editMode == true){
            let selectedSubtheme = subthemeData?.find(sub => sub.id == selectedSubthemeId)
            setTheme(selectedSubtheme?.theme.id.toString());
            setSubtheme(selectedSubtheme?.description.toString());
            setCountry(selectedSubtheme?.country.id.toString());
            setEntity(selectedSubtheme?.entity.map(entity => entity.id.toString()));
        }
      }, [selectedSubthemeId, editMode]);

    const fieldTranslations = {
        theme: { key: 'theme', ns: 'general', required: true },
        subtheme: { key: 'subtheme', ns: 'general', required: true },
        country: { key: 'country', ns: 'general', required: true },
        entity: { key: 'entities', ns: 'general', required: true },
    };
    
    const validateForm = () => {
        const errors = Object.entries(fieldTranslations)
            .filter(([field, { required }]) => {
                const fieldValue = eval(field); // Dynamically get the field's value
    
                if (!required) return false; // Skip non-required fields
    
                // Validate based on field type
                if (typeof fieldValue === 'string') {
                    return fieldValue.trim().length === 0; // Check for empty strings
                } else if (Array.isArray(fieldValue)) {
                    return fieldValue.length === 0; // Check for empty arrays
                } else {
                    return !fieldValue; // Check for undefined, null, or other falsy values
                }
            })
            .map(([field]) => field);
    
        if (errors.length > 0) {
            const unfilledFields = errors.map(field => {
                const { key, ns } = fieldTranslations[field];
                return t(key, { ns });
            }).join(', ');
    
            screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields}`;
            return false;
        }
    
        return true;
    };
    

    const clearData = () => {
        
        setEditMode(false);
        setIsFormSubmitted(false);
        setTheme('');
        setSubtheme('');
        setCountry('');
        setEntity([]);

    }

    useEffect(() => {
        if(!isModalVisible) { // if modal is closed, clear form data
            clearData();
        }
    }, [isModalVisible])

    const handleSubmit = async () => {
        setIsLoading(true); // Start loading indicator
        setIsFormSubmitted(true); // Mark form as submitted
    
        try {
            if (!validateForm()) {
                return; // Exit early if form validation fails
            }
    
            const body = {
                theme,
                description: subtheme,
                entity_ids: entity,
                country,
                deleted: 0,
            };
    
            let response;
            if (!editMode) {
                response = await request.post('subtheme/', body, 'POST');
            } else if (editMode && selectedSubthemeId) {
                response = await request.post(`subtheme/${selectedSubthemeId}/`, body, 'PUT');
            }
        } catch (error) {
            console.error("Error during form submission");
        } finally {
            setSubthemeData(await request.get('subtheme/', {})); // Refresh MotifTable data after adding/editing
            setIsLoading(false);
            setModalVisibility(false);
        }
    };    

    const warningForm = () => {
        setConfirmModalText(t('modal_subtheme_remove_confirm_body', {ns: 'admin'}))
        setHideModalCloseButton(true);
        setConfirmModalFooter(
        <>
          <MotifButton
            variant="warn"
            className="me-3"
            disabled={isLoading}
            onClick={() => { handleDelete() }}>{t('confirm', {ns: 'general'})}</MotifButton>
          <MotifButton
            className="me-3"
            disabled={isLoading}
            onClick={() => setConfirmModalVisibility(false)}>{t('cancel', {ns: 'general'})}</MotifButton>
        </>
        );
        setConfirmModalVisibility(true);
      }

    const handleDelete = async () => {
        setIsLoading(true);
    
        try {
            const response = await request.post(`subtheme/${selectedSubthemeId}/`, { deleted: true }, 'PATCH');
    
            // Refresh MotifTable data after deleting
            setSubthemeData(await request.get('subtheme/', {}));
    
            // Set modal confirmation message and footer buttons
            setConfirmModalText(t('modal_subtheme_removed_body', { ns: 'admin' }));
            setHideModalCloseButton(true);
            setConfirmModalFooter(
                <MotifButton
                    className="me-3"
                    onClick={() => {
                        setConfirmModalVisibility(false);
                        setModalVisibility(false);
                    }}
                >
                    {t('close', { ns: 'general' })}
                </MotifButton>
            );
            setConfirmModalVisibility(true);
    
        } catch (error) {
            console.error("Error deleting subtheme.");
        } finally {
            setIsLoading(false);
        }
    };
    

    return (
        <>
            <MotifModal show={isModalVisible} 
                        onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>{editMode == true ? t('modal_subtheme_edit_header', {ns: 'admin'}) : t('modal_subtheme_add_header', {ns: 'admin'})}</MotifModalHeader>
                <MotifModalBody>
                    <MotifFormField>
                        <MotifLabel>{t('theme', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-theme' message={`${t('theme', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                            <MotifSelect key={forceRenderKey}
                                value={theme}
                                disabled={isLoading}
                                onChange={val => {setTheme(val);}}
                                visibleOptions='3'
                                ariaLabelledBy='sr-theme'>
                                {
                                    themeData?.map(item => (<MotifOption key={item.id} value={item.id.toString()}>{item.description}</MotifOption>))
                                }

                            </MotifSelect>
                        {(theme?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('valid_theme', {ns: 'admin'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('subtheme', {ns: 'general'})}</MotifLabel>
                        <ScreenReaderLabel id='sr-subtheme' message={`${t('subtheme', {ns: 'general'})} ${t('required', {ns: 'screenreader'})}`}/>
                        <MotifInput value={subtheme}
                            disabled={isLoading}
                            onChange={event => setSubtheme(validateGeneralInput(event.target.value).cleanedValue)}
                            maxLength={100}
                            aria-labelledby='sr-subtheme' />
                            {(subtheme?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('valid_subtheme', {ns: 'admin'})}</MotifErrorMessage>}
                    </MotifFormField>

                    <MotifFormField>
                        <MotifLabel>{t('country', {ns: 'general'})}</MotifLabel>
                            <MotifSelect key={forceRenderKey}
                                value={country}
                                filter={true}
                                disabled={isLoading}
                                onChange={val => setCountry(val)}
                                visibleOptions='3'>
                                {
                                    countries?.map(item => (<MotifOption key={item.id} value={item.id.toString()}>{item.country}</MotifOption>))
                                }

                            </MotifSelect>
                        {(country?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('valid_country', {ns: 'admin'})}</MotifErrorMessage>}
                    </MotifFormField>
    
                    <MotifFormField style={{height: '20vh'}}>
                        <MotifLabel>{t('entities', {ns: 'general'})}</MotifLabel>
                            <MotifSelect key={forceRenderKey}
                                value={entity}
                                disabled={isLoading}
                                multiple={true}
                                filter={true}
                                onChange={val => {setEntity(val);}}
                                visibleOptions='3'>
                                {
                                    entityData?.map(item => (<MotifOption key={item.id} value={item.id.toString()}>{item.name}</MotifOption>))
                                }

                            </MotifSelect>
                        {(entity?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('valid_entity', {ns: 'add_control'})}</MotifErrorMessage>}
                    </MotifFormField> 
                </MotifModalBody>
                <MotifModalFooter>
                    <div
                        ref={screenReaderAlertRef}
                        aria-live="assertive"
                        className="visually-hidden"
                        role="alert"
                    ></div>
                    <div className="row" style={{width: '100%'}}>
                        <div className="col-6 text-left">
                            {editMode && selectedSubthemeId ? 
                            <MotifButton
                                variant="warn"
                                disabled={isLoading}
                                onClick={() => { warningForm() }}>{t('remove', {ns: 'general'})}</MotifButton>
                            : null}
                        </div>
                        <div className="col-6 text-right d-flex justify-content-end">
                            <MotifButton 
                                size="medium" 
                                type="button" 
                                disabled={isLoading} 
                                onClick={() => { handleSubmit() }}
                                style={{margin: '5px'}}
                            >
                                {editMode ? t('edit', {ns: 'general'}) : t('add', {ns: 'general'})}
                            </MotifButton>
                            <MotifButton
                                size="medium"
                                variant="secondary"
                                type="button"
                                disabled={isLoading}
                                onClick={() => setModalVisibility(false)}
                                style={{margin: '5px'}}
                            >
                                {t('cancel', {ns: 'general'})}
                            </MotifButton>
                        </div>
                    </div>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal show={isConfirmModalVisible} onClose={() => {setConfirmModalVisibility(false)}}>
                <MotifModalHeader closeModalButton={hideModalCloseButton}>{t('alert', {ns: 'general'})}</MotifModalHeader>
                <MotifModalBody>{ConfirmModalText}</MotifModalBody>
                <MotifModalFooter>{ConfirmModalFooter}</MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default NewThemeModal