import React, { useState, useEffect, useRef } from 'react';
import UploadItem from '../../components/UploadItem';
import EffectiveControlsModal from './EffectiveControlsModal';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';
import { adjustFileData, calculateFiscalYear, sanitizeFilename, validateCommentInput } from '../../utils/utils';

import {
	MotifFormField,
	MotifLabel,
	MotifOption,
	MotifSelect,
	MotifTextArea,
	MotifButton,
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifErrorMessage,
	MotifDatePicker,
	MotifTextLink
} from '@ey-xd/motif-react'
import ScreenReaderLabel from '../../components/ScreenReaderLabel';

const PerformMonitoringForm = ({ draft, role, monitorTask, taskId, taskFinal, deviations, setDeviations, setTaskFinal, executionDate, fiscalYear, setFiscalYear, clientFiscalYear, navigate, request }) => {

	const { t } = useTranslation();
	const { isLoading, setIsLoading } = useLoading();

	const [forceRenderKey, setForceRenderKey] = useState('');
	const screenReaderAlertRef = useRef(null);

	const [isModalVisible, setModalVisibility] = useState(false);
	const [modalData, setModalData] = useState({
		headerText: t('alert', { ns: 'general' }),
		bodyText: '',
		footerContent: '',
		hideCloseButton: true,
	});

	const showModal = (bodyText, footerContent, hideCloseButton = true) => {
		setModalData(prevData => ({
			...prevData,
			bodyText,
			footerContent,
			hideCloseButton,
		}));
		setModalVisibility(true); // Make sure the modal is shown
	};

	const [isControlsModalVisible, setControlsModalVisibility] = useState(false);
	const [isControlFeedbackSubmitted, setIsControlFeedbackSubmitted] = useState(false);
	const [controlFeedback, setControlFeedback] = useState({});
	const [rating, setRating] = useState('3');
	const [feedback, setFeedback] = useState('');

	const [commentId, setCommentId] = useState(null);

	const [fiscalYearItems, setFiscalYearItems] = useState([]);
	const [comments, setComments] = useState('');
	const [attachments, setAttachments] = useState([]);
	const [completedSelect, setCompletedSelect] = useState('');

	const [fileData, setFileData] = useState([]); // Store file metadata and blobs locally

	const [disableForm, setDisableForm] = useState(true);
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);
	const [formAction, setFormAction] = useState(null);

	useEffect(() => {
		!!fiscalYear && setForceRenderKey(crypto.randomUUID());
		!!deviations && setForceRenderKey(crypto.randomUUID());
		!!completedSelect && setForceRenderKey(crypto.randomUUID());
	}, [fiscalYearItems, deviations, completedSelect]);

	useEffect(() => {
		if (monitorTask?.deadline) {
			const calculatedFiscalYear = calculateFiscalYear(clientFiscalYear[0], monitorTask?.deadline);
			if (calculatedFiscalYear) {
				// Check if the fiscal year format is standard calendar year
				const isStandardCalendarYear = clientFiscalYear[0].start_month === 1 && clientFiscalYear[0].start_day === 1 &&
					clientFiscalYear[0].end_month === 12 && clientFiscalYear[0].end_day === 31;

				let newFiscalYearItems = [];

				if (isStandardCalendarYear) {
					// For standard calendar year, generate options without splitting
					const year = Number(calculatedFiscalYear); // Convert to number to perform arithmetic
					newFiscalYearItems = [`${year - 1}`, `${year}`, `${year + 1}`];
				} else {
					// For split financial years, keep the original logic
					const yearParts = calculatedFiscalYear.split('/').map(Number); // Convert strings to numbers
					const prevYear = yearParts[0] - 1; // Previous year
					const nextYear = (yearParts[1] || yearParts[0]) + 1; // Next year

					newFiscalYearItems = [`${prevYear}/${prevYear + 1}`, calculatedFiscalYear, `${nextYear}/${nextYear + 1}`];
				}

				setFiscalYearItems(newFiscalYearItems);

				if (!fiscalYear && calculatedFiscalYear) {
					setFiscalYear(calculatedFiscalYear); // prefill calculated fiscal year based on the deadline
				}
			}
		}
	}, [clientFiscalYear, monitorTask?.deadline]);

	useEffect(() => {
		if (draft.length > 0) {
			setCommentId(draft[0]['id']);
			setComments(draft[0]['comment'] || ''); // If the draft comment is empty, then set it to '' to prevent errors with comments.length
			setAttachments(draft[0]['attachments']);
		}
	}, [draft]);

	useEffect(() => {
		if (draft.length > 0 && !taskFinal && attachments?.length > 0) { // allow user to delete attachments if the input is still a draft
			setFileData(adjustFileData(attachments));
		}
	}, [attachments])

	useEffect(() => {
		if (taskFinal
			|| role == 'other'
			|| monitorTask?.status.id == 1
			|| role == 'reviewer' && monitorTask?.status.id == 2
			|| role == 'responsible' && monitorTask?.status.id == 3
			|| role == 'reviewer' && monitorTask?.status.id == 4
			|| role == 'reviewer' && monitorTask?.status.id == 5) {
			setDisableForm(true)
		}
		else {
			setDisableForm(false)
		}
	}, [monitorTask, role]);

	const warningForm = () => {
		const bodyText = role === 'reviewer' && completedSelect === '0'
			? t('modal_confirm_return_body', { ns: 'perform_monitoring' })
			: t('modal_confirm_body', { ns: 'perform_monitoring' });

		showModal(
			bodyText,
			<>
				<MotifButton
					className="me-3"
					disabled={isLoading}
					onClick={() => { submitForm('submit') }}>
					{t('confirm', { ns: 'general' })}
				</MotifButton>
				<MotifButton
					className="me-3"
					disabled={isLoading}
					onClick={() => setModalVisibility(false)}>
					{t('cancel', { ns: 'general' })}
				</MotifButton>
			</>,
			true
		);
	};


	const fieldTranslations = {
		fiscalYear: { key: 'fiscal_year', ns: 'general', required: true },
		deviations: { key: 'outcome', ns: 'perform_monitoring', required: true },
		comments: { key: 'comments', ns: 'general', required: true },
		completedSelect: { key: 'task_completed', ns: 'perform_monitoring', required: role === 'reviewer' ? true : false }
	};

	const resetMtask = async () => {
		try {
		  setIsLoading(true);
		  await request.post(`reset-mtask/${taskId}/`, {}, 'PATCH');
		} catch (error) {
		  console.error(error.message)
		} finally {
		  navigate('/performmonitoringoverview');
		}
	};

	const validateForm = () => {
		const errors = Object.entries(fieldTranslations)
			.filter(([field, { required }]) => {
				const fieldValue = eval(field); // Dynamically get the field's value

				if (!required) return false; // Skip non-required fields

				// Validate based on field type
				if (typeof fieldValue === 'string') {
					return fieldValue.trim().length === 0; // Check for empty strings
				} else if (Array.isArray(fieldValue)) {
					return fieldValue.length === 0; // Check for empty arrays
				} else {
					return !fieldValue; // Check for undefined, null, or other falsy values
				}
			})
			.map(([field]) => field);

		if (errors.length > 0) {
			const unfilledFields = errors.map(field => {
				const { key, ns } = fieldTranslations[field];
				return t(key, { ns });
			}).join(', ');

			screenReaderAlertRef.current.textContent = `${t('field_errors', { ns: 'screenreader' })}: ${unfilledFields}`;
			setModalVisibility(false);
			return false;
		}

		return true;
	};

	const submitForm = async (action) => {
		setIsFormSubmitted(true);
		setFormAction(action);

		if (action === 'save' || validateForm()) {
			setIsLoading(true);
			try {
				let modalBodyText = '';
				let disableForm = false;

				if (action === 'submit' && role == 'reviewer' && completedSelect === '1' && !isControlFeedbackSubmitted) {
					setControlsModalVisibility(true);
					setModalVisibility(false);
					setIsControlFeedbackSubmitted(true);
					return;

				} else if (action === 'submit' && role == 'responsible' && (monitorTask?.status.id == 2 || monitorTask?.status.id == 4 || monitorTask?.status.id == 5)) {
					await request.post(`monitoring-task/${taskId}/`, { "status": 3, "fiscal_year": fiscalYear ? fiscalYear : null, "deviations": deviations === '1' }, 'PATCH');
					disableForm = true;
					modalBodyText = t('modal_added_body', { ns: 'perform_monitoring' });

				} else if (action === 'save' && role == 'responsible' && (monitorTask?.status.id == 2 || monitorTask?.status.id == 4 || monitorTask?.status.id == 5)) {
					if (monitorTask?.status.id !== 4) {
						await request.post(`monitoring-task/${taskId}/`, { "status": 4, "fiscal_year": fiscalYear ? fiscalYear : null, "deviations": deviations === '1' }, 'PATCH');
					}
					disableForm = true;
					modalBodyText = t('modal_draft_body', { ns: 'perform_monitoring' });

				} else if (action === 'submit' && role === 'reviewer' && completedSelect === '1') {
					const controlsFeedback = monitorTask.monitoring?.controls.map(control => ({
						control_id: control.id,
						effective: controlFeedback[control.id] === '1',
					}));

					const hasNegativeFeedback = controlsFeedback.some(feedbackItem => !feedbackItem.effective);

					await request.post(`monitoring-task/${taskId}/`, {
						status: 1,
						fiscal_year: fiscalYear,
						deviations: deviations === '1',
						feedback: feedback,
						controlsFeedback: controlsFeedback,
						negativeFeedback: hasNegativeFeedback,
						rating: parseInt(rating),
					}, 'PATCH');

					disableForm = true;
					setTaskFinal(true);
					modalBodyText = t('modal_completed_body', { ns: 'perform_monitoring' });

				} else if (action === 'submit' && role == 'reviewer' && completedSelect === '0') {
					await request.post(`monitoring-task/${taskId}/`, { "status": 2, "fiscal_year": fiscalYear ? fiscalYear : null, "deviations": deviations === '1' }, 'PATCH');
					modalBodyText = t('modal_returned_body', { ns: 'perform_monitoring' });

				} else if (action === 'save' && role == 'reviewer') {
					modalBodyText = t('modal_draft_body', { ns: 'perform_monitoring' });
				}

				let url, performMonitoringBody, method;
				if (role === 'responsible') {
					performMonitoringBody = {
						comment: comments.length > 0 ? comments : null,
						monitoringtask: taskId,
						is_draft: action === 'save' ? 1 : 0,
					};
					url = commentId ? `perform-monitoring/${commentId}/` : 'perform-monitoring/';
					method = commentId ? 'PATCH' : 'POST';

				} else if (role === 'reviewer') {
					performMonitoringBody = {
						comment: comments.length > 0 ? comments : null,
						monitoringtask: taskId,
						is_draft: action === 'save' ? 1 : 0,
					};
					url = commentId ? `perform-monitoring-review/${commentId}/` : 'perform-monitoring-review/';
					method = commentId ? 'PATCH' : 'POST';
				}

				await processFormSubmission(url, commentId, performMonitoringBody, method);

				showModal(
					modalBodyText,
					<>
						<MotifButton size="medium" type="button" disabled={isLoading} onClick={() => navigate('/performmonitoringoverview')}>
							{t('modal_added_button_taskoverview', { ns: 'perform_monitoring' })}
						</MotifButton>
						<MotifButton size="medium" type="button" disabled={isLoading} onClick={() => navigate('/overview')}>
							{t('dashboard', { ns: 'general' })}
						</MotifButton>
					</>,
					!disableForm
				);

				setDisableForm(disableForm);

			} catch (error) {
				console.error(error.message);
			} finally {
				setIsLoading(false);
			}
		}
	};

	const processFormSubmission = async (url, commentId, performMonitoringBody, method) => {
		setIsLoading(true);

		try {
			const response = await request.post(url, performMonitoringBody, method);
			let newCommentId = !commentId ? response.id + '/' : '';

			if (fileData.length > 0) {
				const uploadPromises = fileData.filter(file => file.file instanceof Blob).map((file) => {
					const formData = new FormData();
					formData.append('file', file.file);
					formData.append('performmonitoring', newCommentId);

					const sanitizedFilename = sanitizeFilename(file.filename);
					formData.append('filename', sanitizedFilename);
					formData.append('path', file.path);
					formData.append('timestamp', file.timestamp);

					if (role === 'responsible') {
						formData.append('execution_comment', newCommentId);
					} else if (role === 'reviewer') {
						formData.append('reviewer_comment', newCommentId);
					}

					return request.post(`${url}${newCommentId}upload_document/?taskId=${taskId}`, formData, 'POST');
				});

				await Promise.all(uploadPromises);
			}

			showModal(
				t('modal_completed_body', { ns: 'perform_monitoring' }),
				<>
					<MotifButton size="medium" type="button" disabled={isLoading} onClick={() => {
						setModalVisibility(false);
						navigate('/performmonitoringoverview');
					}}>
						{t('modal_added_button_taskoverview', { ns: 'perform_monitoring' })}
					</MotifButton>
					<MotifButton size="medium" type="button" disabled={isLoading} onClick={() => {
						setModalVisibility(false);
						navigate('/overview');
					}}>
						{t('dashboard', { ns: 'general' })}
					</MotifButton>
				</>,
				true
			);

		} catch (error) {
			console.error(error.message);
		} finally {
			setIsLoading(false);
		}
	};

	const [negativeFeedbackButton, setNegativeFeedbackButton] = useState(false)

	useEffect(() => {
		if (taskFinal && monitorTask?.negativeFeedback) {
			setNegativeFeedbackButton(true); // turn controls feedback button red if there is negative feedback on controls
		}
	}, [monitorTask, taskFinal]);

	return (
		<>
			<EffectiveControlsModal isLoading={isLoading} modalVisible={isControlsModalVisible} setModalVisibility={setControlsModalVisibility} controls={monitorTask?.monitoring?.controls} controlFeedback={controlFeedback} setControlFeedback={setControlFeedback} feedback={feedback} setFeedback={setFeedback} monitorTask={monitorTask} taskFinal={taskFinal} setControlsModalVisibility={setControlsModalVisibility} setIsControlFeedbackSubmitted={setIsControlFeedbackSubmitted} submitForm={submitForm} rating={rating} setRating={setRating} />
			{taskFinal && executionDate ? // only show executed date if task is finished
				<MotifFormField>
					<MotifLabel>{t('execution_date', { ns: 'general' })}</MotifLabel>
					<MotifDatePicker
						calendarType="iso8601"
						disabled={true} // auto set in backend, not set by user
						value={executionDate}
						showLeadingZeros={true}
						format="dd-MM-yyyy"
						yearPlaceholder="YYYY"
						dayPlaceholder="DD"
						monthPlaceholder="MM"
					/>
					{(!executionDate && isFormSubmitted && formAction == 'submit') && <MotifErrorMessage>
						{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
				</MotifFormField>
				: null}

			<MotifFormField>
				<ScreenReaderLabel id='sr-fiscal_year' message={`${t('fiscal_year', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
				<MotifLabel id="select-fy">{t('fiscal_year', { ns: 'general' })}</MotifLabel>
				<MotifSelect
					key={forceRenderKey}
					disabled={disableForm || isLoading}
					value={fiscalYear}
					onChange={val => setFiscalYear(val)}
					ariaLabelledBy="sr-fiscal_year">
					{fiscalYearItems.map((item, i) => (<MotifOption key={i} value={item}>{item}</MotifOption>))}
				</MotifSelect>
				{(fiscalYear.length == 0 && isFormSubmitted && formAction == 'submit') && <MotifErrorMessage>
					{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
			</MotifFormField>

			<MotifFormField>
				<ScreenReaderLabel id='sr-deviation' message={`${t('outcome', { ns: 'perform_monitoring' })} ${t('required', { ns: 'screenreader' })}`} />
				<MotifLabel id="select-deviation">{t('outcome', { ns: 'perform_monitoring' })}</MotifLabel>
				<MotifSelect
					key={forceRenderKey}
					disabled={disableForm || isLoading}
					value={deviations}
					onChange={val => setDeviations(val)}
					ariaLabelledBy="sr-deviation"
				>
					<MotifOption key={1} value={'1'}>{t('deviations_yes', { ns: 'perform_monitoring' })}</MotifOption>
					<MotifOption key={2} value={'0'}>{t('deviations_no', { ns: 'perform_monitoring' })}</MotifOption>
				</MotifSelect>
				{(role == 'responsible' && deviations.length == 0 && isFormSubmitted && formAction !== 'save') && <MotifErrorMessage>
					{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
			</MotifFormField>

			<MotifFormField style={{ marginTop: '1%' }}>
				<ScreenReaderLabel id='sr-comments' message={`${t('comments', { ns: 'general' })} ${t('required', { ns: 'screenreader' })}`} />
				<MotifLabel position="in">{t('comments', { ns: 'general' })}</MotifLabel>
				<MotifTextArea
					aria-labelledby='sr-comments'
					disabled={disableForm || isLoading}
					value={comments}
					rows={5}
					onChange={event => setComments(validateCommentInput(event.target.value).cleanedValue)}
					maxLength={2000}
				/>
				{(comments.length == 0 && isFormSubmitted && formAction !== 'save') && <MotifErrorMessage>
					{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
			</MotifFormField>

			{!disableForm ?
				<UploadItem blobPath="monitoring" deleteUrl="perform-monitoring-documents" fileData={fileData} setFileData={setFileData} taskId={taskId} disabled={taskFinal} label={t('task_uploaditem', { ns: 'general' })} maxFiles={30} />
				: ''}
			{role == 'reviewer' ?
				<MotifFormField style={{ width: "250px" }}>
					<ScreenReaderLabel id='sr-completed' message={`${t('task_completed', { ns: 'perform_monitoring' })} ${t('required', { ns: 'screenreader' })}`} />
					<MotifLabel id="select-completed">{t('task_completed', { ns: 'perform_monitoring' })}</MotifLabel>
					<MotifSelect
						key={forceRenderKey}
						disabled={disableForm || isLoading}
						value={completedSelect}
						onChange={val => setCompletedSelect(val)}
						ariaLabelledBy="sr-completed">
						<MotifOption key='1' value='1'>{t('completed_yes', { ns: 'perform_monitoring' })}</MotifOption>
						<MotifOption key='2' value='0'>{t('completed_no', { ns: 'perform_monitoring' })}</MotifOption>
					</MotifSelect>
					{(role == 'reviewer' && completedSelect.length == 0 && isFormSubmitted && formAction !== 'save') && <MotifErrorMessage>
						{t('required_field', { ns: 'general' })}</MotifErrorMessage>}
				</MotifFormField> : null}
			<div
				ref={screenReaderAlertRef}
				aria-live="assertive"
				className="visually-hidden"
				role="alert"
			></div>

			<div className="row mt-2 mb-3" style={{ width: '100%' }}>
				<div className="col d-flex align-items-center">
					<MotifButton
						variant="primary-alt"
						disabled={disableForm || isLoading}
						className="me-2"  // Use a smaller margin between the first two buttons
						onClick={() => { submitForm('save') }}>
						{t('save', { ns: 'general' })}
					</MotifButton>

					<MotifButton
						variant="primary-alt"
						disabled={disableForm || isLoading}
						onClick={() => { warningForm() }}>
						{t('save_submit', { ns: 'general' })}
					</MotifButton>
				</div>

				{/* Conditionally render the reset button if debug features are enabled */
				process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' ? (
					<div className="col text-right d-flex justify-content-end">
						<MotifTextLink href="#" disabled={isLoading} onClick={() => { resetMtask() }}>Reset (debug)</MotifTextLink>
					</div>
				) : null}
			</div>

			<div className="d-flex flex-row mb-3">
				{taskFinal ? <MotifButton href='#' variant={negativeFeedbackButton ? 'warn' : 'primary'} disabled={isLoading} onClick={() => setControlsModalVisibility(true)}>{t('controls_feedback_link', { ns: 'perform_monitoring' })}</MotifButton> : ''}
			</div>

			<MotifModal show={isModalVisible} onClose={() => window.location.reload()}>
				<MotifModalHeader closeModalButton={modalData.hideCloseButton}>
					{modalData.headerText}
				</MotifModalHeader>
				<MotifModalBody>{modalData.bodyText}</MotifModalBody>
				<MotifModalFooter>{modalData.footerContent}</MotifModalFooter>
			</MotifModal>
		</>
	);
}

export default PerformMonitoringForm;