import React, {useState, useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';
import { useLocation, useNavigate } from 'react-router-dom'

import {
    MotifFormField,
    MotifInput,
    MotifLabel,
    MotifSelect,
    MotifOption,
    MotifButton,
    MotifModal,
    MotifModalBody,
    MotifModalHeader,
    MotifModalFooter,
    MotifErrorMessage,
    MotifCheckbox
} from '@ey-xd/motif-react';

import { request } from '../../utils/request';
import { validateGeneralInput, validateEmailInput, myeyMessages, tooltitle } from '../../utils/utils';

const NewAdmin = () => {
    const { state } = useLocation();
    const { adminId } = state || {};
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const [forceRenderKey, setForceRenderKey] = useState('');
    const [adminFirstName, setAdminFirstName] = useState('');
    const [adminLastName, setAdminLastName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [language, setLanguage] = useState('');
    const [employeeLanguage, setEmployeeLanguage] = useState([]);

    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [showDeletionModal, setShowDeletionModal] = useState(false);
    const [deletionModalText, setDeletionModalText] = useState('');

    const [myey, setMyey] = useState(process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True'); // default to true in production, allows to create non-myey test dummy accounts with a default password for testing purposes

    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');
    const navigate = useNavigate()

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    useEffect(() => {
        !!language?.length && setForceRenderKey(crypto.randomUUID());
        !!setShowModal && setForceRenderKey(crypto.randomUUID());
      }, [language]);

    useEffect(() => {

        const fetchData = async () => { 
            setIsLoading(true);
            setEmployeeLanguage(await request.get('language/', {})); 
            if(adminId != null){
                var data = await request.get(`ey-employee/${adminId}`, {})
                setAdminFirstName(data.first_name)
                setAdminLastName(data.last_name)
                setAdminEmail(data.email)
                setLanguage(data.language.toString())
            } 

            setIsLoading(false);
            };
    
        fetchData();
    
      }, [adminId]);

    const handleDeletion = async () => {
        setIsLoading(true);
        let msg = '';
        msg = t('admindeleteddesc', {ns: 'admin'}) // default success message
        try {
            var response = await request.delete(`ey-employee/${adminId}`);

            const translationKey = myeyMessages[response.myey];
            if (translationKey) {
                msg = t(translationKey, {ns: 'admin'});
            } else {
                msg = t('myey_other_error', {ns: 'admin'}); // default error if unknown error
                console.error('No response from MyEY')
            }

            setDeletionModalText(msg);
            setShowConfirmationModal(false);
            setShowDeletionModal(true);
            
        } catch (error) {
            console.error(error.message)
        } finally {
            setIsLoading(false);
            navigate('/adminoverview')
        }
    }

      const validateForm = () => {
        if (adminFirstName.length > 0 &&
            adminLastName.length > 0 &&
            adminEmail.length > 0 &&
            language.length > 0) {
            return true
        }
        return false;
    }

    const submit = async (how) => {
        setIsLoading(true);
        setIsFormSubmitted(true);
    
        if (validateForm()) {
            var data = {
                'first_name': adminFirstName,
                'last_name': adminLastName,
                'email': adminEmail,
                'language': language,
                'myey': myey // always true if debug features are off, to test dummy accounts without valid e-mail using the old login
            };
    
            try {
                // Attempt to create or update the admin
                const response = adminId
                    ? await request.post(`ey-employee/${adminId}/`, data, how)
                    : await request.post('ey-employee/', data, how);
                
                if (how === 'POST') {
                    const translationKey = myeyMessages[response.myey];
                    if (translationKey) {
                        setModalText(t(translationKey, {ns: 'admin'}));
                    } else {
                        setModalText(t('myey_other_error', {ns: 'admin'})); // default error if unknown error
                        console.error('No response from MyEY')
                    }
                } else {
                    setModalText(t('adminediteddesc', {ns:'admin'}));
                }
    
                // Check if the response was successful and show the modal
                if (response.success) {
                    setShowModal(true);
                } else {
                    // Handle the case where response.success is false
                    console.error('Failed to submit admin data:', response);
                }
            } catch (error) {
                // Handle any errors that occur during the API call
                console.error('An error occurred during submission:', error.message);
            } finally {
                setIsFormSubmitted(false);
                setIsLoading(false);
            }
        }
    };
    

    const addAnotherAdmin = () => {
        setAdminFirstName('')
        setAdminLastName('')
        setAdminEmail('')
        setLanguage(null)

        setShowModal(false);
        navigate('/adminoverview/addadmin', {state:{adminId:null}})
    }

    return (
        <>  
        <title>{t('addadmin', {ns: 'menu'})} | {tooltitle}</title>
        <div className="d-flex justify-content-center">
            <h1>{t('addadmin', {ns: 'menu'})}</h1>
        </div>
            <div className="row justify-content-center mx-2 mt-5">
                <div className="col-6">
                    <MotifFormField>
                        <MotifLabel>{t('firstname', {ns:'account'})}</MotifLabel>
                        <MotifInput
                            value={adminFirstName}
                            disabled={isLoading}
                            required={true}
                            maxLength={100}
                            onChange={event => setAdminFirstName(validateGeneralInput(event.target.value).cleanedValue)}
                        />
                        {(adminFirstName?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>{t('lastname', {ns:'account'})}</MotifLabel>
                        <MotifInput
                            value={adminLastName}
                            disabled={isLoading}
                            required={true}
                            maxLength={100}
                            onChange={event => setAdminLastName(validateGeneralInput(event.target.value).cleanedValue)}
                        />
                        {(adminLastName?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>{t('email', {ns:'account'})}</MotifLabel>
                        <MotifInput
                            value={adminEmail}
                            disabled={isLoading}
                            required={true}
                            maxLength={100}
                            onChange={event => setAdminEmail(event.target.value)}
                            type={'email'}
                        />
                        {(validateEmailInput(adminEmail).isNotValid && isFormSubmitted) && <MotifErrorMessage>{t('invalid_email', {ns: 'general'})}</MotifErrorMessage>}
                        {(adminEmail?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                    <MotifLabel id="select-language">{t('language', {ns: 'account'})}</MotifLabel>
                        <MotifSelect 
                            key={forceRenderKey}
                            value={language}
                            onChange={val => setLanguage(val)} ariaLabelledBy="select-language">
                            {employeeLanguage.map((item, i) => (<MotifOption key={i} value={item.id.toString()}>{item.language}</MotifOption>))}
                        </MotifSelect>
                        {(language?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        {/* Conditionally render the checkbox if debug features are enabled */}
                        {process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' ? (
                            <MotifCheckbox
                                id="myey-checkbox"
                                name="myey-checkbox"
                                onChange={() => setMyey(!myey)} // Toggle the state
                                checked={myey} // Controlled checkbox state
                            >
                                MyEY (uncheck if testing with fake account)
                            </MotifCheckbox>
                        ) : (
                            // Hide checkbox and force `myey` to true in production
                            <input type="hidden" value={true} />
                        )}
                    </MotifFormField>
                    <div className="d-flex mt-2">
                        <MotifButton
                            className="me-2"
                            disabled={isLoading}
                            onClick={() => { submit(!adminId ? 'POST' : 'PUT') }}>
                            {adminId?t('edit', {ns: 'general'}):t('add', {ns: 'general'})}
                        </MotifButton>
                        {adminId ? 
                            <>
                            <MotifButton
                                className="me-2"
                                disabled={isLoading}
                                variant="warn"
                                onClick={() => setShowConfirmationModal(true)}
                            >
                                    {t('remove', {ns: 'general'})}
                            </MotifButton>
                            </>
                        : null}
                    </div>

                    <MotifModal show={showModal} onClose={() => {setShowModal(false)}}>
                        <MotifModalHeader>EY Admin</MotifModalHeader>
                        <MotifModalBody>{modalText}</MotifModalBody>
                        <MotifModalFooter>
                            <MotifButton disabled={isLoading} size="medium" type="button" onClick={() => {navigate('/adminoverview')}}>
                                {t('toadminoverview', {ns:'admin'})}
                            </MotifButton>
                            <MotifButton disabled={isLoading} size="medium" type="button" onClick={() => addAnotherAdmin()}>
                                {t('anotheradmin', {ns:'admin'})}
                            </MotifButton>
                        </MotifModalFooter>
                    </MotifModal>

                    <MotifModal show={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
                        <MotifModalHeader>
                            {t('deleteadmin', {ns:'admin'})}
                        </MotifModalHeader>
                        <MotifModalBody>
                            <p>{t('rusure', {ns:'admin'})}</p>
                            <p>{adminFirstName + ' ' + adminLastName}<br/>{adminEmail}</p>
                        </MotifModalBody>
                        <MotifModalFooter>
                            <MotifButton onClick={() => handleDeletion()} disabled={isLoading}>
                                {t('confirm', {ns: 'general'})}
                            </MotifButton>
                            <MotifButton onClick={() => setShowConfirmationModal(false)} disabled={isLoading}>
                                {t('cancel', {ns: 'general'})}
                            </MotifButton>
                        </MotifModalFooter>
                    </MotifModal>

                    <MotifModal show={showDeletionModal} onClose={() => window.location.reload(true)}>
                        <MotifModalHeader>
                            {t('admindeleted', {ns:'admin'})}
                        </MotifModalHeader>
                        <MotifModalBody>
                            {deletionModalText}
                            <br></br>
                            <p>{adminFirstName + ' ' + adminLastName}<br/>{adminEmail}</p>
                        </MotifModalBody>
                    </MotifModal>
                </div>
            </div>
        </>
    )
}

export default NewAdmin