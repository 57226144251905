import React, {useState, useEffect} from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import {
    MotifModal,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader,
    MotifButton,
    MotifTextLink,
} from '@ey-xd/motif-react';

import { isExpired, isDeleted } from '../../utils/utils';

import { MakeRequests } from '../../utils/request';
import DetailsItem from '../../components/DetailsItem';
import RiskDetails from '../../views/risks/RiskDetails';
import ControlDetails from '../../views/controls/ControlDetails';

const MonitoringDetails = ({ monitoringData, isModalVisible, setModalVisibility, navigate }) => {

    const { t } = useTranslation();
    const { userRole, isSuperuser } = useOutletContext();
    const { isLoading, setIsLoading } = useLoading();
    const request = new MakeRequests()

    const [scaleNumber, setScaleNumber] = useState(1);

    const [isCModalVisible, setCModalVisibility] = useState(false);
    const [isRModalVisible, setRModalVisibility] = useState(false);

    const [currentRiskData, setCurrentRiskData] = useState({});
    const [currentControlData, setCurrentControlData] = useState({});

    const uniqueSubthemes = [...new Set(monitoringData.controls?.map(control => control.risk.subtheme.description_code))];
    const subthemes = uniqueSubthemes.join(', ');

    const uniqueRisks = Array.from(new Set(monitoringData.controls?.map(control => control.risk.id)))
    .map(id => monitoringData.controls.find(control => control.risk.id === id).risk);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const dateObj = new Date(dateString);
        return [
          dateObj.getFullYear(),
          ('0' + (dateObj.getMonth() + 1)).slice(-2), // Ensure two digits
          ('0' + dateObj.getDate()).slice(-2)         // Ensure two digits
        ].join('-');
    };

    useEffect(() => {
        const fetchData = async () => {
          setIsLoading(true);
    
          var scaleData = await request.get('key-risk-data'); // fetch scale number for the risk pop up
          setScaleNumber(scaleData[0].scale.number);
  
          setIsLoading(false);
        };
    
        fetchData();
      }, []);

    let data = {
        [t('subtheme', {ns: 'general'})]: subthemes,
        [t('risks', {ns: 'general'})]: (
            <ul>
                {uniqueRisks.map((risk, i) => (
                    <li key={i}>
                        <MotifTextLink  
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault(); // prevent default link behavior
                                setCurrentRiskData(risk); // set the control data for modal
                                setRModalVisibility(true); // show the modal
                            }}
                        >
                            {risk.risk}
                        </MotifTextLink>
                    </li>
                ))}
            </ul>
        ),
        [t('controls', {ns: 'general'})]: (
            <ul>
              {monitoringData.controls?.map((control, i) => {
                // Check if the control is expired and/or deleted
                const expired = isExpired(control.expiry);
                const deleted = isDeleted(control.deleted);
                
                return (
                  <li key={i}>
                    <MotifTextLink  
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior
                        setCurrentControlData(control); // Set the control data for modal
                        setCModalVisibility(true); // Show the modal
                      }}
                    >
                      <span style={(expired || deleted) ? { textDecoration: 'line-through' } : {}}>
                        {control.control}
                      </span>
                      {/* Display expired and/or deleted status */}
                      {(expired || deleted) && (
                        <span>
                          &nbsp;(
                          {expired && `${t('expired_on', {ns: 'general'})} ${formatDate(control.expiry)}`}
                          {expired && deleted && ' & '}
                          {deleted && `${t('deleted_on', {ns: 'general'})} ${formatDate(control.deleted_on)}`}
                          )
                        </span>
                      )}
                    </MotifTextLink>
                  </li>
                );
              })}
            </ul>
        ),
        [t('monitoring', {ns: 'general'})]: monitoringData.monitoring,
        [t('monitoring_desc', {ns: 'general'})]: monitoringData.monitoring_desc,
        [t('proof', {ns: 'general'})]: monitoringData.proof,
        [t('monitoring_type', {ns: 'general'})]: monitoringData.monitoring_type?.description,
        [t('frequency', {ns: 'general'})]: monitoringData.frequency?.description,
        [t('entities', { ns: 'general' })]: (
            <ul>
              {(monitoringData?.unique_entities || []).map((entity, i) => (
                <li key={i}>
                  {entity.name}
                </li>
              ))}
            </ul>
        ), 
        [t('responsible', {ns: 'general'})]: monitoringData.responsible_fullname ? monitoringData.responsible_fullname : [t('none', {ns: 'general'})],
        [t('reviewer', {ns: 'general'})]: monitoringData.reviewer_fullname ? monitoringData.reviewer_fullname : [t('none', {ns: 'general'})],
        [t('notify_on_failure', {ns: 'general'})]: monitoringData.notify_on_failure_fullname ? monitoringData.notify_on_failure_fullname : [t('none', {ns: 'general'})],
        [t('expiry', {ns: 'general'})]: monitoringData.expiry ? monitoringData.expiry : [t('none', {ns: 'general'})],
    }

    if (monitoringData.monitoring_number) { // if has monitoring_number, insert it to the first row of data
        data = {
            [t('monitoring_number', {ns: 'general'})]: monitoringData.monitoring_number,
            ...data
        };
    }

    return (
        <>
            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>Monitoring details</MotifModalHeader>
                <MotifModalBody>
                    <div className='d-flex flex-column' style={{ whiteSpace: 'pre-wrap' }}>
                        {Object.entries(data).map(([key, value]) => <DetailsItem description={key} value={value} />)}
                    </div>


                </MotifModalBody>
                <MotifModalFooter>
                    {userRole === 'Admin' &&
                        <MotifButton
                        className="me-3"
                        onClick={() => navigate('/monitoring/add_monitoring/', { state: { monitoringId: monitoringData.id } })}>{t('edit', {ns: 'general'})}</MotifButton>
                    }
                    <MotifButton
                    className="me-3"
                    onClick={() => setModalVisibility(false)}>{t('close', {ns: 'general'})}</MotifButton>
                </MotifModalFooter>
            </MotifModal>

        <RiskDetails 
            scaleNumber={scaleNumber}
            riskData={currentRiskData} 
            isModalVisible={isRModalVisible} 
            setModalVisibility={setRModalVisibility} 
            hideEditButton={true}
            />

        <ControlDetails 
            controlData={currentControlData} 
            isModalVisible={isCModalVisible} 
            setModalVisibility={setCModalVisibility} 
            hideEditButton={true}
            />
        </>
    )
}

export default MonitoringDetails