import React, { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import { useNavigate } from 'react-router-dom'
import { formatDate, colors } from '../../utils/utils';

import {
  MotifButton,
  MotifTextLink,
  MotifModal,
  MotifModalBody,
  MotifModalHeader,
  MotifCheckbox,
  MotifFormField,
  MotifTabNavigation as MotifTabNav,
  MotifTabControl,
  MotifRadioButton,
  MotifToast,
  MotifToggleSwitch
} from '@ey-xd/motif-react'

import FetchDashboardData from './FetchDashboardData'
import TaskCount from './TaskCount';
import TaskCharts from './TaskCharts';
import OverviewSwitch from './OverviewSwitch'
import TableFilter from './TableFilter';
import TaskTable from './TaskTable';

import ExportButton from './ExportButton';
import { tooltitle } from '../../utils/utils';

const OverviewPage = () => {

  const { t } = useTranslation();
  const { userRole, isSuperuser } = useOutletContext();
  const { isLoading, setIsLoading } = useLoading();

  const navigate = useNavigate();

  const date1 = new Date();
  const date2 = new Date();
  date2.setDate(date1.getDate() + 7); // upcoming kader is gezet binnen de komende week

  const [modalVisibility, setModalVisibility] = useState(false);

  const choices = {
    RCM_matrix: "RCM matrix",
    future_tasks: t('future_tasks', { ns: 'general' }),
    past_tasks: t('tasks_past_3years', { ns: 'general' }),
    all_tasks: t('all_tasks', { ns: 'general' })
}

const sheets = {
    one_sheet: t('one_sheet', { ns: 'general' }),
    multiple_sheets: t('multiple_sheets', { ns: 'general' })
}

  const [exports, setExports] = useState([]);
  const [sheetChoice, setSheetChoice] = useState('one_sheet')  
  const [languageIsDutch, setLanguageIsDutch] = useState(true);

  const [checked, setChecked] = useState(
    new Array(Object.keys(choices).length).fill(false)
  );

  const handleCheck = (i) => {
    const updatedChecked = checked.map((item, index) => 
      (index) === i ? !item : item
    );
      setChecked(updatedChecked);

      // Reset sheetChoice to 'one_sheet' if RCM_matrix is unchecked
      if (Object.keys(choices)[i] === 'RCM_matrix' && !updatedChecked[i]) {
          setSheetChoice('one_sheet');
      }
  }
  

  const getExports = () => {
    const all = Object.keys(choices).filter((key, index) => checked[index])
    setExports(all)
  }

  useEffect(() => {
    getExports()
  }, [checked])

  const [overviewSwitch, setOverviewSwitch] = useState(true);

  const [activeTab, setActiveTab] = useState('controls');

  const [filtered, setFiltered] = useState(false);

  const [originalTasks, setOriginalTasks] = useState({ controls: [], monitoring: [] });
  const [tasks, setTasks] = useState({ controls: [], monitoring: [], });
  const [selectedTasks, setSelectedTasks] = useState({ controls: [], monitoring: [], });

  const [subthemes, setSubthemes] = useState([]);
  const [fiscalYears, setFiscalYears] = useState([]);
  const [entities, setEntities] = useState([]);
  const [countries, setCountries] = useState([]);

  const completed = 0; // don't fetch completed tasks from database

  const [subthemeItemsCounts, setSubthemeItemsCounts] = useState([]);

  const todayDate = formatDate(date1);
  const soonDate = formatDate(date2);

  const [overdueTasks, setOverdueTasks] = useState({ controls: [], monitoring: [], });
  const [todayTasks, setTodayTasks] = useState({ controls: [], monitoring: [], });
  const [soonTasks, setSoonTasks] = useState({ controls: [], monitoring: [], });
  const [toDoTasks, setToDoTasks] = useState({ controls: [], monitoring: [], });
  
  const [missingControlFieldsCount, setMissingControlFieldsCount] = useState(0);
  const [missingMonitoringFieldsCount, setMissingMonitoringFieldsCount] = useState(0);
  const [closedFieldsWarning, setClosedFieldsWarning] = useState(false);


  FetchDashboardData(completed, setOriginalTasks, tasks, setTasks, setSelectedTasks, setSubthemes, setEntities, setCountries, setFiscalYears, setIsLoading);

  useEffect(() => {
    const newOverdueTasks = { controls: [], monitoring: [] };
    const newTodayTasks = { controls: [], monitoring: [] };
    const newSoonTasks = { controls: [], monitoring: [] };
    const newToDoTasks = { controls: [], monitoring: [] };
  
    Object.keys(tasks).forEach(key => {
      const keyTasks = tasks[key];
      keyTasks?.forEach(task => {
        const deadlineDate = task.deadline;
        if (deadlineDate < todayDate) {
          newOverdueTasks[key].push(task);
        } else if (deadlineDate === todayDate) {
          newTodayTasks[key].push(task);
        } else if (deadlineDate <= soonDate) {
          newSoonTasks[key].push(task);
        } else {
          newToDoTasks[key].push(task);
        }
      });
    });

    setOverdueTasks(newOverdueTasks);
    setTodayTasks(newTodayTasks);
    setSoonTasks(newSoonTasks);
    setToDoTasks(newToDoTasks);
  
  }, [tasks]);

useEffect(() => {
  const newSubthemeItemsCounts = subthemes?.map(subthemeItem => {
      const overdueCount = { controls: 0, monitoring: 0 };
      const todayCount = { controls: 0, monitoring: 0 };
      const upcomingCount = { controls: 0, monitoring: 0 };
      const toDoCount = { controls: 0, monitoring: 0 };

      // Process Controls
      tasks.controls?.forEach(task => {
          const isTaskForSubthemeItem = String(task.control.risk.subtheme.id) === String(subthemeItem.id);
          if (isTaskForSubthemeItem) {
              if (overdueTasks.controls.some(ot => ot.id === task.id)) {
                  overdueCount.controls++;
              } else if (todayTasks.controls.some(tt => tt.id === task.id)) {
                  todayCount.controls++;
              } else if (soonTasks.controls.some(st => st.id === task.id)) {
                  upcomingCount.controls++;
              } else {
                  toDoCount.controls++;
              }
          }
      });

      // Process Monitoring
      tasks.monitoring?.forEach(task => {
        // Use a Set to keep track of unique subtheme IDs for this monitoring task. 
        const uniqueSubthemeIds = new Set();

        // Collect all unique subtheme IDs from controls within this monitoring task. This is to prevent counting duplicate subthemes (e.g. 2 controls with the same subtheme) under a single monitoring task double.
        task.monitoring?.controls?.forEach(control => {
            uniqueSubthemeIds.add(Number(control.risk.subtheme.id)); // Ensure IDs are numbers
        });

        // Check if current subthemeItem.id is in uniqueSubthemeIds
        const currentSubthemeId = Number(subthemeItem.id); // Ensure it's a number
        if (uniqueSubthemeIds.has(currentSubthemeId)) {

            if (overdueTasks.monitoring.some(ot => ot.id === task.id)) {
                overdueCount.monitoring++;
            } else if (todayTasks.monitoring.some(tt => tt.id === task.id)) {
                todayCount.monitoring++;
            } else if (soonTasks.monitoring.some(st => st.id === task.id)) {
                upcomingCount.monitoring++;
            } else {
                toDoCount.monitoring++;
            }
        }
      });
      
      return {
          subtheme: subthemeItem.description,
          overdueCount,
          todayCount,
          upcomingCount,
          toDoCount,
      };
  });
  setSubthemeItemsCounts(newSubthemeItemsCounts);
}, [tasks, overdueTasks, todayTasks, soonTasks, toDoTasks, subthemes]);

useEffect(() => {
  if (userRole === 'Admin') {
    /* Filter control tasks which missing required fields */
    let controlsWithMissingFields = tasks.controls?.filter(control => {
      /* Check if 'responsible', 'notify_on_failure' fields are null. Also, if 'has_reviewer' is true, 
        check if 'reviewer' is null. */
      return control.control.responsible === null || control.control.notify_on_failure === null || 
        (control.control.has_reviewer && control.control.reviewer === null);
    }) || [];
    
    /* Set the count of control tasks that are missing required fields */
    setMissingControlFieldsCount(controlsWithMissingFields.length);

    /* Filter monitoring tasks which missing required fields */
    let monitoringWithMissingFields = tasks.monitoring?.filter(monitoring => {
      /* Check if 'responsible', 'reviewer' and 'notify_on_failure' fields are null. */
      return monitoring.monitoring.responsible === null || monitoring.monitoring.notify_on_failure === null || monitoring.monitoring.reviewer === null;
    }) || [];

    /* Set the count of monitoring tasks that are missing required fields */
    setMissingMonitoringFieldsCount(monitoringWithMissingFields.length);
  }
}, [tasks]);

  return (
    <>
    <div style={{minWidth:'1200px'}}>
      <title>{t('dashboard', {ns: 'menu'})} | {tooltitle}</title>
          <div className="d-flex justify-content-center">
              <h1>{t('tasks_overview', {ns: 'general'})}</h1>
          </div>
            {(userRole === 'Admin' && missingControlFieldsCount > 0 || missingMonitoringFieldsCount > 0) && !closedFieldsWarning ?
            <MotifToast variant='error' onClose={() => setClosedFieldsWarning(true)} className="mb-2">
              {missingControlFieldsCount > 0 ? 
                <p>
                  <MotifTextLink href='/controls/'>
                    {t('control_fields_warning', {ns: 'general', count: missingControlFieldsCount})}
                  </MotifTextLink>
                </p> 
                : null
              }
              {missingMonitoringFieldsCount > 0 ? 
                <p>
                  <MotifTextLink href='/monitoring/'>
                    {t('monitoring_fields_warning', {ns: 'general', count: missingMonitoringFieldsCount})}
                  </MotifTextLink>
                </p> 
                : null
              }
            </MotifToast>
          : null
          }

          {userRole === 'Admin' && 
          <div className="d-flex justify-content-end pe-5">
                <MotifButton
                onClick={() => setModalVisibility(true)}
                disabled={isLoading}
                >
                  {t('export_data', {ns: 'general'})}
                </MotifButton>
            </div>
          }
          
          {userRole === 'Admin' && originalTasks && (
          <OverviewSwitch isLoading={isLoading} originalTasks={originalTasks} tasks={tasks} setTasks={setTasks} setSelectedTasks={setSelectedTasks} overviewSwitch={overviewSwitch} setOverviewSwitch={setOverviewSwitch} completed={false} />
          )}
            

            <div className="row">
              <div className="col">
                <TaskCount colors={colors} overdueTasks={overdueTasks} todayTasks={todayTasks} soonTasks={soonTasks} toDoTasks={toDoTasks} />
                <TaskCharts setFiltered={setFiltered} colors={colors} overdueTasks={overdueTasks} todayTasks={todayTasks} soonTasks={soonTasks} toDoTasks={toDoTasks} setSelectedTasks={setSelectedTasks} subthemes={subthemes} subthemeItemsCounts={subthemeItemsCounts} setActiveTab={setActiveTab} />
                <div className="row justify-content-center mx-1 my-4">
                  <MotifTabNav controlled='true'>
                    <MotifTabControl onClick={() => setActiveTab('controls')} className={activeTab === 'controls' ? 'motif-active' : ''}>{t('controls', {ns: 'general'})}</MotifTabControl>
                    <MotifTabControl tabIndex="0" onClick={() => setActiveTab('monitoring')} className={activeTab === 'monitoring' ? 'motif-active' : ''}>{t('monitoring', {ns: 'general'})}</MotifTabControl>
                  </MotifTabNav>
                </div>

                <div className="row">
                  <TableFilter colors={colors} isLoading={isLoading} filtered={filtered} setFiltered={setFiltered} originalTasks={originalTasks} tasks={tasks} selectedTasks={selectedTasks} setSelectedTasks={setSelectedTasks} subthemes={subthemes} entities={entities} countries={countries} fiscalYears={fiscalYears} completed={0} />
                  <div className="col mt-1">
                    <MotifButton 
                      stepper="next"
                      onClick={() => navigate('/overview/completed_tasks/')}
                      disabled={isLoading}>
                        {t('completed_tasks', {ns: 'menu'})}
                    </MotifButton>
                  </div>
                </div>
                <TaskTable selectedTasks={selectedTasks} activeTab={activeTab} navigate={navigate} completed={0} sortDeadline='asc' hideStartdate={true} hideDeadline={false} overviewSwitch={overviewSwitch} />
              </div>
            </div>
          </div>
            <MotifModal
              show={modalVisibility}
              onClose={() => setModalVisibility(false)}
              >
              <MotifModalHeader>
                  {t('choose_export_data', { ns: 'general' })}
                </MotifModalHeader>
                <MotifModalBody>
                  <MotifFormField>
                  <div style={{ paddingBottom: "1.5em" }}>
                      <MotifToggleSwitch
                          offLabel={'English'}
                          onLabel={'Nederlands'}
                          checked={languageIsDutch}
                          onChange={event => setLanguageIsDutch(event.target.checked)}
                          id='language_toggle'
                          disabled={isLoading}
                          >
                      </MotifToggleSwitch>
                      </div>

                    {Object.entries(choices).map(([key, choice], index) => 
                      <MotifCheckbox
                      id={key}
                      value={key}
                      name={key}
                      checked={checked[index]}
                      onChange={() => handleCheck(index)}
                      disabled={isLoading}
                      >{choice}</MotifCheckbox>)}
                  </MotifFormField>
                  {Object.values(exports).includes('RCM_matrix') && (<h6>{t('how', { ns: 'general' })}</h6>)}
                  {Object.values(exports).includes('RCM_matrix') && (<MotifFormField>
                    {Object.entries(sheets).map(([key, sheet]) =>
                      <MotifRadioButton
                      id={key}
                      value={key}
                      checked={key === sheetChoice}
                      onChange={event => setSheetChoice(event.target.value)}
                      disabled={isLoading}
                      >
                        {sheet}
                      </MotifRadioButton>
                    )}
                  </MotifFormField>)}
                  <ExportButton exports={exports} sheets={sheetChoice} languageIsDutch={languageIsDutch} />
                </MotifModalBody>
              </MotifModal>
    
    </>
  )
}

export default OverviewPage