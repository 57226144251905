import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLoading } from '../../utils/loadingcontext';

import {
    MotifFormField,
    MotifLabel,
    MotifInput,
    MotifSelect,
    MotifOption, 
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifErrorMessage,
    MotifCheckbox
} from '@ey-xd/motif-react';

import { request } from '../../utils/request';
import { validateGeneralInput, validateEmailInput, myeyMessages, validateDescriptionInput, tooltitle } from '../../utils/utils';

import { useTranslation } from 'react-i18next';
import { number } from 'prop-types';

const NewClient = () => {
    const { state } = useLocation();
    const { clientId } = state || {};
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const navigate = useNavigate();

    const [clientName, setClientName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [functionTitle, setFunctionTitle] = useState('');
    const [street, setStreet] = useState('');
    const [housenumber, setHousenumber] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('')
    const [language, setLanguage] = useState('');
    const [myEyIndustry, setMyEyIndustry] = useState('')
    const [forceRenderKey, setForceRenderKey] = useState('');
    const [countries, setCountries] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [scales, setScales] = useState([]);
    const [selectedScale, setSelectedScale] = useState('');
    const [scaleNumber, setScaleNumber] = useState(1);
    const [maxRanking, setMaxRanking] = useState(1);
    const [numberOptions, setNumberOptions] = useState([]);
    const [keyRiskThreshold, setKeyRiskThreshold] = useState(0);
    const [myEyIndustries, setMyEyIndustries] = useState([]);
    const [statusId, setStatusId] = useState();
    const [employeeId, setEmployeeId] = useState()

    const [myey, setMyey] = useState(process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True'); // default to true in production, allows to create non-myey test dummy accounts with a default password for testing purposes

    const [showModal, setShowModal] = useState(false);
    const [modalText, setModalText] = useState('');

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const getData = async () => {
        try {
            const [scales, languages, countries] = await Promise.all([
                request.get('scale/'),
                request.get('language/'),
                request.get('country/'),
            ]);

            setScales(scales);
            setLanguages(languages);
            setCountries(countries);

            await fetchAndSortIndustries();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getStatusId = async () => {
        try {
            const statuses = await request.get('employee-status');
            const adminStatus = statuses.find(status => status.status === 'Admin');
            setStatusId(adminStatus?.id);
        } catch (error) {
            console.error('Error fetching status ID:', error);
        } 
    };

    // Fetch and sort industries
    const fetchAndSortIndustries = async () => {
        try {
            const response = await request.get('api/get-industries/');
            const sortedIndustries = response.sort((a, b) => a.name.localeCompare(b.name));
            setMyEyIndustries(sortedIndustries);
        } catch (error) {
            console.error('Failed to fetch and sort industries:', error);
        }
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            setIsLoading(true);
            try {
                await getData(); // Fetch scales, languages, countries, and industries
                await getStatusId(); // Fetch status ID
                await fetchData(); // Fetch the main client data only after the above two have completed
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsLoading(false)
            }
        };

        fetchInitialData();
    }, [clientId]);

    const fetchData = async () => {
        if (clientId == null) return;

        try {
            const data = await request.get(`client-detail/${clientId}`);
            setClientName(data.name);
            setStreet(data.street_name);
            setHousenumber(data.house_number);
            setPostalCode(data.postal_code);
            setCity(data.city);
            setLanguage(data.language.toString());
            setCountry(data.country.id.toString());
            setSelectedScale(data.scale.toString());
            setKeyRiskThreshold(data.key_risk_threshold.toString());
            setFirstName(data.admin_first_name);
            setLastName(data.admin_last_name);
            setEmail(data.admin_email);
            setFunctionTitle(data.admin_function);
            setEmployeeId(data.admin_id);
            setMyEyIndustry(data.myey_industry);
        } catch (error) {
            console.error('Error fetching client data:', error);
        }
    };


    useEffect(() => {
        // Take the number from the selected scale to calculate the maximum keyrisk
        const scale = scales.find(scale => scale?.id === Number(selectedScale));
        setScaleNumber(scale?.number);
    }, [selectedScale, scales]);
    
    useEffect(() => {
        // Calculate maximum keyrisk
        setMaxRanking(scaleNumber * scaleNumber);
    }, [scaleNumber]);
    
    useEffect(() => {
        // Generate an array of numbers from 1 to maxRanking, then convert them to strings
        const options = Array.from({ length: maxRanking }, (_, index) => (index + 1).toString());
        setNumberOptions(options);
    }, [maxRanking]);
    
    useEffect(() => {
        if (!clientId) { 
        // Reset keyRiskThreshold when selectedScale changes, don't trigger in edit mode where these fields can't be edited anyway
        setKeyRiskThreshold('');
        }
    }, [selectedScale]);

    useEffect(() => {
        !!countries.length && setForceRenderKey(crypto.randomUUID()); 
        !!languages.length && setForceRenderKey(crypto.randomUUID());

        // important for edit mode since the keyriskthreshold value will be set before the options are set
        !!numberOptions.length && setForceRenderKey(crypto.randomUUID()); 
    }, [countries, languages, numberOptions]);

    const validateForm = () => {
        if (clientName.length > 0 &&
            firstName.length > 0 &&
            lastName.length > 0 &&
            email.length > 0 &&
            functionTitle.length > 0 &&
            street.length > 0 &&
            housenumber.length > 0 &&
            postalCode.length > 0 &&
            city.length > 0 &&
            country.length > 0 &&
            myEyIndustry.length > 0 &&
            selectedScale.length > 0 &&
            keyRiskThreshold) { 
            return true
        }
        return false;
    }

    const submitClient = async (how) => {
        setIsLoading(true);
        setIsFormSubmitted(true);
        
        if (validateForm()) {
            var clientData = {
                'name':clientName,
                'street_name':street,
                'house_number':housenumber,
                'postal_code':postalCode,
                'city':city,
                'country': country,
                'language':language,
                'scale':selectedScale,
                'key_risk_threshold':keyRiskThreshold,
                'function_title':functionTitle,
                'myey_industry': myEyIndustry
            };
    
            try {
                if (how === 'POST') {
                    // First check if the user already exists with creation
                    const userCheckResponse = await request.get(`check-user-exists/?email=${email}`);
                    
                    if (userCheckResponse.user_exists) {
                        alert(`${email} already exists. Creation canceled.`); // Simple alert for now, pending nicer look in the future.
                        return; // Prevent further creation if the user exists
                    }
                }

                // Attempt to create or update the client
                const clientResponse = clientId
                    ? await request.post(`client/${clientId}/`, clientData, how)
                    : await request.post('client/', clientData, how);
    
                var client = clientResponse['id'];
    
                var employeeData = {
                    'first_name': firstName,
                    'last_name': lastName,
                    'email': email,
                    'status': statusId,
                    'language': language,
                    'client_id': client,
                    'new_client':1,
                    'function_title':clientResponse['function_entity']['function_title']['id'],
                    'entity':clientResponse['function_entity']['entity']['id'],
                    'myey': myey // always true if debug features are off, to test dummy accounts without valid e-mail using the old login
                };
    
                // Attempt to create or update the employee
                // This will only execute if the client creation/update was successful
                const employeeResponse = clientId
                    ? await request.post(`employee/${employeeId}/`, employeeData, how)
                    : await request.post('employee/', employeeData, how);

                if (how === 'POST') {
                    const translationKey = myeyMessages[employeeResponse.myey];
                    if (translationKey) {
                        setModalText([
                            t('modal_eyadmin_added', {ns: 'admin'}),
                            <br />,
                            t(translationKey, {ns: 'admin'})
                        ]);
                    } else {
                        setModalText(t('myey_other_error', {ns: 'admin'})); // default error if unknown error
                        console.error('No response from MyEY')
                    }
                } else {
                    setModalText(t('modal_eyadmin_edited', {ns:'admin'}));
                }
                setShowModal(true);
                } catch (error) {
                    // Handle any errors that occur during the API calls
                    console.error('An error occurred:', error.message);
                } finally {
                    setIsFormSubmitted(false);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false); // form validation false
            }
        };

        
    return (
        <>  
        <title>{t('addclient', {ns: 'menu'})} | {tooltitle}</title>
        <div className="d-flex justify-content-center">
            <h1>{t('addclient', {ns: 'menu'})}</h1>
        </div>
            <div className="row justify-content-center mx-2 mt-5">
                <div className="col-4" >
                    <MotifFormField>
                        <MotifLabel>{t('name_client', {ns: 'admin'})}</MotifLabel>
                        <MotifInput
                        disabled={isLoading}
                        value={clientName}
                        onChange={event => setClientName(validateDescriptionInput(event.target.value).cleanedValue)}
                        maxLength={256}
                        />
                        {(clientName?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                </div>
                <div className="col-3">
                    <MotifFormField>
                            <MotifLabel>MyEY Industry</MotifLabel>
                            <MotifSelect 
                                disabled={isLoading}
                                id="select-country"
                                key={forceRenderKey}
                                value={myEyIndustry}
                                filter={true}
                                onChange={val => setMyEyIndustry(val)}>
                                {myEyIndustries?.map(item => (<MotifOption key={item.guid} value={item.name}>{item.name}</MotifOption>))}
                            </MotifSelect>
                            {(myEyIndustry?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                </div>
            </div>
            <div className="row justify-content-center mx-2">
                <div className='col-5'>
                    <MotifFormField>
                        <MotifLabel>{t('street', {ns: 'admin'})}</MotifLabel>
                        <MotifInput 
                        disabled={isLoading}
                        value={street}
                        onChange={event => setStreet(validateGeneralInput(event.target.value).cleanedValue)}
                        maxLength={256}
                        />
                        {(street?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                </div>
                <div className='col-2'>
                    <MotifFormField>
                        <MotifLabel>{t('number', {ns: 'admin'})}</MotifLabel>
                        <MotifInput 
                        disabled={isLoading}
                        value={housenumber}
                        onChange={event => setHousenumber(validateGeneralInput(event.target.value).cleanedValue)}
                        maxLength={10}
                        />
                        {(housenumber?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                </div>
            </div>
            <div className='row justify-content-center mx-2'>
                <div className='col-2'>
                    <MotifFormField>
                        <MotifLabel>{t('postal', {ns: 'admin'})}</MotifLabel>
                        <MotifInput 
                        disabled={isLoading}
                        value={postalCode}
                        onChange={event => setPostalCode(validateGeneralInput(event.target.value).cleanedValue)}
                        maxLength={10}
                        />
                        {(postalCode?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                </div>
                <div className='col-2'>
                    <MotifFormField>
                        <MotifLabel>{t('city', {ns: 'admin'})}</MotifLabel>
                        <MotifInput 
                        disabled={isLoading}
                        value={city}
                        onChange={event => setCity(validateGeneralInput(event.target.value).cleanedValue)}
                        maxLength={256}
                        />
                        {(city?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                </div>
                <div className='col-3'>
                    <MotifFormField>
                        <MotifLabel>{t('country', {ns: 'general'})}</MotifLabel>
                        <MotifSelect 
                            disabled={isLoading}
                            id="select-country"
                            key={forceRenderKey}
                            value={country}
                            filter={true}
                            onChange={val => setCountry(val)}>
                            {countries.map(item => (<MotifOption key={item.id} value={item.id.toString()}>{item.country}</MotifOption>))}
                        </MotifSelect>
                        {(country?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                </div>
            </div>
            <div className='row justify-content-center mx-2'>
                <div className='col-2'>
                    <MotifFormField>
                        <MotifLabel id='lang'>{t('pref_language', {ns: 'admin'})}</MotifLabel>
                        <MotifSelect 
                        disabled={isLoading || clientId}
                        key={forceRenderKey}
                        ariaLabelledBy="lang"
                        id="select-language"
                        onChange={(val) => setLanguage(val)}
                        value={language}>
                            {languages.map(option => <MotifOption key={option.id} value={option.id.toString()}>{option.language_description}</MotifOption>)}
                        </MotifSelect>
                        {(language?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                </div>
                <div className='col-3'>
                    <MotifFormField>
                        <MotifLabel id="select-label-scale">{t('scale_impact_probability', {ns: 'admin'})}</MotifLabel>
                        <MotifSelect
                            disabled={isLoading || clientId}
                            key={forceRenderKey}
                            value={selectedScale}
                            onChange={val => setSelectedScale(val)}
                            ariaLabelledBy="select-label-scale">
                            {scales.map(scale => <MotifOption key={scale.id} value={scale.id.toString()}>{scale.description}</MotifOption>)}
                        </MotifSelect>
                        {(selectedScale?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                </div>
                <div className='col-2'>
                <MotifFormField>
                    <MotifLabel id="select-keyrisk-threshold">{t('keyrisk_treshold', {ns: 'admin'})}</MotifLabel>
                    <MotifSelect
                        key={forceRenderKey}
                        disabled={!selectedScale || clientId}
                        value={keyRiskThreshold}
                        onChange={val => setKeyRiskThreshold(val)}
                        ariaLabelledBy="select-label-keyrisk">
                        {numberOptions?.map(item => (<MotifOption key={item} value={item}>{item}</MotifOption>))}
                    </MotifSelect>
                    {(keyRiskThreshold?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                </MotifFormField>
                </div>
            </div>
            <div className="row justify-content-center mx-2">
                <div className='col-7'>
                    <p>{t('eyadmin_form', {ns: 'admin'})}</p>
                    <MotifFormField>
                        <MotifLabel>{t('firstname', {ns: 'account'})}</MotifLabel> 
                        <MotifInput
                        disabled={isLoading}
                        value={firstName}
                        onChange={event => setFirstName(validateGeneralInput(event.target.value).cleanedValue)}
                        maxLength={50}
                        />
                        {(firstName?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>{t('lastname', {ns: 'account'})}</MotifLabel> 
                        <MotifInput
                        disabled={isLoading}
                        value={lastName}
                        onChange={event => setLastName(validateGeneralInput(event.target.value).cleanedValue)}
                        maxLength={50}
                        />
                        {(lastName?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>{t('email', {ns: 'login'})}</MotifLabel>
                        <MotifInput 
                        disabled={isLoading}
                        type={'email'}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                        maxLength={256}
                        />
                        {(email?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                        {(validateEmailInput(email).isNotValid && isFormSubmitted) && <MotifErrorMessage>{t('invalid_email', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>{t('function_title', {ns: 'general'})}</MotifLabel>
                        <MotifInput 
                        disabled={isLoading || clientId}
                        value={functionTitle}
                        onChange={event => setFunctionTitle(validateGeneralInput(event.target.value).cleanedValue)}
                        maxLength={256}
                        />
                        {(functionTitle?.length == 0 && isFormSubmitted) && <MotifErrorMessage>{t('required_field', {ns: 'general'})}</MotifErrorMessage>}
                    </MotifFormField>
                    <MotifFormField>
                        {/* Conditionally render the checkbox if debug features are enabled */}
                        {process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' ? (
                            <MotifCheckbox
                                id="myey-checkbox"
                                name="myey-checkbox"
                                onChange={() => setMyey(!myey)} // Toggle the state
                                checked={myey} // Controlled checkbox state
                            >
                                MyEY (uncheck if testing with fake account)
                            </MotifCheckbox>
                        ) : (
                            // Hide checkbox and force `myey` to true in production
                            <input type="hidden" value={true} />
                        )}
                    </MotifFormField>
                    <MotifButton
                        className="mt-2"
                        disabled={isLoading}
                        onClick={() => submitClient(!clientId ? 'POST' : 'PUT')}>
                        {!clientId ? t('add', {ns: 'general'}) : t('edit', {ns: 'general'})}
                    </MotifButton>
                </div>
            </div>

            <MotifModal show={showModal} onClose={() => setShowModal(false)}>
                <MotifModalHeader closeModalButton={true}>Client</MotifModalHeader>
                <MotifModalBody>{modalText}</MotifModalBody>
                <MotifModalFooter>
                    <MotifButton disabled={isLoading} size="medium" type="button" onClick={() => navigate('/clientoverview/')}>
                            {t('modal_eyadmin_button', {ns: 'admin'})}
                        </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default NewClient