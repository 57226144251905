import React, {useState, useEffect} from 'react';

import { useTranslation } from 'react-i18next';

import {
    MotifFormField,
    MotifLabel,
    MotifTextArea,
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifErrorMessage,
    MotifRadioButtonGroup, 
    MotifRadioButton,
    MotifSlider
} from '@ey-xd/motif-react'

const EffectiveControlsModal = ({ isLoading, modalVisible, setModalVisibility, controls, controlFeedback, setControlFeedback, feedback, setFeedback, monitorTask, taskFinal, setControlsModalVisibility, setIsControlFeedbackSubmitted, submitForm, rating, setRating}) => {

    const { t } = useTranslation();

    const [isFormSubmitted, setIsFormSubmitted] = useState(false)

    // Determine which controls to use based on `taskFinal` and existence of `monitorTask.snapshot.controls`
    const actualControls = taskFinal && monitorTask.snapshot?.monitoring?.controls
    ? monitorTask.snapshot?.monitoring?.controls.map(({ control }) => control) // Adjust if necessary to match expected structure
    : controls;

    useEffect(() => {
        if (taskFinal && monitorTask.snapshot?.monitoring?.controls) {
            const initialFeedback = {};
    
            monitorTask.snapshot.monitoring.controls.forEach(({ control }) => {
                initialFeedback[control.id] = control.feedback ? '1' : '0';
            });
    
            setControlFeedback(initialFeedback);
    
            if (monitorTask.feedback) {
                setFeedback(monitorTask.feedback);
            }

            if (monitorTask.rating) {
                setRating((monitorTask.rating).toString());
            }
        }
    }, [monitorTask, taskFinal]);

    useEffect(() => {
        // There is a bug in MotifSlider (v6.5.0). Setting min attribute to '1' glitches out the sliders. It will show the values 1 too high (e.g. when the slider is on its lowest, 1, it will show as 2). The bug has been reported to the Motif team 12-9-2024
        // This useEffect serves as a workaround so 0 values can't be submitted.
        // There is also seemingly a bug where you can pull the slider under the minimum (e.g. -1) and over the maximum, this also gets prevented by this useEffect.
        // parseInt because MotifSlider sets its values and attributes as strings, not integers.
        if (parseInt(rating) <= 0) {
            setRating('1');
        }

        if (parseInt(rating) >= 5) {
          setRating('5');
        }
      }, [rating]);

    const handleRadioChange = (e, controlID) => {
        setControlFeedback({
            ...controlFeedback,
            [controlID]: e.target.value
        });
    }

    const validateForm = () => {
        for (let id of controls?.map(control => control.id)) {
            if (!Object.keys(controlFeedback).includes(id.toString())) {
                setIsFormSubmitted(true);
                return false; // Returns false if any control id is not in controlFeedback
            }
        }
        return true; // Returns true if all controls have been addressed
    }

    return (
        <>
            <MotifModal show={modalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader closeModalButton={!taskFinal}>{t('modal_controlreview_header', {ns: 'perform_monitoring'})}</MotifModalHeader>
                <MotifModalBody>
                    <MotifFormField>
                        <MotifRadioButtonGroup>
                            <ul>
                                {actualControls?.map((control, i) => (
                                    <li key={i}>
                                        {control.control} <br />
                                        <MotifRadioButton
                                            disabled={taskFinal||isLoading}
                                            value={'1'}
                                            checked={controlFeedback[control.id]?.toString() === '1'}
                                            onChange={(e) => handleRadioChange(e, control.id)}
                                            >
                                            {t('yes', {ns: 'general'})}
                                        </MotifRadioButton>
                                        <MotifRadioButton
                                            disabled={taskFinal||isLoading}
                                            value={'0'}
                                            checked={controlFeedback[control.id]?.toString() === '0'}
                                            onChange={(e) => handleRadioChange(e, control.id)}
                                            >
                                            {t('no', {ns: 'general'})}
                                        </MotifRadioButton>
                                    </li>
                                ))}
                            </ul>
                        </MotifRadioButtonGroup>
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel>{t('comments', {ns: 'general'})}</MotifLabel>
                        <MotifTextArea 
                            disabled={taskFinal || isLoading}
                            value={feedback}
                            rows={4}
                            onChange={event => setFeedback(event.target.value)}
                            maxLength={2000}
                            />
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel id="label_monitoring_rating">{t('monitoring_rating', { ns: 'perform_monitoring' })}</MotifLabel>
                        <MotifSlider
                                disabled={taskFinal || isLoading}
                                value={rating}
                                onChange={event => setRating(event.target.value)}
                                id="slider_monitoring_rating"
                                min="0"
                                max="5"
                            />
                    </MotifFormField>
                    {(isFormSubmitted) && <MotifErrorMessage>{t('controls_feedback_valid', {ns: 'perform_monitoring'})}</MotifErrorMessage>}
                    <MotifModalFooter>
                        { !taskFinal ?
                        <MotifButton
                            className="me-3"
                            disabled={isLoading}
                            onClick={() => {
                                if (validateForm()) {
                                    setIsControlFeedbackSubmitted(true);
                                    setControlsModalVisibility(false);
                                    submitForm('submit');
                                }
                            }}
                        >
                            {t('finalise', {ns: 'general'})}
                        </MotifButton>
                        : null }
                    </MotifModalFooter>
                </MotifModalBody>
            </MotifModal>
        </>
    )
}

export default EffectiveControlsModal