import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
    MotifTable,
    MotifChip,
    MotifTextLink,
    MotifIcon,
    MotifIconButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifButton,
    MotifSearch,
    MotifFormField
} from '@ey-xd/motif-react'

import {
    actionIcAssignment24px,
    hardwareIcKeyboardArrowDown24px
} from '@ey-xd/motif-react/assets/icons';

import MonitoringTaskDescription from '../../components/MonitoringTaskDescription';

const PerformMonitoringTable = ({ monitoringTasks, myMonitoringTasks, navigate, overviewSwitch, isLoading }) => {

    const { t } = useTranslation();
    const { userRole, isSuperuser } = useOutletContext();

    const [isModalVisible, setModalVisibility] = useState(false);
    const [modalHeaderText, setModalHeaderText] = useState(t('monitoring_task', { ns: 'general' }))
    const [modalBodyText, setModalBodyText] = useState('')
    const [modalFooterText, setModalFooterText] = useState('');

    const [hasMonitoringNumber, setHasMonitoringNumber] = useState(false);
    const [filteredData, setFilteredData] = useState({ items: [], myItems: [] });
    const [searchValue, setSearchValue] = useState('');

    useEffect(() => { // check if any monitoring has a monitoring number, if not, hide the table column
        const someMonitoringNumberExists = monitoringTasks?.some(task => task.monitoring?.monitoring_number !== undefined && task.monitoring?.monitoring_number !== null && task.monitoring?.monitoring_number.length > 0);

        setHasMonitoringNumber(someMonitoringNumberExists);
    }, [monitoringTasks]);

    const doesItemMatchSearch = (item, searchValue) => {
        const controlMatches = item.monitoring.controls.some(control => {
            const basicMatches = (
                control.control && control.control.toLowerCase().includes(searchValue) || // controls
                control.risk && control.risk.risk && control.risk.risk.toLowerCase().includes(searchValue) || // risks
                control.risk && control.risk.subtheme && control.risk.subtheme.description && control.risk.subtheme.description.toLowerCase().includes(searchValue) || // subthemes
                control.risk && control.risk.subtheme && control.risk.subtheme.theme && control.risk.subtheme.theme.description && control.risk.subtheme.theme.description.toLowerCase().includes(searchValue) // themes
            );
            return basicMatches;
        });

        const entityMatches = item.monitoring.unique_entities.some(entity => {
            return entity.name && entity.name.toLowerCase().includes(searchValue); // entities
        });

        return controlMatches ||
            entityMatches ||
            (item.monitoring && item.monitoring.monitoring && item.monitoring.monitoring.toLowerCase().includes(searchValue)) || // monitoring
            (item.monitoring && item.monitoring.monitoring_number && item.monitoring.monitoring_number.toLowerCase().includes(searchValue)) || // monitoring number
            (item.monitoring && item.monitoring.monitoring_type && item.monitoring.monitoring_type.description && item.monitoring.monitoring_type.description.toLowerCase().includes(searchValue)) || // monitoring type
            (item.monitoring && item.monitoring.frequency && item.monitoring.frequency.description && item.monitoring.frequency.description.toLowerCase().includes(searchValue)) || // frequency
            (item.monitoring && item.monitoring.responsible_fullname && item.monitoring.responsible_fullname.toLowerCase().includes(searchValue)) || // responsible
            (item.monitoring && item.monitoring.reviewer_fullname && item.monitoring.reviewer_fullname.toLowerCase().includes(searchValue)) || // reviewer
            (item.monitoring && item.monitoring.notify_on_failure_fullname && item.monitoring.notify_on_failure_fullname.toLowerCase().includes(searchValue)) || // notify on failure
            (item.monitoring && item.monitoring.expiry && item.monitoring.expiry.toLowerCase().includes(searchValue)) ||// expiry date

            (item.status && item.status.description && item.status.description.toLowerCase().includes(searchValue)) || // status
            (item.start_date && item.start_date.toLowerCase().includes(searchValue)) || // start date
            (item.deadline && item.deadline.toLowerCase().includes(searchValue)); // deadline
    };

    const filterData = (inputValue) => {
        setSearchValue(inputValue);
        let allResults = [];
        let myResults = [];

        if (inputValue.length > 0) {
            const searchValue = inputValue.toLowerCase(); // Convert once and use throughout

            // Filter all monitoring items
            monitoringTasks.forEach(item => {
                if (doesItemMatchSearch(item, searchValue)) {
                    allResults.push(item);
                }
            });

            // Filter admin's monitoring items
            myMonitoringTasks.forEach(item => {
                if (doesItemMatchSearch(item, searchValue)) {
                    myResults.push(item);
                }
            });

            // Set filtered data for both monitoring and myMonitoring
            setFilteredData({
                items: allResults,
                myItems: myResults
            });

        } else {
            // Reset to the whole list when search input is cleared
            setFilteredData({ items: [], myItems: [] });
        }
    };

    const taskPopUp = (value) => {
        const task = monitoringTasks.find(task => task.id == value);
        const taskFinal = task.status.id == 1;

        setModalBodyText(<MonitoringTaskDescription monitorTask={task} taskFinal={taskFinal} />)
        setModalFooterText(
            <>
                <MotifButton
                    className="me-3"
                    onClick={() => navigate('performmonitoring', { state: { taskId: value } })}>{t('open_task', { ns: 'general' })}</MotifButton>
                <MotifButton
                    className="me-3"
                    onClick={() => setModalVisibility(false)}>{t('close', { ns: 'general' })}</MotifButton>
            </>
        );
        setModalVisibility(true);
    }

    const columnDef = [
        {
            headerName: ' ',
            cellRenderer: ({ value }) => (
                <MotifIconButton value={value}
                    type="button"
                    title={t('tablebutton', { ns: 'perform_monitoring' })}>
                    <MotifIcon src={actionIcAssignment24px} />
                </MotifIconButton>
            ),
            field: 'id',
            width: 60,
            pinned: 'left',
            headerComponentParams: {
                dataColumn: false,
                headerEndComponent: (
                    <MotifIconButton aria-label="Click here to see options" type="button">
                        <MotifIcon src={hardwareIcKeyboardArrowDown24px} />
                    </MotifIconButton>
                )
            }
        },
        {
            headerName: '#',
            field: 'monitoring.monitoring_number',
            minWidth: 100,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
            hide: !hasMonitoringNumber,
        },
        {
            headerName: t('subtheme', { ns: 'general' }),
            field: 'monitoring.controls',
            cellRenderer: ({ value }) => {
                const uniqueSubthemes = [...new Set(value.map(control => control.risk.subtheme.description_code))];
                const subthemes = uniqueSubthemes.join(', ');
                return (
                    <>
                        {subthemes}
                    </>
                );
            },
            minWidth: 170,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 2,
        },
        {
            headerName: t('risks', { ns: 'general' }),
            field: 'monitoring.controls',
            cellRenderer: ({ value }) => {
                const uniqueRisks = [...new Set(value.map(control => control.risk.risk))];
                const risks = uniqueRisks.join(', ');
                return (
                    <>
                        {risks}
                    </>
                );
            },
            minWidth: 170,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 2,
        },
        {
            headerName: t('monitoring', { ns: 'general' }),
            field: 'monitoring.monitoring',
            minWidth: 170,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 2,
        },
        {
            headerName: t('monitoring_type', { ns: 'general' }),
            field: 'monitoring.monitoring_type.description',
            minWidth: 170,
            sortable: true,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
        },
        {
            headerName: t('status', { ns: 'general' }),
            field: 'status',
            width: 250,
            sortable: true,
            cellRenderer: ({ value }) => (
                <div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
                    <MotifChip variant={value.chipVariant}>{value.description}</MotifChip>
                </div>
            ),
        },
        {
            headerName: t('deadline', { ns: 'general' }),
            field: 'deadline',
            width: 160,
            sortable: true,
            filter: true,
            sort: 'asc',
        },
        {
            headerName: t('responsible', { ns: 'general' }),
            field: 'responsible_fullname',
            minWidth: 140,
            wrapText: true,
            sortable: true,
            filter: true,
            flex: 1,
        },
        {
            headerName: t('reviewer', { ns: 'general' }),
            field: 'reviewer_fullname',
            minWidth: 140,
            wrapText: true,
            sortable: true,
            filter: true,
            flex: 1,
        },
    ]
    return (
        <>
            <div className="row justify-content-center">
                <div className="col-8">
                    <MotifFormField>
                        <MotifSearch
                            value={searchValue}
                            id="search-monitoring-tasks"
                            onEnter={event => filterData(event.target.value)}
                            onSelect={val => setSearchValue(val)}
                            onClear={event => filterData('')}
                            aria-label="Search in monitoring tasks"
                            placeholder={t('search2', { ns: 'general' })}
                            disabled={isLoading} />
                    </MotifFormField>
                </div>
            </div>

            <div className="row justify-content-center mx-2 my-4">
                <div className="col">
                    {overviewSwitch && monitoringTasks?.length > 0 ?
                        <MotifTable className='pb-5'
                            columnDefs={columnDef}
                            rowData={searchValue.length > 0 ? filteredData.items : monitoringTasks}
                            onRowClicked={row => taskPopUp(row.data.id)}
                            onCellKeyDown={row => {
                                if (row.event.key === 'Enter') {
                                    taskPopUp(row.data.id)
                                }
                            }}
                            compact={true}
                            zebra={true}
                            pagination={monitoringTasks?.length > 30 ? true : false}
                            paginationPageSize="25"
                        /> : null}
                    {!overviewSwitch && monitoringTasks?.length > 0 ?
                        <MotifTable className='pb-5'
                            columnDefs={columnDef}
                            rowData={searchValue.length > 0 ? filteredData.myItems : myMonitoringTasks}
                            onRowClicked={row => taskPopUp(row.data.id)}
                            onCellKeyDown={row => {
                                if (row.event.key === 'Enter') {
                                    taskPopUp(row.data.id)
                                }
                            }}
                            compact={true}
                            zebra={true}
                            pagination={myMonitoringTasks?.length > 30 ? true : false}
                            paginationPageSize="25"
                        /> : null}
                </div>
            </div>
            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>{modalHeaderText}</MotifModalHeader>
                <MotifModalBody>{modalBodyText}</MotifModalBody>
                <MotifModalFooter>{modalFooterText}</MotifModalFooter>
            </MotifModal>
        </>
    );
}

export default PerformMonitoringTable;