const today = new Date();
export const currentYear = today.getFullYear();

//To do: create table in database for this
export const months = { 1:"Januari", 
                        2:"Februari", 
                        3:"Maart", 
                        4:"April", 
                        5:"Mei", 
                        6:"Juni", 
                        7:"Juli", 
                        8:"Augustus", 
                        9:"September", 
                        10:"Oktober", 
                        11:"November", 
                        12:"December"}

export const startDate = ({startYear, startMonth}) => { 
    var startDate = new Date(`${startYear}-${startMonth}-1`)

    return startDate
}

export const calculateYearRange = ({startYear, endYear}) => {
    var range = [];

    for (var i = startYear; i <= endYear; i++){
        range.push(parseInt(i))
    }

    return range
}

export const createCalendarArray = ({startYear, endYear, startMonth, endMonth}) => {
    const yearRange = calculateYearRange({startYear, endYear})

    var outerArray = []
    var id = 1
    var dayId = 1

    for(var year in yearRange){
        var yearDict = {}

        yearDict['id'] = id;
        yearDict['year'] = yearRange[year];
        yearDict['months'] = createMonthDict(yearRange[year], dayId, startYear, endYear, startMonth, endMonth)[0];
        dayId=createMonthDict(yearRange[year], dayId, startYear, endYear, startMonth, endMonth)[1];

        outerArray.push(yearDict);
        id++;
    }

    return outerArray
}

const createMonthDict = (year, dayId, startYear, endYear, startMonth, endMonth) => {
    var monthArray = []
    var id = 1

    if(startYear==endYear){
        var selected_months = Object.fromEntries(Object.entries(months).slice(startMonth-1, endMonth))
    } else if(year == startYear){
        var selected_months = Object.fromEntries(Object.entries(months).slice(startMonth-1))
    } else if(year != endYear){
        var selected_months = months
    } else {
        var selected_months = Object.fromEntries(Object.entries(months).slice(0, endMonth))
    }

    for(var [index, month] of Object.entries(selected_months)){
        var monthDict = {};

        var number_of_days = daysInMonth(index, year);
        var dayRange = calculateDayRange(number_of_days, dayId)[0]
        dayId = calculateDayRange(number_of_days, dayId)[1];

        monthDict['month'] = month;
        monthDict['days'] = dayRange;
        monthDict['id'] = id;

        monthArray.push(monthDict);
        id++;

    }
    return [monthArray, dayId]
}

const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
}

const calculateDayRange = (max_days, dayId) => {
    var range = [];

    for (var i = 1; i <= max_days; i++){
        var dict = {};
        dict['day'] = i;
        dict['id'] = dayId

        range.push(dict)
        dayId++
    };

    return [range, dayId]
}
export const totalNumberOfDays = ({startYear, endYear, startMonth, endMonth}) => {

    const yearRange = calculateYearRange({startYear, endYear})

    if(year === startYear){
        var selected_months = Object.fromEntries(Object.entries(months).slice(startMonth-1))
    } else if(year !== endYear){
        var selected_months = months
    } else {
        var selected_months = Object.fromEntries(Object.entries(months).slice(0, endMonth))
    }

    var total = 0

    for(year in yearRange){
        var year = year
        for(var [index, month] of Object.entries(selected_months)){
            total = total + daysInMonth(index, year)
        }
    }
    return total
}