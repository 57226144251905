import React, { useState, useEffect } from 'react'

import BookYear from './BookYear';
import Scale from './Scale';
import KeyRisk from './KeyRisk';
import { request } from '../../../utils/request';
import { useLoading } from '../../../utils/loadingcontext';

const General = () => {

  const { isLoading, setIsLoading } = useLoading();

  const [client, setClient] = useState({});

  useEffect(() => {

    const fetchData = async () => {
        setIsLoading(true);

        setClient((await request.get('client/'))[0]);

        setIsLoading(false);

    };

    fetchData();

},[])

  return (
    <>
      {client ?
      <div className='row mx-3'>
          <div className="col">
            <BookYear />
            <Scale 
              client={client} 
              setClient={setClient}/>
            <KeyRisk 
              client={client}
              setClient={setClient} />
          </div>
      </div>
       : null}
    </>
    
  )
}

export default General