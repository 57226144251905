import React, {useState, useEffect} from 'react';
import { MakeRequests } from '../../utils/request'
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';
import { useNavigate } from 'react-router-dom';

import { MotifTable,
        MotifButton,
        MotifIconButton,
        MotifIcon,
        MotifModal,
        MotifModalHeader,
        MotifModalBody,
        MotifModalFooter
} from '@ey-xd/motif-react'

import { editorIcModeEdit24px, actionIcDelete24px } from '@ey-xd/motif-react/assets/icons';
import { myeyMessages, tooltitle } from '../../utils/utils';

const AdminOverview = () => {
    const request = new MakeRequests();
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const navigate = useNavigate();

    const [adminData, setAdminData] = useState();

    useEffect(() => {
        const getAdminData = async () =>{
            setIsLoading(true);

            var admins = await request.get('ey-employee');
            setAdminData(admins);
            setIsLoading(false);
        }
        getAdminData()
    }, [])

    // const editAdmin = (props) => {
    //     const id = props.data.id;
    //     navigate('/adminoverview/addadmin', { state: { adminId:id } });
    // }

      const columnDef = [
        {
            headerName: ' ',
            cellRenderer: (props) => {
                return <MotifIconButton
                    type="button"
                    title={t('tablebutton', {ns: 'admin'})}>
                    <MotifIcon src={editorIcModeEdit24px} />
                </MotifIconButton>
            },
            field: 'id',
            width: 60,
            pinned: 'left',
            headerComponentParams: {
              dataColumn: false
            }
        },
        {
            headerName: t('name_ey_admin', {ns: 'admin'}),
            field: 'admin_name',
            minWidth: 100,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
        },
        {
            headerName: t('email_ey_admin', {ns: 'admin'}),
            field: 'email',
            minWidth: 100,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
        },
        {
            headerName: t('lang', {ns: 'admin'}),
            field: 'language_description',
            minWidth: 100,
            resizable: true,
            sortable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            flex: 1,
        },
    ];

    return (
        <>  
        <title>{t('adminoverview', {ns: 'menu'})} | {tooltitle}</title>
        <div className="d-flex justify-content-center">
            <h1>{t('adminoverview', {ns: 'menu'})}</h1>
        </div>
            <div className='row p-3'>
                <div className='col-6'>
                    <MotifButton onClick={() => navigate('/adminoverview/addadmin', {state:{adminId:null}})} disabled={isLoading}>
                        {t('addadmin', {ns: 'admin'})}
                    </MotifButton>
                </div>
            </div>
            { !isLoading && adminData?.length > 0 &&
            <div className='row p-3'>
                <MotifTable  className='pb-5'
                    columnDefs={columnDef}
                    rowData={adminData}
                    onRowClicked={row => navigate('/adminoverview/addadmin', { state: { adminId: row.data.id } })}
                    onCellKeyDown={row => {
                        if (row.event.key === 'Enter') {
                            navigate('/adminoverview/addadmin', { state: { adminId: row.data.id } })
                        }
                    }}
                    compact={true}
                    zebra={true}
                    pagination={adminData?.length > 30 ? true : false}
                    paginationPageSize="25"
                    />
            </div>  
            }
        </>
    )
}

export default AdminOverview