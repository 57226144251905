import React, {useState, useEffect} from 'react';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../utils/loadingcontext';

import {
    MotifTextLink
} from '@ey-xd/motif-react';

import { MakeRequests } from '../utils/request';

import ControlItem from './ControlItem';
import RiskDetails from '../views/risks/RiskDetails';

import DebugTaskDateModal from './DebugTaskDateModal'; // allows changing individual task dates for debug purposes, hidden in production

const ControlTaskDescription = ( {controlTask, taskFinal}) => {

    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();
    const request = new MakeRequests()

    const [scaleNumber, setScaleNumber] = useState(1);

    const [isRModalVisible, setRModalVisibility] = useState(false);

    const [currentRiskData, setCurrentRiskData] = useState({});

    const [showDateModal, setShowDateModal] = useState(false); // for DebugTaskDateModal

    useEffect(() => {
        const fetchData = async () => {
          setIsLoading(true);
    
          var scaleData = await request.get('key-risk-data'); // fetch scale number for the risk pop up
          setScaleNumber(scaleData[0].scale.number);
  
          setIsLoading(false);
        };
    
        fetchData();
      }, []);

    // Data structure for the open task
    const dataOpen = !taskFinal ? {
        ...(controlTask?.control?.control_number && {
            [t('control_number', { ns: 'general' })]: controlTask?.control?.control_number,
        }),
        [t('subtheme', {ns: 'general'})]: controlTask?.control?.risk.subtheme.description_code,
        [t('risk', {ns: 'general'})]: (
            <ul>
                    <li key={1}>
                        <MotifTextLink  
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault(); // prevent default link behavior
                                setCurrentRiskData(controlTask?.control?.risk); // set the control data for modal
                                setRModalVisibility(true); // show the modal
                            }}
                        >
                            {controlTask?.control?.risk?.risk}
                        </MotifTextLink>
                    </li>
            </ul>
        ),
        [t('control', {ns: 'general'})]: controlTask?.control?.control,
        [t('description', {ns: 'general'})]: controlTask?.control?.description,
        [t('proof', {ns: 'general'})]: controlTask?.control?.proof,
        [t('net_risk', {ns: 'general'})]: `${controlTask?.control?.net_risk} (${t('scale', { ns: 'general' })}: 1 - ${scaleNumber * scaleNumber})`,
        [t('frequency', {ns: 'general'})]: controlTask?.control?.frequency.description,
        [t('control_approach', {ns: 'general'})]: controlTask?.control?.control_level.description,
        [t('control_type', {ns: 'general'})]: controlTask?.control?.control_type.description,
        [t('entities', {ns: 'general'})]: (
            <ul>
                {controlTask?.control?.entity?.map((entity, i) => (
                    <li key={i}>
                        {entity.name}
                    </li>
                ))}
            </ul>
        ),
        [t('responsible', { ns: 'general' })]: (controlTask?.responsible_fullname || t('none', { ns: 'general' })),
        [t('reviewer', { ns: 'general' })]: (controlTask?.reviewer_fullname || t('none', { ns: 'general' })),
        [t('notify_on_failure', { ns: 'general' })]: (controlTask?.notify_on_failure_fullname || t('none', { ns: 'general' })),
        [t('startdate', {ns: 'general'})]: controlTask?.start_date,
        [t('deadline', {ns: 'general'})]: controlTask?.deadline,
        [t('expiry', {ns: 'general'})]: controlTask?.control?.expiry ? controlTask?.control.expiry :  [t('none', {ns: 'general'})],
        [t('status', {ns: 'general'})]: controlTask?.status?.description
    } : {};

    // Data structure for the completed task
    const dataFinal = taskFinal ? {
        ...(controlTask?.control?.control_number && {
            [t('control_number', { ns: 'general' })]: controlTask?.snapshot?.control?.control_number,
        }),
        [t('subtheme', {ns: 'general'})]: controlTask?.snapshot?.control?.risk.subtheme.description_code,
        [t('risk', {ns: 'general'})]: controlTask?.snapshot?.control?.risk.risk,
        [t('risk', {ns: 'general'})]: (
            <ul>
                    <li key={1}>
                        <MotifTextLink  
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault(); // prevent default link behavior
                                setCurrentRiskData(controlTask?.snapshot?.control?.risk); // set the control data for modal
                                setRModalVisibility(true); // show the modal
                            }}
                        >
                            {controlTask?.snapshot?.control?.risk?.risk}
                        </MotifTextLink>
                    </li>
            </ul>
        ),
        [t('control', {ns: 'general'})]: controlTask?.snapshot?.control?.control,
        [t('description', {ns: 'general'})]: controlTask?.snapshot?.control?.description,
        [t('proof', {ns: 'general'})]: controlTask?.snapshot?.control?.proof,
        [t('net_risk', {ns: 'general'})]: `${controlTask?.snapshot?.control?.net_risk} (${t('scale', { ns: 'general' })}: 1 - ${scaleNumber * scaleNumber})`,
        [t('frequency', {ns: 'general'})]: controlTask?.snapshot?.control?.frequency.description,
        [t('control_approach', {ns: 'general'})]: controlTask?.snapshot?.control?.control_level.description,
        [t('control_type', {ns: 'general'})]: controlTask?.snapshot?.control?.control_type.description,
        [t('entities', {ns: 'general'})]: (
            <ul>
                {controlTask?.snapshot?.control?.entity?.map((entity, i) => (
                    <li key={i}>
                        {entity.name}
                    </li>
                ))}
            </ul>
        ),
        [t('responsible', { ns: 'general' })]: (controlTask?.archived_responsible_fullname || controlTask?.archived_function_responsible || t('none', { ns: 'general' })),
        [t('reviewer', { ns: 'general' })]: (controlTask?.archived_reviewer_fullname || controlTask?.archived_function_reviewer || t('none', { ns: 'general' })),
        [t('notify_on_failure', { ns: 'general' })]: (controlTask?.archived_notify_on_failure_fullname || controlTask?.archived_function_notifyonfailure || t('none', { ns: 'general' })),
        [t('startdate', {ns: 'general'})]: controlTask?.start_date,
        [t('execution_date', {ns: 'general'})]: controlTask?.execution_date.substring(0, 10),
        [t('deadline', {ns: 'general'})]: controlTask?.deadline,
        [t('expiry', {ns: 'general'})]: controlTask?.snapshot?.control?.expiry ? controlTask?.snapshot?.control?.expiry :  [t('none', {ns: 'general'})],
        [t('fiscal_year', {ns: 'general'})]: controlTask?.fiscal_year, 
        [t('status', {ns: 'general'})]: controlTask?.status?.description,
        [t('executed', {ns: 'general'})]: controlTask?.execution ? [t('yes', {ns: 'general'})] : [t('no', {ns: 'general'})]
    } : {};

    // Choose which data to use based on the taskFinal condition
    const dataToUse = taskFinal ? dataFinal : dataOpen;

    return (
        <>
            {process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' && !taskFinal && (
                <>
					<div className="col text-right d-flex justify-content-end">
						<MotifTextLink
                            href="#"
							onClick={() => setShowDateModal(true)}
						>
							Set dates (debug)
						</MotifTextLink>
					</div>
                    <DebugTaskDateModal showDateModal={showDateModal} setShowDateModal={setShowDateModal} taskStartDate={controlTask?.start_date} taskDeadline={controlTask?.deadline} taskType={'control'} taskId={controlTask?.id} />
				</>
            )}

            <div style={{ whiteSpace: "pre-wrap" }}>
                {Object.entries(dataToUse).map(([key, value]) => (
                    <ControlItem key={key} description={key} value={value} />
                ))}
            </div>

            <RiskDetails 
                scaleNumber={scaleNumber}
                riskData={currentRiskData} 
                isModalVisible={isRModalVisible} 
                setModalVisibility={setRModalVisibility} 
                hideEditButton={true}
            />
        </>
    )
}

export default ControlTaskDescription