import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

import './privacynotice.css';

import { MotifButton,
        MotifIcon,
        MotifHeader,
        MotifHeaderLogo } from '@ey-xd/motif-react'

        import { MotifEy } from '@ey-xd/motif-icon';

import { request } from '../../utils/request';

import { useTranslation } from 'react-i18next';
import { tooltitle } from '../../utils/utils';

const PrivacyNotice = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        // Frame-busting script
        if (window.top !== window.self) {
          window.top.location = window.self.location;
        }
        checkPrivacyNoticeAccepted();
    }, []);

    const checkPrivacyNoticeAccepted = async () => { // if the user visits the privacy notice page while it is already accepted, forward them.
        try {
            const response = await request.get('privacy-notice/');

            // Check if the response is empty and set defaults if needed
            const { accepted = false, is_superuser = false } = response[0] || {};
            
            if (accepted) {
                if (is_superuser) {
                    navigate('/clientoverview');
                } else {
                    navigate('/overview/');
                }
            }
        } catch (error) {
            console.error('Error checking privacy notice acceptance.');
        }
    }

    const logout = async (navigateToLogin) => {

        var response = await request.post('logout/', {}, 'POST')
    
        localStorage.clear()
        navigateToLogin && navigate('/login/')
    
      }

    const handleClick = async () => {

        try {
            const response = await request.post('privacy-notice/', {}, 'POST'); // empty POST, setting accepted to true is handled by the back-end. Denying the privacy notice simply logs the user out.
            
            if(response.is_superuser === true){
                navigate('/clientoverview');
            } else {
                navigate('/overview/');
            }
        } catch (error) {
            console.error(error.message);
            navigate('/overview/'); // despite privacy notice acceptance failing, let the user access the website since they clicked accept.
        }
    }

    return (
        <div className="content-column">
        <title>{t('privacy', {ns: 'menu'})} | {tooltitle}</title>
            <MotifHeader
                fixed={true}
                logo={
                    <MotifHeaderLogo>
                        <a href="/privacy_notice">
                            <MotifIcon alt='EY Logo' title='EY Logo' src={MotifEy} stroke={0} size="30" />
                        </a>
                    </MotifHeaderLogo>
                }
                appHeaderName="InControl Tool">
            </MotifHeader>
            <div className='row justify-content-center p-5 privacynotice'>
                <h1>
                    Privacy Notice - InControl Tool
                </h1>
                <h3>
                    1.	Introduction
                </h3>
                <p>
                    This Privacy Notice is intended to describe the practices EY follows in relation to the InControl Tool (“Tool”) with respect to the privacy of all individuals whose personal data is processed and stored in the Tool.
                </p>
                <h3>
                    2.	Who manages the Tool?
                </h3>
                <p>
                    “EY” refers to one or more of the member firms of Ernst & Young Global Limited (“EYG”), each of which is a separate legal entity and can act as a data controller in its own right. The entity that is acting as data controller by providing this Tool on which your personal data will be processed and stored is Ernst & Young Belastingadviseurs LLP. 
                </p>
                <p>
                    The Tool is hosted on servers in Europe. 
                </p>
                <h3>
                    3.	Why do we need your information? 
                </h3>
                <p>
                    The Tool keeps track of all tax risks in your company, including the controls and monitoring that are in place to manage those risks. 
                </p>
                <p>
                    Your personal data processed in the Tool is used as follows: data (such as first name, last name, email address, function, country you work in and the department you work in) is used to identify who is responsible for performing control and monitoring tasks and who will be reviewing those tasks. These tasks will be visible for the person responsible, the reviewer and stakeholders such as the company CEO.
                </p>
                <p>
                    EY relies on the following basis to legitimize the processing of your personal data in the Tool: Processing of your personal data is necessary for the purposes of the legitimate interests pursued by the data controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data. The specific legitimate interest(s) are complying with regulatory and legal obligations and quality & Risk Management.  
                </p>
                <p>
                    You have the right to object at any time, on grounds relating to your particular situation, to the processing of personal data concerning you based on the above legitimate interest(s).
                </p>
                <p>
                    The provision of your personal data to EY is optional. However, if you do not provide all or part of your personal data, we may be unable to carry out the purposes for processing. 
                </p>
                <h3>
                    4.	What type of personal data is processed in the Tool? 
                </h3>
                <p>
                    The Tool processes these personal data categories: 
                </p>
                <p>
                    For EY employees: personal data including: first name, last name and email address.
                </p>
                <p>
                    For non-EY employees: personal data including: first name, last name, email address, function, country you work in and the department you work in.
                </p>
                <p>
                    This data is sourced from: entries that will be made in the tool.
                </p>
                <h3>
                    5.	Sensitive Personal Data
                </h3>
                <p>
                    Sensitive personal data reveals your racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, data concerning health or data concerning sex life or sexual orientation.
                </p>
                <p>
                    “EY does not intentionally collect any sensitive personal data from you via the Tool. The Tool’s intention is not to process such information.”
                </p>
                <h3>
                    6.	Who can access your information?
                </h3>
                <p>
                    Your personal data is accessed in the Tool by the following persons/teams:
                </p>
                <p>
                    <ul>
                        <li>
                            EY NL tax team
                        </li>
                        <li>
                            The person reviewing your tasks
                        </li>
                        <li>
                            The tools administrator for your company
                        </li>
                        <li>
                            The CEO of your company
                        </li>
                        <li>
                            Other stakeholders 
                        </li>
                    </ul>
                </p>
                <p>
                    The access rights detailed above involves transferring personal data in various jurisdictions (including jurisdictions outside the European Union) in which EY operates (EY office locations are listed at www.ey.com/ourlocations). EY will process your personal data in the Tool in accordance with applicable law and professional regulations in your jurisdiction. Transfers of personal data within the EY network are governed by EY’s Binding Corporate Rules (www.ey.com/bcr).
                </p>
                <h3>
                    7.	Data retention
                </h3>
                <p>
                    The policies and/or procedures for the retention of personal data in the Tool are: 
                    <ul>
                        <li>
                            The total retention period is defined  by your companies administrator. 
                        </li>
                        <li>
                            Upon termination of the service agreement between your company and EY, aggregated and anonymised results are retained for 30 days to allow your companies administrator to download and archive this data. After this period, the backup data of such data will be kept for 90 days preceding which the data won't be recoverable. Data may also be deleted by your companies administrator at any time using the standard web interface.
                        </li>
                    </ul>
                </p>
                <p>
                    Your personal data will be retained in compliance with privacy laws and regulations. 
                </p>
                <p>
                    After the end of the data retention period, your personal data will be deleted. 
                </p>
                <h3>
                    8.  Cookies
                </h3>
                <p>
                    EY is committed to protecting your privacy and ensuring a smooth user experience. This section explains how we use cookies and similar technologies on our website.
                </p>
                <h2>
                    What are cookies?
                </h2>
                <p>
                Cookies are small text files that are placed on your device by websites that you visit. They are widely used to make websites work, or work more efficiently, as well as to provide information to the owners of the site.
                </p>
                <h2>
                    Necessary Cookies
                </h2>
                <p>
                    We use certain cookies that are essential for the proper operation of our website. These necessary cookies help us:
                    <ul>
                        <li>
                            Authenticate and manage your login sessions, allowing you to navigate between pages without having to log in repeatedly.
                        </li>
                        <li>
                            Retrieve and manage your saved documents in a secure manner.
                        </li>
                    </ul>                    
                    Without these cookies, key aspects of our website, such as the login functionality and document management system, will not function. As such, they cannot be switched off in our systems. They are usually set only in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in, or filling in forms.
                </p>
                <h2>
                    Your Privacy
                </h2>
                <p>
                    The operation of our website requires the use of these necessary cookies, and they do not track your browsing activity on other websites or collect information that is used for marketing purposes. Your consent is not required for the use of necessary cookies as they are fundamental to the services we provide. However, we believe it is important to inform you about their use.
                </p>
                <h3>
                    9.	Security
                </h3>
                <p>
                    EY is committed to making sure your personal data is secure. To prevent unauthorized access or disclosure, EY has technical and organizational measures to safeguard and secure your personal data. All EY personnel and third parties EY engages to process your personal data are obliged to respect your data’s confidentiality.
                </p>
                <h3>
                    10.	Controlling your personal data
                </h3>
                <p>
                    EY will not transfer your personal data to third parties (other than any external parties referred to in section 6 above) unless we have your permission or are required by law to do so.    
                </p>
                <p>
                    You are legally entitled to request details of EY’s personal data about you. 
                </p>
                <p>
                    To confirm whether your personal data is processed in the Tool or to access your personal data in the Tool, contact your usual EY representative or email your request to global.data.protection@ey.com.
                </p>
                <h3>
                    11.	Rectification, erasure, restriction of processing or data portability
                </h3>
                <p>
                    You can confirm your personal data is accurate and current. You can request rectification, erasure, restriction of processing or a readily portable copy of your personal data by contacting your usual EY representative or by sending an e-mail to global.data.protection@ey.com
                </p>
                <h3>
                    12.	Complaints
                </h3>
                <p>
                    If you are concerned about an alleged breach of privacy law or any other regulation, contact EY’s  Privacy Officer, Office Boompjes 258, 3011 XZ Rotterdam or via email at privacy.nl@nl.ey.com or via your usual EY representative. An EY Privacy Officer will investigate your complaint and provide information about how it will be handled and resolved.
                </p>
                <p>
                    If you are not satisfied with how EY resolved your complaint, you have the right to complain to your country’s data protection authority. You can also refer the matter to a court of competent jurisdiction.  
                </p>
                <h3>
                    13.	Contact us
                </h3>
                <p>
                    If you have additional questions or concerns, contact your usual EY representative or email  privacy.nl@nl.ey.com.
                </p>
                <MotifButton className='row justify-content-center mb-5'
                onClick={handleClick}
                >
                    I accept the privacy policy
                </MotifButton>
                <MotifButton variant='warn' className='row justify-content-center mb-5'
                onClick={() => logout(true)}
                >
                    Decline (log out)
                </MotifButton>
            </div>
        </div>
    ) 
}

export default PrivacyNotice