import React, {useState, useEffect } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import { useLoading } from '../../utils/loadingcontext';

import { MakeRequests } from '../../utils/request'

import { useTranslation } from 'react-i18next';

import FilterToggleSwitch from '../../components/FilterToggleSwitch';
import PerformControlTable from './PerformControlTable';
import { tooltitle } from '../../utils/utils';

const PerformControlOverviewPage = () => {
  const { t, i18n } = useTranslation();

  const { isLoading, setIsLoading } = useLoading();
  const { userRole, isSuperuser } = useOutletContext();
  const currentEmployeeName = localStorage.getItem('employee_name');

  const [overviewSwitch, setOverviewSwitch] = useState(true);

  const [controlTasks, setControlTasks] = useState();
  const [myControlTasks, setMyControlTasks] = useState();

  const navigate = useNavigate();

  const request = new MakeRequests()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const data = await request.get('control-task/', {})

      setControlTasks(data);
      setMyControlTasks(data.filter(control => control.responsible_fullname === currentEmployeeName || control.reviewer_fullname === currentEmployeeName));

      setIsLoading(false);
    };

    fetchData();
  }, []);


return (
  <>
  <title>{t('performcontroloverview', {ns: 'menu'})} | {tooltitle}</title>
  <div className="d-flex justify-content-center">
    <h1>{t('performcontroloverview', {ns: 'menu'})}</h1>
  </div>
    {userRole === 'Admin' && controlTasks?.length > 0 && (
      <FilterToggleSwitch switchName={'PerformControlOverviewPage'} overviewSwitch={overviewSwitch} setOverviewSwitch={setOverviewSwitch} />
    )}

    <PerformControlTable controlTasks={controlTasks} myControlTasks={myControlTasks} overviewSwitch={overviewSwitch} navigate={navigate} isLoading={isLoading} />
  </>
  )
}

export default PerformControlOverviewPage