import React, { useState } from 'react'

import { useTranslation } from 'react-i18next';

import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
} from '@ey-xd/motif-react';

import { request } from '../../../utils/request';
import { colors } from '../../../utils/utils';

const DeleteFunctionModal = ({
    isModalVisible,
    setModalVisibility,
    getFunctionTitles,
    functionTitleId,
    functionTitleName }) => {

    const { t } = useTranslation();

    const [inUseError, setInUseError] = useState(false);

    const deleteFunctionTitle = async () => {

        try {
            await request.delete(`new-function-entity/${functionTitleId}/`);
    
            // Close the modal and get function titles if the delete request was successful
            setModalVisibility(false);
            getFunctionTitles();
            
        } catch (error) {
            if (error.message === 'still_in_use') {
                // Do not close the modal , just set the error state
                setInUseError(true);
            } else {
                // Close the modal and get function titles if other types of errors occurs
                setModalVisibility(false);
                getFunctionTitles();
            }
        }
    }
    

    return (
        <>
            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>{t('function_title_remove', {ns: 'admin'})}</MotifModalHeader>
                <MotifModalBody>
                    <p>{t('modal_function_title_remove_confirm_body', {ns: 'admin'})}</p>
                    <p style={{fontWeight: 'bold', fontSize: '20px', textAlign: 'center'}}>{functionTitleName}</p>
                    <p style={{fontWeight: 'bold', color: colors.darkred, textAlign: 'center'}}>{inUseError ? t('modal_function_title_inuse_error', {ns: 'admin'}) : null}</p>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={() => { deleteFunctionTitle() }}>
                        {t('remove', {ns: 'general'})}
                    </MotifButton>
                    <MotifButton
                        size="medium"
                        variant="secondary"
                        type="button"
                        onClick={() => setModalVisibility(false)}
                    >
                        {t('cancel', {ns: 'general'})}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default DeleteFunctionModal