import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { request } from '../../utils/request';

import {
    MotifModal,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader,
    MotifTable,
    MotifIcon,
    MotifIconButton,
    MotifFormField,
    MotifSearch,
    MotifSelect,
    MotifOption,
    MotifLabel
} from '@ey-xd/motif-react';

import { contentIcAddCircleOutline24px } from '@ey-xd/motif-react/assets/icons';

const RiskList = ({ isModalVisible, setModalVisibility, isLoading, setIsLoading, setRisk, setProcess, setDescription }) => {

    const { t } = useTranslation();

    const [filteredData, setFilteredData] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const [language, setLanguage] = useState('1');
    const [selectedSubtheme, setSelectedSubtheme] = useState('');
    const [subthemeOptions, setSubthemeOptions] = useState([]);
    const [selectedProcess, setSelectedProcess] = useState('');
    const [processOptions, setProcessOptions] = useState([]);

    const [riskList, setRiskList] = useState([]);

    // Fetch risk list on component mount
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            if (localStorage.getItem('language') === 'nl') { // prefill language based on user preference
                setLanguage('1');
            } else if (localStorage.getItem('language') === 'en') {
                setLanguage('2');
            }

            try {
                const data = await request.get('risk-list');
                setRiskList(data);
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [setIsLoading]);

    // Update options based on language
    useEffect(() => {
        const filterSubthemesAndProcesses = () => {
            const uniqueSubthemes = new Set();
            const uniqueProcesses = new Set();

            riskList.forEach(item => {
                if (item.language.toString() === language) {
                    if (!selectedProcess || item.process === selectedProcess) {
                        uniqueSubthemes.add(item.subtheme_description);
                    }
                    if (!selectedSubtheme || item.subtheme_description === selectedSubtheme) {
                        uniqueProcesses.add(item.process);
                    }
                }
            });

            setSubthemeOptions([...uniqueSubthemes]);
            setProcessOptions([...uniqueProcesses]);

            // If the selected subtheme or process is no longer valid, reset it
            if (selectedSubtheme && !uniqueSubthemes.has(selectedSubtheme)) {
                setSelectedSubtheme('');
            }
            if (selectedProcess && !uniqueProcesses.has(selectedProcess)) {
                setSelectedProcess('');
            }
        };

        filterSubthemesAndProcesses();
    }, [riskList, language, selectedSubtheme, selectedProcess]);

    useEffect(() => {
        const filterData = () => {
            let results = [];

            const searchValueLower = searchValue.toLowerCase();

            riskList.forEach(item => {
                const matchesSearchValue =
                    !searchValue ||  // If no search value, consider it a match
                    (item.risk && item.risk.toLowerCase().includes(searchValueLower)) || // risk
                    (item.description && item.description.toLowerCase().includes(searchValueLower)) || // risk description
                    (item.process && item.process.toLowerCase().includes(searchValueLower)) || // process
                    (item.risk_level && item.risk_level.description && item.risk_level.description.toLowerCase().includes(searchValueLower)) || // risk level description
                    (item.strategy && item.strategy.description && item.strategy.description.toLowerCase().includes(searchValueLower)); // strategy description

                const matchesLanguage = item.language.toString() === language; // Language match is always required
                const matchesSubtheme = !selectedSubtheme || item.subtheme_description === selectedSubtheme; // Subtheme match if selected
                const matchesProcess = !selectedProcess || item.process === selectedProcess; // Process match if selected

                if (matchesSearchValue && matchesLanguage && matchesSubtheme && matchesProcess) {
                    results.push(item);
                }
            });

            setFilteredData(results);
        };

        filterData();
    }, [riskList, searchValue, language, selectedSubtheme, selectedProcess]);

    const columnDef = [
        {
            headerName: ' ',
            cellRenderer: (props) => {
                return (
                    <MotifIconButton
                        type="button"
                        // onClick={() => {
                        //     setRisk(props.data.risk);
                        //     setProcess(props.data.process);
                        //     setDescription(props.data.description);
                        //     setModalVisibility(false);
                        // }}>
                    >
                        <MotifIcon src={contentIcAddCircleOutline24px} />
                    </MotifIconButton>
                );
            },
            field: 'id',
            width: 60,
            pinned: 'left',
            headerComponentParams: {
                dataColumn: false,
                headerEndComponent: (
                    <MotifIconButton aria-label="Click here to see options" type="button">
                        <MotifIcon src={contentIcAddCircleOutline24px} />
                    </MotifIconButton>
                )
            }
        },
        {
            headerName: t('subtheme', { ns: 'general' }),
            field: 'subtheme_description',
            sortable: true,
            autoHeight: true,
            wrapText: true,
            flex: 1,
        },
        {
            headerName: t('process', { ns: 'general' }),
            field: 'process',
            sortable: true,
            autoHeight: true,
            wrapText: true,
            flex: 1,
        },
        {
            headerName: t('risk', { ns: 'general' }),
            field: 'risk',
            sortable: true,
            autoHeight: true,
            wrapText: true,
            flex: 2,
        },
        {
            headerName: t('description', { ns: 'general' }),
            field: 'description',
            sortable: true,
            autoHeight: true,
            wrapText: true,
            flex: 5,
        },
    ];

    return (
        <>
            <style jsx="true">{`
                .riskListModal {
                    min-width: 100%;
                }
            `}</style>
            <MotifModal className='riskListModal' show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>{t('risk_list', { ns: 'add_risk' })}</MotifModalHeader>
                <MotifModalBody>
                    <div className='row align-items-center mt-5'>
                        <div className="col-auto">
                            <MotifFormField style={{ width: "250px" }}>
                                <MotifLabel id="select-language">{t('risk_language', { ns: 'add_risk' })}</MotifLabel>
                                <MotifSelect
                                    value={language}
                                    onChange={(val) => { setLanguage(val); setSelectedSubtheme(''); setSelectedProcess(''); }}
                                    disabled={isLoading}
                                >
                                    <MotifOption key={1} value={'1'}>{t('dutch', { ns: 'general' })}</MotifOption>
                                    <MotifOption key={2} value={'2'}>{t('english', { ns: 'general' })}</MotifOption>
                                </MotifSelect>
                            </MotifFormField>
                        </div>
                        <div className="col-auto">
                            <MotifFormField style={{ width: "250px" }}>
                                <MotifLabel id="select-subtheme">{t('subtheme', { ns: 'general' })}</MotifLabel>
                                <MotifSelect
                                    value={selectedSubtheme}
                                    onChange={val => setSelectedSubtheme(val)}
                                    disabled={isLoading}
                                >
                                    <MotifOption key="" value="">{t('all_subthemes', { ns: 'add_risk' })}</MotifOption>
                                    {subthemeOptions.map((subtheme, index) => (
                                        <MotifOption key={index} value={subtheme}>{subtheme}</MotifOption>
                                    ))}
                                </MotifSelect>
                            </MotifFormField>
                        </div>
                        <div className="col-auto">
                            <MotifFormField style={{ width: "250px" }}>
                                <MotifLabel id="select-process">{t('process', { ns: 'general' })}</MotifLabel>
                                <MotifSelect
                                    value={selectedProcess}
                                    onChange={val => setSelectedProcess(val)}
                                    disabled={isLoading}
                                >
                                    <MotifOption key="" value="">{t('all_processes', { ns: 'add_risk' })}</MotifOption>
                                    {processOptions.map((process, index) => (
                                        <MotifOption key={index} value={process}>{process}</MotifOption>
                                    ))}
                                </MotifSelect>
                            </MotifFormField>
                        </div>
                        <div className="col-auto">
                            <MotifFormField>
                                <MotifLabel id="search-bar">{t('search', { ns: 'general' })}</MotifLabel>
                                <MotifSearch
                                    value={searchValue}
                                    id="search-risks"
                                    disabled={isLoading}
                                    items={[]} // items arg needs to be set or will have 'Warning: Maximum update depth exceeded' errors
                                    onChange={event => setSearchValue(event.target.value)}
                                    onEnter={event => setSearchValue(event.target.value)}
                                    aria-label="Search"
                                    placeholder={t('search', { ns: 'general' })}
                                />
                            </MotifFormField>
                        </div>
                    </div>
                    {!isLoading && riskList && filteredData ?
                        <div className='row justify-content-center mt-5'>
                            <MotifTable className='pb-5'
                                columnDefs={columnDef}
                                rowData={filteredData}
                                onRowClicked={row => {
                                    setRisk(row.data.risk);
                                    setProcess(row.data.process);
                                    setDescription(row.data.description);
                                    setModalVisibility(false);
                                }}
                                onCellKeyDown={row => {
                                    if (row.event.key === 'Enter') {
                                        setRisk(row.data.risk);
                                        setProcess(row.data.process);
                                        setDescription(row.data.description);
                                        setModalVisibility(false);
                                    }
                                }}
                                compact={true}
                                zebra={true}
                                pagination={filteredData.length > 30 ? true : false}
                                paginationPageSize={25}
                            />
                        </div>
                    : null}
                </MotifModalBody>
                <MotifModalFooter>
                </MotifModalFooter>
            </MotifModal>
        </>
    );
};

export default RiskList;