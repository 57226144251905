import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import { tooltitle } from '../../utils/utils';

import {
    MotifLabel,
    MotifFormField,
    MotifSelect,
    MotifOption,
    MotifButton,
    MotifModal,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader,
} from '@ey-xd/motif-react';

import { request } from '../../utils/request';

import DetailsItem from '../../components/DetailsItem';

const Account = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const [employee, setEmployee] = useState({})
    const [employeeLanguage, setEmployeeLanguage] = useState([]);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [status, setStatus] = useState(null);
    const [language, setLanguage] = useState(null);
    const [entity, setEntity] = useState(null);
    const [employeeFunction, setEmployeeFunction] = useState(null);

    const [isModalVisible, setModalVisibility] = useState(false)

    const [isMessageModalVisible, setMessageModalVisibility] = useState(false);
    const [message, setMessage] = useState(false);

    const [forceRenderKey, setForceRenderKey] = useState('');

    useEffect(() => {
        !!employeeLanguage.length && setForceRenderKey(crypto.randomUUID());
    }, [language, employeeLanguage]);

    const submit = async () => {

        setIsLoading(true);

        try {
            var response = await request.post(`employee-details/${employee.id}/`, {'language': language}, 'PATCH')
        } catch(error) {
            console.error(error.message)
        }

        setIsLoading(false);
        navigate('/login/')

    }
    
    useEffect(() => {
        setFirstName(employee.first_name);
        setLastName(employee.last_name);
        setEmail(employee.email);
        setStatus(employee.status);
        setLanguage(employee.language?.toString());
        setEntity(employee.entitynames);
        setEmployeeFunction(employee.function_title);
    }, [employee, employeeLanguage]);

    useEffect(() => {

        const fetchData = async () => {

            setEmployeeLanguage(await request.get('language/', {}));
            
            var employeeData = await request.get('employee-details/');
            setEmployee(employeeData[0])
            
            setIsLoading(false);

        };

        fetchData();

    }, []);

    const data = {
        [t('firstname', {ns: 'account'})]:firstName,
        [t('lastname', {ns: 'account'})]:lastName,
        [t('email', {ns: 'account'})]:email,
        [t('entity', {ns: 'general'})]:entity,
        [t('function_title', {ns: 'general'})]:employeeFunction,
        [t('status', {ns: 'general'})]:status
    }

    return (

        <div>
            <title>{t('account', {ns: 'menu'})} | {tooltitle}</title>
            {!isLoading && employeeLanguage && language ?
                <div className='row justify-content-center mt-5'>
                    <div className="col-8">

                        {Object.entries(data).map(([key, value]) => <DetailsItem description={key} value={value} />)}

                        <MotifFormField>
                            <MotifLabel id="select-language">{t('language', {ns: 'account'})}</MotifLabel>
                            <MotifSelect 
                                className="mb-2"
                                key={forceRenderKey} 
                                value={language}
                                onChange={val => setLanguage(val)} ariaLabelledBy="select-status">
                                {employeeLanguage.map(item => (<MotifOption key={item.id} value={item.id.toString()}>{item.language}</MotifOption>))}
                            </MotifSelect>
                        </MotifFormField>

                        <MotifButton size="medium" type="button" onClick={() => setModalVisibility(!isModalVisible)}>
                            {t('edit', {ns: 'general'})}
                        </MotifButton>

                    </div>

                </div> : null}

            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>{t('modal_confirm_header', {ns: 'account'})}</MotifModalHeader>
                <MotifModalBody>{t('modal_confirm_body', {ns: 'account'})}</MotifModalBody>
                <MotifModalFooter>
                    <MotifButton
                        variant="warn"
                        className="me-3"
                        disabled={isLoading}
                        onClick={() => { submit() }}>{t('confirm', {ns: 'general'})}</MotifButton>
                    <MotifButton
                        className="me-3"
                        disabled={isLoading}
                        onClick={() => setModalVisibility(false)}>{t('cancel', {ns: 'general'})}</MotifButton>
                </MotifModalFooter>
            </MotifModal>

            <MotifModal
                show={isMessageModalVisible}
                onClose={() => {
                    setMessageModalVisibility(false);
                    setMessage('');
                }}>
                <MotifModalHeader>{t('alert', {ns: 'general'})}</MotifModalHeader>
                <MotifModalBody>
                    {message}
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={() => {
                        setMessageModalVisibility(false);
                        setMessage('');
                    }}>
                        OK
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>

        </div>


    )
}

export default Account