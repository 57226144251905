import React, {useState, useEffect} from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import {
    MotifModal,
    MotifModalBody,
    MotifModalFooter,
    MotifModalHeader,
    MotifButton,
    MotifTextLink,

} from '@ey-xd/motif-react';

import { MakeRequests } from '../../utils/request';

import DetailsItem from '../../components/DetailsItem';
import RiskDetails from '../../views/risks/RiskDetails';

const ControlDetails = ({ controlData, isModalVisible, setModalVisibility, navigate, hideEditButton }) => {

    const { t } = useTranslation();
    const { userRole, isSuperuser } = useOutletContext();
    const { isLoading, setIsLoading } = useLoading();
    const request = new MakeRequests()

    const [scaleNumber, setScaleNumber] = useState(1);

    const [isRModalVisible, setRModalVisibility] = useState(false);

    const [currentRiskData, setCurrentRiskData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
          setIsLoading(true);
    
          var scaleData = await request.get('key-risk-data'); // fetch scale number for the risk pop up
          setScaleNumber(scaleData[0].scale.number);
  
          setIsLoading(false);
        };
    
        fetchData();
      }, []);

    let data = {
        [t('subtheme', {ns: 'general'})]: controlData.risk?.subtheme.description_code,
        [t('risk', {ns: 'general'})]: (
            <ul>
                    <li key={1}>
                        <MotifTextLink  
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault(); // prevent default link behavior
                                setCurrentRiskData(controlData.risk); // set the control data for modal
                                setRModalVisibility(true); // show the modal
                            }}
                        >
                            {controlData.risk?.risk}
                        </MotifTextLink>
                    </li>
            </ul>
        ),
        [t('control', {ns: 'general'})]: controlData.control,
        [t('description', {ns: 'general'})]: controlData.description,
        [t('proof', {ns: 'general'})]: controlData.proof,
        [t('net_risk', {ns: 'general'})]: `${controlData.net_risk} (${t('scale', { ns: 'general' })}: 1 - ${scaleNumber * scaleNumber})`,
        [t('frequency', {ns: 'general'})]: controlData.frequency?.description,
        [t('control_approach', {ns: 'general'})]: controlData.control_level?.description,
        [t('control_type', {ns: 'general'})]: controlData.control_type?.description,
        [t('entities', {ns: 'general'})]: (
            <ul>
                {controlData.entity?.map((entity, i) => (
                    <li key={i}>
                        {entity.name}
                    </li>
                ))}
            </ul>
        ),
        [t('responsible', {ns: 'general'})]: controlData.responsible_fullname ? controlData.responsible_fullname : [t('none', {ns: 'general'})],
        [t('reviewer', {ns: 'general'})]: controlData.reviewer_fullname ? controlData.reviewer_fullname : [t('none', {ns: 'general'})],
        [t('notify_on_failure', {ns: 'general'})]: controlData.notify_on_failure_fullname ? controlData.notify_on_failure_fullname : [t('none', {ns: 'general'})],
        [t('expiry', {ns: 'general'})]: controlData.expiry ? controlData.expiry : [t('none', {ns: 'general'})],
    }

    if (controlData.control_number) { // if has control_number, insert it to the first row of data
        data = {
            [t('control_number', {ns: 'general'})]: controlData.control_number,
            ...data
        };
    }
    return (
        <>
            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>Control details</MotifModalHeader>
                <MotifModalBody>
                    <div className='d-flex flex-column' style={{ whiteSpace: 'pre-wrap' }}>
                        {Object.entries(data).map(([key, value]) => <DetailsItem description={key} value={value} />)}
                    </div>


                </MotifModalBody>
                <MotifModalFooter>
                    { hideEditButton ? null : // hide the edit button when the pop up is opened from MonitoringTaskDescription
                    <>
                        {userRole === 'Admin' &&
                            <MotifButton
                            className="me-3"
                            onClick={() => navigate('/controls/add_control/', { state: { controlId: controlData.id } })}>{t('edit', {ns: 'general'})}</MotifButton>
                        }
                    </> 
                    }
                    <MotifButton
                        className="me-3"
                        onClick={() => setModalVisibility(false)}>{t('close', {ns: 'general'})}</MotifButton>
                </MotifModalFooter>
            </MotifModal>

            <RiskDetails 
                scaleNumber={scaleNumber}
                riskData={currentRiskData} 
                isModalVisible={isRModalVisible} 
                setModalVisibility={setRModalVisibility} 
                hideEditButton={true}
            />
        </>
    )
}

export default ControlDetails