import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../utils/loadingcontext';

import {
    MotifTextLink
} from '@ey-xd/motif-react';

import { isExpired } from '../utils/utils';

import { MakeRequests } from '../utils/request';
import RiskDetails from '../views/risks/RiskDetails';
import ControlItem from './ControlItem';
import ControlDetails from '../views/controls/ControlDetails';

import DebugTaskDateModal from './DebugTaskDateModal'; // allows changing individual task dates for debug purposes, hidden in production

const MonitoringTaskDescription = ( {monitorTask, taskFinal}) => {

  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useLoading();
  const request = new MakeRequests()
      
  const [isRModalVisible, setRModalVisibility] = useState(false);
  const [isCModalVisible, setCModalVisibility] = useState(false);

  const [currentRiskData, setCurrentRiskData] = useState({});
  const [currentControlData, setCurrentControlData] = useState({});

  const [scaleNumber, setScaleNumber] = useState(1);

  const [showDateModal, setShowDateModal] = useState(false); // for DebugTaskDateModal

    // If taskFinal is true, use data from 'snapshot.monitoring.controls' (note the different structure), otherwise from 'monitoring.controls'
    const controlSource = taskFinal
    ? monitorTask?.snapshot?.monitoring?.controls.map(item => item.control)
    : monitorTask?.monitoring?.controls;

    const uniqueSubthemes = [
    ...new Set(
      controlSource?.map(control => 
        control.risk?.subtheme?.description_code
      ).filter(description_code => description_code) || [] // Filter out undefined or null descriptions
    )
    ];
    const subthemes = uniqueSubthemes.join(', ');

    const uniqueRisks = Array.from(new Set(controlSource?.map(control => control.risk.id)))
      .map(id => {
        // Make sure to find the risk within the correct source (snapshot or active controls)
        const control = controlSource.find(control => control?.risk?.id === id);
        return control ? control.risk : null;
      })
      .filter(risk => risk != null);  // Filter out nulls in case a risk with the given ID was not found

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const dateObj = new Date(dateString);
        return [
          dateObj.getFullYear(),
          ('0' + (dateObj.getMonth() + 1)).slice(-2), // Ensure two digits
          ('0' + dateObj.getDate()).slice(-2)         // Ensure two digits
        ].join('-');
    };

    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);

        var scaleData = await request.get('key-risk-data'); // fetch scale number for the risk pop up
        setScaleNumber(scaleData[0].scale.number);

        setIsLoading(false);
      };

      fetchData();
    }, []);

    // Data structure for the open task
    const dataOpen = !taskFinal ? {
        ...(monitorTask?.monitoring?.monitoring_number && {
          [t('monitoring_number', { ns: 'general' })]: monitorTask?.monitoring?.monitoring_number,
        }),
        [t('subtheme', {ns: 'general'})]: subthemes,
        [t('risks', {ns: 'general'})]: (
            <ul>
                {uniqueRisks.map((risk, i) => (
                    <li key={i}>
                        <MotifTextLink  
                            href="#" 
                            onClick={(e) => {
                                e.preventDefault(); // prevent default link behavior
                                setCurrentRiskData(risk); // set the control data for modal
                                setRModalVisibility(true); // show the modal
                            }}
                        >
                            {risk.risk}
                        </MotifTextLink>
                    </li>
                ))}
            </ul>
        ),
        [t('controls', {ns: 'general'})]: (
            <ul>
              {monitorTask.monitoring?.controls?.map((control, i) => {
                // Check if the control is expired
                const expired = isExpired(control.expiry);
          
                return (
                  <li key={i}>
                    <MotifTextLink  
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault(); // Prevent default link behavior
                        setCurrentControlData(control); // Set the control data for modal
                        setCModalVisibility(true); // Show the modal
                      }}
                    >
                      <span style={expired ? { textDecoration: 'line-through' } : {}}>
                        {control.control}
                      </span>
                      {expired && (
                        <span>&nbsp;({t('expired_on', {ns: 'general'})} {formatDate(control.expiry)})</span>
                      )}
                    </MotifTextLink>
                  </li>
                );
              })}
            </ul>
        ),
        [t('monitoring', {ns: 'general'})]: monitorTask.monitoring?.monitoring,
        [t('monitoring_desc', {ns: 'general'})]: monitorTask.monitoring?.monitoring_desc,
        [t('proof', {ns: 'general'})]: monitorTask.monitoring?.proof,
        [t('entities', { ns: 'general' })]: (
          <ul>
            {(monitorTask?.monitoring?.unique_entities || []).map((entity, i) => (
              <li key={i}>
                {entity.name}
              </li>
            ))}
          </ul>
        ),
        [t('monitoring_type', {ns: 'general'})]: monitorTask?.monitoring?.monitoring_type?.description,
        [t('frequency', {ns: 'general'})]: monitorTask?.monitoring?.frequency?.description,
        [t('responsible', {ns: 'general'})]: 
            taskFinal
            ? (monitorTask?.archived_responsible_fullname || monitorTask?.archived_function_responsible || t('none', {ns: 'general'}))
            : (monitorTask?.responsible_fullname || t('none', {ns: 'general'})),
            // If the task is marked as final (completed), display the responsible person's name as it was at the time of task completion. 
            // If there's no employee data available, fall back to the recorded function title. 
            // If none are available, display translated text for "none".

        [t('reviewer', {ns: 'general'})]:
            taskFinal
            ? (monitorTask?.archived_reviewer_fullname || monitorTask?.archived_function_reviewer || t('none', {ns: 'general'}))
            : (monitorTask?.reviewer_fullname || t('none', {ns: 'general'})),
            // Apply similar logic for the reviewer.
        [t('notify_on_failure', {ns: 'general'})]:
            taskFinal
            ? (monitorTask?.archived_notify_on_failure_fullname || monitorTask?.archived_function_notifyonfailure || t('none', {ns: 'general'}))
            : (monitorTask?.notify_on_failure_fullname || t('none', {ns: 'general'})),
            // Apply similar logic for the notify on failure contact.
        [t('startdate', {ns: 'general'})]: monitorTask?.start_date,
        [t('deadline', {ns: 'general'})]: monitorTask?.deadline,
        [t('status', {ns: 'general'})]: monitorTask.status?.description
    } : {};

    // Data structure for the completed task
    const dataFinal = taskFinal ? {
      ...(monitorTask?.monitoring?.monitoring_number && {
        [t('monitoring_number', { ns: 'general' })]: monitorTask?.snapshot?.monitoring?.monitoring_number,
      }),
      [t('subtheme', {ns: 'general'})]: subthemes,
      [t('risks', {ns: 'general'})]: (
          <ul>
              {uniqueRisks.map((risk, i) => (
                  <li key={i}>
                      <MotifTextLink  
                          href="#" 
                          onClick={(e) => {
                              e.preventDefault(); // prevent default link behavior
                              setCurrentRiskData(risk); // set the control data for modal
                              setRModalVisibility(true); // show the modal
                          }}
                      >
                          {risk.risk}
                      </MotifTextLink>
                  </li>
              ))}
          </ul>
      ),
      [t('controls', {ns: 'general'})]: (
        <ul>
          {monitorTask?.snapshot?.monitoring?.controls.map(item => item.control).map((control, i) => {
            // Check if the control is expired
            const expired = isExpired(control.expiry);
      
            return (
              <li key={i}>
                <MotifTextLink  
                  href="#" 
                  onClick={(e) => {
                    e.preventDefault(); // Prevent default link behavior
                    setCurrentControlData(control); // Set the control data for modal
                    setCModalVisibility(true); // Show the modal
                  }}
                >
                  <span style={expired ? { textDecoration: 'line-through' } : {}}>
                    {control.control}
                  </span>
                  {expired && (
                    <span>&nbsp;({t('expired_on', {ns: 'general'})} {formatDate(control.expiry)})</span>
                  )}
                </MotifTextLink>
              </li>
            );
          })}
        </ul>
    ),
      [t('monitoring', {ns: 'general'})]: monitorTask?.snapshot?.monitoring.monitoring,
      [t('monitoring_desc', {ns: 'general'})]: monitorTask?.snapshot?.monitoring.monitoring_desc,
      [t('proof', {ns: 'general'})]: monitorTask?.snapshot?.monitoring.proof,
      [t('entities', { ns: 'general' })]: (
        <ul>
          {(monitorTask.unique_entities || []).map((entity, i) => (
            <li key={i}>
              {entity.name}
            </li>
          ))}
        </ul>
      ),  
      [t('monitoring_type', {ns: 'general'})]: monitorTask?.snapshot?.monitoring?.monitoring_type?.description,
      [t('frequency', {ns: 'general'})]: monitorTask?.snapshot?.monitoring?.frequency?.description,
      [t('responsible', {ns: 'general'})]: 
          taskFinal
          ? (monitorTask?.archived_responsible_fullname || monitorTask?.archived_function_responsible || t('none', {ns: 'general'}))
          : (monitorTask?.responsible_fullname || t('none', {ns: 'general'})),
          // If the task is marked as final (completed), display the responsible person's name as it was at the time of task completion. 
          // If there's no employee data available, fall back to the recorded function title. 
          // If none are available, display translated text for "none".

      [t('reviewer', {ns: 'general'})]:
          taskFinal
          ? (monitorTask?.archived_reviewer_fullname || monitorTask?.archived_function_reviewer || t('none', {ns: 'general'}))
          : (monitorTask?.reviewer_fullname || t('none', {ns: 'general'})),
          // Apply similar logic for the reviewer.
      [t('notify_on_failure', {ns: 'general'})]:
          taskFinal
          ? (monitorTask?.archived_notify_on_failure_fullname || monitorTask?.archived_function_notifyonfailure || t('none', {ns: 'general'}))
          : (monitorTask?.notify_on_failure_fullname || t('none', {ns: 'general'})),
          // Apply similar logic for the notify on failure contact.
      [t('startdate', {ns: 'general'})]: monitorTask?.start_date,
      [t('execution_date', {ns: 'general'})]: monitorTask?.execution_date.substring(0, 10),
      [t('deadline', {ns: 'general'})]: monitorTask?.deadline,
      [t('fiscal_year', {ns: 'general'})]: monitorTask?.fiscal_year,
      [t('status', {ns: 'general'})]: monitorTask.status?.description,
      [t('deviations_found', {ns: 'general'})]: monitorTask?.deviations ? [t('yes', {ns: 'general'})] : [t('no', {ns: 'general'})],
      [t('monitoring_rating', {ns: 'perform_monitoring'})]: monitorTask?.rating,
  } : {};

    // Choose which data to use based on the taskFinal condition
    const dataToUse = taskFinal ? dataFinal : dataOpen;

    return (
        <>
          {process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' && !taskFinal && (
                <>
					<div className="col text-right d-flex justify-content-end">
						<MotifTextLink
              href="#"
							onClick={() => setShowDateModal(true)}
						>
							Set dates (debug)
						</MotifTextLink>
					</div>
          <DebugTaskDateModal showDateModal={showDateModal} setShowDateModal={setShowDateModal} taskStartDate={monitorTask?.start_date} taskDeadline={monitorTask?.deadline} taskType={'monitoring'} taskId={monitorTask?.id} />
				  </>
            )}

          <div style={{ whiteSpace: "pre-wrap" }}>
            {
                Object.entries(dataToUse).map(([key, value], i) => <ControlItem key={i} description={key} value={value} />)
            }
          </div>

        <RiskDetails 
            scaleNumber={scaleNumber}
            riskData={currentRiskData} 
            isModalVisible={isRModalVisible} 
            setModalVisibility={setRModalVisibility} 
            hideEditButton={true}
            />

        <ControlDetails 
            controlData={currentControlData} 
            isModalVisible={isCModalVisible} 
            setModalVisibility={setCModalVisibility} 
            hideEditButton={true}
            />
        </>
    )
}

export default MonitoringTaskDescription