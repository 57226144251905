class Employee{

  constructor() {

    this.authenticated = false;
    this.first_name = null;
    this.last_name = null;
    this.language = null;
  
  }

  set (data){

    this.authenticated = true;
    this.first_name = data['first_name']
    this.last_name = data['last_name']
    this.language = data['language']

  }

  clear () {

    this.authenticated = false;
    this.first_name = null;
    this.last_name = null;
    this.language = null;

  }

}

const convertMonthDay = (number) => {

  if (number.toString().length == 1) {
      number = `0${number}`
  }

  return number.toString()

}

export const formatDate = (date) => {

  var day = convertMonthDay(date.getDate())
  var month = convertMonthDay(date.getMonth() + 1)

  var newDate = `${date.getFullYear()}-${month}-${day}`
  return newDate
}

export const employee = new Employee();

export const colors = {
    darkred: '#b9251c', // Red 600 (alias: $error)
    red: '#ff4136', // Red 400
    orange: '#ff810a', // Orange 300
    yellow: '#ffe600', // Yellow 400
    lightgreen: '#189d3e', // Green 500
    green: '#168736', // Green 600 (alias: $success)
    darkgreen: '#13652a', // Green 700
    darkgrey: '#23232F', // Grey 600
    lightgrey: '#fafafc', // Grey 50
    grey:'#C4C4CD' // Grey 300
  };

export const tooltitle = 'InControl'

  export const isExpired = (expiry) => {
    if (!expiry) return false; // If there's no expiry date, it's not expired.
    const expiryDate = new Date(expiry);
    const currentDate = new Date();
    return expiryDate < currentDate; // Return true if expired.
  };

  export const isDeleted = (deleted) => {
    return deleted; // Returns true if the control is marked as deleted.
  };  

  // For tasks that are in draft state and have attachments added. Adjusts the saved attachments format so they can be shown with the Motif File Uploader and can be deleted
  export const adjustFileData = (attachments) => {
    return attachments.map(att => ({
      id: att.id,
      filename: att.name,
      path: att.path,
    }));
  };

  export const calculateFiscalYear = (clientFiscalYear, deadline) => {
    if (!clientFiscalYear || !clientFiscalYear.start_month) return;
    const deadlineDate = new Date(deadline);
    const fiscalYearStart = new Date(deadlineDate.getFullYear(), clientFiscalYear.start_month - 1, clientFiscalYear.start_day);
    let fiscalYearEnd = new Date(fiscalYearStart);
    fiscalYearEnd.setFullYear(fiscalYearEnd.getFullYear() + 1);
  
    // Adjust for fiscal years that end in the year before they start
    if (clientFiscalYear.end_month < clientFiscalYear.start_month ||
      (clientFiscalYear.end_month === clientFiscalYear.start_month && clientFiscalYear.end_day < clientFiscalYear.start_day)) {
      fiscalYearEnd.setFullYear(fiscalYearEnd.getFullYear() - 1);
    }
  
    // Standard calendar year check
    const isStandardCalendarYear = clientFiscalYear.start_month === 1 && clientFiscalYear.start_day === 1 &&
                                    clientFiscalYear.end_month === 12 && clientFiscalYear.end_day === 31;
  
    let fiscalYearStr;
  
    if (isStandardCalendarYear) {
      // If it's a standard calendar year, always use the YYYY format
      fiscalYearStr = deadlineDate.getFullYear().toString();
    } else {
      // For non-standard fiscal years, determine the correct fiscal year range
      if (fiscalYearStart <= deadlineDate && deadlineDate < fiscalYearEnd) {
        fiscalYearStr = `${fiscalYearStart.getFullYear()}/${fiscalYearEnd.getFullYear()}`;
      } else if (deadlineDate < fiscalYearStart) {
        fiscalYearStr = `${fiscalYearStart.getFullYear() - 1}/${fiscalYearStart.getFullYear()}`;
      } else {
        fiscalYearStr = `${fiscalYearEnd.getFullYear()}/${fiscalYearEnd.getFullYear() + 1}`;
      }
    }
  
    return fiscalYearStr;
  };

  export const myeyMessages = {
    'create_success': 'myey_create_success', // myey account succesfully created and added to the tool
    'create_fail': 'myey_create_fail', // myey account creation failed
    'patch_success': 'myey_patch_success', // existing myey account succesfully added to the tool
    'patch_fail': 'myey_patch_fail', // adding existing myey account to the tool failed
    'disconnect_success': 'myey_disconnect_success', // disconnecting MyEY account from the tool successful
    'disconnect_fail': 'myey_disconnect_fail', // disconnecting MyEY account from the tool failure
    'user_notfound': 'myey_user_notfound', // provided e-mail address could not be found in myey
    'lookup_fail': 'myey_lookup_fail', // failed to connect with myey to look up a user
    'other_error': 'myey_other_error' // unknown error
  };
  
  
// Regex for general input fields - forbids characters not in the following set:
// Latin-1 Supplement and Latin Extended-A characters, word characters (letters, digits, underscore), whitespace, commas, periods, single quotes, and hyphens.
// Equivalent of the general_validator in the back-end
const generalExcludedCharacters = /[^\u00C0-\u017F\w\s,.'\-]+/g;

export const validateGeneralInput = (inputValue) => {
    const isValid = generalExcludedCharacters.test(inputValue); // Check if input contains forbidden characters
    const cleanedValue = inputValue.replaceAll(generalExcludedCharacters, ''); // Replace forbidden characters with an empty string
    return { cleanedValue, isValid };
};

// Regex for description fields - forbids characters not in the following set:
// Latin-1 Supplement and Latin Extended-A characters, word characters (letters, digits, underscore), whitespace, commas, periods, single quotes, hyphens, @, $, €, %, (, ), :, ;, &, /.
// This allows more flexibility for description fields where currency symbols and special characters are often needed.
// Equivalent of the description_validator in the back-end
const descriptionExcludedCharacters = /[^\u00C0-\u017F\w\s,.'\-@$€%():;&/]+/g;

export const validateDescriptionInput = (inputValue) => {
    const isValid = descriptionExcludedCharacters.test(inputValue);
    const cleanedValue = inputValue.replaceAll(descriptionExcludedCharacters, '');
    return { cleanedValue, isValid };
};

// Regex for comment fields - forbids characters not in the following set:
// Latin-1 Supplement and Latin Extended-A characters, word characters (letters, digits, underscore), whitespace, commas, periods, single quotes, hyphens, @, $, €, %, (, ), :, ;, &, /, !, ?, *.
// This provides the most flexibility for comment fields where conversational input is common.
// Equivalent of the comment_validator in the back-end
const commentExcludedCharacters = /[^\u00C0-\u017F\w\s,.'\-@$€%()!?*:;&/]+/g;

export const validateCommentInput = (inputValue) => {
    const isValid = commentExcludedCharacters.test(inputValue);
    const cleanedValue = inputValue.replaceAll(commentExcludedCharacters, '');
    return { cleanedValue, isValid };
};

// Function that validates email inputs according to the RFC 5322 standard
// Regex validates email addresses by ensuring the local part contains allowed characters (letters, digits, and special characters), 
// followed by an @ symbol, and a domain part with labels separated by dots, ending with a top-level domain of at least two letters.
// Equivalent of the email_validator in the back-end
export const validateEmailInput = (inputValue) => {
  
  const emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

  // Check if input is a valid email address
  const isNotValid = !emailRegex.test(inputValue);
  return { isNotValid };
};

// Function to sanitize filenames based on general input validation rules
export const sanitizeFilename = (filename) => {
  // Replace spaces with hyphens
  let sanitizedFilename = filename.replace(/\s+/g, '-');

  // Remove any forbidden characters
  sanitizedFilename = sanitizedFilename.replace(generalExcludedCharacters, '');

  return sanitizedFilename;
};