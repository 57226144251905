import React, { useState, useEffect } from 'react';

import FilterToggleSwitch from '../../components/FilterToggleSwitch';

const OverviewSwitch = ({ isLoading, originalTasks, tasks, setTasks, setSelectedTasks, overviewSwitch, setOverviewSwitch, completed }) => {

    const currentEmployeeName = localStorage.getItem('employee_name');
    
    useEffect(() => {
        if (!overviewSwitch) {
            const newTasks = {
                controls: tasks.controls?.filter(task => {
                    const responsibleName = completed ? task.archived_responsible_fullname : task.responsible_fullname;
                    const reviewerName = completed ? task.archived_reviewer_fullname : task.reviewer_fullname;
                    return responsibleName === currentEmployeeName || reviewerName === currentEmployeeName;
                }),
                monitoring: tasks.monitoring?.filter(task => {
                    const responsibleName = completed ? task.archived_responsible_fullname : task.responsible_fullname;
                    const reviewerName = completed ? task.archived_reviewer_fullname : task.reviewer_fullname;
                    return responsibleName === currentEmployeeName || reviewerName === currentEmployeeName;
                })
            };
            setTasks(newTasks);
            setSelectedTasks(newTasks);
        } else {
            setTasks(originalTasks);  // Reset to original, unfiltered tasks
            setSelectedTasks(originalTasks);
        }
    }, [overviewSwitch, originalTasks, completed]);
    
    return (
        <FilterToggleSwitch switchName={'OverviewSwitch'} isLoading={isLoading} overviewSwitch={overviewSwitch} setOverviewSwitch={setOverviewSwitch} />
    );
}

export default OverviewSwitch;