import React from 'react';


const EmployeeDetails = () => {
  return (
    <>
    <div className="d-flex flex-column align-items-center m-2 p-2"
         style={{color: '#e1e1e6', borderBottom: '0.5px solid #f6f6fa'}}>
          <h4 style={{fontWeight: 'bold'}}>{localStorage.getItem('client')}</h4>
        <h5 className='mb-3'>{localStorage.getItem('employee_name')}</h5>
        <h6 className='mb-3'>{localStorage.getItem('function_title')}</h6>
    </div>
    </>
  )
}

export default EmployeeDetails