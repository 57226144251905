import React, { useState, useEffect } from 'react'

import { useNavigate, ScrollRestoration, useOutletContext } from 'react-router-dom'

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import {
	MotifTable,
	MotifButton,
	MotifIconButton,
	MotifIcon,
	MotifToggleSwitch,
	MotifFormField,
	MotifSearch
} from '@ey-xd/motif-react'

import {
	hardwareIcKeyboardArrowDown24px,
	actionIcAssignment24px,
} from '@ey-xd/motif-react/assets/icons';

import { MakeRequests } from '../../utils/request'
import { isExpired } from '../../utils/utils';

import FilterToggleSwitch from '../../components/FilterToggleSwitch';
import ControlDetails from './ControlDetails';
import { tooltitle } from '../../utils/utils';

const ControlOverview = () => {

	const { t } = useTranslation();
	const { userRole, isSuperuser } = useOutletContext();
	const { isLoading, setIsLoading } = useLoading();
	const currentEmployeeName = localStorage.getItem('employee_name');

	const [overviewSwitch, setOverviewSwitch] = useState(true);
	const [missingFieldsSwitch, setMissingFieldsSwitch] = useState(false);
	const [expiredSwitch, setExpiredSwitch] = useState(false);

	const [controls, setControls] = useState();
	const [myControls, setMyControls] = useState();
	const [expiredControls, setExpiredControls] = useState();
	const [hasControlNumber, setHasControlNumber] = useState(false);
	const [hasReviewer, setHasReviewer] = useState(false);

	const [controlsWithMissingFields, setControlsWithMissingFields] = useState([])

	const [controlData, setControlData] = useState({});
	const [filteredData, setFilteredData] = useState({ items: [], myItems: [] });
	const [searchValue, setSearchValue] = useState('');

	const [isControlDetailsVisible, setControlDetailsVisibility] = useState(false)
	const navigate = useNavigate();

	const request = new MakeRequests()

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoading(true);
				const data = await request.get('control/', {});

				// Filter non-expired controls separately for general and user-specific controls.
				const nonExpiredControls = data.filter(control => !isExpired(control.expiry));
				const myFilteredControls = nonExpiredControls.filter(control =>
					control.responsible_fullname === currentEmployeeName ||
					control.reviewer_fullname === currentEmployeeName
				);

				// Filter expired controls.
				const expiredControlData = data.filter(control => isExpired(control.expiry));

				// Set states.
				setControls(nonExpiredControls);
				setMyControls(myFilteredControls);
				setExpiredControls(expiredControlData);

				setIsLoading(false);
			} catch (error) {
				console.error(error.message);
			}
		};

		fetchData();
	}, []);

	const doesItemMatchSearch = (item, searchValue) => {
		const entityMatches = item.entity && item.entity.some(entity => {
			return entity.name && entity.name.toLowerCase().includes(searchValue);
		});

		return (
			(item.risk && item.risk.risk && item.risk.risk.toLowerCase().includes(searchValue)) || // risk
			(item.risk && item.risk.subtheme && item.risk.subtheme.description_code && item.risk.subtheme.description_code.toLowerCase().includes(searchValue)) || // subtheme
			(item.risk && item.risk.subtheme && item.risk.subtheme.theme && item.risk.subtheme.theme.description && item.risk.subtheme.theme.description.toLowerCase().includes(searchValue)) || // theme
			(item.control && item.control.toLowerCase().includes(searchValue)) || // control
			(item.control_number && item.control_number.toLowerCase().includes(searchValue)) || // control number
			(item.frequency && item.frequency.description && item.frequency.description.toLowerCase().includes(searchValue)) || // frequency
			(item.control_type && item.control_type.description && item.control_type.description.toLowerCase().includes(searchValue)) || // control type
			(item.control_level && item.control_level.description && item.control_level.description.toLowerCase().includes(searchValue)) || // control level
			(item.responsible_fullname && item.responsible_fullname.toLowerCase().includes(searchValue)) || // responsible
			(item.reviewer_fullname && item.reviewer_fullname.toLowerCase().includes(searchValue)) || // reviewer
			(item.notify_on_failure_fullname && item.notify_on_failure_fullname.toLowerCase().includes(searchValue)) || // notify on failure
			(item.expiry && item.expiry.toLowerCase().includes(searchValue)) || // expiry date
			entityMatches // entities
		);
	};

	const filterData = (inputValue) => {
		setSearchValue(inputValue);
		let allResults = []; // For items accessible by the whole team
		let myResults = []; // For items specific to the admin user

		if (inputValue.length > 0) {
			const searchValue = inputValue.toLowerCase(); // Convert once and use throughout

			// Filter all controls
			controls.forEach(item => {
				if (doesItemMatchSearch(item, searchValue)) {
					allResults.push(item);
				}
			});

			// Filter admin's controls (myControls)
			myControls.forEach(item => {
				if (doesItemMatchSearch(item, searchValue)) {
					myResults.push(item);
				}
			});

			// Set filtered data for both controls and myControls
			setFilteredData({
				items: allResults,
				myItems: myResults
			});

		} else {
			// Reset to the whole list when search input is cleared
			setFilteredData({ items: [], myItems: [] });
		}
	};

	const getTableData = () => {
		if (missingFieldsSwitch && !expiredSwitch) {
			return searchValue.length > 0 ? filteredData.myItems : controlsWithMissingFields;
		} else if (expiredSwitch) {
			return searchValue.length > 0 ? filteredData.myItems : expiredControls;
		} else if (overviewSwitch) {
			// Toggle between "overview" and "my" views
			return searchValue.length > 0 ? filteredData.items : controls;
		} else {
			return searchValue.length > 0 ? filteredData.myItems : myControls;
		}
	}

	useEffect(() => { // check if any control has a control number and reviewer, if not, hide the table column
		const someControlNumberExists = controls?.some(control => control?.control_number !== undefined && control?.control_number !== null && control?.control_number.length > 0);
		const someReviewerExists = controls?.some(control => control?.reviewer !== undefined && control?.reviewer !== null);
		setHasControlNumber(someControlNumberExists);
		setHasReviewer(someReviewerExists);
	}, [controls]);

	useEffect(() => {
		if (userRole === 'Admin') {
			/* Filter controls which has missing required fields */
			const filteredControls = controls?.filter(control => {
				/* Check if 'responsible', 'notify_on_failure' fields are null. Also, if 'has_reviewer' is true, 
				   check if 'reviewer' is null. */
				return control.responsible === null || control.notify_on_failure === null ||
					(control.has_reviewer && control.reviewer === null);
			}) || [];

			const controlsWithMissingFields = filteredControls.map((control) => ({
				...control,
				missingFields: ['responsible', 'notify_on_failure', 'reviewer'].filter(fieldName => control[fieldName] === null).map(fieldName => t(fieldName, { ns: 'general' })),
			}));

			setControlsWithMissingFields(controlsWithMissingFields);
		}
	}, [controls]);

	const columnDef = [
		{
			headerName: ' ',
			cellRenderer: (props) => {

				return <MotifIconButton
					type="button"
					title={t('tablebutton', { ns: 'add_control' })}>
					<MotifIcon src={actionIcAssignment24px} />
				</MotifIconButton>

			},
			field: 'id',
			width: 60,
			pinned: 'left',
			headerComponentParams: {
				dataColumn: false,
				headerEndComponent: (
					<MotifIconButton aria-label="Click here to see options" type="button">
						<MotifIcon src={hardwareIcKeyboardArrowDown24px} />
					</MotifIconButton>
				)
			}
		},
		{
			headerName: '#',
			field: 'control_number',
			minWidth: 100,
			resizable: true,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			hide: !hasControlNumber,
			flex: 1,
		},
		{
			headerName: t('missing_fields', { ns: 'general' }),
			field: 'missingFields',
			minWidth: 100,
			sortable: true,
			filter: true,
			flex: 2,
			hide: !(missingFieldsSwitch && controlsWithMissingFields.length > 0)
		},
		{
			headerName: t('expiry', { ns: 'general' }),
			field: 'expiry',
			minWidth: 100,
			sortable: true,
			filter: true,
			flex: 2,
			hide: !(expiredSwitch && expiredControls.length > 0)
		},
		{
			headerName: t('subtheme', { ns: 'general' }),
			field: 'risk.subtheme.description_code',
			minWidth: 100,
			resizable: true,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('risk', { ns: 'general' }),
			field: 'risk.risk',
			minWidth: 140,
			sortable: true,
			resizable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('control', { ns: 'general' }),
			field: 'control',
			minWidth: 140,
			sortable: true,
			filter: true,
			wrapText: true,
			resizable: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('frequency', { ns: 'general' }),
			field: 'frequency.description',
			minWidth: 100,
			sortable: true,
			filter: true,
			hide: missingFieldsSwitch,
			flex: 1,
		},
		{
			headerName: t('responsible', { ns: 'general' }),
			field: 'responsible_fullname',
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			hide: !overviewSwitch,
			flex: 1,
		},
		{
			headerName: t('reviewer', { ns: 'general' }),
			field: 'reviewer_fullname',
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			hide: !hasReviewer || !overviewSwitch,
			flex: 1,
		},
	];

	return (
		<>
			<title>{t('controls', { ns: 'menu' })} | {tooltitle}</title>
			<div className="d-flex justify-content-center">
				<h1>{t('controls', { ns: 'menu' })}</h1>
			</div>
			{userRole === 'Admin' && controls?.length > 0 && !missingFieldsSwitch && !expiredSwitch && (
				<FilterToggleSwitch switchName={'ControlOverview'} isLoading={isLoading} overviewSwitch={overviewSwitch} setOverviewSwitch={setOverviewSwitch} />
			)}

			<div className="row justify-content-center">
				<div className="col-8">
					<MotifFormField>
						<MotifSearch
							value={searchValue}
							onClear={event => filterData('')}
							id="search-controls"
							onEnter={event => filterData(event.target.value)}
							onSelect={val => setSearchValue(val)}
							aria-label="Search in controls"
							placeholder={t('search2', { ns: 'general' })}
							disabled={isLoading} />
					</MotifFormField>
				</div>
			</div>

			{userRole === 'Admin' &&
				<div className="row ms-2 mt-3">
					<div className="col-2 m-1">
						<MotifButton
							onClick={() => navigate('/controls/add_control/', { state: { controlId: null } })}
							disabled={isLoading}
							className="m-2"
						>
							{t('add_control', { ns: 'general' })}
						</MotifButton>
						{(userRole === 'Admin' && controlsWithMissingFields.length) ?
							<MotifToggleSwitch
								offLabel=''
								onLabel={t('missing_field_switch', { ns: 'add_control' })}
								checked={missingFieldsSwitch}
								onChange={event => {
									setMissingFieldsSwitch(event.target.checked);
									setExpiredSwitch(false);
									setOverviewSwitch(true);
								}}
								id="toggle2"
							/>
							: null}
					</div>
					<div class="col-auto ms-auto m-1">
						{(userRole === 'Admin' && expiredControls?.length > 0) ?
							<MotifToggleSwitch
								offLabel={t('expired_switch_off', { ns: 'add_control' })}
								onLabel={t('expired_switch_on', { ns: 'add_control' })}
								checked={expiredSwitch}
								onChange={event => {
									setExpiredSwitch(event.target.checked);
									setMissingFieldsSwitch(false);
									setOverviewSwitch(true);
								}}
								id="toggle3"
							/>
							: null}
					</div>
				</div>
			}

			<div className="row justify-content-center mx-2 mt-3">
				<div className="col">
					{controls && myControls ?
						<MotifTable className='pb-5'
							columnDefs={columnDef}
							rowData={getTableData()}
							onRowClicked={row => { setControlData(row.data); setControlDetailsVisibility(!isControlDetailsVisible); }}
							onCellKeyDown={row => {
								if (row.event.key === 'Enter') {
									setControlData(row.data);
									setControlDetailsVisibility(!isControlDetailsVisible);
								}
							}}
							compact={true}
							zebra={true}
							pagination={getTableData()?.length > 30}
							paginationPageSize="25"
						/>
						: null}
				</div>
			</div>
			<ScrollRestoration />

			<ControlDetails controlData={controlData} isModalVisible={isControlDetailsVisible} setModalVisibility={setControlDetailsVisibility} navigate={navigate} hideEditButton={false} />
		</>
	)
}

export default ControlOverview