import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import {
    MotifFormField,
    MotifLabel,
    MotifInput,
    MotifButton,
    MotifHeader,
    MotifHeaderLogo,
    MotifIcon, 
    MotifModal,
    MotifModalHeader,
    MotifModalFooter,
    MotifModalBody,
    MotifProgressLoader, 
    MotifTextLink
} from '@ey-xd/motif-react';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import { MotifEy } from '@ey-xd/motif-icon';

import { request } from '../../utils/request';
import { tooltitle } from '../../utils/utils';

const LoginPage = () => {

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const error = queryParams.get('error');

    const [userName, setUserName] = useState('');

    const [isMessageModalVisible, setMessageModalVisibility] = useState(false);
    const [message, setMessage] = useState(false);

    const [languageIsDutch, setLanguageIsDutch] = useState(false);

    // //Applying styling to SVG to comply with EY branding policy
    // //decoding
    // var decodedSvg = decodeURIComponent(defaultLogo.split(',')[1]);
    // //Adding styling
    // var styledSvg = decodedSvg.replace('<svg ', '<svg style="vertical-align: baseline;" ');
    // //Encoding
    // var updatedLogo = "data:image/svg+xml," + encodeURIComponent(styledSvg);

    useEffect(() => {
        if (error === 'UserDoesNotExist') {
            // Alert if account does not exist
            alert(t('login_fail_doesnotexist', {ns: 'login'}));
        } else if (error === 'AuthenticationFailure') {
            // Alert if MyEY login fails
            alert(t('login_fail_myey', {ns: 'login'}));
        } else if (error) {
            // other error
            alert(t('login_fail_other', {ns: 'login'}));
        }
    }, [error]);

    useEffect(() => {
        // if user tries to open the login page while logged in, send them back to the overview
        const fetchUserAuthInfo = async () => {
            try {
                const response = await request.get('auth/check-auth-status/');
                if (response.is_authenticated === true) {
                    if (response.is_superuser) {
                        navigate('/clientoverview/');
                    } else {
                        navigate('/overview/')
                    }
                }
            } catch (error) {
                console.error('Error fetching user auth info:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserAuthInfo();
    }, [navigate]);

    useEffect(() => {
        // Frame-busting script
        if (window.top !== window.self) {
          window.top.location = window.self.location;
        }
      }, []);

    // Function to redirect to Azure AD login page
    const redirectToAzureADLogin = () => {
        let loginUrl = `${process.env.REACT_APP_API_URL}/login/?email=${encodeURIComponent(userName)}`;
        
        // Redirect the user to the login URL
        window.location.href = loginUrl;
    };

    // Function to get the browser's default language
    const getBrowserLanguage = () => {
        const browserLanguage = navigator.language || navigator.languages[0];
        // Return 'nl' if the browser's language starts with 'nl', otherwise 'en'
        return browserLanguage.startsWith('nl') ? 'nl' : 'en';
    };

    // UseEffect hook to set the initial language based on browser settings or default to English
    useEffect(() => {
        const browserLanguage = getBrowserLanguage();
        setLanguageIsDutch(browserLanguage === 'nl');
        i18n.changeLanguage(browserLanguage);
    }, [i18n]);

    // Function to handle language toggle switch changes
    const handleLanguageToggle = (event) => {
        const isDutch = event.target.checked;
        setLanguageIsDutch(isDutch);
        const language = isDutch ? 'nl' : 'en';
        i18n.changeLanguage(language);
    };

    return (
        <>
        <title>{t('login', {ns: 'menu'})} | {tooltitle}</title>
        { isLoading ?
            <MotifProgressLoader /* bug: show={isLoading} is always triggered as true, added isLoading ? : null as workaround */
                show={isLoading}
                variant="default"
                fullscreen={true}
                />  
        : null}
            <div className="row d-inline">
                <div className="col-1">
                    <MotifHeader
                        fixed={true}
                        logo={
                            <MotifHeaderLogo>
                                <a href="/overview">
                                    <MotifIcon alt='EY Logo' title='EY Logo' src={MotifEy} stroke={0} size="30" />
                                </a>
                            </MotifHeaderLogo>
                        }
                        appHeaderName="InControl Tool">
                    </MotifHeader>
                </div>
            </div>

            <div className="row justify-content-center mt-5">
                <div className="col-4" >

                    <h1>{t('login', {ns: 'login'})}</h1>

                    <MotifFormField>
                        <MotifLabel>{t('email', {ns: 'login'})}</MotifLabel>
                        <MotifInput id="username"
                            type="email"
                            value={userName}
                            required={true}
                            onChange={event => setUserName(event.target.value)}
                            onKeyDown={event => {
                                if (event.key === 'Enter' && !isLoading) {
                                    event.preventDefault();  // Prevent the default form submit behavior
                                    redirectToAzureADLogin();
                                }
                            }}
                            maxLength={256} />
                    </MotifFormField>
                </div>
            </div>

            <div className="row justify-content-center mt-3">
                <div className="col-4">
                    <MotifButton disabled={isLoading} onClick={redirectToAzureADLogin}>
                        {t('login', { ns: 'login' })}
                    </MotifButton>
                    <br />
                    {/* Conditionally render the old login link */}
                    {process.env.REACT_APP_ENABLE_DEBUG_FEATURES === 'True' && (
                        <div>
                            <MotifTextLink onClick={() => navigate('/login2/')}>
                                Old login (debug) &gt;
                            </MotifTextLink>
                        </div>
                    )}
                </div>
            </div>

            <MotifModal
                show={isMessageModalVisible}
                onClose={() => {
                    setMessageModalVisibility(false);
                    setMessage('');
                }}>
                <MotifModalHeader>{t('alert', {ns: 'general'})}</MotifModalHeader>
                <MotifModalBody>
                    {message}
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton disabled={isLoading} size="medium" type="button" onClick={() => {
                        setMessageModalVisibility(false);
                        setMessage('');
                    }}>
                        OK
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>



        </>
    )
}

export default LoginPage