import i18next from "i18next";

import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

let userLanguage = localStorage.getItem('language') || "nl";
i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['login', 'general', 'menu', 'account', 'admin', 'add_risk', 'add_control', 'add_monitoring', 'perform_control', 'perform_monitoring', 'screenreader'],
    debug: false,
    lng: userLanguage,
    fallbackLng: "nl",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;