import React from 'react'

const ControlItem = ({ description, value }) => {

    return (
        <>
            <h6 style={{ fontWeight: 'bold'}}>{description}</h6>
            <p className="motif-body mb-3" style={{ maxWidth: 600, margin: 0 }}>
                {value}
            </p>
        </>
    )
}

export default ControlItem