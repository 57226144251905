import React from 'react'

import {
    MotifLabel,
  } from '@ey-xd/motif-react';

//Label that will not be visually displayed but will be read to screen readers
const ScreenReaderLabel = ({ id, message }) => {
  return (
    <span
      style={{
        position: 'absolute',
        width: '1px',
        height: '1px',
        padding: '0',
        margin: '-1px',
        overflow: 'hidden',
        clip: 'rect(0, 0, 0, 0)',
        border: '0',
        whiteSpace: 'nowrap'
      }}
      id={id}
    >
      {message}
    </span>
  );
};


export default ScreenReaderLabel