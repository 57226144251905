import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../utils/loadingcontext';

import {
    MotifButton,
    MotifTable,
    MotifIcon,
    MotifIconButton,
    MotifTextLink,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
} from '@ey-xd/motif-react';

import {
    imageIcEdit24px,
    editorIcAttachFile24px,
    hardwareIcKeyboardArrowDown24px
} from '@ey-xd/motif-react/assets/icons';

import { request } from '../../../utils/request';

import NewDocumentModal from './NewDocumentModal.jsx';

const Documents = () => {

    const { t } = useTranslation();
    const { isLoading, setIsLoading } = useLoading();

    const [isFileModalVisible, setFileModalVisibility] = useState(false);

    const [editMode, setEditMode] = useState(false); // add or edit tax policy
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [selectedFileId, setSelectedFileId] = useState('')
    const [files, setFiles] = useState('')

    const [entities, setEntities] = useState([]);

    const [attData, setAttData] = useState([]); // send the attachments to the NewDocumentModal so they can also be opened there in edit mode
    
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);

        try {
          setEntities(await request.get('entity/', {}));
          setFiles(await request.get('file/', {}));
        } catch (error) {
          console.error(error.message);
        } finally {
          setIsLoading(false);
        }
    };
    fetchData();
    }, [])

  const setEditData = (documentId) => {
    setEditMode(true); 
    
    setSelectedFileId(documentId);
    
    setFileModalVisibility(true)
  }
  
  const columnDef = [
        {
          headerName: ' ',
          cellRenderer: (props) => (
            <MotifIconButton value={props.data.id}
              type="button"
              title={t('tablebutton', {ns: 'admin'})}>
              <MotifIcon src={imageIcEdit24px} />
            </MotifIconButton>
          ),
          field: 'id',
          width: 60,
          pinned: 'left',
          sort: 'asc',
          headerComponentParams: {
            dataColumn: false,
            headerEndComponent: (
              <MotifIconButton aria-label="Click here to edit" type="button">
                <MotifIcon src={hardwareIcKeyboardArrowDown24px} />
              </MotifIconButton>
            )
          }
        },
        {
            headerName: t('title', {ns: 'general'}),
            field: 'title',
            minWidth: 100,
            sortable: true,
            filter: true,
            flex: 2,
            wrapText: true,
            autoHeight: true,
        },
        {
            headerName: t('description', {ns: 'general'}),
            field: 'description',
            minWidth: 140,
            sortable: true,
            filter: true,
            flex: 2,
            wrapText: true,
            autoHeight: true,
        },
        {
          headerName: t('category', {ns: 'general'}),
          field: 'category.description',
          minWidth: 140,
          sortable: true,
          filter: true,
          flex: 1,
          wrapText: true,
          autoHeight: true,
      },
      {
          headerName: t('entities', {ns: 'general'}),
          cellRenderer: ({ value }) => {
            const names = value.map(item => item.name).join(", ");
        return (
          <>
            <p>{names}</p>
          </>
          );
        },
          field: 'entity',
          minWidth: 140,
          sortable: true,
          filter: true,
          flex: 3,
          wrapText: true,
          autoHeight: true,
      },
    ]

    return (
        <>
            <div className="row justify-content-left mt-5">
                <div className="col-8">
                    <MotifButton onClick={() => {setAttData([]); setEditMode(false); setIsFormSubmitted(false); setFileModalVisibility(true);}} disabled={isLoading}>{t('new_document', {ns: 'general'})}</MotifButton>
                </div>
            </div>

            {files ?
            <div className="row justify-content-left mt-5">
                <div className="col-10">
                    <MotifTable className='pb-5'
                      columnDefs={columnDef}
                      rowData={files}
                      onRowClicked={row => { setAttData(row.data.attachments); setEditData(row.data.id); }}
                      onCellKeyDown={row => {
                        if (row.event.key === 'Enter') {
                          setAttData(row.data.attachments); setEditData(row.data.id);
                        }
                      }}
                      compact={true}
                      zebra={true}
                      pagination={files?.length > 30 ? true : false}
                      paginationPageSize="25"
                       />
                </div>
            </div>
            : null}

            <NewDocumentModal
                isModalVisible={isFileModalVisible}
                setModalVisibility={setFileModalVisibility}
                selectedFileId={selectedFileId}
                attData={attData}
                setAttData={setAttData}
                entities={entities}
                editMode={editMode}
                setEditMode={setEditMode}
                isFormSubmitted={isFormSubmitted} 
                setIsFormSubmitted={setIsFormSubmitted}
                setFiles={setFiles}
            />
        </>
    )
}

export default Documents