import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const TaskCharts = ({ setFiltered, setSelectedTasks, subthemes, activeTab, setActiveTab, colors, subthemeItemsCounts, overdueTasks, todayTasks, soonTasks, toDoTasks }) => {
    const { t } = useTranslation();

    // Maps task types to their respective data
    const overdueLabel = t('overdue', { ns: 'general' });
    const todayLabel = t('today', { ns: 'general' });
    const soonLabel = t('soon', { ns: 'general' });
    const toDoLabel = t('to_do', { ns: 'general' });

    // Maps task types to their respective data using translated labels
    const taskMap = {
        [overdueLabel]: overdueTasks,
        [todayLabel]: todayTasks,
        [soonLabel]: soonTasks,
        [toDoLabel]: toDoTasks,
    };

    // Generates chart data for controls and monitoring
    const generateChartData = (title, key) => ({
        labels: subthemeItemsCounts?.map((sub) => sub.subtheme),
        datasets: [
            {
                label: t('overdue', { ns: 'general' }),
                backgroundColor: colors.red,
                hoverBackgroundColor: colors.darkred,
                data: subthemeItemsCounts?.map((item) => item.overdueCount[key]),
            },
            {
                label: t('today', { ns: 'general' }),
                backgroundColor: colors.orange,
                data: subthemeItemsCounts?.map((item) => item.todayCount[key]),
            },
            {
                label: t('soon', { ns: 'general' }),
                backgroundColor: colors.yellow,
                data: subthemeItemsCounts?.map((item) => item.upcomingCount[key]),
            },
            {
                label: t('to_do', { ns: 'general' }),
                backgroundColor: colors.green,
                borderColor: colors.darkgreen,
                hoverBackgroundColor: colors.darkgreen,
                data: subthemeItemsCounts?.map((item) => item.toDoCount[key]),
            },
        ],
    });

    const chartDataControls = generateChartData('Controls', 'controls');
    const chartDataMonitoring = generateChartData('Monitoring', 'monitoring');

    // Options for the Controls chart
    const optionsControls = {
        scale: { ticks: { precision: 0 } },
        indexAxis: 'y',
        plugins: {
            legend: { display: true, labels: { color: colors.lightgrey } },
        },
        scales: {
            x: {
                stacked: true,
                suggestedMax: 10,
                ticks: { color: colors.lightgrey },
                grid: { color: colors.grey },
            },
            y: {
                stacked: true,
                ticks: { color: colors.lightgrey },
                grid: { color: colors.grey },
            },
        },
        onClick: (evt, item) => {
            if (item.length > 0) {
                const datasetIndex = item[0].datasetIndex;
                const labelIndex = item[0].index;
                const selectedDatasetLabel = chartDataControls.datasets[datasetIndex].label;
                const selectedBarLabel = chartDataControls.labels[labelIndex];

                const subtheme = subthemes?.find((sub) => sub.description === selectedBarLabel);

                // Filter tasks based on selected subtheme and task type
                if (subtheme) {
                    const filteredTasks = taskMap[selectedDatasetLabel]?.controls.filter(
                        (task) => task.control.risk.subtheme.id === parseInt(subtheme.id)
                    );
                    if (filteredTasks) {
                        setSelectedTasks({ controls: filteredTasks, monitoring: [] });
                        setFiltered(true);
                        if (activeTab !== 'controls') {
                            setActiveTab('controls');
                        }
                    }
                }
            }
        },
    };

    // Options for the Monitoring chart
    const optionsMonitoring = {
        scale: { ticks: { precision: 0 } },
        indexAxis: 'y',
        plugins: {
            legend: { display: true, labels: { color: colors.lightgrey } },
        },
        scales: {
            x: {
                stacked: true,
                suggestedMax: 10,
                ticks: { color: colors.lightgrey },
                grid: { color: colors.grey },
            },
            y: {
                stacked: true,
                ticks: { color: colors.lightgrey },
                grid: { color: colors.grey },
            },
        },
        onClick: (evt, item) => {
            if (item.length > 0) {
                const datasetIndex = item[0].datasetIndex;
                const labelIndex = item[0].index;
                const selectedDatasetLabel = chartDataMonitoring.datasets[datasetIndex].label;
                const selectedBarLabel = chartDataMonitoring.labels[labelIndex];

                const subtheme = subthemes?.find((sub) => sub.description === selectedBarLabel);

                // Filter tasks based on selected subtheme and task type
                if (subtheme) {
                    const filteredTasks = taskMap[selectedDatasetLabel]?.monitoring.filter(
                        (task) =>
                        task.monitoring.controls.some(
                            (control) => control.risk.subtheme.id === parseInt(subtheme.id)
                        )
                    );
                    if (filteredTasks) {
                        setSelectedTasks({ controls: [], monitoring: filteredTasks });
                        setFiltered(true);
                        if (activeTab !== 'monitoring') {
                            setActiveTab('monitoring');
                        }
                    }
                }

                setFiltered(true);
                if (activeTab !== 'monitoring') {
                    setActiveTab('monitoring');
                }
            }
        },
    };

    return (
        <div className="row row-cols-2 justify-content-center text-center p-3">
            <div className="col">
                <h4 style={{ color: colors.lightgrey }}>Controls</h4>
                <Bar
                    tabIndex="0"
                    data={chartDataControls}
                    options={optionsControls}
                    title={t('control chart', { ns: 'screenreader' })}
                    alt={t('control chart', { ns: 'screenreader' })}
                />
            </div>
            <div className="col">
                <h4 style={{ color: colors.lightgrey }}>Monitoring</h4>
                <Bar
                    tabIndex="0"
                    data={chartDataMonitoring}
                    options={optionsMonitoring}
                    title={t('monitoring chart', { ns: 'screenreader' })}
                    alt={t('monitoring chart', { ns: 'screenreader' })}
                />
            </div>
        </div>
    );
};

export default TaskCharts;