import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import {
	MotifTable,
	MotifChip,
	MotifIcon,
	MotifIconButton,
	MotifModal,
	MotifModalHeader,
	MotifModalBody,
	MotifModalFooter,
	MotifButton,
	MotifFormField,
	MotifSearch
} from '@ey-xd/motif-react'

import {
	actionIcAssignment24px,
	hardwareIcKeyboardArrowDown24px
} from '@ey-xd/motif-react/assets/icons';

import ControlTaskDescription from '../../components/ControlTaskDescription';

const PerformControlTable = ({ controlTasks, myControlTasks, navigate, overviewSwitch, isLoading }) => {

	const { t } = useTranslation();
	const { userRole, isSuperuser } = useOutletContext();

	const [isModalVisible, setModalVisibility] = useState(false);
	const [modalHeaderText, setModalHeaderText] = useState(t('control_task', { ns: 'general' }));
	const [modalBodyText, setModalBodyText] = useState('');
	const [modalFooterText, setModalFooterText] = useState('');

	const [hasReviewer, setHasReviewer] = useState();
	const [filteredData, setFilteredData] = useState({ selectedItems: [], selectedMyItems: [] });
	const [searchValue, setSearchValue] = useState('');

	useEffect(() => { // show reviewer column if any control task has a reviewer
		const someReviewerExists = controlTasks?.some(task => task.reviewer_fullname?.length > 0);
		setHasReviewer(someReviewerExists);
	}, [controlTasks]);

	const [hasControlNumber, setHasControlNumber] = useState(false);

	useEffect(() => { // check if any control has a control number, if not, hide the table column
		const someControlNumberExists = controlTasks?.some(task => task.control?.control_number !== undefined && task.control?.control_number !== null && task.control?.control_number.length > 0);

		setHasControlNumber(someControlNumberExists);
	}, [controlTasks]);

	const doesItemMatchSearch = (item, searchValue) => {
		const entityMatches = item.control.entity && item.control.entity.some(entity => {
			return entity.name && entity.name.toLowerCase().includes(searchValue);
		});

		return (
			(item.control && item.control.risk && item.control.risk.risk && item.control.risk.risk.toLowerCase().includes(searchValue)) || // risk
			(item.control && item.control.risk && item.control.risk.subtheme && item.control.risk.subtheme.description_code && item.control.risk.subtheme.description_code.toLowerCase().includes(searchValue)) || // subtheme
			(item.control && item.control.risk && item.control.risk.subtheme && item.control.risk.subtheme.theme && item.control.risk.subtheme.theme.description && item.control.risk.subtheme.theme.description.toLowerCase().includes(searchValue)) || // theme
			(item.control && item.control.control && item.control.control.toLowerCase().includes(searchValue)) || // control
			(item.control && item.control.control_number && item.control.control_number.toLowerCase().includes(searchValue)) || // control number
			(item.control && item.control.frequency && item.control.frequency.description && item.control.frequency.description.toLowerCase().includes(searchValue)) || // frequency
			(item.control && item.control.control_type && item.control.control_type.description && item.control.control_type.description.toLowerCase().includes(searchValue)) || // control type
			(item.control && item.control.control_level && item.control.control_level.description && item.control.control_level.description.toLowerCase().includes(searchValue)) || // control level
			(item.control && item.control.responsible_fullname && item.control.responsible_fullname.toLowerCase().includes(searchValue)) || // responsible
			(item.control && item.control.reviewer_fullname && item.control.reviewer_fullname.toLowerCase().includes(searchValue)) || // reviewer
			(item.control && item.control.notify_on_failure_fullname && item.control.notify_on_failure_fullname.toLowerCase().includes(searchValue)) || // notify on failure
			(item.control && item.control.expiry && item.control.expiry.toLowerCase().includes(searchValue)) || // expiry date
			(item.control && item.control.description && item.control.description.toLowerCase().includes(searchValue)) || // control description / task description

			(item.status && item.status.description && item.status.description.toLowerCase().includes(searchValue)) || // status
			(item.start_date && item.start_date.toLowerCase().includes(searchValue)) || // start date
			(item.deadline && item.deadline.toLowerCase().includes(searchValue)) || // deadline

			entityMatches // entities
		);
	};

	const filterData = (inputValue) => {
		setSearchValue(inputValue);
		let allResults = []; // For items accessible by the whole team
		let myResults = []; // For items specific to the admin user

		if (inputValue.length > 0) {
			const searchValue = inputValue.toLowerCase(); // Convert once and use throughout

			// Filter all control tasks
			controlTasks.forEach(item => {
				if (doesItemMatchSearch(item, searchValue)) {
					allResults.push(item);
				}
			});

			// Filter admin's control tasks (myControlTasks)
			myControlTasks.forEach(item => {
				if (doesItemMatchSearch(item, searchValue)) {
					myResults.push(item);
				}
			});

			// Set filtered data for both controls and myControls
			setFilteredData({
				items: allResults,
				myItems: myResults
			});

		} else {
			// Reset to the whole list when search input is cleared
			setFilteredData({ items: [], myItems: [] });
		}
	};

	const taskPopUp = (value) => {
		const task = controlTasks.find(task => task.id == value);
		const taskFinal = task.status.id == 1;

		setModalBodyText(<ControlTaskDescription controlTask={task} taskFinal={taskFinal} />)
		setModalFooterText(
			<>
				<MotifButton
					className="me-3"
					onClick={() => navigate('performcontrol', { state: { taskId: value } })}>{t('open_task', { ns: 'general' })}</MotifButton>
				<MotifButton
					className="me-3"
					onClick={() => setModalVisibility(false)}>{t('close', { ns: 'general' })}</MotifButton>
			</>
		);
		setModalVisibility(true);
	}

	const columnDef = [
		{
			headerName: ' ',
			cellRenderer: ({ value }) => (
				<MotifIconButton value={value}
					type="button"
					title={t('tablebutton', { ns: 'perform_control' })}>
					<MotifIcon src={actionIcAssignment24px} />
				</MotifIconButton>
			),
			field: 'id',
			width: 60,
			pinned: 'left',
			headerComponentParams: {
				dataColumn: false,
				headerEndComponent: (
					<MotifIconButton aria-label="Click here to see options" type="button">
						<MotifIcon src={hardwareIcKeyboardArrowDown24px} />
					</MotifIconButton>
				)
			}
		},
		{
			headerName: '#',
			field: 'control.control_number',
			minWidth: 100,
			resizable: true,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			hide: !hasControlNumber,
			flex: 1,
		},
		{
			headerName: t('subtheme', { ns: 'general' }),
			field: 'control.risk.subtheme.description_code',
			resizable: true,
			minWidth: 100,
			sortable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 1,
		},
		{
			headerName: t('risk', { ns: 'general' }),
			field: 'control.risk.risk',
			minWidth: 170,
			sortable: true,
			resizable: true,
			filter: true,
			wrapText: true,
			autoHeight: true,
			flex: 3,
		},
		{
			headerName: t('control', { ns: 'general' }),
			field: 'control.control',
			minWidth: 140,
			sortable: true,
			filter: true,
			wrapText: true,
			resizable: true,
			autoHeight: true,
			flex: 2,
		},
		{
			headerName: t('status', { ns: 'general' }),
			field: 'status',
			width: 250,
			sortable: true,
			cellRenderer: ({ value }) => (
				<div style={{ alignSelf: 'center', display: 'flex', alignItems: 'center' }}>
					<MotifChip variant={value.chipVariant}>{value.description}</MotifChip>
				</div>
			),
		},
		{
			headerName: t('deadline', { ns: 'general' }),
			field: 'deadline',
			width: 160,
			sortable: true,
			filter: true,
			sort: 'asc',
		},
		{
			headerName: t('responsible', { ns: 'general' }),
			field: 'responsible_fullname',
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			flex: 1,
		},
		{
			headerName: t('reviewer', { ns: 'general' }),
			field: 'reviewer_fullname',
			minWidth: 140,
			wrapText: true,
			sortable: true,
			filter: true,
			hide: !hasReviewer,
			flex: 1,
		},
	]

	return (
		<>
			<div className="row justify-content-center">
				<div className="col-8">
					<MotifFormField>
						<MotifSearch
							value={searchValue}
							id="search-control-tasks"
							onEnter={event => filterData(event.target.value)}
							onSelect={val => setSearchValue(val)}
							onClear={event => filterData('')}
							aria-label="Search in control tasks"
							placeholder={t('search2', { ns: 'general' })}
							disabled={isLoading} />
					</MotifFormField>
				</div>
			</div>

			<div className="row justify-content-center mx-2 my-4">
				<div className="col">
					{overviewSwitch && controlTasks?.length > 0 ?
						<MotifTable className='pb-5'
							columnDefs={columnDef}
							rowData={searchValue.length > 0 ? filteredData.items : controlTasks}
							onRowClicked={row => taskPopUp(row.data.id)}
							onCellKeyDown={row => {
								if (row.event.key === 'Enter') {
									taskPopUp(row.data.id)
								}
							}}
							compact={true}
							zebra={true}
							pagination={controlTasks?.length > 30 ? true : false}
							paginationPageSize="25"
						/> : null}
					{!overviewSwitch && controlTasks?.length > 0 ?
						<MotifTable className='pb-5'
							columnDefs={columnDef}
							rowData={searchValue.length > 0 ? filteredData.myItems : myControlTasks}
							onRowClicked={row => taskPopUp(row.data.id)}
							onCellKeyDown={row => {
								if (row.event.key === 'Enter') {
									taskPopUp(row.data.id)
								}
							}}
							compact={true}
							zebra={true}
							pagination={myControlTasks?.length > 30 ? true : false}
							paginationPageSize="25"
						/> : null}
				</div>
			</div>
			<MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
				<MotifModalHeader>{modalHeaderText}</MotifModalHeader>
				<MotifModalBody>{modalBodyText}</MotifModalBody>
				<MotifModalFooter>{modalFooterText}</MotifModalFooter>
			</MotifModal>
		</>
	);
}

export default PerformControlTable;