import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import { 
    MotifButton
    } from '@ey-xd/motif-react'

import { MakeRequests } from '../../utils/request'

const ExportButton = ({exports, sheets, languageIsDutch}) => {

  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useLoading();

  const request = new MakeRequests();

  const handleClick = async () => {
    setIsLoading(true);
  
    try {
      const export_array = exports.join(',');
      const language = languageIsDutch ? 'nl' : 'en'; // Determine the language based on the toggle
      const response = await request.get(`exports/?exports=${export_array}&sheets=${sheets}&language=${language}`);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
  
      // Get the current date and time in UTC
      const now = new Date();
      const dateStr = now.toISOString().replace(/[:.]/g, '-'); // Format date and replace colons and periods with hyphens
  
      // Construct the file name with the current date and time
      const fileName = `TCF-export-${dateStr}.xlsx`;
  
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = fileName; // Use the fileName with the date and time
  
      a.click();
  
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      // Handle any errors that occurred during the export
      console.error('Error during export:', error.message);
    } finally {
      // This will run regardless of whether the try block succeeds or catches an error
      setIsLoading(false);
    }
  }
  

  return (
    <>
      <div>
        <MotifButton
          disabled={isLoading || exports.length === 0}
          onClick={handleClick}
        >{t('export', { ns: 'general' })}</MotifButton>
      </div>
    </>
  )
}

export default ExportButton