export class MakeRequests {
    constructor() {
        
        // note: in Azure, React env has to be set in the pipeline variables
        this.apiUrl = process.env.REACT_APP_API_URL
        this.baseUrl = process.env.REACT_APP_BASE_URL

        this.headers = {
            'Content-Type': 'application/json',
        };
    }

    async getCsrfToken() {
        const response = await fetch(`${this.apiUrl}/csrf/`, {
            method: 'GET',
            credentials: 'include',
        });

        if (response.ok) {
            const data = await response.json();
            return data.token;
        } else {
            throw new Error('Failed to fetch CSRF token');
        }
    }

    async get(urlExtension, params = {}) {
        // Check if params is an object and convert to query string if it has keys
        const queryString = Object.keys(params).length ? new URLSearchParams(params).toString() : '';
        
        // Construct the full URL with query string if present
        const fullUrl = queryString ? `${this.apiUrl}/${urlExtension}?${queryString}` : `${this.apiUrl}/${urlExtension}`;
    
        const response = await fetch(fullUrl, {
            method: 'GET',
            headers: this.headers,
            credentials: 'include'
        });
    
        if (response.status === 200) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.indexOf("application/json") !== -1) {
                return await response.json();
            } else {
                return response;
            }
        } else if (response.status === 403) {
            window.location = '/login/';
        } else {
            console.error(`Failed to fetch: ${response.statusText}`);
            throw new Error(response.statusText);
        }
    }    

    async post(urlExtension, requestBody, method = 'POST') {
        this.headers['X-Csrftoken'] = await this.getCsrfToken();

        let headers, body;
        if (requestBody instanceof FormData) {
            headers = { ...this.headers };
            delete headers['Content-Type'];
            body = requestBody;
        } else {
            headers = this.headers;
            body = JSON.stringify(requestBody);
        }

        const response = await fetch(`${this.apiUrl}/${urlExtension}`, {
            method: method,
            headers: headers,
            body: body,
            credentials: 'include'
        });

        if (response.ok) {
            if (response.status !== 204) {
                return await response.json();
            } else {
                return {};
            }
        } else {
            try {
                const errorResponse = await response.json();
                const error = {
                    message: errorResponse.message,
                    statusCode: response.status
                };
                throw error;
            } catch (error) {
                if (error instanceof SyntaxError) {
                    throw {
                        message: response.statusText || "An unknown error occurred",
                        statusCode: response.status
                    };
                } else {
                    throw error;
                }
            }
        }
    }

    async delete(urlExtension) {
        this.headers['X-Csrftoken'] = await this.getCsrfToken();

        const response = await fetch(`${this.apiUrl}/${urlExtension}`, {
            credentials: "include",
            method: 'DELETE',
            headers: this.headers,
        });

        if (response.ok) {
            if (response.status === 204) {
                return {};
            } else {
                return await response.json();
            }
        } else {
            try {
                const errorResponse = await response.json();
                const errorMessage = errorResponse.message || response.statusText || "An unknown error occurred";
                throw {
                    message: errorMessage,
                    statusCode: response.status
                };
            } catch (error) {
                if (error instanceof SyntaxError) {
                    throw {
                        message: response.statusText || "An error occurred during the request.",
                        statusCode: response.status
                    };
                } else {
                    throw error;
                }
            }
        }
    }
}

export const request = new MakeRequests();