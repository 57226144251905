import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import SubTitle from './SubTitle'

const KeyRisk = ({ client, setClient }) => {

    const { t } = useTranslation();

    const [keyRiskThreshold, setKeyRiskThreshold] = useState(0);

    useEffect(() => {

        if (client && client.key_risk_threshold && client.scale) {
            setKeyRiskThreshold(client.key_risk_threshold);
        }

    }, [client])

    return (
        <div className="row mt-5">
            <div className="col-6">
                <SubTitle title={t('keyrisk_treshold', {ns: 'admin'})} />
                <div>
                    <h5>{t('current_treshold', {ns: 'admin'})}: {keyRiskThreshold}</h5>
                </div>
            </div>
        </div>
    )
}

export default KeyRisk