import React, { useState, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { MotifTooltip } from '@ey-xd/motif-react';

import ScreenReaderLabel from '../../components/ScreenReaderLabel';

const HeatMapChart = ({ data, risks, setFilteredData, maxRanking, searchValue, setIsHeatmapActive, displayedData, setDisplayedData, setHeatmapFilteredData, setSearchValue, filterData, setCurrentImpact, setCurrentProbability}) => {
    const { t } = useTranslation();
    const gridSize = maxRanking === 9 ? 3 : 5;
    const scaleNumber = maxRanking === 9 ? 3 : 5;

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [focusedIndex, setFocusedIndex] = useState(null);
    const [screenReaderMessage, setScreenReaderMessage] = useState("");
    const [resetFilter, setResetFilter] = useState(false);

    // Define color mappings and ranking mappings based on gridSize
    const colorMapping = gridSize === 5
        ? [
            ["#FFE600", "#FF9831", "#FF735E", "#EA011D", "#EA011D"],
            ["#FFE600", "#FF9831", "#FF9831", "#FF735E", "#EA011D"],
            ["#2DB757", "#FFE600", "#FF9831", "#FF9831", "#FF735E"],
            ["#2DB757", "#FFE600", "#FFE600", "#FF9831", "#FF9831"],
            ["#2DB757", "#2DB757", "#2DB757", "#FFE600", "#FFE600"]
        ]
        : [
            ["#FFE600", "#FF735E", "#EA011D"],
            ["#2DB757", "#FF9831", "#FF735E"],
            ["#2DB757", "#2DB757", "#FFE600"]
        ];

    const darkerColorMapping = gridSize === 5
        ? [
            ["#D4BA00", "#D47B31", "#D45A5E", "#BA011D", "#BA011D"],
            ["#D4BA00", "#D47B31", "#D47B31", "#D45A5E", "#BA011D"],
            ["#209547", "#D4BA00", "#D47B31", "#D47B31", "#D45A5E"],
            ["#209547", "#D4BA00", "#D4BA00", "#D47B31", "#D47B31"],
            ["#209547", "#209547", "#209547", "#D4BA00", "#D4BA00"]
        ]
        : [
            ["#D4BA00", "#D45A5E", "#BA011D"],
            ["#209547", "#D47B31", "#D45A5E"],
            ["#209547", "#209547", "#D4BA00"]
        ];

    const rankingMapping = gridSize === 5
        ? [
            [5, 10, 15, 20, 25],
            [4, 8, 12, 16, 20],
            [3, 6, 9, 12, 15],
            [2, 4, 6, 8, 10],
            [1, 2, 3, 4, 5]
        ]
        : [
            [3, 6, 9],
            [2, 4, 6],
            [1, 2, 3]
        ];

    const heatmapData = Array.from({ length: gridSize }, () => Array(gridSize).fill(0));
    
    if (data && data.length > 0) {
        data.forEach(item => {
            const row = gridSize - item.probability;
            const col = item.impact - 1;
            if (row >= 0 && row < gridSize && col >= 0 && col < gridSize) {
                heatmapData[row][col] = item.count;
            }
        });
    }

    const handleSquareClick = (impact, probability, index) => {
        if (index === selectedIndex) { // deselect a square
            setCurrentImpact(null);
            setCurrentProbability(null);
            setSelectedIndex(null);
            setFilteredData([]);
            setIsHeatmapActive(false);

            setDisplayedData(risks);
            setResetFilter(true); // reapply the search filter if the user has typed in a search

            setScreenReaderMessage(t('heatmap_cleared', { ns: 'screenreader' }));
        } else { // select a square
            const filteredByHeatmap = risks.filter(
                risk => risk.impact === impact && risk.probability === probability
            );
            setSelectedIndex(index);
            setHeatmapFilteredData(filteredByHeatmap);
            setDisplayedData(filteredByHeatmap);
            setIsHeatmapActive(true);
            setCurrentImpact(impact);
            setCurrentProbability(probability);

            setResetFilter(true); // reapply the search filter if the user has typed in a search
            setScreenReaderMessage(t('heatmap_filtered', { ns: 'screenreader', impact, probability }));
        }
    };    

    // Effect to reapply the search filter after resetting the dataset
    useEffect(() => {
        if (resetFilter && searchValue && searchValue.length > 0) {
            filterData(searchValue);
            setResetFilter(false);
        }
    }, [displayedData, resetFilter, searchValue]);

    const handleKeyDown = (event, impact, probability, index) => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            handleSquareClick(impact, probability, index);
        }
    };

    const handleFocus = (impact, probability, ranking, index) => {
        setFocusedIndex(index);
        setScreenReaderMessage(t('heatmap_square', {ns: 'screenreader', impact, probability, ranking}));
    };

    return (
        <>
        <style jsx="true">{`
            .motif-tooltip-wrapper .motif-tooltip-trigger-wrap {
                display: grid;
            }
        `}</style>
        <div style={styles.heatmapContainer}>
            <ScreenReaderLabel id="heatmap-status" message={screenReaderMessage} aria-live="assertive" />
            <div style={styles.probabilityLabel}>{t('probability', { ns: 'general' })} &#10230;</div>
    
            <div
                style={{
                    ...styles.gridContainer,
                    gridTemplateColumns: `repeat(${gridSize}, 1fr)`,
                    gridTemplateRows: `repeat(${gridSize}, 1fr)`
                }}
            >
                {heatmapData.flat().map((count, index) => {
                    const col = index % gridSize;
                    const row = Math.floor(index / gridSize);
                    const baseColor = colorMapping[row][col];
                    const hoverColor = darkerColorMapping[row][col];
                    const backgroundColor = index === hoveredIndex && count > 0 ? hoverColor : baseColor;
                    const ranking = rankingMapping[row][col];
                    const impact = col + 1;
                    const probability = gridSize - row;
    
                    return (
                        <MotifTooltip
                            key={index}
                            placement="top"
                            trigger={
                                <div
                                    onClick={count > 0 ? () => handleSquareClick(impact, probability, index) : undefined}
                                    onMouseEnter={() => setHoveredIndex(index)}
                                    onMouseLeave={() => setHoveredIndex(null)}
                                    onFocus={() => handleFocus(impact, probability, ranking, index)}
                                    onBlur={() => setFocusedIndex(null)}
                                    onKeyDown={count > 0 ? (e) => handleKeyDown(e, impact, probability, index) : undefined}
                                    tabIndex={0}
                                    style={{
                                        ...styles.gridItem,
                                        backgroundColor,
                                        cursor: count > 0 ? "pointer" : "default",
                                        border: index === selectedIndex ? "3px solid #000000" : "1px solid #e1e1e1",
                                        boxShadow: index === selectedIndex ? "0 0 12px #000000" : "none",
                                        outline: index === focusedIndex ? "3px solid #FFD700" : "none"
                                    }}
                                    aria-label={`Risk square with impact ${impact}, probability ${probability}, and ranking ${ranking}`}
                                    role="button"
                                    aria-disabled={count === 0}
                                >
                                    <span style={styles.countText}>{count > 0 ? count : "0"}</span>
                                    <span style={styles.rankingText}>{ranking}</span>
                                </div>
                            }
                            allowClick={false}
                            allowHover={true}
                            usePortal
                            variant="alt"
                        >
                            <div>
                                <div>{t('impact', { ns: 'general' })} ({t('scale', { ns: 'general' })}: 1-{scaleNumber}) - {impact}</div>
                                <div>{t('probability', { ns: 'general' })} ({t('scale', { ns: 'general' })}: 1-{scaleNumber}) - {probability}</div>
                                <div>= {t('ranking', { ns: 'general' })} ({t('scale', { ns: 'general' })}: 1-{scaleNumber * scaleNumber}) - {ranking}</div>
                            </div>
                        </MotifTooltip>
                    );
                })}
            </div>
            <div style={styles.impactLabel}>{t('impact', { ns: 'general' })} &#10230;</div>
        </div>
        </>
    );    
};

// Styles
const styles = {
    heatmapContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        width: "80%",
        height: "80vh",
        maxWidth: "600px",
        maxHeight: "600px",
    },
    probabilityLabel: {
        position: "absolute",
        left: "-85px",
        top: "50%",
        transform: "rotate(-90deg)",
        transformOrigin: "center",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#FFFFFF"
    },
    impactLabel: {
        marginTop: "2px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#FFFFFF"
    },
    gridContainer: {
        display: "grid",
        gap: "1px",
        width: "100%",
        height: "100%",
        border: "1px solid white"
    },
    gridItem: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#000",
        fontSize: "clamp(14px, 1.5vw, 24px)",
        fontWeight: "bold",
        aspectRatio: "1",
        position: "relative",
        transition: "background-color 0.2s ease, border 0.2s ease, box-shadow 0.2s ease, outline 0.2s ease",
        border: "1px solid white"
    },
    countText: {
        fontSize: "clamp(18px, 1.5vw, 34px)",
        zIndex: 2
    },
    rankingText: {
        position: "absolute",
        bottom: "2px",
        right: "4px",
        fontSize: "clamp(10px, 0.8vw, 14px)",
        color: "rgba(0, 0, 0, 0.4)",
        zIndex: 1
    }
};

export default HeatMapChart;
