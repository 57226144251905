import React, {useState, useEffect} from 'react'

import { useTranslation } from 'react-i18next';

import {
    MotifFormField,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
    MotifMessage,
    MotifButton,
    MotifLabel,
    MotifOption,
    MotifSelect,
} from '@ey-xd/motif-react'

const RiskFilter = ({ setIsFiltered, setFilters, clearFilters, isModalFiltersVisible, setModalFiltersVisibility, subtheme, setSubtheme, process, setProcess, filteredSubthemeItems, filteredProcessItems }) => {

    const { t } = useTranslation();

    return (
        <MotifModal show={isModalFiltersVisible} onClose={() => setModalFiltersVisibility(false)} variant="slideout">
            <MotifModalHeader>{t('risk_filter', {ns: 'add_control'})}</MotifModalHeader>
            <MotifModalBody>
                <MotifFormField>
                    <MotifLabel id="select-subtheme">{t('subtheme_filter', {ns: 'add_control'})}</MotifLabel>
                        <MotifSelect
                            filter={true}
                            multiple={true}
                            value={subtheme}
                            onChange={val => setSubtheme(val)}
                        >
                            {filteredSubthemeItems.map((subtheme, i) => (<MotifOption key={i} value={subtheme.id.toString()}>{subtheme.description_code}</MotifOption>))}
                        </MotifSelect>
                        <MotifMessage>{filteredSubthemeItems.length + ' results'}</MotifMessage>
                    </MotifFormField>
                    <MotifFormField>
                        <MotifLabel id="select-process">{t('process_filter', {ns: 'add_control'})}</MotifLabel>
                        <MotifSelect
                            filter={true}
                            multiple={true}
                            value={process}
                            onChange={val => setProcess(val)}
                        >
                            {filteredProcessItems.map((process, i) => (<MotifOption key={i} value={process}>{process}</MotifOption>))}
                        </MotifSelect>
                        <MotifMessage>{filteredProcessItems.length + ' results'}</MotifMessage>
                    </MotifFormField>

                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={setFilters}>
                        {t('apply', {ns: 'general'})}
                    </MotifButton>
                    <MotifButton size="medium" type="button" variant="warn" onClick={clearFilters}>
                        {t('reset', {ns: 'general'})}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        );
    }

export default RiskFilter;


