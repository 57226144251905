import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import './css/base.css';
import {
  MotifHeader,
  MotifIcon,
  MotifHeaderLogo,
  MotifMenu,
  MotifIconButton,
  MotifMenuItem,
  MotifProgressLoader,
} from '@ey-xd/motif-react';
import { MotifEy } from '@ey-xd/motif-icon';

import { navigationIcMenu24px } from '@ey-xd/motif-react/assets/icons';
import Menu from '../../components/menu/Menu';
import { request } from '../../utils/request';
import { useLoading } from '../../utils/loadingcontext';
import { useTranslation } from 'react-i18next';
import BreadcrumbHeader from './breadcrumb';

import EmployeeDetails from '../../components/menu/EmployeeDetails';

const BasePage = ({ userRole, isSuperuser }) => {

  const { isLoading, setIsLoading } = useLoading();
  const { t } = useTranslation();
  const location = useLocation().pathname;
  const navigate = useNavigate();
  const [visible, setVisibility] = useState(false);
  const [logOutInProgress, setLogOutInProgress] = useState(false);

  useEffect(() => {
    // Frame-busting script
    if (window.top !== window.self) {
      window.top.location = window.self.location;
    }
  }, []);

  useEffect(() => {
    // close the employee sidebar with esc button
    const handleEsc = (event) => {
       if (event.key === 'Escape') {
        setVisibility(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const logout = async () => {
    if (logOutInProgress) return; // Prevent logout if already in progress

    try {
      setIsLoading(true);
      setLogOutInProgress(true);
      await request.post('logout/', {}, 'POST');
      localStorage.clear();
      navigate('/login/');
    } catch (error) {
      console.error(error.message);
    } finally {
      setIsLoading(false);
      setLogOutInProgress(false);
    }
  };

  return (
    <>
      <MotifHeader
        className='header'
        fixed={true}
        logo={
          <MotifHeaderLogo>
            <a href="/overview">
              <MotifIcon alt='EY Logo' title='EY Logo'  src={MotifEy} stroke={0} size="30" />
            </a>
          </MotifHeaderLogo>
        }
        iconButton={
          <MotifIconButton
            aria-label="Click to open menu"
            type="button"
            onClick={() => setVisibility(true)}
          >
            <MotifIcon src={navigationIcMenu24px} />
          </MotifIconButton>
        }
        menu={
          <MotifMenu
            show={visible}
            style={{ cursor: 'pointer' }}
            onClose={() => setVisibility(false)}
            active={true}
            variant="alt">
              <EmployeeDetails/>
            <MotifMenuItem>
              <a href='/account/'>Account</a>
            </MotifMenuItem>
            <MotifMenuItem
              onClick={logout}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  logout();
                }
              }}
            >
              <a
                href="#"
                onClick={(e) => e.preventDefault()} // Prevent default anchor behavior
                aria-disabled={logOutInProgress} // Accessibility: informs screen readers that the link is disabled
                style={{ pointerEvents: logOutInProgress ? 'none' : 'auto', color: logOutInProgress ? 'grey' : 'inherit' }} // Disable link visually
              >
                {t('logout', { ns: 'menu' })}
              </a>
            </MotifMenuItem>
          </MotifMenu>
        }
        appHeaderName="InControl"
        middleComponents={<BreadcrumbHeader />}
        >
      </MotifHeader>

      <div className="flex-container">
        <div className="nav-column">
          <Menu userRole={userRole} isSuperuser={isSuperuser} />
        </div>
        <div className="content-column">
          {isLoading && <MotifProgressLoader className='progressloader' show fullscreen={true}/>}
          <Outlet context={{ userRole, isSuperuser }} />
        </div>
      </div>
    </>
  );
};

export default BasePage;