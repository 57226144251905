import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next';

import {
    MotifButton,
    MotifModal,
    MotifModalHeader,
    MotifModalBody,
    MotifModalFooter,
} from '@ey-xd/motif-react';

import { request } from '../../../utils/request';


const DeleteDepartmentModal = ({
    isModalVisible,
    setModalVisibility,
    getDepartments,
    departmentId,
    departmentName,
    entityId }) => {

    const { t } = useTranslation();

    const deleteDepartment = async () => {
        setModalVisibility(false);

        try {
            var response = await request.delete(`new-department-entity/delete/${entityId}/${departmentId}/`);
            } catch (error) {
                console.error(error.message);
            }
        
        getDepartments();
       
    }

    return (
        <>
            <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
                <MotifModalHeader>{t('department_remove', {ns: 'admin'})}</MotifModalHeader>
                <MotifModalBody>
                    {t('modal_department_remove_confirm_body', {ns: 'admin'})}
                    <p style={{fontWeight: 'bold', fontSize: '20px', textAlign: 'center'}}>{departmentName}</p>
                </MotifModalBody>
                <MotifModalFooter>
                    <MotifButton size="medium" type="button" onClick={() => { deleteDepartment() }}>
                        {t('remove', {ns: 'general'})}
                    </MotifButton>
                    <MotifButton
                        size="medium"
                        variant="secondary"
                        type="button"
                        onClick={() => setModalVisibility(false)}
                    >
                        {t('cancel', {ns: 'general'})}
                    </MotifButton>
                </MotifModalFooter>
            </MotifModal>
        </>
    )
}

export default DeleteDepartmentModal