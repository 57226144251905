import React from 'react'

const DetailsItem = ({ description, value}) => {
  return (
    <div className='d-flex flex-column mb-2'>
        <div className='d-flex align-items-start'
            style={{ fontWeight: 'bold'}}>
            { description }
        </div>
        <div className='d-flex align-items-start'>
            { value }
        </div>
    </div>
  )
}

export default DetailsItem