import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MakeRequests } from '../../utils/request';

import { useLoading } from '../../utils/loadingcontext';

import MonitoringTaskDescription from '../../components/MonitoringTaskDescription';
import PerformMonitoringForm from './PerformMonitoringForm';
import Communication from '../../components/Communication';

import { useTranslation } from 'react-i18next';
import { tooltitle } from '../../utils/utils';

const PerformMonitoringPage = () => {
  const { t, i18n } = useTranslation();

  const request = new MakeRequests();
  const { isLoading, setIsLoading } = useLoading();
  const [dataFetched, setDataFetched] = useState(false);

  const navigate = useNavigate()
  const { state } = useLocation();
  const { taskId } = state || {};
  const { completed } = state || {};

  const [executionDate, setExecutionDate] = useState(null);

  const [fiscalYear, setFiscalYear] = useState('');
  const [clientFiscalYear, setClientFiscalYear] = useState('');
  const [deviations, setDeviations] = useState('');

  const [monitorTask, setMonitorTask] = useState();
  const [taskFinal, setTaskFinal] = useState(false);

  const [draft, setDraft] = useState([]);

  const [communication, setCommunication] = useState({responsible: [], reviewer: [],});

  const [role, setRole] = useState('');

  useEffect(() => {
    setTaskFinal(monitorTask?.status.id === 1);
  }, [monitorTask])
  useEffect(() => {
    const fetchData = async () => {

      let param = completed === 1 || taskFinal ? '-completed' : '';

      try {
        setIsLoading(true);
        const [bookyearResp, monitorTaskResp, responsibleResp, reviewerResp] = await Promise.all([
          request.get('bookyear'),
          request.get(`monitoring-task${param}/${taskId}/`),
          request.get(`perform-monitoring/?monitoringtask=${taskId}`),
          request.get(`perform-monitoring-review/?monitoringtask=${taskId}`)
        ]);

        setClientFiscalYear(bookyearResp);
        setFiscalYear(monitorTaskResp?.fiscal_year || '');
        setDeviations(monitorTaskResp?.deviations === true ? '1' : '0' || '');
        if (completed === 1) {
          setExecutionDate(new Date(monitorTaskResp?.execution_date)); // only set the executed date if task is finished
        }
  
        setMonitorTask(monitorTaskResp);
        setCommunication({ responsible: responsibleResp, reviewer: reviewerResp });
  
        // User role and task finalization
        const userRole = getUserRole(monitorTaskResp, localStorage.getItem('username'));
        setRole(userRole);

        setIsLoading(false);
        
      } catch (error) {
        console.error(error);
      } finally {
        setDataFetched(true);
      }
    };
  
    fetchData();
  }, [taskFinal]);

  useEffect(() => {
    if (monitorTask && dataFetched) {
      const userRole = getUserRole(monitorTask, localStorage.getItem('username'));

      if(!taskFinal) {
        setDraft(getDraftCommunications(userRole, communication));
      }
    }
  }, [communication, monitorTask]);

  const getUserRole = (task, username) => {
    if (username === task?.responsible_username) return 'responsible';
    if (username === task?.reviewer_username) return 'reviewer';
    return 'other';
  };

  const getDraftCommunications = (role, communication) => {
    const noCommunicationAvailable = !communication || (!communication.responsible && !communication.reviewer);
    if (noCommunicationAvailable) return [];

    if (role === 'responsible') return communication.responsible?.filter(com => com.is_draft === true) || [];
    if (role === 'reviewer') return communication.reviewer?.filter(com => com.is_draft === true) || [];
    return []; // Default empty array for any other role
  };

  return (
    <>
    <title>{t('performmonitoring', {ns: 'menu'})} | {tooltitle}</title>
    <div className="d-flex justify-content-center">
      <h1>{t('performmonitoring', {ns: 'menu'})}</h1>
    </div>
      <div className="row ms-2 mt-5">
        <div className="col-3">
          {monitorTask && <MonitoringTaskDescription monitorTask={monitorTask} taskFinal={completed} />}
        </div>

        <div className="col-8">
          { dataFetched ?
            <>
              <PerformMonitoringForm draft={draft} role={role} monitorTask={monitorTask} taskId={taskId} taskFinal={completed} setTaskFinal={setTaskFinal} deviations={deviations} setDeviations={setDeviations} executionDate={executionDate} fiscalYear={fiscalYear} setFiscalYear={setFiscalYear} clientFiscalYear={clientFiscalYear} navigate={navigate} request={request} />
              <Communication communication={communication} taskFinal={completed} type='monitoring' />
            </>
          : null }
        </div>
      </div>
    </>
  )
}

export default PerformMonitoringPage