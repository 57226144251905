import React, {useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'

import { useLoading } from '../../utils/loadingcontext';

import { useTranslation } from 'react-i18next';

import { request } from '../../utils/request';

import FilterToggleSwitch from '../../components/FilterToggleSwitch';
import PerformMonitoringTable from './PerformMonitoringTable';
import { tooltitle } from '../../utils/utils';

const PerformMonitoringOverviewPage = () => {
    const { t, i18n } = useTranslation();

    const { isLoading, setIsLoading } = useLoading();
    const { userRole, isSuperuser } = useOutletContext();
    const currentEmployeeName = localStorage.getItem('employee_name');

    const [overviewSwitch, setOverviewSwitch] = useState(true);

    const [monitoringTasks, setMonitoringTasks] = useState();
    const [myMonitoringTasks, setMyMonitoringTasks] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
        
        setIsLoading(true);

        const data = await request.get('monitoring-task/', {});

        setMonitoringTasks(data);
        setMyMonitoringTasks(data.filter(monitoring => monitoring.responsible_fullname === currentEmployeeName || monitoring.reviewer_fullname === currentEmployeeName));

        setIsLoading(false);
        };

        fetchData();
    }, []);

    return (
    <>
    <title>{t('performmonitoringoverview', {ns: 'menu'})} | {tooltitle}</title>
    <div className="d-flex justify-content-center">
        <h1>{t('performmonitoringoverview', {ns: 'menu'})}</h1>
    </div>
        {userRole === 'Admin' && monitoringTasks?.length > 0 && (
        <FilterToggleSwitch switchName={'PerformMonitoringOverviewPage'} isLoading={isLoading} monitoring={monitoringTasks} setMonitoring={setMonitoringTasks} overviewSwitch={overviewSwitch} setOverviewSwitch={setOverviewSwitch} />
        )}

        <div className="row justify-content-center mx-2 mt-3">
            <div className="col">
                <PerformMonitoringTable monitoringTasks={monitoringTasks} myMonitoringTasks={myMonitoringTasks} navigate={navigate} overviewSwitch={overviewSwitch} isLoading={isLoading} />
            </div>
        </div>

        
    </>
    )
}

export default PerformMonitoringOverviewPage