import React from 'react';

import { Link } from 'react-router-dom';

import { useTranslation} from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
    MotifVerticalNavigation,
    MotifVerticalNavigationContent,
    MotifVerticalNavigationMenu,
    MotifVerticalNavigationMenuItem,
    MotifIcon
} from '@ey-xd/motif-react';

import {
    actionIcHome24px,
    actionIcCheckCircle24px,
    actionIcDashboard24px,
    actionIcPanTool24px,
    actionIcAssignmentTurnedIn24px,
    fileIcCreateNewFolder24px,
    fileIcFolder24px,
    actionIcAssessment24px,
    actionIcFingerprint24px,
    socialIcPersonAdd24px
} from '@ey-xd/motif-react/assets/icons';

const Menu = ({ userRole, isSuperuser }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <MotifVerticalNavigation className={'max'}>
                <MotifVerticalNavigationContent>
                    <MotifVerticalNavigationMenu>
                        
                        {isSuperuser === false ? 
                            <Link
                                to={'overview'}
                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                <MotifVerticalNavigationMenuItem
                                    label={t('dashboard', {ns: 'menu'})}
                                    icon={<MotifIcon
                                    src={actionIcHome24px} />}>
                                    {t('dashboard', {ns: 'menu'})}
                                </MotifVerticalNavigationMenuItem>
                            </Link>
                        : <></>}


                        {isSuperuser === false ? 
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('documents')
                                }}
                                label={t('documents', {ns: 'menu'})}
                                icon={<MotifIcon
                                    src={fileIcFolder24px} />}>
                                {t('documents', {ns: 'menu'})}
                            </MotifVerticalNavigationMenuItem>
                        : <></>}

                        {isSuperuser === false ? 
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('risks')
                                }}
                                label={t('risks', {ns: 'menu'})}
                                icon={<MotifIcon
                                    src={actionIcPanTool24px} />}>
                                {t('risks', {ns: 'menu'})}
                            </MotifVerticalNavigationMenuItem>
                        : <></>}
                        
                        {isSuperuser === false ? 
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('controls')
                                }}
                                label={t('controls', {ns: 'menu'})}
                                icon={<MotifIcon
                                    src={actionIcCheckCircle24px} />}>
                                {t('controls', {ns: 'menu'})}
                            </MotifVerticalNavigationMenuItem>
                        : <></>}

                        {isSuperuser === false ? 
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('performcontroloverview')
                                }}
                                label={t('performcontroloverview', {ns: 'menu'})}
                                icon={<MotifIcon
                                    src={actionIcAssignmentTurnedIn24px} />}>
                                {t('performcontroloverview', {ns: 'menu'})}
                            </MotifVerticalNavigationMenuItem>
                        : <></>}

                        {isSuperuser === false ? 
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('monitoring')
                                }}
                                label={t('monitoring', {ns: 'menu'})}
                                icon={<MotifIcon
                                    src={actionIcDashboard24px} />}>
                                {t('monitoring', {ns: 'menu'})}
                            </MotifVerticalNavigationMenuItem>
                        : <></>}

                        {isSuperuser === false ? 
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('performmonitoringoverview')
                                }}
                                label={t('performmonitoringoverview', {ns: 'menu'})}
                                icon={<MotifIcon
                                    src={actionIcAssessment24px} />}>
                                {t('performmonitoringoverview', {ns: 'menu'})}
                            </MotifVerticalNavigationMenuItem>
                        : <></>}

                        {userRole === 'Admin' && isSuperuser === false ? 
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('admin')
                                }}
                                icon={<MotifIcon
                                    src={actionIcFingerprint24px} />}
                                    label={'Admin'}>
                                Admin
                            </MotifVerticalNavigationMenuItem>
                        : <></>}

                        {isSuperuser === true ? 
                        <>
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('clientoverview')
                                }}
                                label={t('clientoverview', {ns: 'menu'})}
                                icon={<MotifIcon
                                    src={actionIcDashboard24px} />}>
                                {t('clientoverview', {ns: 'menu'})}
                            </MotifVerticalNavigationMenuItem>
                        
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('adminoverview')
                                }}
                                label={t('adminoverview', {ns: 'menu'})}
                                icon={<MotifIcon
                                    src={actionIcFingerprint24px} />}>
                                {t('adminoverview', {ns: 'menu'})}
                            </MotifVerticalNavigationMenuItem>
                        
                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('/clientoverview/addclient', {state:{clientId:null}})
                                }}
                                label={t('addclient', {ns: 'menu'})}
                                icon={<MotifIcon
                                    src={fileIcCreateNewFolder24px} />}>
                                {t('addclient', {ns: 'menu'})}
                            </MotifVerticalNavigationMenuItem>

                            <MotifVerticalNavigationMenuItem
                                onClick={()=>{
                                    navigate('/adminoverview/addadmin', {state:{adminId:null}})
                                }}
                                label={t('addadmin', {ns: 'admin'})}
                                icon={<MotifIcon
                                    src={socialIcPersonAdd24px} />}>
                                {t('addadmin', {ns: 'admin'})}
                            </MotifVerticalNavigationMenuItem>
                        </>
                        : <></>}                       

                    </MotifVerticalNavigationMenu>
                </MotifVerticalNavigationContent>
            </MotifVerticalNavigation>
        </>
    )
}

export default Menu