import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import SubTitle from './SubTitle'

const Scale = ({ client, setClient }) => {

    const { t } = useTranslation();
    
    const [selectedScaleDescription, setSelectedScaleDescription] = useState('')

    useEffect(() => {

        if (client && client.scale) {
            setSelectedScaleDescription(client.scale.description)
        }


    }, [client])

    return (
        <div className='row mt-5'>
            <div className="col">
                <SubTitle title={t('scale_impact_probability', {ns: 'admin'})} />
                <div className="row">
                    <div className="col-3">
                        <h5>{t('current_treshold', {ns: 'admin'})}: {selectedScaleDescription}</h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Scale