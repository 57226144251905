import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next';
import { useLoading } from '../../utils/loadingcontext';

import {
  MotifTable,
  MotifIcon,
  MotifIconButton,
  MotifTextLink,
  MotifModal,
  MotifModalHeader,
  MotifModalBody,
  MotifFormField,
  MotifSearch
} from '@ey-xd/motif-react'

import { request } from '../../utils/request';

import { MakeRequests } from '../../utils/request'

import {
  editorIcAttachFile24px,
  hardwareIcKeyboardArrowDown24px
} from '@ey-xd/motif-react/assets/icons';

const GeneralDocuments = ({taxpolicy}) => {

  const { t } = useTranslation();
  const { isLoading, setIsLoading } = useLoading();

  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [isModalVisible, setModalVisibility] = useState(false)
  const [attachments, setAttachments] = useState([]);
  const [blobUrls, setBlobUrls] = useState({}); // This will store the blob URLs. Key is attachment ID, value is blob URL.

  const [tcfFiles, setTcfFiles] = useState('')

  useEffect(() => {

    const fetchData = async () => {
        setIsLoading(true);
        setTcfFiles(await request.get(`file/${taxpolicy}`, {}));
        setIsLoading(false);
    };

    fetchData();

}, [])

  const filterData = (inputValue) => {
    setSearchValue(inputValue);
    let results = [];

    if (inputValue.length > 0) {
      const searchValue = inputValue.toLowerCase();
      tcfFiles.forEach(item => {
        const entityMatch = item.entity && item.entity.some(entity => 
          entity.name.toLowerCase().includes(searchValue)
        );

        if (
          (item.title && item.title.toLowerCase().includes(searchValue)) || // title
          (item.description && item.description.toLowerCase().includes(searchValue)) || // description
          entityMatch // entity name
        ) {
          results.push(item);
        }
      });
      setFilteredData(results);
    } else {
      setFilteredData([]); // Reset to the whole list when search input is cleared
    }
  }

  const fetchBlobUrl = async (attachment) => {
    const response = await request.get(`file/stream_blob/${attachment.id}/`);
    if (response.ok) {
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    }
    return null;
  };

  const showAttachments = async (value) => {
    // If there is only one attachment, open it directly
    if (value.length === 1) {
      const blobUrl = await fetchBlobUrl(value[0]);
      if (blobUrl) {
        window.open(blobUrl, '_blank');
        return;
      }
    }
    
    // If there are multiple attachments, show them in the modal
    const newBlobUrls = {};
    for (let attachment of value) {
      newBlobUrls[attachment.id] = await fetchBlobUrl(attachment);
    }
    setBlobUrls(newBlobUrls);
    setAttachments(value);
    setModalVisibility(true);
  };

const columnDef = [
    {
      headerName: ' ',
      cellRenderer: () => (
        <MotifIconButton
          type="button"
          title={t('tablebuttondocuments', {ns: 'general'})}>
          <MotifIcon src={editorIcAttachFile24px} />
        </MotifIconButton>
      ),
      field: 'attachments',
      width: 60,
      pinned: 'left',
      sort: 'asc',
      headerComponentParams: {
        dataColumn: false,
        headerEndComponent: (
          <MotifIconButton aria-label="Click here to edit" type="button">
            <MotifIcon src={hardwareIcKeyboardArrowDown24px} />
          </MotifIconButton>
        )
      }
    },
    {
        headerName: t('title', {ns: 'general'}),
        field: 'title',
        minWidth: 100,
        sortable: true,
        filter: true,
        flex: 1,
        wrapText: true,
        autoHeight: true,
    },
    {
        headerName: t('description', {ns: 'general'}),
        field: 'description',
        minWidth: 140,
        sortable: true,
        filter: true,
        flex: 1,
        wrapText: true,
        autoHeight: true,
    },
    {
        headerName: t('entities', {ns: 'general'}),
        cellRenderer: ({ value }) => {
          const names = value.map(item => item.name).join(", ");
          return (
            <>
              <p>{names}</p>
            </>
            );
          },
        field: 'entity',
        minWidth: 140,
        sortable: true,
        filter: true,
        flex: 2,
        wrapText: true,
        autoHeight: true,
    },
]

  return (
    <>
      <div className="row justify-content-left mt-5">
        <div className="col-8">
          <MotifFormField>
            <MotifSearch
              value={searchValue}
              id="search-risks"
              onEnter={event => filterData(event.target.value)}
              onSelect={val => setSearchValue(val)}
              onClear={event => filterData('')}
              aria-label="Search"
              placeholder={t('search documents', {ns: 'screenreader'})}
              disabled={isLoading}
            />
          </MotifFormField>
        </div>
        {tcfFiles ?
        <div className="col-8">
          <MotifTable className='pb-5'
            columnDefs={columnDef}
            rowData={searchValue.length > 0 ? filteredData : tcfFiles}
            onRowClicked={row => showAttachments(row.data.attachments)}
                onCellKeyDown={row => {
                  if (row.event.key === 'Enter') {
                    showAttachments(row.data.attachments);
                  }
            }}
            compact={true}
            zebra={true}
            pagination={tcfFiles?.length > 30 ? true : false}
            paginationPageSize="25"
            />
        </div>
        : null }
      </div>

      <MotifModal show={isModalVisible} onClose={() => setModalVisibility(false)}>
            <MotifModalHeader>{t('attachments', {ns: 'general'})}</MotifModalHeader>
            <MotifModalBody>
            {attachments.map((file, index) => (
                  <div key={index}>
                     <MotifTextLink href={blobUrls[file.id]} target="_blank" rel="noopener noreferrer">
                      {file.name}
                    </MotifTextLink>
                  </div>
                ))}
            </MotifModalBody>
        </MotifModal>
    </>
  )
}

export default GeneralDocuments