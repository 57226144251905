import React, { useState } from 'react'

import { useTranslation } from 'react-i18next';

import {
    MotifTabNavigation as MotifTabNav,
    MotifTabControl
} from '@ey-xd/motif-react';

import Entity from './entity_department/Entity';
import Employee from './employee/Employee';
import General from './general/General';
import Documents from './documents/Documents';
import Themes from './themes/Themes'
import { tooltitle } from '../../utils/utils';

const Admin = () => {

    const { t } = useTranslation();

    const [tabKey, handleTabKey] = useState(0);

    return (
        <>
        <title>{t('admin', {ns: 'menu'})} | {tooltitle}</title>
        <div className="d-flex justify-content-center">
            <h1>{t('admin', {ns: 'menu'})}</h1>
        </div>
            <MotifTabNav
                className="mt-3 mx-2"
                controlled>
                <MotifTabControl
                    alt={t('general', {ns: 'general'})}
                    title={t('general', {ns: 'general'})}
                    onClick={() => handleTabKey(0)}
                    className={tabKey === 0 ? 'motif-active' : ''}
                >
                    {t('general', {ns: 'general'})}
                </MotifTabControl>
                <MotifTabControl tabIndex="0"
                    alt={t('entities', {ns: 'general'})}
                    title={t('entities', {ns: 'general'})}
                    onClick={() => handleTabKey(1)}
                    className={tabKey === 1 ? 'motif-active' : ''}
                >
                    {t('entities', {ns: 'general'})}
                </MotifTabControl>
                <MotifTabControl tabIndex="0"
                    alt={t('employees', {ns: 'general'})}
                    title={t('employees', {ns: 'general'})}
                    onClick={() => handleTabKey(2)}
                    className={tabKey === 2 ? 'motif-active' : ''}
                >
                    {t('employees', {ns: 'general'})}
                </MotifTabControl>
                <MotifTabControl tabIndex="0"
                    alt={t('documents', {ns: 'general'})}
                    title={t('documents', {ns: 'general'})}
                    onClick={() => handleTabKey(3)}
                    className={tabKey === 3 ? 'motif-active' : ''}
                >
                    {t('documents', {ns: 'general'})}
                </MotifTabControl>
                <MotifTabControl tabIndex="0"
                    alt={t('theme', {ns: 'general'})}
                    title={t('theme', {ns: 'general'})}
                    onClick={() => handleTabKey(4)}
                    className={tabKey === 4 ? 'motif-active' : ''}
                >
                    {t('theme', {ns: 'general'})}
                </MotifTabControl>

            </MotifTabNav>

            <div className="mx-2">
                {tabKey === 0 && <General />}
                {tabKey === 1 && <Entity />}
                {tabKey === 2 && <Employee />}
                {tabKey === 3 && <Documents />}
                {tabKey === 4 && <Themes />}
            </div>



        </>
    )
}

export default Admin