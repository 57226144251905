import React, { useState, useEffect, useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import {
    MotifFormField,
    MotifLabel,
    MotifSelect,
    MotifOption,
    MotifButton,
    MotifIcon,
    MotifIconButton
} from '@ey-xd/motif-react';

import SubTitle from './SubTitle';

import {
    imageIcEdit24px,
    hardwareIcKeyboardArrowDown24px
} from '@ey-xd/motif-react/assets/icons';

import { request } from '../../../utils/request';

const BookYear = () => {

    const { t } = useTranslation();

    const [startMonth, setStartMonth] = useState('');
    const [startDay, setStartDay] = useState('1');
    const [endMonth, setEndMonth] = useState(null);
    const [endDay, setEndDay] = useState(null);
    const [days, setDays] = useState([]);
    const [startMonths, setStartMonths] = useState([]);
    const [definedBookYear, setDefinedBookYear] = useState(null);

    const [editMode, setEditMode] = useState(false);
    const [forceRenderKey, setForceRenderKey] = useState('');

    useEffect(() => {
        !!startMonth.length && setForceRenderKey(crypto.randomUUID());
        !!startDay.length && setForceRenderKey(crypto.randomUUID());

    }, [startMonth, startDay]);

    useEffect(() => {

        var date = new Date(2022, startMonth, 0)
        var lastDayOfMonth = parseInt(date.getDate().toString())
        var daysOfMonth = Array.from({ length: lastDayOfMonth }, (x, i) => i + 1);
        setDays(daysOfMonth)
    },
        [startMonth])

    useEffect(() => {

        const fetchData = async () => {
            const data = await request.get('bookyear/');

            const monthsData = await request.get('month/');
            setDefinedBookYear(data[0])
            setStartMonths(monthsData);
            data ? setStartMonth(data[0].start_month.toString()) : setStartMonth('1');
            data ? setStartDay(data[0].start_day.toString()) : setStartDay('1');
        };

        fetchData();
    }, [])

    useEffect(() => {
        var startBookYearMonth = startMonths.filter(month => month.id.toString() == startMonth)[0]

        if (startBookYearMonth) {
            var startDate = new Date(2022, startBookYearMonth.month_number - 1, startDay)
            var endDate = new Date(startDate.setDate(startDate.getDate() + 364))
            setEndMonth(endDate.getMonth() + 1);
            setEndDay(endDate.getDate());
        }

    }, [startDay, startMonth])

    const submit = async (how) => {

        var url = '';

        var data = {
            "start_day": startDay,
            "end_day": endDay,
            "start_month": startMonth,
            "end_month": endMonth,
        }

        if (how === 'POST') {
            url = 'bookyear/';
        } else {
            url = `bookyear/${definedBookYear.id}/`
        }

        var response = await request.post(url, data, how);

        if (response.success && editMode === true) {
            setEditMode(false);
        }

    }

    return (
        <div className='row mt-5'>
            <div className="col">
                <SubTitle title={t('set_bookyear', {ns: 'admin'})}/>
                {(editMode === true) ?
                    <div className="row mt-3">
                        <div className="col-2">
                            <MotifFormField>
                                <MotifLabel id="select-label-startmonth">{t('bookyear_first_month', {ns: 'admin'})}</MotifLabel>
                                <MotifSelect
                                    key={forceRenderKey}
                                    value={startMonth}
                                    onChange={val => setStartMonth(val)}
                                    ariaLabelledBy="select-label-startmonth">
                                    {startMonths.map(month => <MotifOption key={month.id} value={month.id.toString()}>{month.month}</MotifOption>)}
                                </MotifSelect>
                            </MotifFormField>
                        </div>
                        <div className="col-2">
                            <MotifFormField>
                                <MotifLabel id="select-label-endmonth">{t('bookyear_first_day', {ns: 'admin'})}</MotifLabel>
                                <MotifSelect
                                    key={forceRenderKey}
                                    value={startDay}
                                    onChange={val => setStartDay(val)}
                                    ariaLabelledBy="select-label-endmonth">
                                    {days.map((day, i) => <MotifOption key={i} value={day.toString()}>{day.toString()}</MotifOption>)}
                                </MotifSelect>
                            </MotifFormField>
                        </div>
                    </div> : null}
                <div className='d-flex flex-row d-flex align-items-top'>
                    {endDay ? <h5 className='mb-3'>{`${t('bookyear_current', {ns: 'admin'})}: ${startDay}/${startMonths.filter(month => month.id.toString() == startMonth)[0].month_number} - ${endDay}/${endMonth}`}</h5> : null}
                    {definedBookYear && !editMode && (
                        <MotifIconButton style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                        onClick={() => setEditMode(true)}
                        className={"ms-2"}>
                            <MotifIcon
                            src={imageIcEdit24px}
                            />
                        </MotifIconButton>
                        
                    )}
                </div>

                <div className="d-flex flex-row">
                    {(!definedBookYear || editMode === true) ?
                        <MotifButton className='me-2' onClick={() => submit(editMode === true ? 'PUT' : 'POST')}>{editMode === false ? t('add', {ns: 'general'}) : t('confirm', {ns: 'general'})}</MotifButton>
                        : null}
                    {editMode === true ?
                        <MotifButton variant='warn' onClick={() => setEditMode(!editMode)}>{t('cancel', {ns: 'general'})}</MotifButton>

                        : null}

                </div>

            </div>
        </div>
    )
}

export default BookYear